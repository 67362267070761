<template>
  <base-form
      :loading="loading"
      :show-back="showBack"
      :show-cancel="showCancel"
      :save-text="saveText"
      :update-text="updateText"
      :focus-on-first-input="!$route.params.id"
      layout="vertical"
      class="employee-rates-form"
      grid-classes="grid grid-cols-3 gap-x-3"
      @cancel="$router.push(`/payroll/employees/${rateType}`)"
      @submit="onSubmit"
  >
    <template v-if="!hideEmployeeInfo">
      <div class="col-span-3 md:col-span-1 flex items-center">
        <employee-select
            v-model="model.id"
            :display-pay-column="true"
            :disabled="!!$route.params.id"
            :initial-value="data"
            :url-params="employeeUrlParams"
            rules="required"
            class="flex-1"
            id="employee"
            @on-map-entry="onChangeEmployee"
        />
        <div class="mx-2">
          <base-tooltip
              :content="$t('Specify hourly pay rates and deductions as well as special pay and deductions for each employee.')">
            <IconInfo class="text-gray-500 hover:text-primary-500 cursor-help"/>
          </base-tooltip>
        </div>
      </div>
      <div v-if="employeeDetails.id"
           class="col-span-3">
          <span>
            {{ $t('Union') }}
            <span class="py-1 px-3 bg-gray-200 rounded-md text-sm font-semibold">{{ employeeDetails.union_code }}</span>
          </span>
        <span class="mx-4">
            {{ $t('Craft Code/level') }}
            <span
                class="py-1 px-3 bg-gray-200 rounded-md text-sm font-semibold">
              {{ employeeDetails.craft_code }} / {{ employeeDetails.craft_level }}</span>
          </span>
        <span>
            {{ $t('Pay Frequency') }}
            <span
                class="py-1 px-3 bg-gray-200 rounded-md text-sm font-semibold capitalize">
              {{ employeeDetails.pay_frequency || '' }}
            </span>
          </span>
      </div>
      <div v-if="model.id"
           class="h-1 border-b col-span-3 my-3"
      />
    </template>
    <template v-if="model.id">
      <el-collapse v-model="activeGroups"
                   class="col-span-3"
                   :key="ratesKey"
                   :class="{ '-mt-6 -mx-2': employeeRatesTypes.PayRates && hideEmployeeInfo }"
      >
        <base-collapse-section
            v-if="rateType === employeeRatesTypes.PayRates"
            :title="$t('Employee Hourly Rates')"
            key="reserved_pay_rates"
            name="reserved_pay_rates"
        >
          <EmployeeRatesReserved
              :entries="rateForReservedPayCodes"
              :has-union-rates="hasUnionRates"
              :loading="loading"
              @refresh="ratesKey++"
              ref="employeeReservedRates"
          />
        </base-collapse-section>
        <EmployeeRates
            v-if="rateType === [employeeRatesTypes.DeductionRates, employeeRatesTypes.BenefitRates].includes(rateType) && hideEmployeeInfo"
            :employee-id="model.id"
            :rate-type="rateType"
            :map-rates="mapAllEmployeeRates"
            :has-union-rates="hasUnionRates"
            ref="employeeOtherRates"
            class="mt-4"
        />
        <base-collapse-section
            v-else
            :title="sectionTitle"
            key="other_pay_rates"
            name="other_pay_rates">
          <EmployeeRates
              :employee-id="model.id"
              :rate-type="rateType"
              :map-rates="mapAllEmployeeRates"
              :has-union-rates="hasUnionRates"
              ref="employeeOtherRates"
          />
        </base-collapse-section>
      </el-collapse>
    </template>
  </base-form>
</template>
<script>

  import axios from 'axios'
  import { jobTableOverrides, reservedPayCodeList } from '@/enum/enums'
  import EmployeeRates from '@/modules/payroll/components/employee-rates/EmployeeRates'
  import { employeeRatesTypes, rateTypeFrequencies } from '@/modules/payroll/components/rates/util'
  import EmployeeRatesReserved from '@/modules/payroll/components/employee-rates/EmployeeRatesReserved'
  import Cache from "@/utils/Cache";

  export default {
    components: {
      EmployeeRates,
      EmployeeRatesReserved,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      showCancel: {
        type: Boolean,
        default: true,
      },
      rateType: {
        type: String,
        default: employeeRatesTypes.PayRates,
      },
      hideEmployeeInfo: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        reservedPayCodeList,
        employeeRatesTypes,
        employeeUrlParams: {
          sort: 'code',
          related: 'craftCode,union',
        },
        activeGroups: ['reserved_pay_rates', 'other_pay_rates'],
        loading: false,
        employeeId: this.$route.params?.id,
        employeeDetails: {
          id: '',
          union_code: '',
          craft_code: '',
          craft_level: '',
        },
        model: {},
        employeeRates: [],
        rateForReservedPayCodes: [],
        standardUnionRateFilters: {
          union_id: null,
        },
        hasUnionRates: false,
        ratesKey: 1,
      }
    },
    computed: {
      saveText() {
        if (this.rateType === employeeRatesTypes.PayRates) {
          return this.$t('Create pay rates')
        }
        if (this.rateType === employeeRatesTypes.BenefitRates) {
          return this.$t('Create benefit rates')
        }
        if (this.rateType === employeeRatesTypes.RetirementRates) {
          return this.$t('Create retirement rates')
        }
        if (this.rateType === employeeRatesTypes.FlexibleBenefitRates) {
          return this.$t('Create flexible benefit rates')
        }
        return this.$t('Create deduction rates')
      },
      updateText() {
        if (this.rateType === employeeRatesTypes.PayRates) {
          return this.$t('Update pay rates')
        }
        if (this.rateType === employeeRatesTypes.BenefitRates) {
          return this.$t('Update benefit rates')
        }
        if (this.rateType === employeeRatesTypes.RetirementRates) {
          return this.$t('Update retirement rates')
        }
        if (this.rateType === employeeRatesTypes.FlexibleBenefitRates) {
          return this.$t('Update flexible benefit rates')
        }
        return this.$t('Update deduction rates')
      },
      sectionTitle() {
        if (this.rateType === employeeRatesTypes.PayRates) {
          return this.$t('Pay Rates')
        }
        if (this.rateType === employeeRatesTypes.BenefitRates) {
          return this.$t('Benefit Rates')
        }
        if (this.rateType === employeeRatesTypes.DeductionRates) {
          return this.$t('Deduction Rates')
        }
        if (this.rateType === employeeRatesTypes.RetirementRates) {
          return this.$t('Retirement Rates')
        }
        if (this.rateType === employeeRatesTypes.FlexibleBenefitRates) {
          return this.$t('Flexible Benefit Rates')
        }
        return ''
      },
      reservedPayCodes() {
        return this.$store.getters['globalLists/getReservedPayCodes'](reservedPayCodeList)
      },
      reservedPayCodeIds() {
        return this.reservedPayCodes.map(el => el.id)
      },
    },
    methods: {
      async mapAllEmployeeRates(rates) {
        this.employeeRates = await this.tryMapStdUnionRates(rates)
        this.composeRateForReservedPayCodes()
        return this.baseEmployeeRates()
      },
      async tryMapStdUnionRates(rates) {
        const employeeRates = []
        const employeeRateIds = []

        rates.forEach(rate => {
          rate.attributes['effective_rate'] = rate.attributes?.rate
          const rateToAdd = {
            ...rate.attributes,
            ...rate.relationships,
          }
          employeeRates.push(rateToAdd)
          employeeRateIds.push(rate.id)
        })

        const standardUnionRates = await this.getUnionRates()

        if (!standardUnionRates.length) {
          return employeeRates
        }

        const newStandardUnionRates = []

        this.hasUnionRates = standardUnionRates.length > 0

        standardUnionRates.forEach(unionRate => {

          let employeeRate = employeeRates.find(rate => rate.source_id === unionRate.source_id)

          if (employeeRate) {
            employeeRate.union_rate = unionRate.rate
            employeeRate.is_union_rate = false
          } else {

            const { id, rate, source_id, calculate_by, multiply_by } = unionRate

            const newRate = {
              id: null,
              is_union_rate: true,
              active: true,
              _localId: id,
              union_rate: rate,
              effective_rate: rate,
              rate: 0,
              employee_id: this.data.id,
              frequency: rateTypeFrequencies.EveryPayPeriod,
              limit_on_accumulated_amount: 0,
              period_limit_amount: 0,
              job_can_override_rate: jobTableOverrides.OverrideAlways,
              accumulation_interval: 'annual',
              source_id,
              calculate_by,
              multiply_by,
            }
            newStandardUnionRates.push(newRate)
          }

        })

        return [...employeeRates, ...newStandardUnionRates]
      },
      composeRateForReservedPayCodes() {
        const rates = this.employeeRates.filter(el => this.reservedPayCodeIds.includes(el.source_id))

        const rateCodes = this.reservedPayCodes.map(payCode => {
          const definedRate = rates.find(rate => rate.source_id.toString() === payCode.id.toString()) || {}
          return {
            _code: payCode.code,
            _localId: crypto.randomUUID(),
            employee_id: this.data.id,
            source_id: payCode.id,
            active: true,
            rate: 0,
            pay_code_label: `${payCode.code} (${payCode.description})`,
            job_can_override_rate: jobTableOverrides.OverrideAlways,
            frequency: rateTypeFrequencies.EveryPayPeriod,
            ...definedRate,
            effective_rate: definedRate.rate,
          }
        })
        this.sortRateCodes(rateCodes)
      },
      sortRateCodes(rateCodes) {
        let rates = []

        reservedPayCodeList.forEach((key) => {
          let found = false
          rateCodes = rateCodes.filter((item) => {
            if (!found && item?._code === key) {
              rates.push(item)
              found = true
              return false
            } else {
              return true
            }
          })
        })
        this.rateForReservedPayCodes = rates
      },
      baseEmployeeRates() {
        return this.employeeRates.filter(el => !this.reservedPayCodeIds.includes(el.source_id))
      },
      async onChangeEmployee(employee) {
        const { craftCode, union } = employee.relationships || {}

        const { pay_frequency, union_id, craft_code_id } = employee.attributes || {}
        const { code: craft_code, level: craft_level } = craftCode?.attributes || {}

        this.employeeDetails = {
          id: employee.id,
          union_code: union?.attributes?.code || '',
          craft_code,
          craft_level,
          pay_frequency,
        }

        if (!union_id || !craft_code_id) {
          return
        }

        const sourceTypesMap = {
          [employeeRatesTypes.PayRates]: 'pay-code',
          [employeeRatesTypes.DeductionRates]: 'deduction-code',
          [employeeRatesTypes.BenefitRates]: 'benefit-code',
          [employeeRatesTypes.RetirementRates]: 'retirement-plan',
          [employeeRatesTypes.FlexibleBenefitRates]: 'flexible-benefit-plan',
          default: '',
        }

        const source_type = sourceTypesMap[this.rateType]

        const for_craft_code_and_level = `${craft_code},${craft_level}`

        this.standardUnionRateFilters = {
          union_id,
          craft_code_id,
          source_type,
          active: true,
          for_craft_code_and_level,
        }
      },
      async getUnionRates() {
        try {
          this.loading = true
          if (!this.standardUnionRateFilters.union_id) {
            // Employee has no union, therefore no need to fetch union rates
            return []
          }
          const { data } = await axios.get('/restify/union-rates', {
            params: this.standardUnionRateFilters,
          })

          return data.map(entry => entry.attributes)

        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },

      async onSubmit() {
        try {
          this.loading = true
          await this.runEntitiesOperations()
          this.$success(this.$t('Employee rates updated successfully'))
          if (!this.employeeId) {
            await this.$router.push(`/payroll/${this.rateType}/${this.model.id}/edit`)
          }
          Cache.removeForEntity('employee')
        } catch (err) {
          if (err.handled) {
            return
          }
          console.warn(err)
          this.$error(this.$t('Could not update employee pay rates.'))
        } finally {
          this.loading = false
        }
      },
      async runEntitiesOperations() {
        if (this.rateType === employeeRatesTypes.PayRates) {
          await this.$refs.employeeReservedRates.storeProgress(this.model.id)
        }

        await this.$refs.employeeOtherRates.storeProgress(this.model.id)
        await this.$store.dispatch('payroll/getEmployee', this.model.id)
      },
    },
    watch: {
      data: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.onChangeEmployee(value)

          this.model = {
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
<style lang="scss">
  .employee-rates-form {
    .min-h-20 {
      min-height: 5rem;
    }

    .override-select .el-select, .rate input {
      min-width: 120px;
    }
  }
</style>
