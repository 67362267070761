<template>
  <div>
    <div class="mx-auto md:flex md:items-start md:justify-between md:space-x-5 px-4 mb-8">
      <div class="flex items-center space-x-5">
        <div>
          <h2>{{ $t('Payment Batch ') }} {{ journalName }}</h2>
          <div class="text-sm text-gray-700 mb-2">{{ $t('Posted on ') }} {{ $formatDate(paymentDate, '', true) }}</div>
          <BatchStats :batch="paymentsMeta"></BatchStats>
        </div>
      </div>
      <div class="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-end sm:space-x-3">
        <base-button
            v-if="false"
            :disabled="paymentsMeta.direct_deposit_count === 0"
            variant="white"
            @click="$error('Coming soon...')"
        >
          <MailIcon class="w-5 h-5 mr-2"></MailIcon>
          <span>{{ $t('Email Direct Deposit(s)') }}</span>
        </base-button>

        <base-button
            v-if="false"
            variant="primary"
            :disabled="paymentsMeta.payments_count === 0"
            @click="$error('Coming soon...')"
        >
          <MailIcon class="w-5 h-5 mr-2"></MailIcon>
          <span>{{ $t('Email Check(s)') }}</span>
        </base-button>
        <StimulsoftPrintButton
          :data="printData"
          :entity="StimulsoftPrintEntities.APPaymentBatch"
          :updateRouteOnPrint="false"
        />
        <base-button :disabled="paymentsMeta.payments_count === 0"
                     variant="white"
                     class="ml-2"
                     @click="showPositiveVerificationDialog = true"
        >
          <EyeIcon class="w-5 h-5 mr-2"/>
          <span>{{ $t('Positive Verification') }}</span>
        </base-button>

        <SendBulkEmailForm
          :data="payments"
          entity="invoice-payment"
          contact-entity="vendor"
          action-url="/restify/invoice-payments/action"
          action-name="send-invoice-payments-mail"
        />

        <DownloadAPChecksDialog
          ref="downloadChecksDialog"
          :journal="journal"
          :journal-name="journalName"
        />

        <PreviewChecksButton
          :disabled="paymentsMeta.payments_count === 0 || journalId === undefined"
          :download-function="downloadChecks"
        >
          <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
          <span>{{ $t('Print Checks (Old)') }}</span>
        </PreviewChecksButton>
      </div>
    </div>

    <div class="px-4 mb-8">
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">

        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-4 sm:px-6 sm:py-5">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ $t('Total Discount Amount') }}
            </dt>
            <dd class="mt-1 text-2xl font-semibold text-gray-900">
              {{ $formatPrice(paymentsMeta.total_discount_amount) }}
            </dd>
          </div>
        </div>

        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-4 sm:px-6 sm:py-5">
            <dt class="text-sm font-medium text-gray-500 truncate">
              {{ $t('Total Amount') }}
            </dt>
            <dd class="mt-1 text-2xl font-semibold text-gray-900">
              {{ $formatPrice(paymentsMeta.total_net_amount) }}
            </dd>
          </div>
        </div>
      </dl>
    </div>
    <PaymentsTable
      :url-params="paymentsTableUrlParams"
      :has-filters="false"
      :can-void="true"
      :enable-select-actions="true"
      :title="$t('Payments')"
      class="px-4"
      @payments-meta="onPaymentsMeta"
      @data-fetch="payments = $event"
    />
    <PositiveVerificationDialog
        v-if="showPositiveVerificationDialog"
        :open.sync="showPositiveVerificationDialog"
        :action="positivePayAction"
        :payload="positivePayPayload"
    />
    <base-back-link to="/accounts-payable/payments/batches"/>
  </div>
</template>
<script>
  import PaymentsTable from "@/modules/accounts-payable/pages/payments/PaymentsTable";
  import BatchStats from "@/modules/accounts-payable/components/payment/BatchStats";
  import { EyeIcon, MailIcon, PrinterIcon } from 'vue-feather-icons'
  import bus from "@/event-bus/EventBus";
  import PositiveVerificationDialog from "@/modules/accounts-payable/components/payment/PositiveVerificationDialog";
  import DownloadAPChecksDialog from "@/modules/accounts-payable/components/payment/DownloadAPChecksDialog.vue";
  import axios from "axios";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton.vue";
  import SendBulkEmailForm from "@/components/form/SendBulkEmailForm.vue";
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

  export default {
    components: {
      SendBulkEmailForm,
      PreviewChecksButton,
      DownloadAPChecksDialog,
      PaymentsTable,
      BatchStats,
      MailIcon,
      EyeIcon,
      PrinterIcon,
      PositiveVerificationDialog,
    },
    data() {
      return {
        StimulsoftPrintEntities,
        showEmailChecksDialog: false,
        showPositiveVerificationDialog: false,
        payments: [],
        paymentsMeta: {
          total_net_amount: 0,
          total_retention_amount: 0,
          total_discount_amount: 0,
        }
      }
    },
    computed: {
      paymentsTableUrlParams() {
        return !this.$route.params.journal ? {} : {
          journal_id: this.$route.params.journal,
          related: 'invoices,journal,vendor.contacts',
          sort: 'number',
        }
      },
      firstPayment() {
        return this.get(this.payments, '[0].attributes')
      },
      journal() {
        return this.get(this.payments, '[0].relationships.journal.attributes')
      },
      journalName() {
        if (!this.journal) {
          return ''
        }
        return this.journal?.name || `${this.journal?.code}-${this.journal?.number}`
      },
      paymentDate() {
        return this.firstPayment?.date
      },
      journalId() {
        return this.journal?.id
      },
      positivePayAction() {
        return `/restify/invoice-payments/action?action=generate-positive-pay`
      },
      positivePayPayload() {
        return {
          repositories: this.payments.map(p => p.id)
        }
      },
      printData() {
        return {
          batch: {
            number: this.journalName,
          },
          payments: this.payments.map(payment => {
            const vendor = payment.relationships?.vendor
            const sent_count = payment.attributes?.telemetry?.send || 0
            const download_count = payment.attributes?.telemetry?.download || 0
            const bank_initials = this.getBankInitials(payment.attributes?.bank_id)

            const invoices = (payment.relationships?.invoices || [])
              .map(invoice => {
                return {
                  ...invoice.attributes,
                  pivots_gross_amount: invoice.pivots?.gross_amount,
                  pivots_discount_amount: invoice.pivots?.discount_amount,
                  pivots_net_amount: invoice.pivots?.net_amount,
                }
              })

            return {
              ...payment.attributes,
              vendor_code: vendor?.attributes?.code,
              vendor_name: vendor?.attributes?.name,
              bank_initials,
              sent_count,
              download_count,
              invoices,
            }
          })
        }
      }
    },
    methods: {
      async downloadChecks(model = {}) {
        const url = '/restify/invoice-payments/action?action=print-invoice-payment-batches'
        const data = {
          batches: [this.journalId],
          ...model,
        }
        const params = { responseType: 'blob' }
        return await axios.post(url, data, params)
      },
      onPaymentsMeta(meta) {
        this.paymentsMeta = meta
      },
      sendDirectDeposits() {
        // TODO integrate api call
      },
      triggerPrintDialog() {
        const shouldPrint = this.$route.query.print
        if (!shouldPrint) {
          return
        }
        this.$refs.downloadChecksDialog?.openDialog()
      },
      checkForPrint() {
        bus.$on('print-ready', () => {
          setTimeout(this.triggerPrintDialog, 300)
        })
      },
      getBankInitials(bankId) {
        return this.$store.state.company.banks.find(a => a.id === bankId)?.initials || ''
      }
    },
    mounted() {
      this.checkForPrint()
    },
    watch: {
      '$route.query.print': {
        immediate: true,
        handler(shouldPrint) {
          if (!shouldPrint) {
            return
          }
          setTimeout(this.triggerPrintDialog, 300)
        }
      }
    }
  }
</script>
