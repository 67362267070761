<template>
  <div class="h-full">
    <span class="tip">
      {{$t('To invite new users to your organization, consider using the ')}}
      <router-link to="/settings/company-users">{{$t('Company Users')}}</router-link>
      {{ $t('page.')}}
    </span>
    <AgDataTable
        key="active-users"
        ref="usersTable"
        url="/restify/users"
        :suppressCellFocus="true"
        :columns="userColumns"
        :actions="actions"
        :actions-column-width="140"
        :url-params="urlParams"
        :deleteTitle="$t('Remove From Organization')"
        :deleteDescription="userDeleteMessage"
        :delete-action-text="$t('Remove')"
        :delete-action="removeUserFromOrganization"
        default-sort="name"
    >

      <template #attributes.current_company_id="{row}">
        <CompanyLink :company="get(row, 'relationships.currentCompany')"/>
      </template>
      <template #companies="{row}">
        <div class="py-1">
          <CompanyLink v-for="company in row.relationships.currentOrganizationCompanies || []"
                       :key="company.id"
                       :company="company"/>
        </div>
      </template>

      <template #relationships.employee="{row}">
        <status-badge :status="get(row, 'relationships.employee') !== null ? $t('Yes') : $t('No')"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import axios from "axios";
  import CompanyLink from "@/components/links/CompanyLink.vue";

  export default {
    components: {
      CompanyLink,
    },
    computed: {
      urlParams() {
        return {
          related: 'employee,currentCompany,currentOrganizationCompanies',
        }
      },
      userDeleteMessage() {
        return this.$t('After removing from the organization, the user will no longer have access to this organization. However, we will keep all the data and records associated with this user. You can invite the user again at a later point.')
      },
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
      actions() {
        let baseActions = ['search','refresh','view']
        if (this.$can('users_update')) {
          baseActions.push('edit')
          baseActions.push('delete')
        }
        return baseActions.join(',')
      },
      userColumns() {
        return [
          {
            headerName: this.$t('Name'),
            field: 'attributes.name',
            minWidth: 200,
            component: 'EntityLink',
            redirectTo: '/settings/users/{ID}/view',
          },
          {
            headerName: this.$t('Email'),
            field: 'attributes.email',
            minWidth: 250,
            maxWidth: 280,
            component: 'EmailLink'
          },
          {
            headerName: this.$t('Phone'),
            field: 'attributes.phone',
            component: 'PhoneLink'
          },
          {
            headerName: this.$t('Default Company'),
            field: 'attributes.current_company_id',
            minWidth: 150,
            maxWidth: 230,
          },
          {
            headerName: this.$t('Companies'),
            field: 'companies',
            minWidth: 150,
            maxWidth: 230,
            wrapText: true,
            autoHeight: true,
          },
          {
            headerName: this.$t('Employee'),
            field: 'relationships.employee',
            minWidth: 150,
            maxWidth: 230,
          }
        ]
      },
    },
    methods: {
      async removeUserFromOrganization(row) {
        try {
          await axios.post(`/restify/users/${row.id}/actions?action=remove-user-from-organization`, {
            organization_id: this.$currentOrganization.id
          })
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not remove user from the organization.'))
        }
        this.$refs.usersTable.refresh()
      },
    },
  }
</script>
