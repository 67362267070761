const Settings = [

  "employee_consent_required",
  "show_reports_code",
  "print_report_orientation",
  "shade_reports",
  "shading",

  "charge_out_method",
  "cogs_account",
  "cogs_subaccount",
  "default_address_id",
  "inventory_account",
  "inventory_subaccount",
  "job_cost_for_material",
  "last_fiscal_year_posted",
  "last_inventory_journal_posted",
  "last_period_posted",
  "last_purchase_journal_posted",
  "last_purchase_order_printed",
  "post_accrued_sales_tax_equipment_cost_type_id",
  "post_accrued_sales_tax_work_order_cost_type_id",
  "post_sales_tax_to_cost_code",
  "post_sales_tax_to_job_phase",
  "purchase_order_status",
  "purchase_order_template_id",
  "sales_tax_percent",
  "ship_purchase_order_to_multiple_locations",
  "should_adjust_by",
  "should_filter_closed_purchase_orders",
  "should_reduce_for_lump_sum_jobs",
  "should_reduce_for_lump_sum_non_jobs",
  "should_reduce_for_service_billing_non_history",
  "unit_rate_source",


  "calculated_depreciation_account",
  "calculated_depreciation_subaccount",
  "default_address_id",
  "disposal_account",
  "disposal_subaccount",
  "economic_cost_factor",
  "last_fiscal_year_posted",
  "last_journal_posted",
  "last_period_posted",
  "unallocated_expense_factor",


  "city",
  "cost_type_id",
  "default_service_code",
  "default_status",
  "equipment_utilization_credit_account",
  "equipment_utilization_credit_subaccount",
  "frequently_used_service_codes",
  "gen_liability_rate_code",
  "hours",
  "income_type_id",
  "last_journal_number",
  "last_period_posted",
  "last_recurring_invoice_number",
  "last_work_order_number",
  "last_year_posted",
  "invoice_prefix_letter",
  "state",
  "workers_comp_rate_code",

  "account",
  "apply_tax_to_billings",
  "calendar_year",
  "customer_cash_on_deposit_account",
  "customer_cash_on_deposit_subaccount",
  "default_bank_id",
  "default_cpb_status",
  "default_lmp_status",
  "default_prg_status",
  "default_unt_status",
  "discounts_given_account",
  "discounts_given_subaccount",
  "invoice_terms",
  "joint_payments_clear_account",
  "joint_payments_clear_subaccount",
  "last_fiscal_period_posted",
  "last_fiscal_year_posted",
  "late_charges_account",
  "late_charges_phrase",
  "late_charges_subaccount",
  "sales_tax_district_id",
  "sales_tax_payable_account",
  "sales_tax_payable_subaccount",
  "sales_tax_state",
  "subaccount",

  "default_bank_id",
  "federal_employer_id",
  "calendar_year",
  "last_calendar_year",
  "last_payroll_journal_number",
  "last_fiscal_year_posted",
  "last_fiscal_period_posted",
  "payroll_period_start_day",
  "workers_comp_mod_percent",
  "employer_benefits_job_expense_account",
  "employer_benefits_job_expense_subaccount",
  "employer_benefits_sb_expense_account",
  "employer_benefits_sb_expense_subaccount",
  "employer_benefits_eqp_expense_account",
  "employer_benefits_eqp_expense_subaccount",
  "employer_benefits_ga_expense_account",
  "employer_benefits_ga_expense_subaccount",
  "fit_liability_account",
  "fit_liability_subaccount",
  "fica_liability_account",
  "fica_liability_subaccount",
  "fica_job_expense_account",
  "fica_job_expense_subaccount",
  "fica_sb_expense_account",
  "fica_sb_expense_subaccount",
  "fica_eqp_expense_account",
  "fica_eqp_expense_subaccount",
  "fica_ga_expense_account",
  "fica_ga_expense_subaccount",
  "fui_liability_account",
  "fui_liability_subaccount",
  "fui_job_expense_account",
  "fui_job_expense_subaccount",
  "fui_sb_expense_account",
  "fui_sb_expense_subaccount",
  "fui_eqp_expense_account",
  "fui_eqp_expense_subaccount",
  "fui_ga_expense_account",
  "fui_ga_expense_subaccount",
  "sit_liability_account",
  "sit_liability_subaccount",
  "sdi_liability_account",
  "sdi_liability_subaccount",
  "medicare_liability_account",
  "medicare_liability_subaccount",
  "medicare_job_expense_account",
  "medicare_job_expense_subaccount",
  "medicare_sb_expense_account",
  "medicare_sb_expense_subaccount",
  "medicare_eqp_expense_account",
  "medicare_eqp_expense_subaccount",
  "medicare_ga_expense_account",
  "medicare_ga_expense_subaccount",
  "sui_liability_account",
  "sui_liability_subaccount",
  "sui_job_expense_account",
  "sui_job_expense_subaccount",
  "sui_sb_expense_account",
  "sui_sb_expense_subaccount",
  "sui_eqp_expense_account",
  "sui_eqp_expense_subaccount",
  "sui_ga_expense_account",
  "sui_ga_expense_subaccount",
  "lit_liability_account",
  "lit_liability_subaccount",
  "lit_job_expense_account",
  "lit_job_expense_subaccount",
  "lit_sb_expense_account",
  "lit_sb_expense_subaccount",
  "lit_eqp_expense_account",
  "lit_eqp_expense_subaccount",
  "lit_ga_expense_account",
  "lit_ga_expense_subaccount",
  "workers_comp_liability_account",
  "workers_comp_liability_subaccount",
  "workers_comp_job_expense_account",
  "workers_comp_job_expense_subaccount",
  "workers_comp_sb_expense_account",
  "workers_comp_sb_expense_subaccount",
  "workers_comp_eqp_expense_account",
  "workers_comp_eqp_expense_subaccount",
  "workers_comp_ga_expense_account",
  "workers_comp_ga_expense_subaccount",
  "gen_liability_account",
  "gen_liability_subaccount",
  "gen_liability_job_expense_account",
  "gen_liability_job_expense_subaccount",
  "gen_liability_sb_expense_account",
  "gen_liability_sb_expense_subaccount",
  "gen_liability_eqp_expense_account",
  "gen_liability_eqp_expense_subaccount",
  "gen_liability_ga_expense_account",
  "gen_liability_ga_expense_subaccount",
  "is_burden_charged_to_specified_phase_and_cost_code",
  "estimated_burden_job_phase",
  "estimated_burden_job_cost_code",
  "only_post_estimated_burden_job",
  "estimated_burden_job_cost_type",
  "estimated_burden_job_liability_account",
  "estimated_burden_job_liability_subaccount",
  "estimated_burden_job_expense_account",
  "estimated_burden_job_expense_subaccount",
  "percent_of_estimated_burden_job_to_post",
  "dollars_per_hour_of_estimated_burden_job_to_post",
  "only_post_estimated_burden_eqp",
  "estimated_burden_eqp_cost_type",
  "estimated_burden_eqp_liability_account",
  "estimated_burden_eqp_liability_subaccount",
  "estimated_burden_eqp_expense_account",
  "estimated_burden_eqp_expense_subaccount",
  "percent_of_estimated_burden_eqp_to_post",
  "dollars_per_hour_of_estimated_burden_eqp_to_post",
  "only_post_estimated_burden_sb",
  "estimated_burden_sb_cost_type",
  "estimated_burden_sb_liability_account",
  "estimated_burden_sb_liability_subaccount",
  "estimated_burden_sb_expense_account",
  "estimated_burden_sb_expense_subaccount",
  "percent_of_estimated_burden_sb_to_post",
  "dollars_per_hour_of_estimated_burden_sb_to_post",
  "calculate_rate_using_salary",
  "calculate_hours_using_salary",
  "distribute_benefit_hours",
  "is_8_hour_max_certified_job",
  "is_biweekly_payroll",
  "is_fringe_build",
  "is_ssn_masked",
  "paycode_for_fringe_build",
  "weighted_average_overtime",
  "LCPtracker",

  "default_bank_id",
  "account",
  "subaccount",
  "discounts_taken_account",
  "discounts_taken_subaccount",
  "last_fiscal_year_posted",
  "last_fiscal_period_posted",
  "last_ap_journal_number_posted",
  "last_cd_journal_number_posted",
  "last_direct_deposit_check_no",
  "calendar_year",
  "default_invoice_status",
  "close_po_after_posting_invoice",
  "post_discounts_to_accounts",
  "force_discounts",

  "code_edit_option",
  "master_job_id",
  "progress_billing_exceeds_contract_value",
  "retention_payable_account",
  "retention_payable_subaccount",
  "retention_receivable_account",
  "retention_receivable_subaccount",
  "round_retention_to",
  "calendar_year_of_period_1",
  "current_bank_record_date",
  "current_bank_statement_balance",
  "current_fiscal_year",
  "current_fiscal_year_has_balances_transferred",
  "date_current_fiscal_year_was_openend",
  "last_fiscal_period_posted",
  "last_fiscal_year_with_balances_transferred",
  "last_fiscal_year_posted",
  "last_journal_number_posted",
  "prior_fiscal_year_status",
  "retained_earnings_account",
  "retained_earnings_subaccount"
] as const

export type SettingKey = typeof Settings[number]

export const ContactTypes = {
  Billing: 'billing',
  Payment: 'payment',
  Emergency: 'emergency',
  Normal: 'normal',
} as const

export const ContactTypeLabels: Record<string, string> = {
  [ContactTypes.Billing]: 'Billing',
  [ContactTypes.Payment]: 'Payment',
  [ContactTypes.Emergency]: 'Emergency',
  [ContactTypes.Normal]: 'Normal',
}

export type ContactType = typeof ContactTypes[keyof typeof ContactTypes]
