const entities = {
    'vendor': 'VendorPreview',
    'job': 'JobPreview',
    'customer': 'CustomerPreview',
    'employee': 'EmployeePreview',
    'billing': 'BillingPreview',
    'invoice': 'BillingPreview',
    'worksite': 'WorksitePreview',
    'equipment': 'EquipmentPreview',
    'workOrder': 'WorkOrderPreview',
    'material': 'MaterialPreview',
    default: 'EmptyPreview',
}

export const entityPreviewFields = {
  Customer: 'customer[id|code|name|contact|current_ar_amount|sales_activity_to_date|retention_amount|phone|email]',
  SalesPerson: 'salesPerson[id|code|name|phone_1|email]',
  Job: 'job[id|number|description|status|certified_payroll|owner_name|subcontract_retention_percent|billing_retention_percent|units]',
  JobCategory: 'category[id|code|description]',
  Material: 'material[id|code|description|last_invoice_unit_rate|last_po_unit_rate|standard_unit_rate|um]',
  Equipment: 'equipment[id|code|description|year_and_model|license_no|date_acquired|acquisition_cost|depreciation_amount|repairs_and_maintenance_to_date]',
  Vendor: 'vendor[id|code|name|phone|email|contact|last_payment_date|current_ap_amount|payment_to_date|website_url]',
  WithholdingState: 'withholdingState[id|code|description]',
  WithholdingLocal: 'withholdingLocal[id|code|description]',
  Worksite: 'workSite[id|number|name|worksite_type|contact_name]',
  Source: 'source[id|number|description]',
  Employee: 'employee[id|code|name|hire_date|payroll_consent_sent_at|payroll_consent_confirmed_at]',
  GenLiabilityRate: 'genLiabilityRate[id|code|description]',
  WorkersCompRate: 'workersCompRate[id|code|description]',
  WorkOrder: 'workOrder[id|number|description]',
  LineItemSibling: 'sibling[id|amount|amount_to_date|type]',
  PurchaseOrder: 'purchaseOrder[id|number|description|date]',
}

export function getComponent(compName) {
    return entities[compName] || entities.default
}
