<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="rows"
    :filters="filters"
    :total-rows="rowsLength"
    :has-summary="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>
    <template #header="{row}">
      <div class="flex">
        <AccountLink
          :data="row.header"
          class="w-1/4"
        />
        <div class="flex flex-1 justify-between">
          <span>{{ $t('Begin Balance for Period:') }} {{ options.period_start }}</span>
          <span>
            {{ $formatPrice(row.header?.beginning_balance) }}
          </span>
        </div>
      </div>
    </template>

    <template #thead-infos>
      <th colspan="4"/>
      <th colspan="2" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Reference') }}
        </span>
      </th>
      <th colspan="3"/>
      <th colspan="3" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Periods Amount') }}
        </span>
      </th>
      <th/>
    </template>

    <template #business="{row}">
      <component
        v-if="getRelatedBusinessLink(row)"
        :is="getRelatedBusinessLink(row)"
        :data="row?.business"
        :show-preview="false"
        :show-name="false"
        :show-description="false"
      />
      <span v-else/>
    </template>

    <template #job.number="{row}">
      <JobLink
      :data="row.job"
      :show-description="false"
      :show-preview="false"
      />
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="9" align="right">
        {{
          subtotal.isRangeTotal
            ? $t(`Account Total for Period Range ${ options.period_start } thru ${ options.period_end }:`)
            : $t(`Account Total for Period ${ subtotal.period }:`)
        }}
      </td>
      <td align="right">
        {{ subtotal.debit_amount ? $formatPrice(subtotal.debit_amount) : '' }}
      </td>
      <td align="right">
        {{ subtotal.credit_amount ? $formatPrice(subtotal.credit_amount) : '' }}
      </td>
      <td align="right">
        {{ subtotal.net_amount ? $formatPrice(subtotal.net_amount) : '' }}
      </td>
      <td align="right">
        {{ subtotal.balance ? $formatPrice(subtotal.debit_amount - subtotal.credit_amount) : '' }}
      </td>
    </template>

    <template #html-row="{htmlData}">
      <tr>
        <td colspan="13">&nbsp;</td>
      </tr>
      <tr class="top-border summary-row">
        <td colspan="9" align="right">
          {{ $t(`Grand Totals For Selected Accounts For Period Range ${ options.period_start } thru ${ options.period_end }:`) }}
        </td>
        <td align="right">
          {{ $formatPrice(grandTotals.debit_amount) }}
        </td>
        <td align="right">
          {{$formatPrice(grandTotals.credit_amount) }}
        </td>
        <td align="right">
          {{ $formatPrice(grandTotals.net_amount) }}
        </td>
        <td/>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Column } from '@/components/ag-grid/tableTypes'
import { getRelatedBusinessLink } from '@/components/links/util'
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

export default defineComponent({
  extends: ReportTableWrapper,
  props: {
    filters: {
      type: Object as PropType<GL_REPORTS.GeneralLedger>,
      default: () => ({}),
    }
  },
  data() {
    return {
      columns: <Column[]>[
        {
          label: this.$t('Account'),
          prop: 'account',
          minWidth: 60,
          maxWidth: 80,
          hiddenValue: true,
        },
        {
          label: this.$t('Sub Account'),
          prop: 'subaccount.number',
          minWidth: 100,
          maxWidth: 320,
        },
        {
          label: this.$t('Journal'),
          prop: 'journal.name',
          minWidth: 60,
          maxWidth: 100,
        },
        {
          label: this.$t('Transaction Description'),
          prop: 'transaction.description',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          label: this.$t('NO.'),
          prop: 'transaction.reference_no',
          align: 'left',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          label: this.$t('Date'),
          prop: 'transaction.reference_date',
          align: 'right',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          label: this.$t('Vendor <br> Customer'),
          prop: 'business',
          align: 'center',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          label: this.$t('Job'),
          prop: 'job.number',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          label: this.$t('Type'),
          prop: 'transaction.transaction_type',
          minWidth: 100,
          maxWidth: 200,
        },
        {
          label: this.$t('Debit'),
          prop: 'transaction.debit_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
        },
        {
          label: this.$t('Credit'),
          prop: 'transaction.credit_amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
        },
        {
          label: this.$t('Net Change'),
          prop: 'transaction.amount',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
        },
        {
          label: this.$t('Balance'),
          prop: 'transaction.balance',
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 100,
          maxWidth: 200,
          hideZero: true,
        },
      ],
    }
  },
  methods: {
    getRelatedBusinessLink,
    composeRows(data: Record<string, any>) {

      data.forEach((group: Record<string, any>) => {

        const {account: header, totals, transactions} = group

        this.rows.push({
          header,
        })

        const transactionList = Object.values(transactions)
        if (transactionList.length === 0 && this.filters.period_totals) {
          for (let key in totals.period) {
            this.rows.push({
              subtotal: totals.period[key],
            })
          }
        }
        transactionList.forEach((transactionObject, index) => {
          // @ts-ignore
          this.rows.push(...transactionObject)

          const totalsMap = Object.values(totals.period)
          this.rows.push({
            subtotal: totalsMap[index],
          })
        })

        this.rows.push({
          subtotal: {
            ...totals.account,
            isRangeTotal: true,
          },
        })
      })
      this.rows.push({
        htmlData: this.grandTotals
      })
    },
  },
})
</script>
<style lang="scss">
.account-summaries {
  > td {
    border: none;
  }
}
</style>
