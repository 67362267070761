<template>
  <div class="min-h-[55px] flex flex-col truncate">
    <div class="flex items-center truncate text-gray-800">
      <base-tooltip
        v-if="costCenterIcon"
        :content="iconTooltip">
        <component
          :is="costCenterIcon"
          class="min-w-[16px] h-4 mr-2"
        />
      </base-tooltip>
      <div
        class="min-w-[20px] h-5 mr-2"
        v-if="isLoading"
        v-loading="true"
        element-loading-background="rgba(0, 0, 0, 0)"
      />
      <template v-if="task.name">
        <span class="whitespace-pre text-gray-800 leading-4 truncate">
          <template v-if="prefix">
            {{ prefix }}
          </template>{{ task.name }}
        </span>
      </template>
      <template v-else>
        <AddlSourceLink
          v-if="task?.addl_source_id"
          :id="task?.addl_source_id"
          :data="task"
          link-class="text-gray-800"
        />

        <SourceLink
          v-if="task?.source_id && !task?.addl_source_id"
          :data="task"
          link-class="text-gray-800"

        />
      </template>
      <div v-if="!task?.source_id && !task?.addl_source_id">
        {{ $t('General & Administrative') }}
      </div>
    </div>
    <div class="flex-1 flex w-full justify-end items-end pb-1">
      <div class="flex justify-center space-x-1">
        <base-tooltip
          v-if="task.employee_ids?.length > 1"
          :content="$t('This task has multiple assigned employees. Modifying this task will affect all assigned employees.')"
        >
          <UsersIcon class="w-4 h-4 mr-1"/>
        </base-tooltip>
        <base-tooltip v-if="durationInHours">
          <template #content>
            <template v-if="loggedDuration > eventDuration">
              <div>{{$t('The logged time is greater than the scheduled time')}}</div>
              <div class="mt-1">{{$t('Logged Time')}}: {{loggedDurationInHours}}</div>
              <div class="mt-1">{{$t('Scheduled Time')}}: {{durationInHours}}</div>
            </template>
          </template>
          <span
            :class="{
              'text-red-600': loggedDuration > eventDuration
            }"
            class="truncate text-xs text-gray-500"
          >
          <template v-if="loggedDuration">
            {{ loggedDurationInHours }} /
          </template>
          {{ durationInHours }}
          </span>
        </base-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import SourceLink from "@/components/links/SourceLink.vue";
import AddlSourceLink from "@/components/links/AddlSourceLink.vue";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import EquipmentIcon from "@/modules/payroll/components/timesheets/EquipmentIcon.vue";
import GAIcon from "@/modules/payroll/components/timesheets/GAIcon.vue";
import JobIcon from "@/modules/payroll/components/timesheets/JobIcon.vue";
import WorkOrderIcon from "@/modules/payroll/components/timesheets/WorkOrderIcon.vue";
import { UsersIcon } from 'vue-feather-icons'
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import LunchIcon from "@/modules/payroll/components/timesheets/LunchIcon.vue";
import BreakIcon from "@/modules/payroll/components/timesheets/BreakIcon.vue";
import { getEventDuration } from "@/modules/payroll/components/tasks/taskUtils";

export default {
  components: {
    AddlSourceLink,
    SourceLink,
    UsersIcon,
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    task() {
      const task = this.event.extendedProps || {}
      return {
        ...(task.attributes || {}),
        relationships: task.relationships || {}
      }
    },
    prefix() {
      const cost_center = this.task.cost_center
      const map = {
        [costCenterTypes.GeneralAndAdministrative]: this.$t('G&A'),
        [costCenterTypes.Equipment]: this.$t('EQP'),
        [costCenterTypes.WorkOrder]: this.$t('WO #'),
      }
      return map[cost_center] || ''
    },
    iconTooltip() {
      const cost_center = this.task.cost_center
      const map = {
        [costCenterTypes.GeneralAndAdministrative]: this.$t('General & Administrative'),
        [costCenterTypes.Job]: this.$t('Line Item'),
        [costCenterTypes.Equipment]: this.$t('Equipment'),
        [costCenterTypes.WorkOrder]: this.$t('Work Order'),
      }
      return map[cost_center] || ''
    },
    costCenterIcon() {
      const cost_center = this.task.cost_center
      const map = {
        [costCenterTypes.GeneralAndAdministrative]: GAIcon,
        [costCenterTypes.Job]: JobIcon,
        [costCenterTypes.Equipment]: EquipmentIcon,
        [costCenterTypes.WorkOrder]: WorkOrderIcon,
      }
      if (this.task.is_lunch) {
        return LunchIcon
      }
      if (this.task.is_break) {
        return BreakIcon
      }
      return map[cost_center] || ''
    },
    loggedDuration() {
      return this.task.logged_minutes || 0
    },
    loggedDurationInHours() {
      return minutesToHours(this.loggedDuration)
    },
    eventDuration() {
      return getEventDuration(this.event)
    },
    durationInHours() {
      return minutesToHours(this.eventDuration)
    },
    isLoading() {
      return !this.event.id
    }
  },
}
</script>
