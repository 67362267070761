<template>
  <RatesStartDates
    :id="union.id"
    :url-params.sync="startDateParams"
    :show-add-button="false"
    entity-repo="union-rates"
    class="m-4"
  >
    <UnionAndJobRateEntries
      :data="union"
      :selected-date="startDateParams.start_date"
      :is-union-rate="true"
      :read-only="true"
      api-url="/restify/union-rates"
      ref="entriesTable"
    />
  </RatesStartDates>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import RatesStartDates from '@/modules/payroll/components/rates/RatesStartDates.vue'
import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries.vue'

export default defineComponent({
  name: 'UnionRatesTable',
  inheritAttrs: false,
  components: {
    RatesStartDates,
    UnionAndJobRateEntries,
  },
  props: {
    union: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      startDateParams: {} as any,
    }
  },
})
</script>
