<template>
  <base-form
      :loading="loading"
      :can-create-another-entity="!model.id"
      :show-cancel="true"
      :show-back="true"
      :save-text="saveText"
      :update-text="$t('Update work order')"
      submit-button-type="button"
      :layout="layout"
      grid-classes="grid grid-cols-12 gap-x-4"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <taggable-input
        v-model="model.number"
        :label="$t('W.O. Number')"
        :placeholder="numberPlaceholder"
        :default-state="isLocked"
        :disabled="!!model.id"
        :rules="numberInputRules"
        id="number"
        class="col-span-12 lg:col-span-2"
        @blur.passive="validateNumber"
        @on-update-state="state => model.series = state"
    />
    <div  class="col-span-12 lg:col-span-3 flex items-center">
      <WorkSiteSelect
        v-model="model.work_site_id"
        :edit-entity="true"
        :initial-value="get(data, 'relationships.workSite')"
        :url-params="workSiteUrlParams"
        rules="required"
        class="flex-1 mr-2"
        @entity-change="onWorkSiteChange"
      />
      <WorkSiteFilters @on-select-worksite="onWorkSiteChange"/>
    </div>
    <customer-select
        v-model="model.customer_id"
        :initial-value="selectedCustomer || get(data, 'relationships.customer')"
        :show-open-amount="true"
        class="col-span-12 lg:col-span-3"
        rules="required"
        @entity-change="onCustomerChange"
    />

    <employee-select-new
        v-model="model.assigned_employee_id"
        :label="$t('Assigned To')"
        :initial-value="get(data, 'relationships.assignedEmployee')"
        class="col-span-12 lg:col-span-2"
    />

    <base-select
        v-model="model.status"
        :options="statusOptions"
        :label="$t('Status')"
        :placeholder="$t('Status')"
        class="col-span-12 lg:col-span-1"
        rules="required"
    />
    <div class="col-span-12 lg:col-span-1"/>
    <base-date-picker
        v-model="model.date"
        :label="$t('Date')"
        :placeholder="$t('Date')"
        type="datetime"
        class="col-span-12 lg:col-span-2"
        rules="required"
    />
    <base-input
        v-model="model.call_taken_by"
        :label="$t('Call Taken By')"
        :placeholder="$t('Call Taken By')"
        :tip="$t('Enter the name of the person entering this work order.')"
        class="col-span-12 lg:col-span-2"
        rules="max:50"
    />
    <billing-rate-type-select
        v-model="model.billing_rate_type_id"
        :label="$t('Billing Rate')"
        :add-entity="false"
        :autocomplete="false"
        class="col-span-12 lg:col-span-2"
        clearable
    />
    <base-input
        v-model="model.call_placed_by"
        :label="$t('Call Placed By')"
        :placeholder="$t('Call Placed By')"
        :tip="$t('Enter the name of the person (customer or tenant) requesting the service work.')"
        class="col-span-12 lg:col-span-2"
        rules="max:50"
    />
    <base-input
        v-model="model.customer_purchase_order"
        :label="$t('Purchase Order')"
        :placeholder="$t('Customer Purchase Order')"
        :tip="$t(`Enter the customer's purchase order number, if applicable.`)"
        class="col-span-12 lg:col-span-2"
        rules="max:20"
    />
    <div class="col-span-6 md:col-span-2">
      <BaseSwitch
        v-model="model.available_in_timesheets"
        :label-info="$t(`Show in Timesheets`)"
      />
    </div>
    <div class="col-span-12"/>
    <base-textarea
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        :rows="3"
        rules="max:150"
        class="col-span-12 lg:col-span-6"
    />
    <div v-if="selectedCustomer?.attributes?.current_ar_amount > 0" class="col-span-12 lg:col-span-6">
      <CustomerAging :customer-code="selectedCustomer?.attributes?.code" :customer-id="selectedCustomer?.id"/>
    </div>
    <el-collapse v-model="activeGroup"
                 class="col-span-12">
      <base-collapse-section
          :title="$t('Additional Information')"
          name="additional-information"
          key="additional-information"
      >
        <div class="grid grid-cols-6 gap-x-4">

          <service-billing-category-select
              v-model="model.category_id"
              class="col-span-6 lg:col-span-1"
          />
          <employee-select-new
              v-model="model.salesperson_employee_id"
              :label="$t('Salesperson')"
              :initial-value="get(data, 'relationships.salespersonEmployee')"
              :placeholder="$t('Select Salesperson')"
              :add-label="$t('Add new Salesperson')"
              class="col-span-6 lg:col-span-1"
          />
          <base-input
              v-model="model.minimum_cost_quoted_amount"
              :label="$t('Quoted Minimum Cost')"
              :min="9"
              :max="9999999.99"
              :step="1"
              rules="min_value:0|max_value:9999999.99"
              class="col-span-6 lg:col-span-1"
              type="number"
              format="price"
          />
          <base-input
              v-model="model.estimated_cost_quoted_amount"
              :label="$t('Quoted Estimated Cost')"
              :min="9"
              :max="9999999.99"
              :step="1"
              rules="min_value:0|max_value:9999999.99"
              class="col-span-6 lg:col-span-1"
              type="number"
              format="price"
          />
          <div class="col-span-full" />
          <SalesTaxDistrictSelect
            v-model="model.district_id"
            :add-entity="true"
            :initial-value="get(data, 'district')"
            class="col-span-6 lg:col-span-1"
            clearable
          />
          <div class="col-span-8 md:col-span-2">
            <base-switch
              v-model="model.exempt_from_sales_tax"
              :label-info="$t('Exempt From Sales Tax')"
              id="exempt_from_sales_tax"
            />
          </div>
        </div>
      </base-collapse-section>
      <base-collapse-section
          :title="$t('Dates')"
          name="dates"
          key="dates"
      >
        <AgDataTable
            :columns="getDatesColumns"
            :data="getDates"
            :action-columns="false"
            :pagination="false"
            :compact="true"
            dom-layout="autoHeight"
            class="mb-4"
            ref="dates"
        />
      </base-collapse-section>
      <base-collapse-section
          :title="$t('Service Codes')"
          name="service-codes"
          key="service-codes"
      >
        <ServiceBillingCodeGrid
            :codes="serviceBillingCodes"
            ref="serviceCode"
            class="mb-4"
        />
      </base-collapse-section>
      <base-collapse-section
          :title="$t('Payroll Settings')"
          name="payroll-settings"
          key="payroll-settings"
      >
        <div class="grid grid-cols-12 gap-x-4">
          <div class="col-span-12 lg:col-span-2">
            <base-checkbox
                v-model="model.is_certified_payroll"
                :label="$t('Certified P/R')"
                :vertical="true"
                id="is_certified_payroll"
            />
          </div>
          <base-select
              v-model="model.compliance_format"
              :options="complianceOptions"
              :label="$t('Compliance Format')"
              class="col-span-12 lg:col-span-2"
          />
          <worker-comp-rate-select
              v-model="model.workers_comp_rate_code"
              class="col-span-12 lg:col-span-2"
          />
          <gen-liability-rate-select
              v-model="model.gen_liability_rate_code"
              class="col-span-12 lg:col-span-2"
          />
          <union-select
              v-model="model.union_id"
              class="col-span-12 lg:col-span-2"
          />
          <div class="col-span-12 lg:col-span-2">
            <base-input v-model="model.wa_intent_id"
                        :tip="$t('Intent ID is required for WA State jobs')"
                        :label="$t(`Intent ID`)"
                        :placeholder="$t(`Intent ID`)"
                        rules="max:10"
                        clearable
            />
          </div>
          <div class="col-span-12"></div>
          <div class="col-span-12 lg:col-span-3">
            <EntityStartingPayPeriod
              v-if="model.id"
              :id="model.id"
              :value="model.starting_pay_period"
              entity="work-orders"
              payload-key="work_orders"
              @change="model.starting_pay_period = $event"
            />
          </div>
        </div>
        <timecard-overrides
            v-model="model"
            :data="data"
            :collapsible="false"
        />
        <div class="grid grid-cols-6 gap-x-4">
          <h5 class="form-section-title col-span-6 mb-4">
            {{ $t('Certified Payroll Settings') }}
          </h5>
          <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.contract_agency"
                        :tip="$t('Contract Agency is required for CA State jobs when using Certified Payroll')"
                        :label="$t(`Contract Agency`)"
                        :placeholder="$t(`Contract Agency`)"
                        rules="max:50"
            />
          </div>
          <div class="col-span-6 md:col-span-2">
            <base-input v-model="model.DLSE_project_id"
                        :tip="$t('DLSE Project ID is required for CA State jobs when using Certified Payroll')"
                        :label="$t(`DLSE Project ID`)"
                        :placeholder="$t(`DLSE Project ID`)"
                        type="number"
                        :min="0"
            />
          </div>
        </div>
      </base-collapse-section>
    </el-collapse>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses, taggableInputStates, workOrderStatuses, workOrderStatusOptions } from '@/enum/enums'
  import TimecardOverrides from '@/components/common/TimecardOverrides'
  import WorkSiteFilters from '@/modules/service-billing/components/WorkSiteFilters'
  import ServiceBillingCodeGrid from '@/modules/service-billing/components/ServiceBillingCodeGrid'
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { dateTypes } from "@/plugins/dateFormatPlugin";
  import { formattedText } from "@/utils/utils";
  import { RestifyResources } from "@/components/form/util";
  import format from "date-fns/format";
  import parseISO from "date-fns/parseISO";
  import CustomerAging from "@/modules/service-billing/components/CustomerAging.vue";
  import EntityStartingPayPeriod from "@/modules/job-costing/components/EntityStartingPayPeriod.vue";

  const DefaultDates = {
    promised: '',
    scheduled: '',
    earliest_arrival: '',
    latest_departure: '',
  }

  const complianceFormats = {
    General: 'general',
    UsDollar: 'us-dol',
    CaliforniaTrans: 'california-trans',
    Template: 'template',
    OldFormats: {
      General: 'normal-compliance',
      UsDollar: 'us-dollar-compliance',
      CaliforniaTrans: 'california-trans-compliance',
      Template: 'compliance-template',
    }
  }

  export default {
    components: {
      EntityStartingPayPeriod,
      CustomerAging,
      WorkSiteFilters,
      TimecardOverrides,
      ServiceBillingCodeGrid,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        taggableInputStates,
        loading: false,
        serviceBillingCodes: [],
        activeGroup: ['service-codes', 'dates', 'additional-information'],
        statusOptions: workOrderStatusOptions,
        complianceOptions: [
          {
            label: this.$t('General'),
            value: complianceFormats.General,
          },
          {
            label: this.$t('U.S. Dollar'),
            value: complianceFormats.UsDollar,
          },
          {
            label: this.$t('California Trans'),
            value: complianceFormats.CaliforniaTrans,
          },
          {
            label: this.$t('Template'),
            value: complianceFormats.Template,
          },
        ],
        model: {
          exempt_from_sales_tax: false,
          withholding_state_id: '',
          withholding_local_id: '',
          withholding_local_2_id: '',
          sui_sdi_state_id: '',
          series: taggableInputStates.Automatic,
          number: null,
          customer_id: this.$route.query.customer_id || '',
          work_site_id: '',
          status: resourceStatuses.Open,
          date: new Date(),
          hour: 0,
          call_taken_by: '',
          billing_rate_type_id: '',
          customer_purchase_order: '',
          call_placed_by: '',
          description: '',
          is_certified_payroll: false,
          compliance_format: complianceFormats.General,
          workers_comp_rate_code: '',
          gen_liability_rate_code: '',
          union_id: '',
          category_id: '',
          assigned_employee_id: '',
          salesperson_employee_id: '',
          minimum_cost_quoted_amount: 0,
          estimated_cost_quoted_amount: 0,
          timetables: [],
          contract_agency: null,
          DLSE_project_id: null,
          available_in_timesheets: true,
          starting_pay_period: null,
        },
        selectedCustomer: null,
        workSiteUrlParams: {
          related: 'customer',
        }
      }
    },
    computed: {
      layout() {
        return this.redirectToListAfterStore ? 'vertical' : 'modal'
      },
      isLocked() {
        return !this.model.id
      },
      numberInputRules() {
        return this.model.series === taggableInputStates.Automatic ? '' : 'required|max:20'
      },
      getDatesColumns() {
        return [
          {
            headerName: this.$t('Date Type'),
            field: 'type',
            cellClass: 'readonly-ag-cell',
            minWidth: 80,
          },
          {
            headerName: this.$t('Date & Time'),
            field: 'value',
            editable: true,
            cellEditor: cellEditors.DatePicker,
            cellEditorParams: {
              type: 'datetime'
            },
            valueFormatter: params => {
              let formattedDate = this.$formatDate(new Date(params.value), dateTypes.DateTimeMinutes)
              formattedDate = formattedDate.substring(0, formattedDate.length - 3)
              return formattedDate
            }
          },
        ]
      },
      getDates() {
        let data = []
        for (let key in DefaultDates) {
          const value = this.model.timetables[key]
          data.push({
            _localId: crypto.randomUUID(),
            key,
            type: formattedText(key),
            value: value || '',
          })
        }
        return data
      },
      numberPlaceholder() {
        if (this.model.series === taggableInputStates.Automatic) {
          return this.$t('Auto assign')
        }

        return this.$t('Enter Number')
      },
      saveText() {
        if (this.model.series === taggableInputStates.Automatic) {
          return this.$t('Create W.O. and assign number')
        }

        return this.$t('Create W.O.')
      }
    },
    methods: {
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/service-billing/work-orders')
        }
        this.$emit('cancel')
      },
      async validateNumber() {
        if (!this.model.number || (this.$route.params.id && !this.data?.attributes?.number)) {
          return
        }
        if (this.model.id && this.model.number === this.data?.attributes?.number) {
          return
        }
        const { data } = await axios.get(`/restify/work-orders?number=${this.model.number}`)

        const workOrderId = this.get(data, '[0].id', '')
        if (!workOrderId) {
          return
        }

        const confirmed = await this.$confirm({
          title: this.$t('Work order already exists'),
          description: this.$t('Work order this number already exists. Do you want to edit it?'),
          buttonText: this.$t('Confirm'),
        })

        if (!confirmed) {
          this.model.number = ''
          this.focusOnNumberInput()
          return
        }
        this.redirectToEditPage(workOrderId)
      },
      redirectToEditPage(id) {
        this.$router.push(`/service-billing/work-orders/${id}/edit`)
      },
      focusOnNumberInput() {
        const numberInput = document.querySelector('#number')
        if (!numberInput) {
          return
        }
        numberInput.focus()
      },
      formatPayload() {
        const { entries } = this.$refs.dates || []

        const timeTables = {}
        entries.forEach(entry => {
          timeTables[entry.key] = entry.value
        })
        const modelCopy = { ...this.model }
        modelCopy.timetables = timeTables
        let date = modelCopy.date
        if (typeof date === 'string') {
          date = parseISO(date)
        }
        modelCopy.date = date?.toUTCString()
        return modelCopy
      },
      onWorkSiteChange(worksite) {
        if (!worksite || !worksite?.attributes) {
          return
        }
        const { customer_id, id } = worksite.attributes

        this.model.customer_id = customer_id
        this.model.work_site_id = id

        const { customer } = worksite?.relationships || {}
        this.onCustomerChange(customer)
      },
      onCustomerChange(customer) {
        if (!customer) {
          return
        }
        this.model.billing_rate_type_id = this.get(customer, 'attributes.billing_rate_type_id', '')
        this.selectedCustomer = customer
      },
      mapComplianceFormat() {
        const format = this.model.compliance_format
        const isOldFormat = Object.values(complianceFormats.OldFormats).includes(format)
        if (!isOldFormat) {
          return
        }
        const formatKey = Object.keys(complianceFormats).find(key => complianceFormats.OldFormats[key] === format)
        this.model.compliance_format = complianceFormats[formatKey]
      },
      async onSubmit() {
        try {
          this.loading = true
          const requestData = this.formatPayload()
          if (this.model.id) {
            this.mapComplianceFormat()
            const { data } = await axios.put(`/restify/work-orders/${this.model.id}`, requestData)
            await this.syncServiceCodes(this.model.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.WorkOrders,
              resourceId: data.id,
              isEdit: true,
            })
            this.$emit('refresh', this.model.id)
            this.$success(this.$t('Work order updated.'))
            this.$emit('save', data)
          } else {
            const { data } = await axios.post('/restify/work-orders/', requestData)
            await this.syncServiceCodes(data.id)
            const { number } = data.attributes
            this.$success(this.$t(`WO #${ number } was created successfully`))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.WorkOrders,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity) {
              return this.$emit('create-another')
            }
            if (this.redirectToListAfterStore) {
              this.$router.push('/service-billing/work-orders')
            }
            this.$emit('save', data)
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not save the work order.'))
        } finally {
          this.loading = false
        }
      },
      async syncServiceCodes(woId) {
        const { getTableData } = this.$refs.serviceCode || {}
        let entries = []
        if (getTableData) {
          entries = getTableData()
        }

        if (!entries || !entries.length) {
          return
        }
        const ids = entries.map(entry => entry.id)
        await axios.post(`/restify/work-orders/${woId}/add-codes`, { ids })
      },
      formatDates() {
        let timeTables = this.model.timetables
        const hasValues = Object.values(timeTables).some(value => value)
        if (hasValues) {
          return
        }
        this.model.timetables = DefaultDates
      },
      async tryAssignWorksite(id) {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/work-sites/${id}`)
          this.onWorkSiteChange(data)
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      mapBillingCodes(workOrder) {
        this.serviceBillingCodes = workOrder.relationships?.serviceBillingCodes || []
      },
      initCreateModel() {
        this.formatDates()
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value.id) {
            return this.initCreateModel()
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.mapBillingCodes(value)
          this.formatDates()
        },
      },
      '$route.query': {
        immediate: true,
        handler(query) {
          const { worksite } = query
          if (!worksite) {
            return
          }
          this.tryAssignWorksite(worksite)
        },
      },
    },
  }
</script>
