<template>
  <div>
    <BaseDataTable :columns="renderedColumns"
                   :is-expandable="true"
                   :add-entity-in-new-tab="true"
                   :add-default-columns="false"
                   :can-manage-columns="false"
                   :data-loading="loading"
                   :url-params="urlParams"
                   url="/restify/payroll-batches"
                   actions="search,refresh"
                   ref="table"
    >
      <template #dropdown-actions v-if="status === resourceStatuses.Posted">
        <TableActionItem>
          <TableActionItem>
            <BaseButton
              variant="primary-link"
              @click="showMyChecksVisibilityDialog = true">
              <EyeOffIcon class="w-4 h-4 mr-2"/>
              {{ $t('Hide batches from My Checks') }}
            </BaseButton>
          </TableActionItem>
        </TableActionItem>
      </template>
      <template v-slot:attributes.period_end_date="{row}">
        <router-link :to="`/payroll/batches/${row.id}`">
          {{ $formatDate(row.attributes.period_end_date) }}
        </router-link>
      </template>
      <template #attributes.journal_id="{row}">
        <router-link
          v-if="row.attributes.journal_id"
          :to="`/payroll/journals/${row.attributes.journal_id}/view`">
          {{ getJournal(row).name }}
        </router-link>
      </template>
      <template #attributes.void_journal_id="{row}">
        <router-link
          v-if="row.attributes.void_journal_id"
          :to="`/payroll/journals/${row.attributes.void_journal_id}/view`">
          {{ getVoidJournal(row).name }}
        </router-link>
      </template>
      <template v-slot:attributes.description="{row}">
        <router-link
          v-if="row.attributes.description"
          :to="`/payroll/batches/${row.id}`">
          {{ row.attributes.description }}
        </router-link>
      </template>
      <template v-slot:attributes.checks_count="{row}">
        <batch-stats :batch="row.attributes"/>
      </template>
      <template #attributes.status="{row}">
        <div class="flex items-center space-x-1">
          <StatusLink :row="row"/>
          <HideFromMyChecksStatus :batch="row"/>
          <DirectDepositConfirmStatus :batch="row"/>
          <PositivePayConfirmStatus :batch="row"/>
        </div>
      </template>
      <template v-slot:extra-actions-before="{row}">
        <base-tooltip :content="$t('View Payroll Register')">
          <table-view-button @click="seeMoreClick(row)"/>
        </base-tooltip>
      </template>
      <template v-slot:expand-content="{row}">
        <PayrollBatchExpandContent :id="row.originalRow.id"
                                   class="p-4 md:p-6"
        />
      </template>
    </BaseDataTable>
    <MyChecksVisibilityDialog
      v-if="showMyChecksVisibilityDialog"
      :open.sync="showMyChecksVisibilityDialog"
      @close="showMyChecksVisibilityDialog = false"
    />
  </div>
</template>
<script>
  import BatchStats from '@/modules/payroll/components/payroll/BatchStats'
  import PayrollBatchExpandContent from '@/modules/payroll/components/payroll/PayrollBatchExpandContent'
  import { resourceStatuses } from "@/enum/enums";
  import DirectDepositConfirmStatus from "@/modules/payroll/components/payroll/DirectDepositConfirmStatus.vue";
  import PositivePayConfirmStatus from "@/modules/payroll/components/payroll/PositivePayConfirmStatus.vue";
  import HideFromMyChecksStatus from "@/modules/payroll/components/payroll/HideFromMyChecksStatus.vue";
  import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
  import MyChecksVisibilityDialog from "@/modules/payroll/components/payroll/MyChecksVisibilityDialog.vue";

  export default {
    props: {
      status: {
        type: String,
      }
    },
    components: {
      MyChecksVisibilityDialog,
      EyeIcon,
      EyeOffIcon,
      HideFromMyChecksStatus,
      PositivePayConfirmStatus,
      DirectDepositConfirmStatus,
      BatchStats,
      PayrollBatchExpandContent,
    },
    data() {
      return {
        loading: false,
        resourceStatuses,
        showMyChecksVisibilityDialog: false,
      }
    },
    computed: {
      urlParams() {
        let related
        if ([resourceStatuses.Posted, resourceStatuses.Voided].includes(this.status)) {
          related = 'journal[id|name],voidJournal[id|name]'
        }
        const statusMap = {
          [resourceStatuses.Posted]: `${resourceStatuses.Posted},${resourceStatuses.PartiallyVoided}`,
          [resourceStatuses.Voided]: `${resourceStatuses.Voided},${resourceStatuses.PartiallyVoided}`,
        }
        const status = statusMap[this.status] || this.status

        return {
          status,
          related,
        }
      },
      columns() {
        return [
          {
            label: this.$t('Period End Date'),
            prop: 'attributes.period_end_date',
            component: 'FormattedDate',
          },
          {
            label: this.$t('Calendar Year'),
            prop: 'attributes.year',
          },
          {
            label: this.$t('Journal'),
            prop: 'attributes.journal_id',
            renderIf: () => this.status === resourceStatuses.Posted,
          },
          {
            label: this.$t('Journal'),
            prop: 'attributes.void_journal_id',
            renderIf: () => this.status === resourceStatuses.Voided,
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Checks Info'),
            prop: 'attributes.checks_count',
            align: 'center',
          },
          {
            label: this.$t('Payrolls'),
            prop: 'attributes.counts.payrolls',
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Voided Payrolls'),
            prop: 'attributes.counts.voided',
            align: 'center',
            component: 'Count',
            renderIf: () => this.status === resourceStatuses.Voided,
          },
          {
            label: this.$t('Employees'),
            prop: 'attributes.counts.employees',
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            renderIf: () => this.status === undefined || this.status === resourceStatuses.Posted,
            maxWidth: 250,
          },
          {
            label: this.$t('Gross Pay'),
            prop: 'attributes.totals.gross_pay',
            component: 'FormattedPrice',
          },
        ]
      },
      renderedColumns() {
        return this.columns.filter(column => column.renderIf === undefined || column.renderIf())
      }
    },
    methods: {
      getDate(row) {
        return this.$formatDate(row.period_end_date, 'yyyy-MM-dd', true)
      },
      seeMoreClick(row) {
        this.$router.push(`/payroll/batches/${row.id}`)
      },
      getJournal(row) {
        return row?.relationships?.journal?.attributes || {}
      },
      getVoidJournal(row) {
        return row?.relationships?.voidJournal?.attributes || {}
      },
    },
  }
</script>
