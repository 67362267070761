<template>
  <BaseReportForm
      :filters="model"
      :report-number="reportNumber"
      :can-export-to-csv="true"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Sort By')">
        <baseSelect
            v-model="model.sort_by"
            :options="sortByOptions"
            class="col-span-2"
            @change="onChangeSortType"
        />
      </BaseFilterRow>
      <BaseFilterRow :title="$t('General Liabilities')">
        <GenLiabilityRangeFilter
            v-model="model"
            :url-params="certifiedPayrollFilters"
        />
        <div class="col-span-2 flex items-baseline">
          <span class="text-label">{{ $t('State') }}</span>
          <StateSelect
              v-model="model.state"
              :add-entity="false"
              :name="$t('State')"
              class="flex-1"
              rules="required"
              clearable
          />
        </div>
      </BaseFilterRow>
      <JobRangeFilter
          v-if="sortByJob"
          v-model="model"
          :url-params="certifiedPayrollFilters"
      >
        <div class="col-span-3 flex items-baseline">
          <span class="text-label">{{ $t('Status') }}</span>
          <BaseSelect
              v-model="model.job_statuses"
              :options="jobStatusOptions"
              class="flex-1"
              rules="required"
              collapse-tags
              multiple
          />
        </div>
      </JobRangeFilter>
      <BaseFilterRow :title="$t('Define Pay As Subject To')">
        <BaseSelect
            v-model="model.total_on"
            :options="totalOnOptions"
            class="col-span-3"
            rules="required"
        />
        <div
            v-if="sortByJob"
            class="col-span-2 flex items-baseline"
        >
          <span class="text-label">{{ $t('Include') }}</span>
          <BaseSelect
              v-model="model.include_jobs"
              :options="includeJobsOptions"
              class="flex-1"
          />
        </div>
      </BaseFilterRow>
      <template v-if="sortByJob">
        <CustomerRangeFilter v-model="model"/>
        <BaseFilterRow :title="$t('OCIPs')">
          <RangeFilter
              :config="RangeFilterConfig.OCIP"
              :model="model"
              :from.sync="model.ocip_code_from"
              :to.sync="model.ocip_code_to"
              class="col-span-4"
          />
        </BaseFilterRow>
      </template>
      <PayrollDatesFilter v-model="model"/>
    </template>
    <template #table="{data, loading}">
      <GeneralLiabilitySummaryReportTable
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :sort-by="model.sort_by"
          ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { resourceStatuses } from '@/enum/enums'
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter.vue'
  import {
    RangeFilter,
    JobRangeFilter,
    RangeFilterConfig,
    CustomerRangeFilter,
    GenLiabilityRangeFilter,
  } from '@/components/range-filters'
  import {
    selectionDateFields,
    certifiedPayrollFilters,
    workerCompSummarySortBy,
  } from '@/modules/payroll/components/reports/util'
  import GeneralLiabilitySummaryReportTable from '@/modules/payroll/components/reports/GeneralLiabilitySummaryReportTable.vue'

  const totalOn = {
    StateUnemploymentInsurance: 'SUI',
    FederalIncomeTax: 'FIT',
    StateIncomeTax: 'SIT',
    FederalInsuranceContributionsAct: 'FICA',
    GrossAmount: 'gross_amount',
  }

  const includeJobs = {
    All: 'all',
    ContractorPay: 'contractor_pay',
    OwnerPay: 'owner_pay',
  }

  export default defineComponent({
    components: {
      JobRangeFilter,
      PayrollDatesFilter,
      CustomerRangeFilter,
      GenLiabilityRangeFilter,
      GeneralLiabilitySummaryReportTable,
    },
    data() {
      return {
        certifiedPayrollFilters,
        jobStatusOptions,
        RangeFilterConfig,
        model: {
          sort_by: workerCompSummarySortBy.Employee,
          state: null,
          liability_code_from: null,
          liability_code_to: null,
          job_number_from: null,
          job_number_to: null,
          job_statuses: [ resourceStatuses.Open, resourceStatuses.Inactive ],
          include_jobs: includeJobs.All,
          customer_code_from: null,
          customer_code_to: null,
          ocip_code_from: null,
          ocip_code_to: null,
          ...selectionDateFields,
          total_on: totalOn.StateUnemploymentInsurance,
        },
        sortByOptions: [
          {
            label: this.$t('Employee'),
            value: workerCompSummarySortBy.Employee,
          },
          // ! TODO: back-end is not ready yet
          // {
          //   label: this.$t('Job'),
          //   value: workerCompSummarySortBy.Job,
          // },
          // {
          //   label: this.$t('Job/Employee Summary'),
          //   value: workerCompSummarySortBy.Summary,
          // },
          // {
          //   label: this.$t('Job/Employee Detail'),
          //   value: workerCompSummarySortBy.Detail,
          // },
        ],
        totalOnOptions: [
          {
            label: this.$t('SUI (State Unemployment Insurance)'),
            value: totalOn.StateUnemploymentInsurance,
          },
          {
            label: this.$t('FIT (Federal Income Tax)'),
            value: totalOn.FederalIncomeTax,
          },
          {
            label: this.$t('SIT (State Income Tax)'),
            value: totalOn.StateIncomeTax,
          },
          {
            label: this.$t('FICA (Federal Insurance Contributions Act)'),
            value: totalOn.FederalInsuranceContributionsAct,
          },
          {
            label: this.$t('$ (Gross Amount))'),
            value: totalOn.GrossAmount,
          },
        ],
        includeJobsOptions: [
          {
            label: this.$t('All'),
            value: includeJobs.All,
          },
          {
            label: this.$t('Jobs Which Contractor Pay'),
            value: includeJobs.ContractorPay,
          },
          {
            label: this.$t('Jobs Which Owner Pay'),
            value: includeJobs.OwnerPay,
          },
        ],
      }
    },
    computed: {
      sortByEmployee(): boolean {
        return this.model.sort_by === workerCompSummarySortBy.Employee
      },
      sortByJob(): boolean {
        return this.model.sort_by === workerCompSummarySortBy.Job
      },
      reportNumber(): string {
        const reportNumberMap = {
          [workerCompSummarySortBy.Employee]: '4877',
          [workerCompSummarySortBy.Job]: '4877a',
          default: '4877',
        }
        return reportNumberMap[this.model.sort_by] || reportNumberMap.default
      },
    },
    methods: {
      onChangeSortType() {
        this.model.job_number_from = this.model.job_number_to = null
        this.model.liability_code_from = this.model.liability_code_to = null
        this.model.customer_code_from = this.model.customer_code_to = null
        this.model.ocip_code_from = this.model.ocip_code_to = null
        this.model.include_jobs = includeJobs.All
        this.model.job_statuses = [ resourceStatuses.Open, resourceStatuses.Inactive ]
      },
    },
  })
</script>
