<template>
  <div class="flex items-center">

    <div class="flex flex-1 space-x-4 items-center">
      <JobLink
          :data="job"
          :show-preview="false"
          link-class="text-sm text-gray-900"
      />
      <base-checkbox
          v-model="selectedAll"
          :label="$t('Select All (Full Withheld Amount)')"
          :id="`${rowIndex}-select-all`"
          @change="updateGroupRetentionsMethod($event)"
      />
    </div>
    <div v-if="params.allowOverrides" class="mr-4 flex space-x-4 items-center">
      <div v-if="hasOverrides" class="flex space-x-4 items-center">
        <span class="font-semibold">{{$t('Overrides')}}:</span>
        <div
          v-if="model.number"
          class="flex space-x-2">
          <span>
            {{ $t('Invoice Number') }}:
          </span>
          <span class="font-medium">
            {{ model.number }}
          </span>
        </div>
        <div
          v-if="model.date"
          class="flex space-x-2">
          <span>
            {{ $t('Date') }}:
          </span>
          <span class="font-medium">
            {{ $formatDate(model.date) }}
          </span>
        </div>
        <div
          v-if="model.description"
          class="flex space-x-2">
          <span>
            {{ $t('Description') }}:
          </span>
          <span class="font-medium max-w-[200px] truncate" :title="model.description">
            {{ model.description }}
          </span>
        </div>
      </div>
      <TableEditButton @click="showOverridesDialog = true"/>
    </div>

    <div class="text-sm text-gray-900 font-semibold">
      {{ $formatPrice(jobGroupTotal) }}
    </div>

    <BaseFormDialog
      v-if="showOverridesDialog"
      :open.sync="showOverridesDialog"
      :append-to-body="true"
      :title="$t('Override Billing Information')"
    >
      <BaseForm
        layout="vertical"
        :show-cancel="true"
        @cancel="onCancelOverrides"
        @submit="overrideBilling"
      >
        <InvoiceNumberInput
          v-model="model.number"
          :model="model"
          rules="max:16"
          class="col-span-6 md:col-span-3"
        />

        <BaseDatePicker
          v-model="model.date"
          :label="$t('Invoice Date')"
          :placeholder="$t('Invoice Date')"
          :name="$t('Invoice Date')"
          id="date"
          class="col-span-6 md:col-span-3"
        />
        <BaseTextarea
          v-model="model.description"
          :label="$t('Description')"
          :placeholder="$t('Description')"
          :rows="2"
          id="description"
          rules="max:500"
          class="col-span-6"
        />
      </BaseForm>

    </BaseFormDialog>

  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
  export default defineComponent({
    components: {
      InvoiceNumberInput
    },
    data()  {
      return {
        selectedAll: false as boolean,
        showOverridesDialog: false,
        model: {
          number: null,
          date: null,
          description: null,
        }
      }
    },
    computed: {
      hasOverrides() {
        return this.model.number || this.model.date || this.model.description
      },
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      rowIndex() {
        return this.params.rowIndex
      },
      job() {
        return this.data?.job
      },
      jobGroupTotal() {
        return this.getRetentions().reduce((acc, invoice) => acc + invoice.release_retention, 0)
      },
      releaseFullMethod() {
        return this.params.releaseFullMethod
      },
    },
    methods: {
      getRetentions() {
        return this.params.node?.allLeafChildren.map(child => child.data)
      },
      onCancelOverrides() {
        this.model = {
          number: null,
          date: null,
          description: null,
        }
        this.showOverridesDialog = false
      },
      overrideBilling() {
        if (!this.params.setOverrides) {
          return
        }
        this.params?.setOverrides(this.getRetentions(), this.model)
        this.showOverridesDialog = false
      },
      updateGroupRetentionsMethod(selected) {
        const method = selected ? this.releaseFullMethod : null
        this.params?.updateGroupRetentionsMethod(this.getRetentions(), method)

        this.updateChildRetentions(selected)
      },
      updateChildRetentions(selected) {
        const release_method = selected ? this.releaseFullMethod : null
        this.params.node.allLeafChildren.forEach(child => {
          child.setData({
            ...child.data,
            release_method,
            release_retention: selected ? child.data.retention_amount : 0,
          })
        })
      },
      setSelectedAllDefaultState() {
        this.selectedAll = this.params.node.allLeafChildren.every(child => child.data.release_method === this.releaseFullMethod)
      },
    },
    mounted() {
      this.setSelectedAllDefaultState()
    },
    watch: {
      'data.overrides': {
        handler(value) {
          if (!value) {
            return
          }
          if (Object.keys(value)?.length) {
            this.model = {
              ...value,
            }
          }
        },
        immediate: true,
      }
    }
  })
</script>
