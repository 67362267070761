<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :name="$t('Copies')"
    :add-entity="false"
    :options="copyOptions"
    :multiple="true"
    :collapse-tags="true"
  />
</template>
<script>
import { EmployeeW2SOptions } from "@/modules/payroll/components/reports/util";
import { Vendor1099sReportOptions } from "@/modules/accounts-payable/components/reports/util";

export default {
  props: {
    showEmployeeCopiesOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    }
  },
  computed: {
    isFourUpType() {
      return this.type === EmployeeW2SOptions.Types.FourUp
    },
    copyOptions() {
      const options = [
        {
          label: this.$t(`Employee 4UP`),
          value: EmployeeW2SOptions.Copies.A,
          showIf: () => this.isFourUpType,
        },
        {
          label: this.$t(`Federal Copy A (Social Security Administration)`),
          value: EmployeeW2SOptions.Copies.A,
          showIf: () => !this.showEmployeeCopiesOnly && !this.isFourUpType,
        },
        {
          label: this.$t(`Employee Copy 1 (State/City/Local Tax Department)`),
          value: EmployeeW2SOptions.Copies.One,
          showIf: () => !this.isFourUpType,
        },
        {
          label: this.$t(`Federal Copy B (For Filing Federal Return)`),
          value: EmployeeW2SOptions.Copies.B,
          showIf: () => !this.showEmployeeCopiesOnly && !this.isFourUpType,
        },
        {
          label: this.$t(`Employee Copy 2 (State/City/Local Tax Return)`),
          value: Vendor1099sReportOptions.Copies.Two,
          showIf: () => !this.isFourUpType,
        },
        {
          label: this.$t(`Employee Copy C (For Employee’s Records)`),
          value: EmployeeW2SOptions.Copies.C,
          showIf: () => !this.isFourUpType,
        },
        {
          label: this.$t(`Employer Copy D (For Employer’s Records)`),
          value: EmployeeW2SOptions.Copies.D,
          showIf: () => !this.isFourUpType,
        },
      ]
      return options.filter(option => !option.showIf || option.showIf())
    }
  }
}
</script>
<style>
</style>
