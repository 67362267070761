<template>
  <div class="import-data">
    <ImportForm v-bind="importSettings"
                @success="redirectToTimeCard"
    >
      <div class="col-span-6"></div>
      <div class="col-span-6 md:col-span-2">
        <PeriodEndDatePicker
          v-model="model.period_end_date"/>
      </div>
      <div class="col-span-6 md:col-span-2">
        <PayrollPaymentDatePicker
          v-model="model.payment_date"
          :period-end-date="model.period_end_date"
          :year="model.year"
          @year-change="model.year = $event"
        />
      </div>
      <div class="col-span-6 md:col-span-1">
        <TimecardYearSelect
          v-model="model.year"
          @change="onYearChange"
        />
      </div>
    </ImportForm>
  </div>
</template>
<script>
  import { colMappings, getInitialMapping, options } from "@/modules/payroll/utils/timecardImportColumns";
  import ImportForm from "@/modules/common/components/import/ImportForm";
  import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
  import PayrollPaymentDatePicker from "@/modules/payroll/components/timecard/PayrollPaymentDatePicker.vue";
  import TimecardYearSelect from "@/modules/payroll/components/timecard/TimecardYearSelect.vue";

  export default {
    components: {
      TimecardYearSelect,
      PayrollPaymentDatePicker,
      ImportForm,
      PeriodEndDatePicker,
    },
    data() {
      return {
        model: {
          period_end_date: null,
          payment_date: null,
          year: null,
        },
        importSettings: {
          url: '/restify/timecard-batches/import',
          columnOptions: options,
          columnMappings: colMappings,
          initialMapping: getInitialMapping(options),
          type: 'timecard-import',
          transformRequestData: this.transformRequestData,
          resource: 'timecards',
          showOverwrite: false,
        },
      }
    },
    methods: {
      onYearChange() {
        this.model.period_end_date = null
        this.model.payment_date = null
      },
      transformRequestData(formData) {
        formData.append('year', this.model.year);
        formData.append('period_end_date', this.model.period_end_date);
        formData.append('payment_date', this.model.payment_date);
      },
      async redirectToTimeCard(data) {
        await this.$router.push('/payroll/timecard-batches/pending')
      },
    }
  }
</script>
