<template>
  <div>
    <base-data-table
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New district')"
        :group-by.sync="selectedGroupBy"
        entity="districts"
        import-url="/accounts-receivable/settings/sales-tax-districts/import"
        default-sort="state"
        actions="search,refresh,import,export"
        hide-actions="view"
        permission="districts"
        ref="table"
        deleteCustom
        @delete="itemToDelete = $event; showDeleteDialog = true"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
      <template #header="{ row }">
        {{ row.header.attributes.state }}
        ({{ getStateDescription(row.header.attributes.state) }})
      </template>
      <template #attributes.account="{row}">
        <AccountLink :data="row.attributes" />
      </template>
    </base-data-table>

    <base-form-dialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Sales Tax District') : $t('Add Sales Tax District')"
        :open.sync="showDialog"
        @close="onEventTrigger"
    >
      <sales-tax-districts-form
          :data="rowToEdit"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
      />
    </base-form-dialog>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.SalesTaxDistricts"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script>
  import { stateList } from '@/enum/state'
  import { groupByType } from '@/components/table/utils/groupByTypes'
  import SalesTaxDistrictsForm from '@/modules/accounts-receivable/components/settings/SalesTaxDistrictsForm'

  export default {
    components: {
      SalesTaxDistrictsForm,
    },
    data() {
      return {
        selectedGroupBy: groupByType.State,
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/districts',
        urlParams: {
          related: 'state,account,subaccount',
        },
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('District'),
            prop: 'attributes.code',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            maxWidth: 200,
          },
          {
            label: this.$t('Account'),
            prop: 'attributes.account',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Sub Account'),
            prop: 'attributes.subaccount',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('County'),
            prop: 'attributes.county',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Tax'),
            prop: 'attributes.sales_tax_percent',
            align: 'right',
            component: 'FormattedPercent',
          },
        ],
        showDeleteDialog: false,
        itemToDelete: null,
      }
    },
    methods: {
      getStateDescription(state) {
        const stateObject = stateList.find(s => s.value === state)

        return stateObject?.description || ''
      },
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onItemDeleted() {
        this.showDeleteDialog = false
        this.refreshTable()
      },
    },
  }
</script>
