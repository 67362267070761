<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      :has-summary="false"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex">
        <AccountLink
            :data="row.header?.account"
            class="w-1/2"
        />
        <div class="flex flex-1 justify-between">
          <span>{{ $t('Beginning Balance:') }}</span>
          <span>{{ $formatPrice(row.header?.beginning_balance_amount) }}</span>
        </div>
      </div>
    </template>

    <template #period_fiscal_year="{row}">
      {{ row.transaction?.period }} -
      {{ row.transaction?.fiscal_year }}
    </template>

    <template #transaction.business="{row}">
      <component
          v-if="get(row, 'business.id')"
          :is="getBusinessComponent(row.business.type)"
          :data="row.business"
          :show-preview="false"
          :show-description="false"
          :show-name="false"
      />
    </template>

    <template #transaction.has_gap="{row}">
      <span v-if="row.transaction?.has_gap">**</span>
      <span v-else></span>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="5"/>
      <td>{{ $t('Account Totals:') }}</td>
      <td align="right">
        {{ $formatPrice(subtotal.check_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.deposit_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.other_amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal.balance_amount) }}
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import { transactionBusinessLinkByTypes } from '@/enum/enums.js'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { checkDepositRegisterSortByTypes } from '@/modules/ledger/components/reports/util.js'

  export default {
    extends: ReportTableWrapper,
    props: {
      sortBy: {
        type: String,
        default: checkDepositRegisterSortByTypes.ReferenceDate,
      },
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Seq <br> Gap'),
            prop: 'transaction.has_gap',
            minWidth: 40,
            maxWidth: 60,
            renderIf: () => [
              checkDepositRegisterSortByTypes['F/Period Ref Number'],
              checkDepositRegisterSortByTypes.ReferenceNumber,
            ].includes(this.sortBy),
          },
          {
            label: this.$t('Ref <br> Date'),
            prop: 'transaction.reference_date',
            minWidth: 100,
            maxWidth: 120,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Ref <br> No.'),
            prop: 'transaction.reference_number',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            label: this.$t('Journal'),
            prop: 'journal.name',
            minWidth: 80,
            maxWidth: 120,
          },
          {
            label: this.$t('Period/Year'),
            prop: 'period_fiscal_year',
            minWidth: 70,
            maxWidth: 90,
          },
          {
            label: this.$t('Rec <br> (*)'),
            prop: 'transaction.reconciled_flag',
            minWidth: 40,
            maxWidth: 60,
            renderIf: () => [
              checkDepositRegisterSortByTypes['F/Period Ref Number'],
              checkDepositRegisterSortByTypes.ReferenceNumber,
            ].includes(this.sortBy),
          },
          {
            label: this.$t('Payee'),
            prop: 'transaction.business',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Description'),
            prop: 'transaction.description',
            minWidth: 100,
            maxWidth: 320,
          },
          {
            label: this.$t('Status'),
            prop: 'transaction.status',
            minWidth: 60,
            maxWidth: 150,
            component: 'Status',
          },
          {
            label: this.$t('Check <br> Amount'),
            prop: 'totals.check_amount',
            component: 'FormattedPrice',
            hideZero: true,
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Deposit <br> Amount'),
            prop: 'totals.deposit_amount',
            component: 'FormattedPrice',
            hideZero: true,
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Other <br> Amount'),
            prop: 'totals.other_amount',
            component: 'FormattedPrice',
            hideZero: true,
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
          },
          {
            label: this.$t('Balance'),
            prop: 'totals.balance_amount',
            component: 'FormattedPrice',
            hideZero: true,
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      composeRows(data) {
        data.forEach(group => {
          const header = {
            account: group.account,
            beginning_balance_amount: group.beginning_balance_amount,
          }

          this.rows.push({
            header,
          })

          const { items, totals } = group

          this.rows.push(...items)

          this.rows.push({
            subtotal: totals,
          })
        })
      },
      getBusinessComponent(businessType) {
        return transactionBusinessLinkByTypes[businessType]
      },
    },
  }
</script>
