<template>
  <div class="flex items-center">
    <slot name="activator" :open="() => showExportDialog = true">
      <BaseButton
        variant="primary-link"
        class="print:hidden send-email-button"
        v-bind="$attrs"
        @click="showExportDialog = true"
      >
      <span class="flex items-center">
        <DownloadIcon class="w-4 h-4 mr-2 -ml-4"/>
        <span>
          {{ $t('Export') }}
        </span>
      </span>
      </BaseButton>
    </slot>
    <BaseFormDialog
      v-if="showExportDialog"
      :open.sync="showExportDialog"
      :title="$t('Export Vendor 1099s')"
      :append-to-body="true"
      :size="exportResult ? 'default' : '2xl'"
    >
      <BaseForm
        layout="modal"
        :saveText="exportResult ? $t('Download All') : $t('Export')"
        :cancelText="exportResult ? $t('Back') : $t('Cancel')"
        :showCancel="true"
        :loading="loading"
        :focusOnFirstInput="false"
        :submitDisabled="!canSubmit"
        :submitTooltip="submitTooltip"
        @submit="onSubmit"
        @cancel="onCancel"
      >
        <div
          v-if="exportResult"
          v-show="exportResult || loading"
          class="col-span-full"
        >
          <BaseAlert
            v-if="exportResult?.length"
          >
            <div>
              {{ $t('The 1099 upload files have been created.') }}
            </div>
            <div class="font-semibold">
              {{ $t('You should RENAME the files and save them for several years in case they need to be re-submitted.') }}
            </div>
          </BaseAlert>
          <TextFileList
            ref="textFileList"
            :data="exportResult || []"
            :loading="loading"
            :showDownloadAll="false"
            layout="modal"
            class="-mx-4 -mb-5 sm:-mx-6 sm:-mb-6"
          />
        </div>
        <div
          v-show="!exportResult"
          class="col-span-full"
        >
          <BaseAlert
            :type="$promptType.Warning"
            class="mb-4"
          >
            <div class="font-semibold flex items-center space-x-2">
              <span>{{ $t('IMPORTANT... PLEASE BE SURE YOU HAVE PRINTED & REVIEWED THE') }}</span>
              <router-link
                :to="`/accounts-payable/reports/vendor-1099s?tax_year=${tax_year}`"
                class="underline text-gray-900"
              >
                {{ $t('PROOF LIST') }}
              </router-link>
              <span>{{ $t('BEFORE CONTINUING') }}</span>
            </div>
          </BaseAlert>
          <div class="flex space-x-2 items-center justify-between col-span-full mb-4 text-gray-700 text-sm">
            <div>
              <span class="font-semibold">{{ $t('Tax Year') }}</span>
              <span class="ml-2">{{ tax_year }}</span>
            </div>
          </div>
          <BaseFilterRow
            :title="$t('Filing System')"
            class="col-span-full"
          >
            <BaseSelect
              v-model="format"
              :options="exportFormatOptions"
              class="col-span-2 md:col-span-6 xl:col-span-10"
            />
          </BaseFilterRow>
          <BaseFilterRow
            :title="$t('Filing Types')"
          >
            <BaseSelect
              v-model="model.form_types"
              :add-entity="false"
              :options="formTypeOptions"
              :multiple="true"
              class="col-span-2 md:col-span-6 xl:col-span-10"
            />
          </BaseFilterRow>
          <BaseFilterRow
            :title="$t('Options')"
          >
            <BaseSwitch
              v-model="model.is_combined"
              :labelInfo="$t('Combined Federal/State Filing')"
              inline
              class="col-span-2 md:col-span-6 xl:col-span-10"
            />
          </BaseFilterRow>
          <div class="col-span-6 mt-4">
            <h4 class="mb-4">
              {{ $t(`Select Vendors (Leave selection blank to export all ${data?.length || 0} Vendor 1099s below)`) }}
            </h4>
            <AgDataTable
              :pagination="false"
              :tooltipShowDelay="0"
              :data="data"
              :columns="columns"
              :selected-rows.sync="selectedRows"
              domLayout="autoHeight"
            />
          </div>
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import {
  Vendor1099sReportOptions
} from '@/modules/accounts-payable/components/reports/util'
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import i18n from "@/i18n";
import { DownloadIcon } from 'vue-feather-icons'

const ExportFormats = {
  FIRE: 'fire',
  IRIS: 'iris',
}

export default {
  components: {
    TextFileList,
    DownloadIcon,
  },
  props: {
    tax_year: {
      type: [Number, String],
      required: true
    },
    batch_id: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      exportResult: null,
      Vendor1099sReportOptions,
      showExportDialog: false,
      model: {
        tax_year: this.tax_year,
        form_types: [
          Vendor1099sReportOptions.FormTypes.NEC,
          Vendor1099sReportOptions.FormTypes.MISC,
        ],
        is_combined: false,
      },
      selectedRows: [],
      format: ExportFormats.IRIS,
    }
  },
  computed: {
    exportFormatOptions() {
      return [
        {
          label: i18n.t('IRIS'),
          value: ExportFormats.IRIS,
        },
        // {
        //   label: i18n.t('FIRE'),
        //   value: ExportFormats.FIRE,
        // },
      ]
    },
    formTypeOptions() {
      return  [
        {
          label: this.$t('1099-MISC'),
          value: Vendor1099sReportOptions.FormTypes.MISC,
        },
        {
          label: this.$t('1099-NEC'),
          value: Vendor1099sReportOptions.FormTypes.NEC,
        }
      ]
    },
    columns() {
      return [
        {
          headerName: i18n.t('Vendor'),
          field: 'vendor.id',
          minWidth: 400,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => {
            return params.data.types?.length && params.data.vendor?.attributes?.federal_tax_id
          },
          component: 'VendorLink',
        },
        {
          headerName: i18n.t('Federal Tax ID'),
          field: 'vendor.attributes.federal_tax_id',
          minWidth: 140,
          maxWidth: 160,
          valueFormatter: (params) => {
            if (!params?.value) {
              return i18n.t('Not set')
            }

            return params.value || ''
          },
          cellClass: (params) => {
            if (!params.value?.length) {
              return 'text-red-500'
            }

            return ''
          },
        },
        {
          headerName: i18n.t('Payment Amount'),
          field: 'vendor_payment_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('Form Types'),
          field: 'types',
          minWidth: 140,
          maxWidth: 160,
          valueFormatter: (params) => {
            if (!params.value?.length) {
              return 'None'
            }

            const formTypeLabels = {
              [Vendor1099sReportOptions.FormTypes.MISC]: 'MISC',
              [Vendor1099sReportOptions.FormTypes.NEC]: 'NEC',
            }
            return (params.value || []).map((x) => formTypeLabels[x]).join(', ')
          },
          cellClass: (params) => {
            if (!params.value?.length) {
              return 'text-red-500'
            }

            return ''
          },
          tooltipValueGetter: (params) => {
            if (!params.value?.length) {
              return i18n.t('No payments reported on 1099-MISC or 1099-NEC')
            }

            return null
          },
        },
        {
          headerName: i18n.t('NEC Amount'),
          field: 'non_compensation_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('MISC Amount'),
          field: 'misc_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('1099s Total Amount'),
          field: 'total_amount',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
          cellClass: (params) => {

            if (Number(params.data.total_amount) !== Number(params.data.vendor_payment_amount)) {
              return 'text-red-500'
            }

            return ''
          },
          tooltipValueGetter: (params) => {
            if (Number(params.value) !== Number(params.data.vendor_payment_amount)) {
              return i18n.t('Total Amount does not match Vendor Payment Amount')
            }

            return null
          },
        },
      ]
    },
    entriesToExport() {
      if (this.selectedRows.length) {
        return this.selectedRows
      }

      return this.data
    },
    hasMISCVendors() {
      return this.entriesToExport.some((x) => x.types.includes(Vendor1099sReportOptions.FormTypes.MISC))
    },
    hasNECVendors() {
      return this.entriesToExport.some((x) => x.types.includes(Vendor1099sReportOptions.FormTypes.NEC))
    },
    hasVendorsWithTaxIDNotSet() {
      return this.entriesToExport.some((x) => !x.vendor?.attributes?.federal_tax_id)
    },
    canSubmit() {
      if (!this.model.form_types.length || !this.entriesToExport.length) {
        return false
      }

      if (this.format === ExportFormats.IRIS && this.entriesToExport.length > 100) {
        return false
      }

      if (this.hasVendorsWithTaxIDNotSet) {
        return false
      }

      if (this.model.form_types.length === 2) {
        return this.hasMISCVendors || this.hasNECVendors
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.MISC) {
        return this.hasMISCVendors
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.NEC) {
        return this.hasNECVendors
      }

      return true
    },
    submitTooltip() {
      if (this.canSubmit) {
        return ''
      }

      if (this.hasVendorsWithTaxIDNotSet) {
        return this.$t('There are Vendors in the current selection with no Federal Tax ID set.')
      }

      if (this.format === ExportFormats.IRIS && this.entriesToExport.length > 100) {
        return this.$t(`IRIS currently only supports up to 100 1099's per CSV file`)
      }

      if (!this.model.form_types.length) {
        return this.$t('Please select at least one Filing Type.')
      }

      if (!this.entriesToExport.length) {
        return this.$t('No Vendor 1099s to print.')
      }

      if (this.model.form_types.length === 2 && !this.hasMISCVendors && !this.hasNECVendors) {
        return this.$t('No Vendors with payments reported on 1099-MISC or 1099-NEC forms in selection.')
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.MISC && !this.hasMISCVendors) {
        return this.$t('No Vendors with payments reported on the 1099-MISC form in selection.')
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.NEC && !this.hasNECVendors) {
        return this.$t('No Vendors with payments reported on the 1099-NEC form in selection.')
      }
    }
  },
  methods: {
    async onSubmit() {
      if (this.exportResult) {
        this.$refs.textFileList?.downloadAll()
        return
      }

      try {

        this.loading = true
        const data = {
          ...this.model
        }

        if (this.batch_id) {
          data.batch_id = this.batch_id
        }

        if (this.selectedRows?.length) {
          data.repositories = this.selectedRows.map((x) => x.id)
        }

        if (data.repositories?.length) {
          delete data.batch_id
        }

        let postUrl = this.format === ExportFormats.IRIS
          ? '/restify/vendor1099s/actions?action=export-vendor-1099s-iris'
          : '/restify/vendor1099s/actions?action=export-vendor-1099s-fire'

        const result = await axios.post(postUrl, data)

        this.exportResult = result
      } finally {
        this.loading = false
      }
    },
    onCancel() {
      if (this.exportResult) {
        this.exportResult = null
        return
      }

      this.showExportDialog = false
    },
    reset() {
      this.exportResult = null
      this.model = {
        tax_year: this.tax_year,
        form_types: [
          Vendor1099sReportOptions.FormTypes.NEC,
          Vendor1099sReportOptions.FormTypes.MISC,
        ],
        is_combined: false,
      }
      this.selectedRows = []
      this.format = ExportFormats.IRIS
    }
  },
  watch: {
    showExportDialog(value) {
      if (!value) {
        this.reset()
      }
    }
  }
}
</script>
