<template>
  <SettingsForm
      :fields="fields"
      :module-name="moduleName"
      grid-classes="grid grid-cols-1"
      @cancel="$router.push('/purchasing-inventory/purchase-orders')"
  >

    <template #overrides="{model}">
      <div class="grid grid-cols-12 gap-x-3">

        <BaseInput
            v-model="model.last_purchase_order_number"
            :label="$t('Last P.O. # Used')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            rules="required"
        />

        <BaseSelect
            v-model="model.purchase_order_status"
            :options="statusOptions"
            :label="$t('Default P.O. Status')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            rules="required"
        />

        <BaseSelect
            v-model="model.form_type"
            :options="formTypeOptions"
            :label="$t('Normal P.O. Form #')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
        />

        <AddressSelect
            v-model="model.default_address_id"
            :address-type="$addressTypes.Warehouse"
            :label="$t('Default Address for Inventory')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
        />

        <BaseSwitch
            v-model="model.ship_purchase_order_to_multiple_locations"
            :label-info="$t('Ship P.O. to Multiple Locations')"
            class="col-span-12 md:col-span-4"
            id="ship_purchase_order_to_multiple_locations"
        />

        <AccountSelect
            v-model="model.inventory_account"
            :label="$t('Inventory Asset Account')"
            value-key="number"
            class="col-span-12 md:col-span-3"
            rules="required"
            warningOnChange
        />

        <SubAccountSelect
            v-model="model.inventory_subaccount"
            :label="$t('Sub Account')"
            value-key="number"
            class="col-span-12 md:col-span-2"
        />

        <AccountSelect
            v-model="model.cogs_account"
            :label="$t('COGS Expense Account')"
            value-key="number"
            class="col-span-12 md:col-span-3"
            rules="required"
            warningOnChange
        />

        <SubAccountSelect
            v-model="model.cogs_subaccount"
            :label="$t('Sub Account')"
            value-key="number"
            class="col-span-12 md:col-span-2"
        />

        <div class="md:col-span-2"/>

        <BaseSelect
            v-model="model.unit_rate_source"
            :options="unitRateSourceOptions"
            :label="$t('Default P.O. Unit Price')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            rules="required"
        />

        <BaseSelect
            v-model="model.charge_out_method"
            :options="chargeOutMethodOptions"
            :label="$t('Material Charge-Out Method')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            rules="required"
        />

        <BaseSelect
            v-model="model.should_adjust_by"
            :options="shouldAdjustByOptions"
            :label="$t('Update Material Received')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            rules="required"
        />

        <BaseSwitch
          v-model="model.is_po_entry_fixed_price"
          :label-info="$t('Default P.O Entry to Fixed Price')"
          class="col-span-12 md:col-span-3 xl:col-span-2"
          id="is_po_entry_fixed_price"
        />

        <BaseSwitch
            v-model="model.should_filter_closed_purchase_orders"
            :label-info="$t('Filter Closed / Voided POs')"
            class="col-span-12 md:col-span-3 xl:col-span-2"
            id="should_filter_closed_purchase_orders"
        />

        <ElCollapse v-model="activeGroup" class="col-span-12 mb-4">

          <BaseCollapseSection :title="$t('Post Accrued Sales Tax to')"
                                 key="post-tax-to"
                                 name="post-tax-to">

            <div class="grid grid-cols-12 gap-x-3">

              <PhaseCostCodeSelect
                  v-model="model.post_sales_tax_to_job_phase"
                  :label="$t('Job Phase Code')"
                  class="col-span-12 md:col-span-2"
                  key="phase_code"
              />

              <PhaseCostCodeSelect
                  v-model="model.post_sales_tax_to_cost_code"
                  :label="$t('Job Cost Code')"
                  class="col-span-12 md:col-span-2"
                  key="cost_code"
              />

              <EntitySelect
                  v-model="model.post_accrued_sales_tax_equipment_cost_type_id"
                  url="/restify/equipment-types"
                  :add-entity="false"
                  :url-params="{readonly: false}"
                  :options="equipmentCostTypeOptions"
                  :label="$t('Equipment Cost Type')"
                  class="col-span-12 md:col-span-2"
              />

              <EntitySelect
                  v-model="model.post_accrued_sales_tax_work_order_cost_type_id"
                  url="/restify/service-billing-types"
                  :add-entity="false"
                  :url-params="{
                    readonly: false,
                    type: 'cost',
                  }"
                  :label-format="formatSBCostTypeLabel"
                  :label="$t('Work Order Cost Type')"
                  class="col-span-12 md:col-span-2"
              />

              <BaseInput
                  v-model="model.sales_tax_percent"
                  v-bind="$validator.salesTaxPercent"
                  :label="$t('Sales Tax Percent')"
                  class="col-span-12 md:col-span-2"
                  format="percent"
              />

              <EntitySelect
                  v-model="model.job_cost_for_material"
                  url="/restify/job-types"
                  :add-entity="false"
                  :url-params="{
                    type: 'cost',
                  }"
                  :options="workOrderCostTypeOptions"
                  :label="$t('Job Cost Type for Material')"
                  class="col-span-12 md:col-span-2"
              />

            </div>
          </BaseCollapseSection>
        </ElCollapse>

        <BaseSwitch
            v-model="model.should_reduce_for_lump_sum_non_jobs"
            :label-info="$t('Reduce Inventory - Lump Sum Invoices (non-job entries)')"
            class="col-span-12 md:col-span-4"
            id="should_reduce_for_lump_sum_non_jobs"
        />

        <BaseSwitch
            v-model="model.should_reduce_for_lump_sum_jobs"
            :label-info="$t('Reduce Inventory - Lump Sum Invoices (job entries)')"
            class="col-span-12 md:col-span-4"
            id="should_reduce_for_lump_sum_jobs"
        />

        <BaseSwitch
            v-model="model.should_reduce_for_service_billing_non_history"
            :label-info="$t('Reduce Inventory - Service Invoices (new, non-history items only)')"
            class="col-span-12 md:col-span-4"
            id="should_reduce_for_service_billing_non_history"
        />

      </div>
    </template>

  </SettingsForm>
</template>
<script>
  import { resourceStatuses } from '@/enum/enums'
  import SettingsForm from '@/modules/common/components/SettingsForm'
  import PhaseCostCodeSelect from '@/components/select/entities/PhaseCostCodeSelect'

  export default {
    components: {
      SettingsForm,
      PhaseCostCodeSelect,
    },
    data() {
      return {
        activeGroup: ['post-tax-to'],
        moduleName: 'inventory-management',
        fields: [
          'form_type',
          'cogs_account',
          'cogs_subaccount',
          'last_year_posted',
          'should_adjust_by',
          'unit_rate_source',
          'charge_out_method',
          'inventory_account',
          'default_address_id',
          'sales_tax_percent',
          'last_period_posted',
          'inventory_subaccount',
          'job_cost_for_material',
          'purchase_order_status',
          'last_purchase_order_number',
          'post_sales_tax_to_cost_code',
          'post_sales_tax_to_job_phase',
          'last_purchase_journal_posted',
          'last_inventory_journal_posted',
          'should_reduce_for_lump_sum_jobs',
          'should_reduce_for_lump_sum_non_jobs',
          'is_po_entry_fixed_price',
          'should_filter_closed_purchase_orders',
          'post_accrued_sales_tax_equipment_cost_type_id',
          'should_reduce_for_service_billing_non_history',
          'post_accrued_sales_tax_work_order_cost_type_id',
          'ship_purchase_order_to_multiple_locations',
        ],
        statusOptions: [
          {
            label: this.$t('Pending'),
            value: resourceStatuses.Pending,
          },
          {
            label: this.$t('No Post'),
            value: resourceStatuses.NoPost,
          },
        ],
        formTypeOptions: [
          {
            label: this.$t('1 - po#1 - U/P'),
            value: '1',
          },
          {
            label: this.$t('2 - po#2 - F/P'),
            value: '2',
          },
        ],
        unitRateSourceOptions: [
          {
            label: this.$t('Vendor Price Book'),
            value: 'vendor-price-book',
          },
          {
            label: this.$t('Last P.O. Price'),
            value: 'last-purchase-price',
          },
          {
            label: this.$t('Last Invoice Price'),
            value: 'last-invoice-price',
          },
          {
            label: this.$t('Standard Price'),
            value: 'standard-price',
          },
        ],
        chargeOutMethodOptions: [
          {
            label: this.$t('Standard Unit Cost'),
            value: 'standard-unit-price',
          },
          {
            label: this.$t('Last P.O. Price'),
            value: 'last-purchase-price',
          },
          {
            label: this.$t('Average Inventory Value'),
            value: 'average-inventory-value',
          },
          {
            label: this.$t('Last Vendor / Invoice Price'),
            value: 'last-vendor-invoice-price',
          },
        ],
        shouldAdjustByOptions: [
          {
            label: this.$t('Material Receipt'),
            value: 'material-receipt',
          },
          {
            label: this.$t('Vendor Invoice'),
            value: 'vendor-invoice',
          },
        ],
      }
    },
    computed: {
      equipmentCostTypeOptions() {
        return this.$store.state.globalLists[this.$globalResources.JobCostTypes]
      },
    },
    methods: {
      formatSBCostTypeLabel(option) {
        const { name, abbr } = option.attributes || {}
        return `${name || ''} (${abbr || ''})`
      }
    }
  }
</script>
