<template>
  <AgDataTable
    v-bind="$attrs"
    :url="getUrl"
    :url-params="urlParams"
    :limit-max-height="false"
    :columns="columns"
    :perPage="9999"
    :showPagination="false"
    :groupDefaultExpanded="-1"
    :groupIncludeFooter="true"
    :groupIncludeTotalFooter="true"
    :suppressAggFuncInHeader="true"
    :groupRowRendererParams="groupRowRendererParams"
    :compact="true"
    :no-borders="true"
    :actions="actions"
    :transform-data="transformData"
    hide-actions="filters"
    groupDisplayType="groupRows"
    customHeightClass="h-[800px]"
    @data-fetch="$emit('data-fetch', $event)"
  >
    <template #thead-infos>
      <slot name="thead-infos"/>
    </template>

    <template #attributes.business_id="{ row }">
      <component
        :is="getBusinessLinkComponent(row)"
        :data="get(row, 'relationships.business')"
        :show-description="false"
      />
    </template>
    <template #attributes.reference_no="{ row }">
      <ReferenceLink
        :id="journal.id"
        :data="row"
      />
    </template>
  </AgDataTable>
</template>
<script>
import { journalActions, transactionBusinessLinkByTypes } from '@/enum/enums'
  import TransactionsGroupRow from "@/modules/common/components/journal/TransactionsGroupRow.vue";
import { gridContext } from "@/components/ag-grid/gridContext";

  export default {
    components: {
      TransactionsGroupRow,
    },
    props: {
      authorizedToPrint: Boolean,
      journal: {
        type: Object,
        default: () => ({}),
      },
      actions: {
        type: String,
        default: 'search,refresh',
      },
    },
    computed: {
      columns() {
        return [
          {
            rowGroup: true,
            hide: true,
            field: 'attributes.reference_no',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            minWidth: gridContext.isPrintMode ? 100: 200,
            maxWidth: gridContext.isPrintMode ? 150: 400,
            sortable: true,
          },
          {
            headerName: this.$t('Source'),
            field: 'attributes.source_id',
            minWidth: gridContext.isPrintMode ? 60: 100,
            maxWidth: gridContext.isPrintMode ? 80 : 200,
            component: 'SourceLink',
          },
          {
            headerName: this.$t('Line Item'),
            field: 'attributes.addl_source_id',
            align: 'center',
            minWidth: 120,
            maxWidth: gridContext.isPrintMode ? 150 : 200,
            component: 'AddlSourceLink'
          },
          {
            headerName: this.$t('Account'),
            field: 'account_id',
            minWidth: gridContext.isPrintMode ? 60 : 200,
            maxWidth: gridContext.isPrintMode ? 100 : 250,
            component: 'AccountLink',
          },
          {
            headerName: this.$t('Sub'),
            field: 'relationships.subaccount.attributes.number',
            minWidth: 40,
            maxWidth: 60,
            hide: gridContext.isPrintMode,
          },
          {
            headerName: gridContext.isPrintMode ? this.$t('Business') : this.$t('Vend/Customer/Employee'),
            field: 'attributes.business_id',
            minWidth: gridContext.isPrintMode ? 60 : 100,
            maxWidth: gridContext.isPrintMode ? 100 : 200,
          },
          {
            headerName: this.$t('Qty'),
            field: 'attributes.meta.quantity',
            align: 'center',
            minWidth: gridContext.isPrintMode ? 40 : 50,
            maxWidth: gridContext.isPrintMode ? 50 : 70,
          },
          {
            headerName: this.$t('Unit'),
            field: 'attributes.meta.unit_price',
            minWidth: gridContext.isPrintMode ? 40: 70,
            maxWidth: gridContext.isPrintMode ? 70 : 100,
            align: 'right',
          },
          {
            headerName: this.$t('Debit Amount'),
            field: 'attributes.debit_amount',
            minWidth: gridContext.isPrintMode ? 70: 110,
            maxWidth: gridContext.isPrintMode ? 100: 150,
            component: 'FormattedPrice',
            align: 'right',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Credit Amount'),
            field: 'attributes.credit_amount',
            minWidth: gridContext.isPrintMode ? 70: 110,
            maxWidth: gridContext.isPrintMode ? 100: 150,
            align: 'right',
            aggFunc: 'sum',
            valueGetter: params => {
              if (!params.data) {
                return
              }
              return this.getCreditAmount(params.data)
            },
            valueFormatter: params => {
              if (params.value === undefined) {
                return
              }
              return this.$formatPrice(params.value)
            }
          },
          {
            headerName: this.$t('Ref#'),
            field: 'attributes.reference_no',
            minWidth: gridContext.isPrintMode ? 60: 100,
            maxWidth: gridContext.isPrintMode ? 80 : 120,
            align: 'right',
          },
          {
            headerName: this.$t('Ref Date'),
            field: 'attributes.reference_date',
            component: 'FormattedDate',
            minWidth: gridContext.isPrintMode ? 80: 100,
            maxWidth: gridContext.isPrintMode ? 100 : 120,
            align: 'right',
          },
        ]
      },
      urlParams() {
        return {
          journal_id: this.journal.id,
          related: 'account,subaccount,business[id|name|code]',
          sort: 'reference_no,addlSource',
        }
      },
      getUrl() {
        return this.url || '/restify/transactions'
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'TransactionsGroupRow',
          suppressCount: true,
          journalId: this.journal.id,
        }
      }
    },
    methods: {
      transformData(data) {
        const rows = []
        let summaryRows = {}
        // Group Journal Summary rows by account_id and reference_no for better readability
        data.forEach(row => {
          const { description } = row.attributes
          const toGroup = ['Journal Summary', 'Journal Summa', 'Retention Receivable']
          const isJournalSummaryEntry = toGroup.includes(description)
          if (!isJournalSummaryEntry) {
            rows.push(row)
            return
          }
          const account = this.$store.getters['company/getAccountById'](row.attributes.account_id)
          row.attributes.description = account?.description || description
          const { account_id, reference_no } = row.attributes
          const rowKey = account_id + reference_no
          if (summaryRows[rowKey]) {
            summaryRows[rowKey].attributes.debit_amount += row.attributes.debit_amount
            summaryRows[rowKey].attributes.credit_amount += row.attributes.credit_amount
          } else {
            summaryRows[rowKey] = row
          }
        })
      
        const journalEntries = data.map(row => {
          const attributes = {
            ...row.attributes,
            account: this.$store.getters['company/getCompanyAccount'](row.attributes.account_id)?.number,
            subaccount: this.$store.getters['company/getCompanySubAccount'](row.attributes.subaccount_id)?.name,
            credit_amount: this.getCreditAmount(row),
            debit_amount: row.attributes.debit_amount,
          }
          return {
            id: row.id,
            attributes
          }
        })

        this.$emit('journal-entries-changed', journalEntries)

        rows.push(...Object.values(summaryRows))
        return rows
      },
      getCreditAmount(row) {
        const credit = row.attributes.credit_amount
        return Math.abs(credit)
      },
      getBusinessLinkComponent(row) {
        if (!row) {
          return
        }
        const { business_type } = row.attributes
        return transactionBusinessLinkByTypes[business_type] || 'div'
      },
    },
  }
</script>
