<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :post-url="postUrl"
    :is-print="isPrint"
    :hide-presets="true"
    :consistentRowColor="true"
    :transform-filters="transformFilters"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-3"
        />
      </BaseFilterRow>
      <BaseAlert
        v-if="format === formats.W2_MMRef_EFW2_EFile"
        :type="$promptType.Warning"
        class="mb-4"
      >
        <div class="font-semibold">
          {{ $t('VERY IMPORTANT... BE SURE YOU HAVE COMPLETED THE FOLLOWING BEFORE CONTINUING') }}
        </div>
        <ul class="list-disc list-inside">
          <li>{{ $t('Printed and reviewed W-2 Proof Listing') }}</li>
          <li>{{ $t('Reconciled W-2 amounts with Federal Quarterly Return') }}</li>
        </ul>
      </BaseAlert>
      <template v-if="isW2Print">
        <BaseFilterRow
          :title="$t('Type')"
        >
          <EmployeeW2sTypeSelect
            v-model="model.type"
            class="col-span-3"
            rules="required"
            @change="model.copies = []"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Copies')"
        >
          <EmployeeW2sCopiesSelect
            v-model="model.copies"
            :type="model.type"
            class="col-span-3"
            rules="required"
          />
        </BaseFilterRow>
      </template>
      <BaseFilterRow
        v-if="![
          formats.W2_PaperForms,
          formats.W3_PaperForms
        ].includes(format)"
        :title="$t('State')"
      >
        <StateSelect
          v-model="model.state"
          class="col-span-3"
          clearable
          @clear="model.state = null"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Tax Year')"
      >
        <BaseInput
          v-model="model.tax_year"
          class="col-span-3"
          :name="$t('Tax Year')"
          :placeholder="$t('Tax Year (YYYY)')"
          type="number"
          rules="required|min_value:2024"
        />
      </BaseFilterRow>
      <EmployeeRangeFilter
        v-if="isW2Print"
        v-model="model"
        :title="$t('Employee Range')"
      />
      <BaseFilterRow
        :title="$t('Filter Untaxed Employees')"
      >
        <BaseSwitch
          v-model="model.filter_untaxed_employees"
          id="filter_untaxed_employees"
          class="col-span-3"
        />
      </BaseFilterRow>

      <template
        v-if="[
          formats.W3_PaperForms,
          formats.W2_MMRef_EFW2_EFile
        ].includes(format)"
      >
        <BaseFilterRow
          :title="$t('Contact Person')"
        >
          <BaseInput
            v-model="model.contact_person"
            :placeholder="$t('Contact Person')"
            :name="$t('Contact Person')"
            class="col-span-3"
            :rules="format === formats.W2_MMRef_EFW2_EFile ? 'required' : ''"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Contact Email')"
        >
          <BaseInput
            v-model="model.contact_email"
            :placeholder="$t('Contact Email')"
            :name="$t('Contact Email')"
            class="col-span-3"
            :rules="format === formats.W2_MMRef_EFW2_EFile ? 'required|email' : 'email'"
          />
        </BaseFilterRow>
      </template>
      <template v-if="format === formats.W3_PaperForms">
        <BaseFilterRow
          :title="$t('Phone & Fax')"
        >
          <BasePhoneInput
            v-model="model.phone"
            :placeholder="$t('Phone')"
            :name="$t('Phone')"
            :label="null"
            class="col-span-3"
          />
          <BasePhoneInput
            v-model="model.fax"
            :placeholder="$t('Fax')"
            :name="$t('Fax')"
            :label="null"
            class="col-span-3"
          />
        </BaseFilterRow>
      </template>
      <template v-if="format === formats.W2_MMRef_EFW2_EFile">
        <BaseFilterRow
          :title="$t('User ID # (Contact SSA to obtain)')"
        >
          <BaseInput
            v-model="model.user_ID_number"
            :placeholder="$t('User ID #')"
            :name="$t('User ID #')"
            class="col-span-3"
            rules="required|length:8"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Submittal Type')"
        >
          <BaseSelect
            v-model="model.submittal_type"
            :options="EFW2SubmittalTypeOptions"
            class="col-span-3"
            rules="required"
          />
          <BaseInput
            v-if="model.submittal_type === EmployeeW2SOptions.EFW2_SumbittalType.Resubmittal"
            v-model="model.WFID_code"
            :placeholder="$t('WFID Code (from SSA Resubmit Notice)')"
            :name="$t('WFID Code')"
            layout="horizontal"
            class="col-span-3"
            rules="required|max:6"
          />
        </BaseFilterRow>
      </template>
      <template
        v-if="[
          formats.W3_PaperForms,
          formats.W2_MMRef_EFW2_EFile
        ].includes(format)"
      >
        <BaseFilterRow
          :title="$t('Kind of Employer')"
        >
          <BaseSelect
            v-model="model.employer_kind"
            :options="W3KindOptions"
            class="col-span-6"
            rules="required"
          />
        </BaseFilterRow>
      </template>


      <template v-if="format === formats.W2_MMRef_EFW2_EFile">
        <BaseFilterRow
          :title="$t('Total Federal Income Tax Withheld by Third Party')"
        >
          <BaseInput
            v-model="model.fed_inc_tax_withheld_third_party"
            :placeholder="$t('Federal Income Tax Withheld Third Party')"
            :name="$t('Federal Income Tax Withheld Third Party')"
            type="number"
            class="col-span-3"
            rules="required"
          />
        </BaseFilterRow>

        <BaseFilterRow
          :title="$t('File Name')"
        >
          <BaseInput
            v-model="model.file_name"
            :placeholder="$t('File Name (Optional)')"
            :name="$t('File Name')"
            class="col-span-3"
          />
        </BaseFilterRow>
      </template>
    </template>

    <template #table="{ data, loading }">
      <PdfPreview
        v-if="isPrint"
        :file="data"
        :loading="loading"
      />
      <div v-else-if="isTextFileList">
        <BaseAlert
          v-if="format === formats.W2_MMRef_EFW2_EFile && data?.fileData"
        >
          <div>
            {{ $t('The W-2 upload file has been created.') }}
          </div>
          <div class="font-semibold">
            {{ $t('You should save this file for at least 7 years for possible future reference.') }}
          </div>
        </BaseAlert>

        <TextFileList
          :data="data?.fileData ? [data.fileData] : []"
          :loading="loading"
          :file-name="data.fileName"
        />
      </div>
      <EmployeeW2ProofListingReportTable
        v-else-if="format === formats.W2_ProofListing"
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
      <div v-else>
        Not implemented
      </div>
    </template>
  </BaseReportForm>
</template>
<script>
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import EmployeeW2ProofListingReportTable
  from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2ProofListingReportTable.vue";
import {
  EmployeeW2SOptions,
  W3KindOptions,
  EFW2SubmittalTypeOptions,
} from '@/modules/payroll/components/reports/util'
import { EmployeeRangeFilter } from "@/components/range-filters";
import PdfPreview from "@/components/common/PdfPreview.vue";
import EmployeeW2sCopiesSelect from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2sCopiesSelect.vue";
import EmployeeW2sTypeSelect from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2sTypeSelect.vue";

export default {
  components: {
    EmployeeW2sTypeSelect,
    EmployeeW2sCopiesSelect,
    PdfPreview,
    EmployeeRangeFilter,
    TextFileList,
    EmployeeW2ProofListingReportTable,
  },
  data() {
    return {
      formats: EmployeeW2SOptions.Formats,
      format: EmployeeW2SOptions.Formats.W2_ProofListing,
      EmployeeW2SOptions,
      W3KindOptions,
      EFW2SubmittalTypeOptions,
      model: {
        single_page: false,
        state: null,
        tax_year: null,
        filter_untaxed_employees: true,
        employee_code_from: null,
        employee_code_to: null,
        copies: [],
        type: EmployeeW2SOptions.Types.Traditional,
        // for W3
        phone: null,
        fax: null,
        contact_email: null,
        contact_person: null,
        employer_kind: EmployeeW2SOptions.W3Kinds.None,
        // for W2_MMRef_EFW2_EFile
        user_ID_number: null,
        submittal_type: EmployeeW2SOptions.EFW2_SumbittalType.Original,
        WFID_code: null, // required for submittal_type = EFW2_SumbittalType.Resubmittal
        fed_inc_tax_withheld_third_party: 0,
        file_name: null, // optional
      },
    }
  },
  computed: {
    isW2Print() {
      return [EmployeeW2SOptions.Formats.W2_PaperForms].includes(this.format)
    },
    isW3Print() {
      return [EmployeeW2SOptions.Formats.W3_PaperForms].includes(this.format)
    },
    isPrint() {
      return [
        EmployeeW2SOptions.Formats.W2_PaperForms,
        EmployeeW2SOptions.Formats.W3_PaperForms
      ].includes(this.format)
    },
    reportNumber() {
      const reportMap = {
        [EmployeeW2SOptions.Formats.W2_MMRef_EFW2_EFile]: '4884c',
        default: '4884',
      }
      return reportMap[this.format] || reportMap.default
    },
    postUrl() {
      if (this.format === EmployeeW2SOptions.Formats.W2_PaperForms) {
        return '/restify/employees/actions?action=print-w2s'
      }
      if (this.format === EmployeeW2SOptions.Formats.W3_PaperForms) {
        return '/restify/employees/actions?action=print-w3'
      }
    },
    formatOptions() {
      const formatOptions = [
        {
          label: this.$t('W-2 Proof Listing'),
          value: EmployeeW2SOptions.Formats.W2_ProofListing,
        },
        {
          label: this.$t('Print W-2 Paper Forms'),
          value: EmployeeW2SOptions.Formats.W2_PaperForms,
        },
        {
          label: this.$t('Print W-3 Paper Forms'),
          value: EmployeeW2SOptions.Formats.W3_PaperForms,
        },
        {
          label: this.$t('W-2 MMREF/EFW2 E-File'),
          value: EmployeeW2SOptions.Formats.W2_MMRef_EFW2_EFile,
        },
        // {
        //   label: this.$t('W-2 CSV Export'),
        //   value: EmployeeW2SOptions.Formats.W2_CSVExport,
        // },
      ]

      return formatOptions
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    isTextFileList() {
      return [
        this.formats.W2_MMRef_EFW2_EFile,
      ].includes(this.format)
    },
    payrollCalendarYear() {
      return this.$settings(this.$modules.PR, 'calendar_year')
    },
  },
  methods: {
    transformFilters(params) {
      params.filter_untaxed_employees = !!params.filter_untaxed_employees
      return params
    }
  },
  watch: {
    payrollCalendarYear: {
      handler(value) {
        if (this.model.tax_year) {
          return
        }

        const priorYear = value - 1
        this.model.tax_year = priorYear
      },
      immediate: true,
    },
  }
}
</script>
