<template>
  <BaseDataTable
      v-bind="$attrs"
      :url="url"
      :columns="columns"
      :per-page="perPage"
      :url-params="urlParams"
      :limit-max-height="false"
      :show-actions-column="false"
      :can-reorder-columns="false"
      default-sort="-posted_at"
      actions="search,refresh"
      class="all-journals"
      ref="table">
    <template #additional-actions>
      <div class="flex items-end">
        <base-select v-model="selectedTypes"
                     :placeholder="$t('Journals per modules')"
                     :collapse-tags="true"
                     :options="types"
                     class="w-64 md:w-72"
                     label=""
                     multiple
                     inline-errors
        />
      </div>
    </template>
    <template #report-header>
      <BaseReportHeader :header="reportHeader"/>
    </template>
    <template #attributes.code="{row}">
      <base-tooltip :content="getModuleByCode(row.attributes)">
          <span>
            {{ row.attributes.code }}
          </span>
      </base-tooltip>
    </template>
    <template #period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
  </BaseDataTable>
</template>
<script>
  import { journalTypes } from '@/enum/journal'

  export default {
    data() {
      return {
        perPage: 150,
        selectedTypes: Object.values(journalTypes),
        url: '/restify/journals',
        types: [
          {
            label: this.$t('GL - General Journal'),
            value: journalTypes.GL,
          },
          {
            label: this.$t('JC - Job Cost/Income Journal'),
            value: journalTypes.JobCosting,
          },
          {
            label: this.$t('AP - Purchase Journal'),
            value: journalTypes.AccountsPayable,
          },
          {
            label: this.$t('CD - Cash Disbursment Journal'),
            value: journalTypes.CashDisbursement,
          },
          {
            label: this.$t('PR - Payroll Journal'),
            value: journalTypes.Payroll,
          },
          {
            label: this.$t('AR - Sales Journal'),
            value: journalTypes.AccountsReceivable,
          },
          {
            label: this.$t('CR - Cash Receipts Journal'),
            value: journalTypes.CashReceipts,
          },
          {
            label: this.$t('EQ - Equipment Journal'),
            value: journalTypes.Equipment,
          },
          {
            label: this.$t('IV - Inventory Journal'),
            value: journalTypes.Inventory,
          },
          {
            label: this.$t('SB - Service Billing Journal'),
            value: journalTypes.ServiceBilling,
          },
        ],
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Module'),
            prop: 'attributes.code',
            align: 'center',
            minWidth: 40,
            maxWidth: 60,
          },
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 80,
            maxWidth: 150,
            component: 'EntityLink',
            redirectTo: '/ledger/journal/{ID}/view',
          },
          {
            label: this.$t('Created By'),
            prop: 'attributes.created_by',
            minWidth: 100,
            maxWidth: 150,
            component: 'UserLink',
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.type',
            minWidth: 100,
            maxWidth: 150,
            component: 'Status',
          },
          {
            label: this.$t('Period'),
            prop: 'period',
            minWidth: 100,
            maxWidth: 180,
          },
          {
            label: this.$t('Year'),
            prop: 'attributes.fiscal_year',
            minWidth: 60,
            maxWidth: 100,
          },
          {
            label: this.$t('Posted At'),
            prop: 'attributes.posted_at',
            minWidth: 160,
            maxWidth: 200,
            component: 'FormattedDate',
            params: {
              format: 'datetime',
            },
          },
          {
            label: this.$t('Posted By'),
            prop: 'attributes.posted_by',
            minWidth: 120,
            maxWidth: 170,
            component: this.$tableColumns.UserLink,
          },
        ]
      },
      reportHeader() {
        return {
          company: this.$currentCompany,
          title: 'All Journals',
        }
      },
      urlParams() {
        return {
          code: this.selectedTypes.join(','),
        }
      },
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      getModuleByCode(row) {
        const { code } = row
        const type = this.types.find(type => type.value === code)
        return type?.label || ''
      },
    },
  }
</script>
