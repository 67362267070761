<template>
  <AgDataTable
    v-bind="editableTableProps"
    :url="url"
    :per-page="9999"
    :columns="columns"
    :url-params="urlParams"
    :add-text="$t('New journal entry')"
    :actions="tableActions"
    :transform-data="mapData"
    :get-empty-row="getEmptyEntry"
    :groupIncludeTotalFooter="true"
    :show-cells-legend="showCellsLegend"
    :no-borders="readOnly"
    :read-only="readOnly"
    :show-pagination="false"
    hide-actions="filters"
    ref="gridTable"
    id="gridTable"
    @grid-ready="grid = $event"
  >

    <template #header-info>
      <DistributionWarning
        :batch-amount="0"
        :entries-amount="remainingAmount()"
        batch-name="journal"
      >
        <IconWarning class="w-5 h-5"/>
        <span class="mx-1">
          {{ $t('Remaining amount') }}: {{ $formatPrice(remainingAmount()) }}
        </span>
      </DistributionWarning>
    </template>
  </AgDataTable>
</template>
<script>
  import sumBy from 'lodash/sumBy'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { editableTableProps, getTableData, storeBatchEntriesProgress } from '@/components/ag-grid/tableUtils'
  import { accountCol, descriptionCol, subAccountCol } from '@/components/ag-grid/columns/costCenterColumns'
  import { cellClasses, getCellClasses, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
  import { getEditablePriceCol } from '@/components/ag-grid/columns/editableColumns'

  const amountFields = {
    DebitFieldKey: 'debit_amount',
    CreditFieldKey: 'credit_amount',
  }

  const ResourceEndpoint = '/restify/journal-entries'

  export default {
    props: {
      journal: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      showCellsLegend: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        editableTableProps,
        grid: null,
      }
    },
    computed: {
      tableActions() {
        return this.readOnly ? '' : 'add,delete'
      },
      url() {
        return this.journal.id ? ResourceEndpoint : ''
      },
      urlParams() {
        return this.journal.id ? {
          journal_id: this.journal.id,
          sort: 'number',
        } : {}
      },
      columns() {
        const columns = [
          {
            ...accountCol(this.readOnly),
            cellClass: getCellClasses,
            minWidth: 200,
            maxWidth: 350,
          },
          {
            ...subAccountCol,
            minWidth: 80,
            maxWidth: 120,
          },
          {
            ...descriptionCol,
            minWidth: null,
            maxWidth: null,
            suppressKeyboardEvent: params => {
              if (this.journal.type !== 'single_period') {
                return
              }
              stopEditingOnTab(params)
            },
          },
          {
            headerName: this.$t('Reverse Next Period'),
            field: 'reverse_next_period',
            renderIf: () => this.journal.type === 'single_period',
            suppressKeyboardEvent: stopEditingOnTab,
            component: 'Status',
            cellEditor: this.$cellEditors.Boolean,
            minWidth: 80,
            maxWidth: 120,
            cellEditorParams: {
              plain: true,
            },
            editable: true,
          },
          {
            ...getEditablePriceCol({
              headerName: this.$t('Debit Amount'),
              field: 'debit_amount',
              minWidth: 110,
              maxWidth: 140,
              cellClassRules :{
                [cellClasses.Invalid]: params => {
                  const { debit_amount, credit_amount } = params?.data || {}
                  return !debit_amount && !credit_amount && params?.data
                }
              },
              onChange(params) {
                if (params.newValue) {
                  params.data.credit_amount = 0
                }
                if (params.newValue < 0) {
                  params.data.debit_amount = Math.abs(params.newValue)
                }
              }
            }),
          },
          {
            ...getEditablePriceCol({
              headerName: this.$t('Credit Amount'),
              field: 'credit_amount',
              minWidth: 110,
              maxWidth: 140,
              cellClassRules: {
                [cellClasses.Invalid]: params => {
                  const { debit_amount, credit_amount } = params?.data || {}
                  return !debit_amount && !credit_amount && params?.data
                }
              },
              onChange(params) {
                if (params.newValue) {
                  params.data.debit_amount = 0
                }
                if (params.newValue < 0) {
                  params.data.credit_amount = Math.abs(params.newValue)
                }
              }
            },),
          },
        ]

        return columns.filter((column) => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      getData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi)
      },
      getAmounts() {
        const rows = this.getData().map(row => {
          return {
            ...row,
            debit_amount: Number(row.debit_amount || 0),
            credit_amount: Number(row.credit_amount || 0),
          }
        })

        const debit = sumBy(rows, 'debit_amount')
        const credit = sumBy(rows, 'credit_amount')

        return {
          debit,
          credit,
        }
      },
      remainingAmount() {
        const { debit, credit } = this.getAmounts()
        return Math.abs(debit - credit)
      },
      mapData(data) {
        this.$emit('journal-entries-changed', data)

        return data.map(entry => {
          return {
            ...entry.attributes,
            meta: entry.meta,
          }
        })
      },
      async storeProgress(journal_id) {
        const gridApi = this.grid.api
        await storeBatchEntriesProgress(gridApi, 'journal-entries', { journal_id })
        this.refreshData()
      },
      refreshData() {
        this.$refs.gridTable.refresh()
      },
      getEmptyEntry() {
        const { description } = this.getData().length ? this.getData().at(-1) : {}

        this.tryCollapseFormHeader()

        return {
          journal_id: this.journal?.id,
          account: '',
          subaccount: '',
          debit_amount: 0,
          credit_amount: 0,
          description,
          reverse_next_period: false,
          post_to_gl: true,
          cost_center: costCenterTypes.GeneralAndAdministrative,
          _localId: crypto.randomUUID(),
        }
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
    },
  }
</script>
