<template>
  <div>
    <template v-if="entityId">
      <BaseDataTable :columns="columns"
                     :data="getAddresses"
                     :can-reorder-columns="false"
                     :show-pagination="showPagination"
                     :delete-action="deleteAddress"
                     :open-entity-in-new-tab="openEntityInNewTab"
                     :add-text="$t('New address')"
                     :limitMaxHeight="false"
                     add-button-variant="primary-light"
                     ref="table"
                     actions="add,delete,edit"
                     @add="onRowAdd"
                     @edit="onRowEdit"
                     small-header
                     compact
      >
        <template #custom-header>
          <h4>
            {{ $t(title) }}
          </h4>
        </template>
        <template #attributes.primary="{row}">
          <status-badge :status="row.attributes.primary"/>
        </template>
      </BaseDataTable>
      <BaseFormDialog v-if="showManageDialog"
                      :title="rowToEdit ? $t('Edit Address') : $t('Add Address')"
                      :open.sync="showManageDialog"
                      @close="onCloseManageDialog"
      >
        <AddressForm :entity="entity"
                     :save-text="rowToEdit ? $t('Edit Address') : $t('Add Address')"
                     :entity-id="entityId"
                     :data="rowToEdit"
                     :resource="resource"
                     layout="modal"
                     @on-add="onAddTemporaryAddress"
                     @save="onCloseManageDialog"
                     @cancel="onCloseManageDialog"
        />
      </BaseFormDialog>
    </template>
    <template v-else>
      <AddressForm
        :entity="entity"
        :save-text="temporaryAddresses.length > 0 ? $t('Update Address') : $t('Add Address')"
        :entity-id="entityId"
        :data="rowToEdit"
        :resource="resource"
        :primary="getAddresses?.length === 0"
        :show-cancel="false"
        layout="modal"
        @on-add="onAddTemporaryAddress($event, 0)"
        @save="onCloseManageDialog"
        @cancel="onCloseManageDialog"
      />
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import AddressForm from '@/modules/settings/components/AddressForm'
import { EntityNameMap, getAddressableType } from "@/enum/enums";

export default {
  components: { AddressForm },
  props: {
    showPagination: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Addresses',
    },
    data: {
      type: Array,
      default: () => [],
    },
    entityId: {
      type: [String, Number],
      default: '',
    },
    resource: {
      type: Object,
      default: () => ({}),
    },
    entity: String,
  },
  data() {
    return {
      openEntityInNewTab: false,
      showManageDialog: false,
      temporaryAddresses: this.data || [],
      rowToEdit: null,
    }
  },
  computed: {
    getAddresses() {
      return this.entityId ? this.data : this.temporaryAddresses
    },
    columns() {
      return [
        {
          label: this.$t('Address'),
          prop: 'attributes',
          maxWidth: 200,
          minWidth: 160,
          component: 'AddressLink',
        },
        {
          label: this.$t('Zip'),
          prop: 'attributes.postal_code',
          maxWidth: 70,
        },
        {
          label: this.$t('Type'),
          prop: 'attributes.type',
          maxWidth: 100,
          component: 'Status',
          align: 'center',
        },
        {
          label: this.$t('Primary'),
          prop: 'attributes.primary',
          maxWidth: 100,
          align: 'center',
        },
        {
          label: this.$t('Active'),
          prop: 'attributes.active',
          maxWidth: 100,
          component: 'Status',
          align: 'center',
        },
      ]
    },
  },
  methods: {
    onAddTemporaryAddress(address = {}, addressIndex) {
      const addressObject = {
        attributes: { ...address }
      }
      const addrIndex = address.index || addressIndex

      if (addrIndex !== undefined) {
        this.$set(this.temporaryAddresses, addrIndex, addressObject)
      } else {
        this.temporaryAddresses.push(addressObject)
      }
      this.showManageDialog = false
      if (this.entityId) {
        this.$refs.table.assignData(this.temporaryAddresses)
      } else {
        const entityName = EntityNameMap[this.entity] || 'Entity'
        this.$success(this.$t(`Address added. Make sure to save the ${entityName} to persist the address.`))
      }
    },
    onRowEdit(row, index) {
      row['index'] = index
      this.showManageDialog = true
      this.rowToEdit = row
    },
    async onRowAdd() {
      this.rowToEdit = null
      await this.$nextTick()
      this.showManageDialog = true
    },
    onCloseManageDialog() {
      this.showManageDialog = false
      this.refreshData()
    },
    async deleteAddress(row, index) {
      const addressId = row.id
      if (!addressId) {
        return this.temporaryAddresses.splice(index, 1)
      }

      await axios.delete(`restify/addresses/${addressId}`)
      this.refreshData()
    },
    async onPrimaryChange(row, value) {
      this.$set(row, 'attributes.primary', value)
      const addressId = row.id
      if (!addressId) {
        return
      }
      try {
        await axios.post(`restify/addresses/${addressId}/actions?action=make-primary`, {})
        this.refreshData()
      } catch (err) {
        console.warn(err)
        this.$set(row, 'primary', !value)
      }
    },
    async attachTemporaryAddresses(entityId) {
      if (!this.temporaryAddresses.length) {
        return
      }
      const savePromises = this.temporaryAddresses.map(
        ({ attributes }) => axios.post(`restify/${this.entity}/${entityId}/addresses`, {
          addressable_type: getAddressableType(this.entity),
          addressable_id: entityId,
          ...attributes
        }),
      )
      await Promise.all(savePromises)
    },
    refreshData() {
      this.$refs.table.forceRefresh()
      this.$emit('refresh')
    },
  },
}
</script>
