<template>
  <ElCollapse
    v-model="expandedSections"
    class="col-span-8 release-retention collapse-form"
  >
    <base-collapse-section
        key="form-header"
        name="form-header"
        class="mb-6 collapse-item"
        wrapperClasses="ml-0"
        hasBackground
    >
      <template #title>
        <div class="pl-4">
          {{ $t('Filters') }}
        </div>
      </template>
      <base-form layout="vertical"
                 class="subcontract-progress-filters"
                 :loading="loading"
                 :save-text="$t('Proceed')"
                 @submit="onSubmit"
      >
        <JobRangeFilter class="col-span-6" v-model="model"/>
        <VendorRangeFilter
          v-if="module === modules.AccountsPayable"
          v-model="model"
          class="col-span-6"
        />
        <CustomerRangeFilter
          v-if="module === modules.AccountsReceivable"
          v-model="model"
          class="col-span-6"
        />
      </base-form>
    </base-collapse-section>
  </ElCollapse>
</template>
<script>
  import JobRangeFilter from "@/components/range-filters/JobRangeFilter.vue";
  import VendorRangeFilter from "@/components/range-filters/VendorRangeFilter.vue";
  import { modules } from "@/modules/common/components/settings/util";
  import { CustomerRangeFilter } from "@/components/range-filters";

  export default {
    computed: {
      modules() {
        return modules
      }
    },
    components: {
      VendorRangeFilter,
      CustomerRangeFilter,
      JobRangeFilter
    },
    props: {
      module: {
        type: String,
        default: modules.AccountsPayable,
      },
    },
    data() {
      return {
        loading: false,
        expandedSections: ['form-header'],
        model: {
          job_number_from: undefined,
          job_number_to: undefined,
          vendor_code_from: undefined,
          vendor_code_to: undefined,
          customer_code_from: undefined,
          customer_code_to: undefined,
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('save', this.model)
        this.expandedSections = []
      }
    }
  }
</script>
<style lang="scss">
.release-retention {
  @apply shadow;
  .form__inner {
    @apply shadow-none;
  }
}
</style>
