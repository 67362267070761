<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Transfer Open Balances')"
    :append-to-body="true"
    size="sm"
  >
    <BaseForm
      :saveText="$t('Confirm')"
      :showCancel="true"
      :loading="loading"
      :submitDisabled="!canSubmit"
      layout="modal"
      @submit="onTransferBalances"
      @cancel="$emit('close')"
    >
      <div class="col-span-6 text-gray-800 text-sm">
        <p>{{ alertMessage }}</p>
        <div v-if="canSubmit">
          <span
            v-html="$t('transfer balances message', {
              from_year: Number(fiscal_year) - 1,
              to_year: fiscal_year,
            })"
          />
        </div>
        <template v-else-if="preflightError">
          <BaseAlert
            v-if="errorCode === 500 || !errorMessages.length"
            :type="$promptType.Danger"
          >
            <p>{{ $t('Error while checking if balances can be transferred.') }}</p>
            <p>{{ $t('Something went wrong, please try again later.') }}</p>
          </BaseAlert>
          <div v-else>
            <p>{{ $t('Balances cannot be transferred. Please fix the errors below to continue:') }}</p>
            <ul class="list-disc list-inside mt-2">
              <li
                v-for="error in errorMessages"
                :key="error"
              >
                {{ error }}
              </li>
            </ul>
          </div>
          <BaseButton
            class="mt-2"
            variant="white"
            :loading="checkingCanTransferBalances"
            @click="checkCanTransferBalances"
            size="xs"
          >
            <RepeatIcon class="mr-2 w-3 h-3" />
            {{ $t('Check Again') }}
          </BaseButton>
        </template>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import axios from 'axios'
import { get } from 'lodash'
import { RepeatIcon } from 'vue-feather-icons'

export default {
  components: {
    RepeatIcon
  },
  props: {
    fiscal_year: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      checkingCanTransferBalances: false,
      loading: false,
      preflightError: null as null | any,
    }
  },
  computed: {
    alertMessage() {
      if (this.checkingCanTransferBalances) {
        return this.$t('Checking if balances can be transferred...')
      }

      return ''
    },
    canSubmit() {
      if (this.checkingCanTransferBalances) {
        return false
      }

      return !this.preflightError
    },
    preflightUrl() {
      return `/restify/fiscal-years/actions?action=transfer-fiscal-year-balances-preflight`
    },
    submitUrl() {
      return '/restify/fiscal-years/actions?action=transfer-fiscal-year-balances'
    },
    errorCode() {
      return this.preflightError?.response?.status
    },
    errorMessages() {
      let errors = get(this.preflightError, 'response.data.errors.fiscal_year')
      if (!errors) {
        errors = get(this.preflightError, 'response.data.data') || []
      }

      return errors
    }
  },
  methods: {
    async checkCanTransferBalances() {
      try {
        this.preflightError = null
        this.checkingCanTransferBalances = true
        await axios.post(this.preflightUrl, {
          fiscal_year: this.fiscal_year,
        })
      }
      catch (err: any) {
        this.preflightError = err
      }
      finally {
        this.checkingCanTransferBalances = false
      }
    },
    async onTransferBalances() {
      try {
        this.loading = true
        await axios.post(this.submitUrl, {
          fiscal_year: this.fiscal_year,
        })

        this.$emit('balances-transferred')
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.checkCanTransferBalances()
  }
}
</script>
