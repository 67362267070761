<template>
  <div>
    <BaseFilterRow
      v-if="showTemplateSelect"
      :title="$t('Template')"
    >
      <BaseSelect
        v-model="value.template_type"
        :add-entity="false"
        :options="templateTypeOptions"
        :disabled="disabled"
        :class="{
          [inputColSpanClass]: true,
        }"
        @change="onTemplateTypeChange"
      />
    </BaseFilterRow>
    <BaseFilterRow
      v-if="showFormTypesSelect"
      :title="$t('Form Types')"
    >
      <BaseSelect
        v-model="value.form_types"
        :add-entity="false"
        :options="formTypeOptions"
        :multiple="true"
        :disabled="disabled"
        :class="{
          [inputColSpanClass]: true,
        }"
      />
    </BaseFilterRow>
    <BaseFilterRow
      v-if="value.template_type === Vendor1099sReportOptions.TemplateTypes['1099']"
      :title="$t('Copies')"
    >
      <BaseSelect
        v-model="value.copies"
        :add-entity="false"
        :options="copyOptions"
        :multiple="true"
        :collapse-tags="true"
        :class="{
          [inputColSpanClass]: true,
        }"
        :name="$t('Copies')"
        rules="required"
      />
    </BaseFilterRow>
    <VendorRangeFilter
      v-if="showVendorRangeFilter"
      v-model="value"
      :gridClass="inputColSpanClass"
      :availableIds="filterableVendorIds"
    />
  </div>
</template>
<script>
import {
  VendorRangeFilter,
} from '@/components/range-filters'

import {
  Vendor1099sReportOptions
} from '@/modules/accounts-payable/components/reports/util'

export default {
  components: {
    VendorRangeFilter,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    tax_year: {
      type: [Number, String],
      required: true
    },
    showVendorRangeFilter: {
      type: Boolean,
      default: true,
    },
    inputColSpanClass: {
      type: String,
      default: 'col-span-4',
    },
    filterableVendorIds: {
      type: Array,
      default: () => [],
    },
    showRecipientOnlyOptions: {
      type: Boolean,
      default: false,
    },
    showTemplateSelect: {
      type: Boolean,
      default: true,
    },
    showFormTypesSelect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultCopies: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    Vendor1099sReportOptions() {
      return Vendor1099sReportOptions
    },
    templateTypeOptions() {
      return [
        {
          value: Vendor1099sReportOptions.TemplateTypes["1099"],
          label: this.$t('1099 Forms')
        },
        {
          value: Vendor1099sReportOptions.TemplateTypes["1096"],
          label: this.$t('1096 Forms')
        }
      ]
    },
    formTypeOptions() {
      if (this.value.template_type === Vendor1099sReportOptions.TemplateTypes["1096"]) {
        return [
          {
            label: this.$t('1096-MISC'),
            value: Vendor1099sReportOptions.FormTypes.MISC,
          },
          {
            label: this.$t('1096-NEC'),
            value: Vendor1099sReportOptions.FormTypes.NEC,
          }
        ]

      }
      return [
        {
          label: this.$t('1099-MISC'),
          value: Vendor1099sReportOptions.FormTypes.MISC,
        },
        {
          label: this.$t('1099-NEC'),
          value: Vendor1099sReportOptions.FormTypes.NEC,
        }
      ]
    },
    copyOptions() {
      const options = [
        {
          label: this.$t(`1099 Copy A (Payer's Federal Pre-Printed)`),
          value: Vendor1099sReportOptions.Copies.A,
          showIf: () => !this.showRecipientOnlyOptions
        },
        {
          label: this.$t(`1099 Copy 1 (Payer's State)`),
          value: Vendor1099sReportOptions.Copies.One,
          showIf: () => !this.showRecipientOnlyOptions
        },
        {
          label: this.$t(`1099 Copy B (Recipient's Records)`),
          value: Vendor1099sReportOptions.Copies.B
        },
        {
          label: this.$t(`1099 Copy 2 (Recipient's State)`),
          value: Vendor1099sReportOptions.Copies.Two
        },
        {
          label: this.$t(`1099 Copy C (Recipient's Federal)`),
          value: Vendor1099sReportOptions.Copies.C,
          showIf: () => Number(this.tax_year || 0) < 2024
        },
      ]

      return options.filter(option => !option.showIf || option.showIf())
    },
  },
  methods: {
    onYearChanged() {
      this.value.copies = this.defaultCopies.length
        ? this.defaultCopies
        : this.copyOptions.map(option => option.value)
    },
    onTemplateTypeChange() {
      if (this.value.template_type !== Vendor1099sReportOptions.TemplateTypes["1096"]) {
        this.value.copies = []
      }
    }
  },
  watch: {
    tax_year: {
      handler (value) {
        if (!value) {
          return
        }

        this.onYearChanged()
      },
      immediate: true,
    }
  }
}
</script>
