<template>
  <AgDataTable
    v-bind="editableTableProps"
    :columns="columns"
    :url="url"
    :url-params="urlParams"
    :per-page="500"
    :table-filters="!!data.id"
    :add-text="$t('New rate')"
    :transform-data="mapData"
    :get-empty-row="getEmptyEntry"
    :show-pagination="true"
    :read-only="readOnly"
    :actions="readOnly ? '' : 'add'"
    ref="gridTable"
    @cell-focused="onCellFocused"
    @grid-ready="grid = $event"
  />
</template>
<script>
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
import { requiredValueSetter } from '@/components/ag-grid/columnUtils'
import {
  codeTypes,
  composeCodeApplication,
  ratesSourceTypeOptions,
  ratesSourceMap
} from '@/modules/payroll/components/rates/util'
import { calculateAmountAs, defaultCraftCode } from '@/enum/payroll'
import { editableTableProps, getRowData, getTableData } from '@/components/ag-grid/tableUtils'
import orderBy from 'lodash/orderBy'
import i18n from '@/i18n'
import { sourceCol, updateColumnHeader } from '@/components/ag-grid/columns/costCenterColumns'
import { globalResources } from '@/components/form/util'
import axios from 'axios'
import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'

const sourceColumnTitle = {
  [codeTypes.PAY]: 'Pay Code',
  [codeTypes.BEN]: 'Benefit Code',
  [codeTypes.DEDUCTION]: 'Deduction Code',
  [codeTypes.TAX]: 'Tax Code',
  default: 'Union Code',
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    apiUrl: {
      type: String,
      default: '/restify/job-rates',
    },
    selectedDate: String,
    isUnionRate: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableTableProps,
      entries: [],
      grid: null,
    }
  },
  computed: {
    url() {
      return this.data.id ? this.apiUrl : ''
    },
    urlParams() {
      if (!this.data.id) {
        return {}
      }

      let params = {}
      if (this.selectedDate === 'active') {
        params.active = true
      } else {
        params.start_date = this.selectedDate
      }
      if (this.isUnionRate) {
        params.union_id = this.data.id
      } else {
        params.job_id = this.data.id
      }

      return params
    },
    columns() {
      return [
        {
          headerName: this.$t('Craft Code'),
          field: 'craft_code_id',
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          cellEditor: cellEditors.GlobalResourceSelect,
          component: 'CraftCodeLink',
          cellRendererParams: {
            target: '_blank',
          },
          cellEditorParams: {
            clearable: true,
            resourceName: this.$globalResources.AllCraftsAndLevels,
          },
          valueSetter: params => {
            params.data.craft_code_id = params.newValue
            const resource = this.findCraftCode(params.newValue)

            if (!resource?.level) {
              return true
            }

            params.data.craft_level = resource.level
            params.data.craft_code = resource.code
            return true
          },
          valueFormatter: params => {
            const craftCode = this.findCraftCode(params.value)
            let { code, description } = craftCode || {}
            if (!code) {
              code = params.data.craft_code || ''
              return code
            }
            return `${ code } (${ description })`
          },
          minWidth: 50,
        },
        {
          headerName: this.$t('Level'),
          field: 'craft_level',
          align: 'center',
          headerClass: 'flex justify-center',
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          cellEditor: cellEditors.BaseSelect,
          cellEditorParams: params => {
            return {
              options: this.getLevelOptions(params.data),
            }
          },
          valueSetter: (params) => {
            const craftCode = this.findCraftCodeByCodeAndLevel(params.data.craft_code, params.newValue)
            if (craftCode?.id) {
              params.data.craft_code_id = craftCode.id
              params.data.craft_code = craftCode.code
            } else {
              params.data.craft_code_id = null
            }
            return requiredValueSetter(params)
          },
          minWidth: 50,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Type'),
          field: 'source_type',
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          cellEditor: cellEditors.BaseSelect,
          cellEditorParams: {
            options: ratesSourceTypeOptions,
          },
          valueSetter: params => {
            params.data.source_id = ''
            return requiredValueSetter(params)
          },
          valueFormatter: params => {
            const option = ratesSourceTypeOptions.find(option => option.value === params.value)
            return option?.label || params.value
          },
          minWidth: 60,
          maxWidth: 80,
        },
        {
          ...sourceCol,
          editable: this.canEditRow,
          cellClass: this.fieldCellClass,
          component: 'RatesLink',
          cellRendererParams: {
            target: '_blank',
          },
          cellEditorParams: params => {
            const { source_type } = params.data
            const resourceName = ratesSourceMap[source_type]

            return {
              resourceName,
              unauthorizedToAdd: false,
            }
          },
          valueSetter: params => {
            params.data.source_id = params.newValue
            this.onChangeSource(params.data)
            return true
          },
          minWidth: 150,
        },
        {
          headerName: this.$t('Code Application'),
          field: 'code_application',
          cellClass: 'readonly-ag-cell',
          minWidth: 150,
          maxWidth: 250,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Calculate By'),
          field: 'calculate_by',
          cellClass: 'readonly-ag-cell',
          component: 'FormattedText',
          minWidth: 100,
          maxWidth: 150,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Multiply By'),
          field: 'multiply_by',
          cellClass: 'readonly-ag-cell',
          component: 'FormattedText',
          minWidth: 100,
          maxWidth: 150,
          suppressNavigable: true,
        },
        {
          headerName: this.$t('Amount'),
          field: 'rate',
          editable: this.isRateEditable,
          cellEditor: cellEditors.Numeric,
          minWidth: 50,
          maxWidth: 150,
          cellClass: params => {
            const isEditable = this.isRateEditable(params)
            return isEditable ? 'flex justify-end' : 'readonly-ag-cell flex justify-end'
          },
          valueFormatter: params => {
            const { calculate_by } = params.data
            if (calculate_by === calculateAmountAs.Percentage) {
              return this.$formatPercent(params.value, {
                maximumFractionDigits: 3,
              })
            }
            return this.$formatPrice(params.value)
          },
          suppressNavigable: params => !this.isRateEditable(params),
          suppressKeyboardEvent: params => {
            let isTabKey = params.event.key === 'Tab'
            if (isTabKey) {
              params.api.stopEditing()
            }
          },
        },
        {
          ...getDeleteColumn({
            title: this.$t('Delete Rate'),
            url: this.apiUrl,
            hide: params => {
              if (params?.data?.is_used) {
                return true
              }
              return this.readOnly
            },
          }),
        },
      ]
    },
    craftCodes() {
      return this.$store.state.globalLists[this.$globalResources.CraftCodes]
    },
  },
  methods: {
    canEditRow(params) {
      return !params?.data?.is_used
    },
    fieldCellClass(params) {
      if (this.canEditRow(params)) {
        return ''
      }
      return 'readonly-ag-cell'
    },
    mapData(data) {
      let entries = data.map(rate => {
        const code_application = composeCodeApplication(rate.attributes)
        const craft_code_id = this.findCraftCodeId(rate.attributes)
        const craftCode = this.findCraftCode(craft_code_id)
        return {
          ...rate.attributes,
          craftCode,
          craft_code_id,
          code_application,
        }
      })
      entries = orderBy(entries, ['craftCode.code', 'craftCode.level'])
      this.entries = entries
      return this.entries
    },
    getEmptyEntry() {
      const entry = {
        craft_code: null,
        craft_code_id: null,
        craft_level: null,
        source_id: null,
        rate: 0,
        source_type: codeTypes.PAY,
        _localId: crypto.randomUUID(),
      }

      return {
        ...entry,
        _localId: crypto.randomUUID(),
        start_date: this.selectedDate,
        end_date: null,
      }
    },
    isRateEditable(params) {
      const { source_type, is_used } = params.data
      return source_type !== codeTypes.UNION && !is_used
    },
    findCraftCodeId(rate = {}) {
      const { craft_code, craft_level } = rate
      if (craft_level === defaultCraftCode) {
        return craft_code
      }

      const { id } = this.craftCodes.find(crCode => crCode.code === craft_code && crCode.level === craft_level) || {}
      return id
    },
    findCraftCode(craftCodeId) {
      return this.craftCodes.find(crCode => crCode.id === craftCodeId) || {}
    },
    findCraftCodeByCodeAndLevel(code, level) {
      return this.craftCodes.find(crCode => crCode.code === code && crCode.level === level) || {}
    },
    findResource({ source_id, source_type }) {
      const resourceName = ratesSourceMap[source_type] || ratesSourceMap.default
      const resourceList = this.$store.getters['globalLists/getResourceList'](resourceName)
      return resourceList.find(resource => resource.id === source_id) || {}
    },
    getLevelOptions(row) {
      if (!row.craft_code_id) {
        return []
      }

      const options = this.craftCodes.filter(c => c.code === row.craft_code).map(l => {
        return {
          value: l.level,
          label: l.level,
        }
      })

      if (row.craft_code_id === '*') {
        return options
      }

      return [
        {
          value: '*',
          label: '*',
        },
        ...options,
      ]
    },
    onChangeSource(data) {
      const resource = this.findResource(data)
      data['code_application'] = composeCodeApplication(resource)
      data['calculate_by'] = resource?.calculate_by
      data['multiply_by'] = resource?.multiply_by
    },
    onCellFocused(params) {
      const row = getRowData(params)
      const sourceCol = params.columnApi.getColumn('source_id')?.colDef
      const source_type = row?.source_type
      const title = sourceColumnTitle[source_type]
      updateColumnHeader(sourceCol, title)
      params.api.refreshHeader()
    },
    async refreshEntriesTable() {
      if (!this.data.id) {
        return
      }
      await this.$refs.gridTable.refresh()
    },
    async storeProgress(id, start_date) {
      let additionalPayload = {
        job_id: id,
        start_date,
      }

      if (this.isUnionRate) {
        additionalPayload = {
          union_id: id,
          start_date,
        }
      }

      const tableData = getTableData(this.grid.api)
        .map(row => {
          return {
            ...row,
            ...additionalPayload,
          }
        })
      const entriesToSave = tableData.filter(t => !t.id && t.dirty)
      const entriesToUpdate = tableData.filter(t => t.id && t.dirty)

      if (entriesToSave.length) {
        await axios.post(`${ this.apiUrl }/bulk`, entriesToSave)
      }
      if (entriesToUpdate.length) {
        await axios.post(`${ this.apiUrl }/bulk/update`, entriesToUpdate)
      }
      await this.refreshEntriesTable()
    },
  },
}
</script>
