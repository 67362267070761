<template>
  <div class="sm:rounded-md overflow-hidden">
    <ProgressInvoicesFilters
      @save="onFilter"
    />
    <ProgressInvoicesForm
      v-if="filters"
      :filters="filters"
      class="mt-4"
    />
  </div>
</template>
<script>
  import ProgressInvoicesFilters from '@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoicesFilters'
  import ProgressInvoicesTable
    from "@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoicesTable.vue";
  import ProgressInvoicesForm
    from "@/modules/accounts-payable/components/subcontract-invoices/ProgressInvoicesForm.vue";

  export default {
    components: {
      ProgressInvoicesForm,
      ProgressInvoicesTable,
      ProgressInvoicesFilters
    },
    data() {
      return {
        filters: null
      }
    },
    methods: {
      onFilter(filters) {
        this.filters = {
          ...filters,
        }
      }
    }
  }
</script>
