<template>
  <div>
    <AgDataTable
        :columns="renderedColumns"
        :add-entity-in-new-tab="true"
        :add-default-columns="false"
        :data-loading="loading"
        :url-params="urlParams"
        :add-text="$t('New timecard batch')"
        :entity="$t('Timecard Batch')"
        :actions-column-width="120"
        domLayout="autoHeight"
        add-entity-url-query="/payroll/timecard-batches/add"
        import-url="/payroll/timecard-batches/import"
        base-entity-path="/payroll/timecard-batches"
        url="/restify/timecard-batches"
        actions="archive,search,refresh,add,import,delete"
        import-model="timecard-batches"
        ref="table"
    >
      <template #period_end_date="{row}">
        <router-link :to="`/payroll/timecard-batches/${row.id}/view`">
          {{ $formatDateOnly(row.attributes.period_end_date) }}
        </router-link>
      </template>
      <template #description="{row}">
        <router-link
            v-if="row.attributes.description"
            :to="`/payroll/timecard-batches/${row.id}/view`"
        >
          {{ row.attributes.description }}
        </router-link>
      </template>
      <template #checks_count="{row}">
        <batch-stats :batch="row.attributes"/>
      </template>
      <template #timecards="{row}">
        <timecard-stats :batch="row.attributes"/>
      </template>
      <template #extra-actions-before="{row}">
        <table-view-button @click="seeMoreClick(row)"/>
        <base-tooltip
            v-if="[resourceStatuses.Posted, resourceStatuses.Voided].includes(row.attributes.status)"
            :content="$t('Clone Batch')">
          <base-button variant="gray-light"
                       title="View"
                       size="icon"
                       class="table-action-button"
                       @click="cloneTimeCardBatch(row)"
          >
            <CopyIcon class="w-4 h-4" />
          </base-button>
        </base-tooltip>
      </template>
    </AgDataTable>
    <CloneTimecardBatchDialog
        v-if="showCloneDialog"
        :open.sync="showCloneDialog"
        :batch="batchToCLone"
        @save="afterTimeCardBatchCloned"
    >
    </CloneTimecardBatchDialog>
  </div>
</template>
<script lang="ts">
import {CopyIcon, UploadIcon} from 'vue-feather-icons'
import BatchStats from '@/modules/payroll/components/payroll/BatchStats.vue'
import TimecardStats from '@/modules/payroll/components/timecard/TimecardStats.vue'
import { defineComponent } from 'vue'
import { Column } from "@/components/ag-grid/tableTypes";
import { TimeCardBatch } from "@/modules/payroll/components/timecard/timecardTypes";
import Data = API.Data;
import {resourceStatuses} from "@/enum/enums";
import axios from "axios";
import CloneTimecardBatchDialog from "@/modules/payroll/components/timecard/CloneTimecardBatchDialog.vue";
import TimecardBatchStatusPopover from "@/modules/payroll/components/timecard/TimecardBatchStatusPopover.vue";
import i18n from "@/i18n";
import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
import {cellClasses} from "@/components/ag-grid/columnUtils";
import {dateTypes} from "@/plugins/dateFormatPlugin";

const editableStatusOptions = [
  {
    label: i18n.t('Pending'),
    value: resourceStatuses.Pending,
  },
  {
    label: i18n.t('No Compute'),
    value: resourceStatuses.NoCompute,
  },
]
  export default defineComponent({
    components: {
      TimecardBatchStatusPopover,
      CloneTimecardBatchDialog,
      CopyIcon,
      UploadIcon,
      BatchStats,
      TimecardStats,
    },
    props: {
      status: {
        type: String,
      }
    },
    data() {
      return {
        loading: false,
        showCloneDialog: false,
        batchToCLone: null as Data<TimeCardBatch> | null,
        dateTypes,
      }
    },
    computed: {
      resourceStatuses() {
        return resourceStatuses
      },
      urlParams() {
        let status = this.status
        if (this.status === resourceStatuses.Pending) {
          status = `${resourceStatuses.Pending},${resourceStatuses.NoCompute},${resourceStatuses.Computed}`
        }
        return {
          status
        }
      },
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Period End Date'),
            prop: 'period_end_date',
            minWidth: 120,
            maxWidth: 160,
          },
          {
            headerName: this.$t('Calendar Year'),
            prop: 'attributes.year',
            minWidth: 60,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Name'),
            prop: 'description',
          },
          {
            headerName: this.$t('Timecards'),
            prop: 'timecards',
            component: 'Count',
            minWidth: 220,
          },
          {
            headerName: this.$t('Employees'),
            prop: 'attributes.counts.employees',
            component: 'Count',
            minWidth: 100,
          },
          {
            headerName: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            editable: params => {
              return [resourceStatuses.Pending, resourceStatuses.NoCompute, resourceStatuses.Computed].includes(params.data.attributes.status)
            },
            headerClass: cellClasses.HeaderEditable,
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: editableStatusOptions,
            },
            valueSetter: params => {
              const status = params.newValue
              const id = params.data.id
              params.data.attributes.status = status
              if (status === resourceStatuses.NoCompute)  {
                axios.post(`/restify/timecard-batches/${id}/actions?action=no-compute-on`, { status })
              } else {
                axios.post(`/restify/timecard-batches/${id}/actions?action=no-compute-off`, { status })
              }
              return true
            },
            renderIf: () => this.status === undefined || this.status === resourceStatuses.Pending
          },
          {
            headerName: this.$t('Checks Info'),
            prop: 'checks_count',
          },
        ]
      },
      renderedColumns() {
        return this.columns.filter(column => column.renderIf === undefined || column.renderIf())
      },
    },
    methods: {
      seeMoreClick(row: Data<TimeCardBatch>) {
        this.$router.push(`/payroll/timecard-batches/${row.id}/view`)
      },
      rmSelectedBatchFromStore() {
        this.$store.state.payroll.currentTimecardBatch = {}
      },
      async cloneTimeCardBatch(row: Data<TimeCardBatch>) {
        this.batchToCLone = row
        this.showCloneDialog = true
      },
      async afterTimeCardBatchCloned() {
        this.showCloneDialog = false
        await this.$router.push('/payroll/timecard-batches/pending')
      }
    },
    mounted() {
      this.rmSelectedBatchFromStore()
    },
  })
</script>
