<template>
  <div>
    <div
      v-if="isLoading"
      class="w-full rounded placeholder-loading min-h-[82vh]"
      v-loading="true"
    />
    <iframe v-else :src="src" class="w-full h-full min-h-[82vh] rounded"/>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: [Blob, Object],
      default: () => null
    },
    loading: {
      type: Boolean,
    }
  },
  data() {
    return {
      src: null
    }
  },
  computed: {
    isLoading() {
      return this.loading || !this.src
    }
  },
  methods :{
    createPDfSrc(value) {
      if (value && value instanceof Blob) {
        this.src = URL.createObjectURL(value)
      } else {
        this.src = null
      }
    }
  },
  watch: {
    file: {
      immediate: true,
      handler(value) {
        this.createPDfSrc(value)
      }
    }
  },
}
</script>
