<template>
  <entity-select
    v-bind="$attrs"
    v-on="$listeners"
    :name="$attrs.name || $t('Contact')"
    :placeholder="$attrs.placeholder || $t('Select Contact')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Contact')"
    :id="$attrs.id || 'contact-select'"
    :add-label="$t('Add new Contact')"
    :url="url"
    :url-params="urlParams"
    :parent-entity-id="contactableId"
    :parent-entity-name="parentEntityName"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    label-key="name"
  />
</template>
<script>

export default {
  name: 'ContactSelect',
  inheritAttrs: false,
  props: {
    contactableId: String,
    parentEntityName: {
      type: String,
      default: 'vendors', // vendors|customers|jobs
    },
  },
  computed: {
    url() {
      return !this.contactableId ? '' : '/restify/contacts'
    },
    urlParams() {
      return !this.contactableId ? {} : {
        contactable_id: this.contactableId,
      }
    },
    tableColumns() {
      return [
        {
          name: this.$t('Name'),
          prop: 'attributes.name',
          minWidth: 160,
          maxWidth: 160,
        },
        {
          name: this.$t('Email'),
          prop: 'attributes.email',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          name: this.$t('Type'),
          prop: 'attributes.type',
          minWidth: 80,
          maxWidth: 100,
          component: 'Status',
        },
      ]
    },
  },
  methods: {
    formatLabel(contact) {
      const { name, title } = contact.attributes
      if (!title) {
        return name
      }
      return `${ name } (${ title })`
    },
  }
}
</script>
