import get from 'lodash/get'
import { jobCostDetailPrintOptions } from '@/modules/job-costing/components/reports/util'
import i18n from "@/i18n";

export function mapDataByFormat(format, data) {
  let rows = []

  if ([jobCostDetailPrintOptions.HistoryAndBudgets, jobCostDetailPrintOptions.History].includes(format)) {
    data.forEach((group, index) => {
      rows.push({
        header: {
          id: group.id,
          number: group.number,
          description: group.description,
        },
      })
      Object.values(group.line_items).forEach(lineItem => {
        lineItem.items.forEach(groupItem => {
          rows.push({
            header: {
              line_item_id: groupItem.id,
              type: groupItem.budget_type,
              phase_code: groupItem.phase_code,
              cost_code: groupItem.cost_code,
              change_order: groupItem.change_order,
              description: groupItem.description,
              amount: groupItem.amount,
              amount_to_date: groupItem.amount_to_date,
              remaining: groupItem.remaining,
            },
          })
          const transactions = groupItem.transactions || []
          if (transactions.length) {
            rows.push(...transactions)
          }
        })
        rows.push({
          subtotal: {
            hours: lineItem.hours,
            units: lineItem.units,
            total: lineItem.total,
          },
        })
      })
      rows.push({
        footer: {
          cost: group.cost,
          income: group.income,
          income_less_cost: group.income_less_cost,
        },
      })
    })
  }

  if (format === jobCostDetailPrintOptions.LineItemTotals) {
    data.forEach((group, index) => {
      rows.push({
        header: {
          id: group.id,
          number: group.number,
          description: group.description,
        },
      })
      Object.values(group.line_items).forEach(lineItem => {
        const groupItem = get(lineItem, 'items[0]', [])

        rows.push({
          id: groupItem.id,
          type: groupItem.budget_type,
          phase_code: groupItem.phase_code,
          cost_code: groupItem.cost_code,
          change_order: groupItem.change_order,
          description: groupItem.description,
          amount: groupItem.amount,
          amount_to_date: groupItem.amount_to_date,
          remaining: groupItem.remaining,
          transactions_total_amount: groupItem.transactions_total_amount,
        })
      })
      rows.push({
        footer: {
          cost: group.cost,
          income: group.income,
          income_less_cost: group.income_less_cost,
        },
      })
    })
  }

  if (
    [
      jobCostDetailPrintOptions.PageSelectedJobs,
      jobCostDetailPrintOptions.PagePeriodActive,
    ].includes(format)
  ) {
    data.forEach((group, index) => {
      rows.push({
        header: {
          id: group.id,
          number: group.number,
          description: group.description,
        },
      })

      rows.push({
        footer: {
          cost: group.cost,
          income: group.income,
          income_less_cost: group.income_less_cost,
        },
      })
    })
  }

  return rows
}

export function getColumnsByFormat(format) {
  const columns = [
    {
      label: i18n.t('Type'),
      prop: 'type',
      minWidth: 40,
      maxWidth: 40,
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
      hiddenValue: true,
    },
    {
      label: i18n.t('Type'),
      prop: 'abbr',
      minWidth: 40,
      maxWidth: 40,
      renderIf: () => format === jobCostDetailPrintOptions.LineItemTotals,
      component: 'FormattedText',
    },
    {
      label: i18n.t('Line Item'),
      prop: 'description',
      minWidth: 250,
      maxWidth: 250,
    },
    {
      label: i18n.t('Budget <br> Amount'),
      prop: 'amount',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'FormattedPrice',
      renderIf: () => format === jobCostDetailPrintOptions.LineItemTotals,
    },
    {
      label: i18n.t('Job-To-Date <br> Amount'),
      prop: 'amount_to_date',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'FormattedPrice',
      renderIf: () => format === jobCostDetailPrintOptions.LineItemTotals,
    },
    {
      label: i18n.t('Remaining <br> Amount'),
      prop: 'remaining',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'FormattedPrice',
      renderIf: () => format === jobCostDetailPrintOptions.LineItemTotals,
    },
    {
      label: i18n.t('Period <br> amount'),
      prop: 'transactions_total_amount',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'FormattedPrice',
      renderIf: () => format === jobCostDetailPrintOptions.LineItemTotals,
    },
    {
      label: i18n.t('Journal'),
      prop: 'journal.name',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'EntityLink',
      entityKey: 'journal.id',
      redirectTo: '/job-costing/journals/{ID}/view',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Reference <br> Date'),
      prop: 'reference_date',
      minWidth: 60,
      maxWidth: 80,
      align: 'right',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Reference <br> Number'),
      prop: 'reference_number',
      minWidth: 60,
      maxWidth: 80,
      align: 'center',
      component: 'EntityLink',
      entityKey: 'meta.invoice_id',
      redirectTo: '/accounts-payable/invoices/{ID}/view',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Vendor <br> Customer'),
      prop: 'business',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Period'),
      prop: 'period_fiscal_year',
      minWidth: 60,
      maxWidth: 80,
      align: 'right',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Account'),
      prop: 'account_number',
      minWidth: 80,
      maxWidth: 100,
      align: 'left',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Material <br> Equipment'),
      prop: 'equipment_code',
      minWidth: 100,
      maxWidth: 120,
      align: 'center',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Units'),
      prop: 'units',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Lab/Eqp <br> Hours'),
      prop: 'hours',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
    {
      label: i18n.t('Period <br> Amount'),
      prop: 'amount',
      minWidth: 100,
      maxWidth: 120,
      align: 'right',
      component: 'FormattedPrice',
      renderIf: () => { return ![jobCostDetailPrintOptions.LineItemTotals, jobCostDetailPrintOptions.LineItemPhaseTotals].includes(format) },
    },
  ]

  return columns.filter(column => column.renderIf === undefined || column.renderIf())
}
