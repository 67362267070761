<template>
  <div>
    <BaseDataTable
      :columns="columns"
      :url="url"
      :edit-entity-url-query="editEntityUrlQuery"
      :add-text="$t('New material class')"
      actions="search,refresh"
      permission="material_class"
      view-entity-url-query="/purchasing-inventory/settings/material-classes"
      ref="table"
      deleteCustom
      @delete="itemToDelete = $event; showDeleteDialog = true"
      @add="onRowAdd"
      @edit="onRowEdit"
    >
      <template #inventoryAccount="{row}">
        <AccountLink :number="get(row, 'attributes.inventory_account')"/>
      </template>
      <template #COGSAccount="{row}">
        <AccountLink :number="get(row, 'attributes.cogs_account')"/>
      </template>
    </BaseDataTable>

    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.MaterialClasses"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />

    <BaseFormDialog
      v-if="showDialog"
      :title="rowToEdit ? $t('Edit Material Class') : $t('Add Material Class')"
      :open.sync="showDialog"
      @close="onEventTrigger"
    >
      <MaterialClassForm
        :data="rowToEdit"
        @cancel="onEventTrigger"
        @save="onEventTrigger"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import MaterialClassForm from '@/modules/purchasing-inventory/components/MaterialClassForm'

  export default {
    components: {
      MaterialClassForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        url: '/restify/material-classes',
        rowToEdit: null,
        showDialog: false,
        showDeleteDialog: false,
        itemToDelete: null,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/purchasing-inventory/settings/material-classes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Account For Inventory'),
            prop: 'inventoryAccount',
          },
          {
            label: this.$t('Account For COGS'),
            prop: 'COGSAccount',
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row.attributes
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
      onItemDeleted() {
        this.showDeleteDialog = false
        this.refreshTable()
      }
    },
  }
</script>
