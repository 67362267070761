<template>
  <div class="grid grid-cols-3 gap-x-3">
    <div class="col-span-3 md:col-span-1">
      <base-input v-model="model.number"
                  :disabled="editDisabled"
                  :key="model.id"
                  :label="$t('Pay #')"
                  :name="$t('Pay #')"
                  :placeholder="$t('Pay #')"
                  :tip="$t('The program will combine all pay/deductions for each Pay # on a separate check.')"
                  :min="1"
                  :max="99"
                  :step="1"
                  :rules="getPayNumberValidationRules"
                  id="number"
                  type="number"
                  @blur="onSubmit"
      />
    </div>
    <div class="col-span-3 md:col-span-1">
      <base-select v-model="model.type"
                   :options="checkTypeOptions"
                   :label="$t('Check Type')"
                   :name="$t('Check Type')"
                   :placeholder="$t('Check Type')"
                   id="type"
                   rules="required"
                   @change="onSubmit"
      />
    </div>
    <template v-if="isComputedOrManualCheck">
      <div class="col-span-3 md:col-span-1">
        <bank-select v-model="model.bank_id"
                     :used-for="BankUsedInTypes.Payroll"
                     :label="$t('Bank #')"
                     :name="$t('Bank #')"
                     :placeholder="$t('Bank #')"
                     id="bank"
                     rules="required"
                     @change="onSubmit"
        />
      </div>
      <div class="col-span-3 md:col-span-1">
        <base-input v-model="model.payment_number"
                    :label="$t('Check #')"
                    :name="$t('Check #')"
                    :placeholder="$t('Check #')"
                    id="payment_number"
                    rules="required"
                    @blur="onSubmit"
        />
      </div>
      <div class="col-span-3 md:col-span-1">
        <base-date-picker v-model="model.payment_date"
                          :picker-options="datePickerOptions"
                          :label="$t('Check Date')"
                          :name="$t('Check Date')"
                          :placeholder="$t('Check Date')"
                          id="payment_date"
                          rules="required"
                          @change="onSubmit"
        />
      </div>
    </template>
    <template v-if="model.selectable_deductions.length">
      <div class="col-span-3">
        <h5 class="form-section-title">{{ $t('Selectable Deductions') }}</h5>
      </div>
      <BaseCheckbox
        v-for="(deduction, index) in model.selectable_deductions"
        v-model="model.selectable_deductions[index].include"
        :label="deduction.description"
        :key="deduction.id"
        :id="deduction.id"
        class="col-span-3 md:col-span-1"
      />
    </template>
  </div>
</template>
<script>
import { checkTypes, payrollStatuses } from "@/modules/payroll/components/rates/util";
import axios from "axios";
import { checkTypeOptions } from "@/modules/payroll/components/timecard/checkTypeOptions";
import { BankUsedInTypes } from "@/enum/enums";

export default {
  props: {
    batchSummary: {
      type: Object,
      default: () => ({}),
    },
    timeCard: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      model: {
        id: null,
        timecard_batch_id: undefined,
        employee_id: undefined,
        bank_id: undefined,
        period_end_date: '',
        year: null,
        number: 1,
        type: checkTypes.NORMAL,
        payment_number: '',
        payment_date: '',
        status: payrollStatuses.PENDING,
        selectable_deductions: [],
      },
      datePickerOptions: {
        disabledDate: (date) => {
          return date.getFullYear() !== Number(this.timeCard.year)
        },
      },
      checkTypeOptions,
      loading: false,
    }
  },
  computed: {
    BankUsedInTypes() {
      return BankUsedInTypes
    },
    editDisabled() {
      if (this.$route.path.endsWith('add')) {
        return false
      }
      return !!this.$route.params.id
    },
    isComputedOrManualCheck() {
      return [checkTypes.COMPUTE_PAYOFF_CHECK, checkTypes.MANUAL_CHECK_ADJUST].includes(this.model.type)
    },
    getPayNumberValidationRules() {
      if (this.editDisabled) {
        return ''
      }
      return `required|min_value:1|max_value:99`
    },
  },
  methods: {
    async onSubmit() {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        if (!this.model.id) {
          return
        }
        await this.$nextTick()
        if (!this.model.selectable_deductions) {
          this.model.selectable_deductions = []
        }
        const hasCheckDetails = [checkTypes.COMPUTE_PAYOFF_CHECK, checkTypes.MANUAL_CHECK_ADJUST].includes(this.model.type)

        if (!hasCheckDetails) {
          this.model.payment_date = ''
          this.model.payment_number = ''
        }
        const requestData = {
          ...this.model,
          period_end_date: this.$formatDate(this.model.period_end_date, this.$dateTypes.IsoDate)
        }
        await axios.put(`/restify/timecards/${this.model.id}`, requestData)
        this.$emit('save', this.model)
      } catch (err) {
        if (err.handled) {
          return
        }
        console.warn('err', err)
        this.$error(this.$t('Could not store timecard changes.'))
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    timeCard: {
      immediate: true,
      handler(timeCard) {
        for (let key in this.model) {
          this.model[key] = timeCard[key] || this.model[key]
        }
      }
    }
  }
}
</script>
