<template>
  <div class="shadow print:shadow-none overflow-hidden sm:rounded-md form__inner px-2 bg-white sm:px-4 print:px-0 sm:print:px-0">
    <el-collapse v-model="activeGroup">
      <base-collapse-section v-if="journal"
                             name="journal"
                             wrapper-classes=""
      >
        <div class="grid grid-cols-2 gap-x-4 md:mb-4">
          <base-detail-card class="col-span-2 md:col-span-1"
                            :title="$t('Journal')"
                            :can-expand="false"
                            :expanded="true"
          >
            <base-detail-card-row :title="$t('Created By')" justifyEnd>
              <UserLink :id="get(journal, 'created_by')"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Name')" stripe justifyEnd>
              <span class="font-medium">
              <template v-if="journal.description">
                {{ `${journal.name}(${journal.description})` }}
              </template>
              <template v-else>
                {{ journal.name }}
              </template>
              </span>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Type')" justifyEnd>
              <span class="capitalize font-medium">
                {{ formattedText(journal.type) }}
              </span>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Reference Date')" stripe justifyEnd>
              <span class="font-medium">
              {{ $formatDate(journal.reference_date) }}
              </span>
            </base-detail-card-row>
          </base-detail-card>

          <base-detail-card v-if="!isPosted"
                            :title="$t('Totals')"
                            :can-expand="false"
                            :expanded="true"
                            class="col-span-2 md:col-span-1">
            <base-detail-card-row :title="$t('Credit')" justifyEnd>
              <span class="font-medium">
                {{ $formatPrice(journal.total_credit) }}
              </span>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Debit')" stripe justifyEnd>
              <span class="font-medium">
                {{ $formatPrice(journal.total_debit) }}
              </span>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('In Balance')" justifyEnd>
              <postable-tooltip :is-postable="journal.is_in_balance"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Year / Period')" stripe justifyEnd>
              <span class="font-medium">
                {{ journal.fiscal_year || '' }} /
                {{ getPeriodName(journal.period) }}
              </span>
            </base-detail-card-row>
          </base-detail-card>
          <base-detail-card v-else
                            class="col-span-2 md:col-span-1"
                            :title="$t('Posting Info')"
                            :can-expand="false"
                            :expanded="true"
          >
            <base-detail-card-row :title="$t('Posted By')" justifyEnd>
              <UserLink :id="journal.posted_by"/>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Posted At')" stripe justifyEnd>
                <span class="font-medium">
                  {{ $formatDate(journal.posted_at) }}
                </span>
            </base-detail-card-row>
            <base-detail-card-row :title="$t('Year / Period')" justifyEnd>
              <span class="font-medium">
                {{ journal.fiscal_year || '' }} /
                {{ getPeriodName(journal.period) }}
              </span>
            </base-detail-card-row>
          </base-detail-card>
        </div>
      </base-collapse-section>
    </el-collapse>
    <template v-if="journal.id">
      <JournalEntries
        v-if="!isPosted"
        :journal="journal"
        :show-cells-legend="false"
        class="my-4"
        readOnly
        @journal-entries-changed="onJournalEntriesChanged"
      />
      <TransactionsTable
        v-else
        :journal="journal"
        :title="$t('Journal Transactions')"
        :show-cells-legend="false"
        class="col-span-2 my-4"
        readOnly
        @journal-entries-changed="onJournalEntriesChanged"
      />
    </template>
  </div>
</template>
<script>
  import { formattedText } from '@/utils/utils'
  import { resourceStatuses } from '@/enum/enums'
  import JournalEntries from '@/modules/ledger/components/journals/JournalEntries'
  import TransactionsTable from '@/modules/common/components/journal/TransactionsTable'

  export default {
    components: {
      JournalEntries,
      TransactionsTable,
    },
    data() {
      return {
        activeGroup: ['journal'],
      }
    },
    computed: {
      journal() {
        const journal = this.$store.state.generalLedger.currentJournal || {}
        return {
          ...journal?.attributes,
          ...journal?.relationships,
        }
      },
      isPosted() {
        return this.journal.status === resourceStatuses.Posted
      },
    },
    methods: {
      formattedText,
      onMetaFetch(meta) {
        this.transactionCount = meta.total
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      onJournalEntriesChanged(journalEntries) {
        this.$store.commit('generalLedger/SET_CURRENT_JOURNAL_ENTRIES', journalEntries)
      },
    },
  }
</script>
