<template>
  <div class="flex-1 h-[calc(100%-50px)]">
    <ag-data-table
      :url="url"
      :columns="columns"
      :url-params="finalUrlParams"
      :group-by.sync="selectedGroupBy"
      :is-expandable="isExpandable"
      :transform-data="mapData"
      :add-entity-in-new-tab="true"
      :select-row-filter="row => !isRowSelectionDisabled(row)"
      :add-text="$t('New purchase order adjustment')"
      :default-filters="!hideAllActions"
      :hide-actions="hideAllActions ? 'add': hideActions"
      :add-entity-url-query="addEntityUrlQuery"
      :groupDefaultExpanded="hideVendor ? 0 : 1"
      :groupRowRendererParams="groupRowRendererParams"
      :masterDetail="true"
      :detailCellRenderer="detailCellRenderer"
      :detailRowAutoHeight="true"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :actions-column-width="200"
      ref="table"
      groupDisplayType="groupRows"
      entity="purchase-order-adjustments"
      permission="purchase_orders"
      :actions="hideAllActions ? '': 'search,refresh,view'"
      base-entity-path="/purchasing-inventory/purchase-orders-adjustments"
      class="h-full"
      domLayout="autoHeight"
      @all-selected="onAllSelected"
      @delete="refreshTable"
      @data-fetch="onDataFetch"
      @meta-fetch="$emit('meta-fetch', $event)"
    >
      <template v-slot:additional-actions>
        <post-button v-if="authorizedToPostBulk"
                     :disabled="postBulkDisabled"
                     :loading="postingProgress"
                     size="xs"
                     variant="gray-link"
                     @click="showPostDialog = true"
        />
      </template>
      <template v-slot:extra-actions="{row, index}">
        <slot
          name="extra-actions"
          :row="row"
          :index="index"
        >
          <PostAction
            v-if="$isAuthorized('authorizedToPost', row)"
            :id="row.id"
            :is-accounting="false"
            :has-custom-message="true"
            entity-name="Purchase Order Adjustment"
            resource-name="purchase-order-adjustments"
            variant="gray-link"
            size="xs"
            class="mt-2"
            :action-url="getPostActionUrl(row)"
            @on-action-callback="refreshTable"
          >
            <template #custom-message>
              <span>{{ $t(`The following action will post and modify P.O #${row.relationships?.purchaseOrder?.attributes?.number}. Are you sure?`) }}</span>
            </template>
          </PostAction>
        </slot>
      </template>
      <template #attributes.status="{row}">
        <invoice-status-popover
          :invoice="row"
          :resource-name="resourceName"
        />
      </template>
      <template #select="{row}">
        <BaseTooltip
          :disabled="!isRowSelectionDisabled(row)"
          :content="$t('Purchase order cannot be voided because it was already invoiced')">
          <base-checkbox v-model="row.selected"
                         :disabled="isRowSelectionDisabled(row)"/>
        </BaseTooltip>
      </template>
      <template #placed_by="{row}">
        <EmployeeLink
          v-if="get(row, 'attributes.employee_id')"
          :id="get(row, 'attributes.employee_id')"
          :show-name="false"
          :show-preview="false"
        />
        <span v-else>{{ row.attributes?.placed_by_name }}</span>
      </template>
    </ag-data-table>
    <BaseFormDialog
      v-if="showPostDialog"
      :title="$t('Post selected purchase order(s)?')"
      :open.sync="showPostDialog">
      <BasePostDialog
        :is-accounting="false"
        prompt-message="This will post the purchase order(s) entries to the corresponding vendor. Note that this action is irreversible."
        @cancel="showPostDialog = false"
        @save="onPost"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import { groupByType } from '@/components/table/utils/groupByTypes'
import PurchaseOrderItems from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems'
import i18n from "@/i18n";
import { getAddButtonPath } from "@/modules/common/util/costCenterUtils";
import VendorGroupRow from "@/modules/purchasing-inventory/components/purchase-orders/VendorGroupRow.vue";
import PurchaseOrderAdjustmentDetails from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustmentDetails.vue";
import { globalResources } from "@/components/form/util";
import { compareValueFormatter, FormatTypes } from "@/modules/purchasing-inventory/components/purchase-orders/util";
import { tableColumns } from "@/components/table/tableColumns";

export default {
  components: {
    VendorGroupRow,
    PurchaseOrderItems,
    PurchaseOrderAdjustmentDetails,
  },
  props: {
    status: String,
    urlParams: {
      type: Object,
      default: () => ({})
    },
    hideActions: {
      type: String,
      default: ''
    },
    hideAllActions: {
      type: Boolean,
      default: false,
    },
    hideVendor: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedGroupBy: groupByType.Vendor,
      resourceName: 'purchase-orders',
      isExpandable: true,
      url: '/restify/purchase-order-adjustments',
      showPostDialog: false,
      postingProgress: false,
      detailCellRenderer: 'PurchaseOrderAdjustmentDetails',
      purchaseOrders: [],
    }
  },
  computed: {
    resourceStatus() {
      return this.status || this.$route.meta?.status
    },
    columns() {
      return [
        {
          headerName: ' ',
          cellRenderer: 'agGroupCellRenderer',
          maxWidth: 55,
          minWidth: 55,
        },
        {
          label: 'Vendor',
          prop: 'relationships.purchaseOrder.attributes.vendor_id',
          hiddenValue: true,
          minWidth: 80,
          maxWidth: 80,
          rowGroup: !this.hideVendor,
          hide: true,
        },
        {
          label: this.$t('PO Adjustment #'),
          prop: 'relationships.purchaseOrder.attributes.number',
          minWidth: 120,
          maxWidth: 120,
          component: 'EntityLink',
          entityKey: 'attributes.id',
          redirectTo: '/purchasing-inventory/purchase-orders-adjustments/{ID}/view',
        },
        {
          label: this.$t('Original PO #'),
          prop: 'relationships.purchaseOrder.attributes.number',
          minWidth: 100,
          maxWidth: 120,
          component: 'EntityLink',
          entityKey: 'relationships.purchaseOrder.id',
          redirectTo: '/purchasing-inventory/purchase-orders/{ID}/view',
        },
        {
          label: this.$t('Delivery Date'),
          prop: 'attributes.delivery_date',
          component: 'FormattedDate',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          align: 'center',
          minWidth: 100,
          maxWidth: 160,
          component: 'Status',
        },
        {
          label: i18n.t('Review Status'),
          align: 'center',
          prop: 'attributes.review_status',
          minWidth: 110,
          maxWidth: 160,
          component: 'ReviewStatus',
          hide: ![resourceStatuses.Review, resourceStatuses.Pending].includes(this.resourceStatus),
        },
        {
          label: i18n.t('Created By'),
          prop: 'attributes.created_by',
          minWidth: 200,
          maxWidth: 250,
          component: 'UserLink',
        },
        {
          label: this.$t('Invoiced Amount'),
          prop: 'relationships.purchaseOrder.attributes.invoiced_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          label: this.$t('Amount'),
          prop: 'attributes.amount',
          align: 'right',
          minWidth: 250,
          maxWidth: 500,
          valueFormatter: params => {
            return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.amount', FormatTypes.Price, 'attributes.meta.prior.amount')
          },
        },
        {
          label: this.$t('Sales Tax Amount'),
          prop: 'attributes.sales_tax_amount',
          align: 'right',
          minWidth: 200,
          maxWidth: 300,
          valueFormatter: params => {
            return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.sales_tax_amount', FormatTypes.Price, 'attributes.meta.prior.sales_tax_amount')
          },
        },
        {
          label: this.$t('Freight Amount'),
          prop: 'attributes.freight_amount',
          align: 'right',
          minWidth: 200,
          maxWidth: 300,
          valueFormatter: params => {
            return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.freight_amount', FormatTypes.Price, 'attributes.meta.prior.freight_amount')
          },
        },
      ]
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'VendorGroupRow',
        suppressCount: true,
        wrapText: true,
        autoHeight: true,
      }
    },
    finalUrlParams() {
      let status = this.$route.meta?.status
      if (status === resourceStatuses.All) {
        status = undefined
      }
      return {
        related: `purchaseOrder`,
        status,
        ...this.urlParams,
      }
    },
    isForPost() {
      const status = this.$route.meta?.status || this.status
      return status === resourceStatuses.Posted
    },
    selectedRows() {
      return this.purchaseOrders.filter(e => e.selected)
    },
    authorizedToPostBulk() {
      return this.status === resourceStatuses.Pending
    },
    postBulkDisabled() {
      return !this.selectedRows.some(row => row.meta[this.$actionPolicies.Post])
    },
    addEntityUrlQuery() {
      const baseUrl = '/purchasing-inventory/purchase-orders-adjustments/add'
      return getAddButtonPath(baseUrl, this.urlParams)
    },
  },
  methods: {
    mapData(data) {
      return data.map(row => {
        const { relationships } = row
        const vendor_id = relationships?.purchaseOrder?.attributes?.vendor_id
        const vendor = this.$store.getters['globalLists/getResourceById'](globalResources.Vendors, vendor_id) || {}
        return {
          ...row,
          relationships: {
            ...relationships,
            vendor,
          }
        }
      })
    },
    onAllSelected(value) {
      this.allPagesSelected = value
    },
    async onPost(data) {
      try {
        this.postingProgress = true
        this.showPostDialog = false
        const repositories = this.selectedRows.map(row => row.id)
        const payload = {
          repositories,
          ...data,
        }
        await axios.post('/restify/purchase-order-adjustments/actions?action=post-purchase-order-adjustment', payload)
        await this.$store('globalLists/getIVConfiguration', true)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t(`Could not post purchase order(s)`))
      } finally {
        this.postingProgress = false
        this.refreshTable()
      }
    },
    isRowSelectionDisabled(row) {
      const { meta } = row
      if (!meta) {
        return false
      }
      return !meta[this.$actionPolicies.Post]
    },
    refreshTable() {
      this.allPagesSelected = false
      this.$refs.table.refresh()
    },
    getPostActionUrl(row) {
      return `/restify/purchase-order-adjustments/${row.id}/actions?action=post-purchase-order-adjustment`
    },
    onDataFetch(data) {
      this.purchaseOrders = data
      this.$emit('data-fetch', data)
    },
  },
}
</script>
