<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :appendToBody="true"
    :title="$t('Mirror Line Items')"
    size="lg"
  >
    <BaseForm
      :show-cancel="true"
      :show-submit="false"
      :loading="loading"
      layout="modal"
      :cancelText="$t('Close')"
      @cancel="$emit('close')"
    >
      <template #extra-buttons="{ valid }">
        <BaseButton
          :loading="loading"
          type="button"
          :disabled="!valid"
          @click.stop="triggerMirror"
        >
          <div class="px-4">{{ submitText }}</div>
        </BaseButton>
      </template>
      <JobSelect
        :value="job.id"
        :label="$t('Job')"
        :name="$t('Job')"
        :placeholder="$t('Select Job')"
        :disabled="true"
        :addEntity="false"
        class="col-span-2"
        rules="required"
      />

      <BaseCheckbox
        v-model="model.autolink"
        :label="$t('Automatically Link Mirrored Line Items')"
        id="autolink"
        class="col-span-2"
      />

      <BaseAlert
        class="col-span-6"
      >
        {{ actionMessage }}
      </BaseAlert>
      <ValidationWarning
        class="col-span-6 mt-2"
      >
        {{ $t('Existing Line Items will be skipped - Line Items that match Phase Code, Cost Code & Change Order with existing Line Items on') }}
        <span class="ml-1 font-semibold"> {{ job.attributes.number }} {{ job.attributes.description }}</span>.
      </ValidationWarning>
      <BaseAlert
        v-if="lastResult.message"
        :closable="true"
        class="col-span-6"
      >
        <div>{{ lastResult.message }}</div>
        <div>{{ $t(`You can continue mirroring Line Items by selecting a different type or changing the Line Item selection.`) }}</div>
      </BaseAlert>
      <div class="col-span-6 h-full">
        <AgDataTable
          v-if="job.id"
          :url="`/restify/line-items`"
          :urlParams="lineItemsUrlParams"
          :title="$t('Select Line Items To Mirror (Leave selection empty to mirror ALL Line Items of the selected type from Job)')"
          :columns="columns"
          actions="search,refresh"
          :selected-rows.sync="selectedLineItems"
          :enableFilterDrawer="false"
          @data-fetch="allLineItems = $event"
        >
        <template #additional-actions-before>
          <BaseSelect
            v-model="model.from_type"
            :label="$t('Line Item Type to mirror')"
            :placeholder="$t('Line Item Type')"
            :add-entity="false"
            :options="lineItemTypeOptions"
          />
        </template>
        </AgDataTable>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import axios from 'axios'
import { costTypes } from '@/enum/enums';
import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";

export default {
  components: {
    JobSelectNew,
  },
  props: {
    job: {
      type: Object,
      required: true
    },
    defaultLineItemType: {
      type: String,
      default: costTypes.Income,
    }
  },
  data() {
    return {
      loading: false,
      allLineItems: [],
      selectedLineItems: [],
      lineItemTypeOptions: [
        {
          label: this.$t('Cost Line Items'),
          value: costTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: costTypes.Income,
        },
      ],
      model: {
        from_type: this.defaultLineItemType,
        autolink: true,
        line_item_ids: [],
      },
      lastResult: {
        message: '',
      }
    }
  },
  computed: {
    masterJobId() {
      return this.$settings(this.$modules.JC, 'master_job_id')
    },
    masterJob() {
      return this.$store.state.jobCosting.masterJob
    },
    columns() {
      return [
        {
          headerName: this.$t('Phase Code'),
          field: 'attributes.phase_code',
          minWidth: 150,
          maxWidth: 150,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sort: 'asc',
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'attributes.cost_code',
          minWidth: 100,
          maxWidth: 100,
          align: 'center',
        },
        {
          headerName: this.$t('Chg Order'),
          field: 'attributes.change_order',
          minWidth: 100,
          maxWidth: 100,
          align: 'center',
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
        },
        {
          headerName: this.$t('Type'),
          field: 'attributes.type',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
          component: 'Status',
        },
      ]
    },
    lineItemsUrlParams() {
      return {
        job_id: this.job.id,
        type: this.model.from_type,
        perPage: 100,
        related: 'budgets',
        sort: 'phase_code',
      }
    },
    submitText() {
      if (this.selectedLineItems.length) {
        return this.$t('Mirror Selected') + ` ${this.selectedLineItemType.label}`
      }
      return this.$t('Mirror All') + ` ${this.selectedLineItemType.label}`
    },
    selectedLineItemType() {
      return this.lineItemTypeOptions.find(x => x.value === this.model.from_type)
    },
    targetLineItemType() {
      return this.lineItemTypeOptions.find(x => x.value !== this.model.from_type)
    },
    actionMessage() {
      const countMirrored = this.selectedLineItems.length 
        ? this.selectedLineItems.length
        : this.allLineItems.length

      return this.$t('mirror x line items', null, {
        count: countMirrored,
        from_type: this.selectedLineItemType.label,
        to_type: this.targetLineItemType.label,
      })
    }
  },
  methods: {
    async triggerMirror() {
      this.loading = true
      this.lastResult = {
        message: '',
        skipped: [],
      }

      try {
        const data = {
          ...this.model
        }

        data.line_item_ids = this.selectedLineItems.map(x => x.id)

        const result = await axios.post(`/restify/jobs/${this.job.id}/actions?action=mirror-line-items`, data)

        this.lastResult = result?.data || {}

        this.selectedLineItems = []

        this.$emit('mirrored')
      } catch (err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Failed to mirror line items'))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
