import EmptyLayout from "@/components/common/EmptyLayout";
import { $modules, resourceStatuses } from "@/enum/enums";
import AccountsList from "@/modules/ledger/pages/account/accounts-list";
import AccountsImport from "@/modules/ledger/pages/account/accounts-import";
import AccountLayout from "@/modules/ledger/layout/AccountLayout";
import AccountHistory from "@/modules/ledger/pages/account/account-history";
import AccountSummary from "@/modules/ledger/pages/account/account-summary";
import SubAccountsList from "@/modules/ledger/pages/subaccount/sub-accounts-list";
import BudgetsList from "@/modules/ledger/pages/budgets-list";
import JournalsLayout from "@/modules/ledger/layout/JournalsLayout";
import PendingJournals from "@/modules/ledger/pages/journals/pending-journals";
import PostedJournals from "@/modules/ledger/pages/journals/posted-journals";
import JournalProofListing from "@/modules/ledger/pages/journals/journal-proof-listing";
import JournalLayout from "@/modules/ledger/layout/JournalLayout";
import JournalAdd from "@/modules/ledger/pages/journals/journal-add";
import JournalEdit from "@/modules/ledger/pages/journals/journal-edit";
import JournalDetails from "@/modules/ledger/pages/journals/journal-details";
import JournalsImport from "@/modules/ledger/pages/journals/journals-import.vue";
import AccountReconciliationsLayout from "@/modules/ledger/layout/AccountReconciliationsLayout";
import AccountReconciliationList from "@/modules/ledger/pages/account-reconciliation/account-reconciliation-list";
import AccountReconciliationAdd from "@/modules/ledger/pages/account-reconciliation/account-reconciliation-add";
import AccountReconciliationLayout from "@/modules/ledger/layout/AccountReconciliationLayout";
import AccountReconciliationEdit from "@/modules/ledger/pages/account-reconciliation/account-reconciliation-edit";
import AccountReconciliationDetails from "@/modules/ledger/pages/account-reconciliation/account-reconciliation-details";
import AccountBalancesList from "@/modules/ledger/pages/account-balances-list";
import Reports from "@/modules/ledger/layout/Reports";
import HistorySelectionReport from "@/modules/ledger/components/reports/HistorySelectionReport";
import AccountListingReport from "@/modules/ledger/components/reports/AccountListingReport";
import SubAccountsBudgetsReport from "@/modules/ledger/components/reports/SubAccountsBudgetsReport";
import BudgetToActualCompareReport from "@/modules/ledger/components/reports/BudgetToActualCompareReport";
import AccountBalanceComparisonReport from "@/modules/ledger/components/reports/AccountBalanceComparisonReport";
import SettingsLayout from "@/modules/ledger/layout/SettingsLayout";
import InitializeSystem from "@/modules/ledger/pages/settings/initialize-system";
import FiscalYears from "@/modules/ledger/pages/settings/fiscal-years";
import GeneralLedgerSettings from "@/modules/ledger/pages/settings/general-ledger-settings";
import FinancialReport from "@/modules/ledger/components/reports/FinancialReport";
import AccountEdit from "@/modules/ledger/pages/account/account-edit";
import FinancialReportsList from "@/modules/ledger/pages/settings/financial-reports-list";
import FinancialReportEdit from "@/modules/ledger/pages/settings/financial-reports/financial-report-edit";
import FinancialReportLayout from "@/modules/ledger/layout/FinancialReportLayout";
import FinancialReportDetails from "@/modules/ledger/pages/settings/financial-reports/financial-report-details.vue";
import FinancialReportAdd from "@/modules/ledger/pages/settings/financial-reports/financial-report-add.vue";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import CheckDepositRegisterReport from "@/modules/ledger/pages/reports/check-deposit-register-report.vue";
import TrialBalanceReport from "@/modules/ledger/components/reports/TrialBalanceReport.vue";
import JobAndOtherDistributionsReport from "@/modules/ledger/pages/reports/job-and-other-distributions-report.vue";
import JournalsReport from "@/modules/ledger/pages/journals/JournalsReport.vue";
import GeneralLedgerReport from "@/modules/ledger/pages/reports/general-ledger-report.vue";

import GLJournals from "@/modules/common/components/journal/journal";
import CommonJournalDetails from "@/modules/common/components/journal/journal-details";

export const reportRoutes = [
  {
    path: 'history-selections',
    name: 'History Selections',
    title: 'G/L History Selections',
    description: 'Preview G/L History Selections',
    meta: {
      appScreen: 'G/L History Selections: 18.71',
      permissions: ['general_ledger_reports'],
    },
    component: HistorySelectionReport,
  },
  {
    path: 'job-and-other-distributions',
    name: 'Job & Other Distributions',
    title: 'G/L Job & Other Distributions',
    description: 'Preview G/L Job & Other Distributions',
    meta: {
      appScreen: 'G/L History Selections: 18.72',
      permissions: ['general_ledger_reports'],
    },
    component: JobAndOtherDistributionsReport,
  },
  {
    path: 'check-deposit-register',
    name: 'Check / Deposit Register',
    title: 'G/L Check / Deposit Register',
    description: 'Preview Check / Deposit Register',
    meta: {
      appScreen: 'G/L History Selections: 18.77',
      permissions: ['general_ledger_reports'],
    },
    component: CheckDepositRegisterReport,
  },
  {
    path: 'general-ledger',
    name: 'General Ledger',
    title: 'G/L General Ledger',
    description: 'Preview G/L General Ledger',
    meta: {
      appScreen: 'G/L General Ledger: 18.81',
      permissions: ['general_ledger_reports'],
    },
    component: GeneralLedgerReport,
  },
  {
    name: 'Account Listing',
    path: 'account-listing',
    title: 'G/L Account Listing',
    description: 'Preview G/L Account Listing',
    meta: {
      appScreen: 'G/L Account Listing: 18.82',
      permissions: ['general_ledger_reports'],
    },
    component: AccountListingReport,
  },
  {
    name: 'Financial Reports',
    path: 'financial-reports',
    title: 'G/L Financial Reports',
    description: 'Preview G/L Financial Reports',
    meta: {
      appScreen: 'G/L Financial Reports: 18.87',
      permissions: ['general_ledger_reports'],
    },
    component: FinancialReport,
  },
  {
    path: 'sub-accounts-budgets',
    title: 'Sub-Account / Budgets',
    name: 'Sub-Account / Budgets',
    description: 'Preview G/L Account Budgets',
    meta: {
      appScreen: 'G/L Sub-Account / Budgets: 18.83',
      permissions: ['general_ledger_reports'],
    },
    component: SubAccountsBudgetsReport,
  },
  {
    path: 'budget-to-actual-compare',
    name: 'Budget To Actual Compare',
    title: 'G/L Budget To Actual Compare',
    description: 'Preview G/L Budget To Actual Compare',
    meta: {
      appScreen: 'G/L Budget To Actual Compare: 18.84',
      permissions: ['general_ledger_reports'],
    },
    component: BudgetToActualCompareReport,
  },
  {
    path: 'trial-balance',
    name: 'Trial Balance',
    title: 'G/L Trial Balance',
    description: 'Preview G/L Trial Balance',
    meta: {
      appScreen: 'Trial Balance: 18.86',
      permissions: ['general_ledger_reports'],
    },
    component: TrialBalanceReport,
  },
  {
    path: 'account-balance-comparison',
    name: 'Account Balance Comparison',
    title: 'G/L Account Balance Comparison',
    description: 'Preview G/L Account Balance Comparison',
    meta: {
      appScreen: 'G/L Account Balance Comparison: 18.85',
      permissions: ['general_ledger_reports'],
    },
    component: AccountBalanceComparisonReport,
  },
  {
    name: 'Financial Reports List',
    path: 'financial-reports',
    title: 'G/L Design Financial Reports',
    description: 'Preview G/L Financial Reports',
    meta: {
      appScreen: 'G/L Financial Reports: 18.87',
      permissions: ['general_ledger_reports'],
    },
    component: FinancialReport,
  },
  {
    path: 'journals',
    name: 'All Journals',
    title: 'All Journals',
    meta: {
      permissions: ['general_ledger_reports'],
    },
    component: JournalsReport,
  },
]
export default [
  {
    path: '/ledger',
    name: 'General Ledger ',
    component: EmptyLayout,
    redirect: '/ledger/accounts',
    meta: {
      isModule: true,
      permissions: ['general_ledger_show'],
    },
    children: [
      {
        path: 'accounts',
        name: 'Accounts List',
        title: 'Accounts',
        description: 'Add or modify General Ledger accounts and descriptions',
        meta: {
          permissions: ['accounts_show', 'general_ledger_show'],
          appScreen: 'G/L Account Descriptions: 10.10',
        },
        component: AccountsList,
      },
      {
        path: 'accounts/import',
        name: 'Import GL Accounts',
        title: 'Import GL Accounts',
        description: 'Import GL Accounts from a CSV or Excel file',
        meta: {
          permissions: ['accounts_update'],
          appScreen: 'G/L Account Descriptions: 10.10',
        },
        component: AccountsImport,
      },
      {
        path: 'accounts/:id',
        name: 'Accounts Details ',
        redirect: '/ledger/accounts',
        meta: {
          permissions: ['accounts_show', 'general_ledger_show'],
          appScreen: 'G/L Account Descriptions: 10.10',
        },
        component: AccountLayout,
        children: [
          {
            path: 'view',
            name: 'Account History',
            meta: {
              permissions: ['accounts_show', 'general_ledger_show'],
              appScreen: 'G/L Account Descriptions: 20.40',
            },
            component: AccountHistory,
          },
          {
            path: 'summary',
            name: 'Account Summary',
            meta: {
              permissions: ['accounts_show', 'general_ledger_show'],
              appScreen: 'Review Account Balances: 10.70',
            },
            component: AccountSummary,
          },
          {
            path: 'edit',
            name: 'Account Edit',
            meta: {
              permissions: ['accounts_update'],
              appScreen: 'Setup G/L Accounts: 10.10',
            },
            component: AccountEdit,
          },
        ],
      },
      {
        path: 'sub-accounts',
        name: 'Sub Accounts',
        title: 'Sub Accounts',
        description: 'Enter period budgets for selected General Ledger accounts for use in producing',
        meta: {
          permissions: ['subaccounts_show', 'general_ledger_show'],
          appScreen: 'G/L Budgets/Subaccounts: 10.20',
        },
        component: SubAccountsList,
      },
      {
        path: 'budgets',
        name: 'Budgets',
        title: 'Budgets',
        description: 'Setup budgets for your accounts and compare them with actual account transactions',
        meta: {
          permissions: ['budgets_show', 'general_ledger_show'],
          appScreen: 'G/L Budgets/Subaccounts: 10.20',
        },
        component: BudgetsList,
      },
      {
        path: 'journal',
        redirect: '/ledger/journal/pending',
        meta: {
          permissions: ['journals_show', 'general_ledger_show'],
        },
        component: JournalsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Journals Entries',
            title: 'Pending Journal Entries',
            description: 'Prepare a General Journal for posting into the General Ledger',
            meta: {
              permissions: ['journals_show'],
              appScreen: 'General Journal Entries: 10.40',
            },
            component: PendingJournals,
          },
          {
            path: 'posted',
            name: 'Posted Journals Entries',
            title: 'Posted Journal Entries',
            description: 'Posted Journal Entries into the General Ledger',
            meta: {
              permissions: ['journals_show', 'general_ledger_show'],
              appScreen: 'General Journal Entries: 10.40',
            },
            component: PostedJournals,
          },
          {
            path: 'proof-listing',
            name: 'Journal Proof Listing',
            title: 'Journal Proof Listing',
            meta: {
              permissions: ['journals_update'],
            },
            component: JournalProofListing,
          },
          {
            path: 'import',
            name: 'Journal Import',
            title: 'Journal Import',
            meta: {
              permissions: ['journals_update'],
            },
            component: JournalsImport,
          },
          {
            path: 'add',
            name: 'Add Journal',
            title: 'Add Journal',
            description: 'Prepare a General Journal',
            meta: {
              permissions: ['journals_store'],
              appScreen: 'General Journal Entries: 10.40',
            },
            component: JournalAdd,
          },
          {
            path: ':id',
            name: 'Journal Entries',
            redirect: '/ledger/journal',
            meta: {
              permissions: ['journals_show', 'journals_update'],
            },
            component: JournalLayout,
            children: [
              {
                path: 'edit',
                name: 'Journal Edit',
                meta: {
                  permissions: ['journals_update'],
                  appScreen: 'General Journal Entries: 10.40',
                },
                component: JournalEdit,
              },
              {
                path: 'view',
                name: 'Journal Details',
                meta: {
                  permissions: ['journals_show', 'general_ledger_show'],
                  appScreen: 'General Journal Entries: 10.40',
                },
                component: JournalDetails,
              },
            ]
          },
        ]
      },
      {
        path: 'account-reconciliation',
        name: 'Account Reconciliation List',
        redirect: '/ledger/account-reconciliation/pending',
        meta: {
          permissions: ['cash_reconciliations_show', 'general_ledger_show'],
          appScreen: 'G/L Cash Reconciliation: 10.60',
        },
        component: AccountReconciliationsLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Reconciliation List',
            title: 'Pending Reconciliation List',
            meta: {
              permissions: ['cash_reconciliations_show', 'general_ledger_show'],
              appScreen: 'G/L Cash Reconciliation: 10.60',
              status: resourceStatuses.Pending
            },
            component: AccountReconciliationList,
          },
          {
            path: 'posted',
            name: 'Posted Reconciliation List',
            title: 'Posted Reconciliation List',
            meta: {
              permissions: ['cash_reconciliations_show', 'general_ledger_show'],
              appScreen: 'G/L Cash Reconciliation: 10.60',
              status: resourceStatuses.Posted
            },
            component: AccountReconciliationList,
          },
          {
            path: 'voided',
            name: 'Voided Reconciliation List',
            title: 'Voided Reconciliation List',
            meta: {
              permissions: ['cash_reconciliations_show', 'general_ledger_show'],
              appScreen: 'G/L Cash Reconciliation: 10.60',
              status: resourceStatuses.Voided
            },
            component: AccountReconciliationList,
          },
          {
            path: 'add',
            name: 'Account Reconciliation',
            title: 'Account Reconciliation',
            description: 'Reconcile a General Ledger cash account to its corresponding bank statement',
            meta: {
              permissions: ['cash_reconciliations_show', 'general_ledger_show'],
              appScreen: 'G/L Cash Reconciliation: 10.60',
            },
            component: AccountReconciliationAdd,
          },
          {
            path: ':id',
            name: 'Account Reconciliation Details ',
            redirect: '/ledger/account-reconciliation',
            meta: {
              permissions: ['cash_reconciliations_show', 'cash_reconciliations_update'],
            },
            component: AccountReconciliationLayout,
            children: [
              {
                path: 'edit',
                name: 'Reconciliation Edit',
                meta: {
                  permissions: ['cash_reconciliations_update'],
                  appScreen: 'G/L Cash Reconciliation: 10.60',
                },
                component: AccountReconciliationEdit,
              },
              {
                path: 'view',
                name: 'Reconciliation Details',
                meta: {
                  permissions: ['cash_reconciliations_show', 'general_ledger_show'],
                  appScreen: 'G/L Cash Reconciliation: 10.60',
                },
                component: AccountReconciliationDetails,
              },
            ]
          },
        ],
      },
      {
        path: 'balances',
        name: 'Account Balances',
        title: 'Account Balances',
        description: 'Visualize, print or export in CSV (Comma Separated Values) account balances',
        meta: {
          permissions: ['review_account_balances_show', 'general_ledger_show'],
          appScreen: 'Review Account Balances: 10.70',
        },
        component: AccountBalancesList,
      },
      {
        path: 'reports',
        name: 'GL Reports',
        redirect: '/ledger/reports/history-selections',
        meta: {
          permissions: ['general_ledger_reports'],
        },
        component: Reports,
        children: reportRoutes,
      },
      {
        path: 'settings',
        name: 'GL Settings',
        redirect: '/ledger/settings/initialize-system',
        meta: {
          hideBreadCrumb: true,
          permissions: ['general_ledger_settings'],
        },
        component: SettingsLayout,
        children: [
          {
            name: 'Periods',
            path: 'initialize-system',
            title: 'Initialize General Ledger Periods',
            description: 'Configure G/L fiscal year periods',
            meta: {
              permissions: ['periods_show', 'general_ledger_show'],
              appScreen: 'Initialize G/L System: 19.91',
            },
            component: InitializeSystem,
          },
          {
            path: 'activity-log',
            name: 'GL Settings Update Activity',
            meta: {
              permissions: ['general_ledger_settings'],
              module: $modules.GL,
            },
            component: SettingsActivityLog,
          },
          {
            name: 'Fiscal Years',
            path: 'fiscal-years',
            title: 'Manage General Ledger Fiscal Years',
            description: 'Configure G/L fiscal years',
            meta: {
              permissions: ['fiscal_year_show', 'general_ledger_show'],
            },
            component: FiscalYears,
          },
          {
            name: 'Financial Reports Configuration',
            path: 'financial-reports',
            title: 'Financial Reports Configuration',
            meta: {
              permissions: ['general_ledger_settings'],
            },
            component: FinancialReportsList,
          },
          {
            path: 'financial-reports/add',
            name: 'Add Financial Report',
            meta: {
              permissions: ['general_ledger_reports'],
            },
            component: FinancialReportAdd,
          },
          {
            path: 'financial-reports/:id',
            name: 'Financial Report Details ',
            meta: {
              permissions: ['general_ledger_reports'],
            },
            component: FinancialReportLayout,
            children: [
              {
                path: 'view',
                name: 'Financial Report Details',
                meta: {
                  permissions: ['general_ledger_reports'],
                },
                component: FinancialReportDetails,
              },
              {
                path: 'edit',
                name: 'Financial Report Edit',
                meta: {
                  permissions: ['general_ledger_reports'],
                },
                component: FinancialReportEdit,
              },
            ]
          },
          {
            name: 'General Ledger Settings',
            path: 'general-ledger',
            title: 'General Ledger Settings',
            description: 'Manage General Ledger Settings',
            meta: {
              permissions: ['general_ledger_settings'],
            },
            component: GeneralLedgerSettings,
          },
        ],
      },
      {
        path: 'journals',
        name: 'GL Journals',
        redirect: 'journals',
        meta: {
          permissions: ['general_ledger_reports'],
          module: 'ledger',
        },
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'GL Journals',
            title: 'GL Journals',
            meta: {
              hideBreadCrumb: true,
              permissions: ['general_ledger_reports'],
              module: 'ledger',
            },
            component: GLJournals,
          },
          {
            path: ':id/view',
            name: 'GL Journal Details',
            meta: {
              permissions: ['inventory_management_reports'],
              module: 'ledger',
            },
            component: CommonJournalDetails,
          },
        ]
      },
    ],
  },
]
