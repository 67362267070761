<template>
  <base-form :save-text="$t('Void')"
             :showCancel="showCancel"
             :loading="loading"
             layout="modal"
             @cancel="$emit('cancel')"
             @submit="onSubmit"
  >
    <div class="col-span-6">
      <div class="flex items-center justify-center text-md leading-5 text-cool-gray-500 font-medium pb-6">
        <svg class="h-10 w-10 text-primary-600"
             stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
        </svg>
        <span class="px-4 text-center">
          {{ $t(promptMessage) }}
        </span>
      </div>
    </div>
    <template v-if="isAccounting">
      <div class="col-span-6 md:col-span-3">
        <YearSelect v-model="model.fiscal_year"
                    :label="$t('Year')"
                    :name="$t('Year')"
                    rules="required"
                    label-key="fiscal_year"
                    value-key="fiscal_year"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <period-select v-model="model.period"
                       :fiscal-year="model.fiscal_year"
                       :label="$t('Period')"
                       :name="$t('Period')"
                       rules="required"
                       id="period-select"
        />
      </div>
    </template>
  </base-form>
</template>
<script>

  export default {
    props: {
      promptMessage: {
        type: String,
        default: '',
      },
      loading: {
        type: [Boolean, Number],
        default: false,
      },
      isAccounting: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showCancel: true,
        model: {},
      }
    },
    methods: {
      onSubmit() {
        this.$emit('save', this.model)
      },
    },
    mounted() {
      this.model = {
        period: this.$store.getters['company/getCurrentPeriodValue'],
        fiscal_year: this.activeFiscalYear,
      }
    },
  }
</script>
