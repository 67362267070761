<template>
  <div class="flex items-center">
    <slot name="activator" :open="onSendClick">
      <BaseButton
        variant="primary-link"
        class="print:hidden send-email-button"
        v-bind="$attrs"
        @click="onSendClick"
      >
      <span class="flex items-center">
        <MailIcon class="w-4 h-4 mr-2"/>
        <span>
          {{ $t('Send Emails') }}
        </span>
      </span>
      </BaseButton>
    </slot>

    <BaseFormDialog
      v-if="showSendEmailDialog"
      :title="$t('Send Employee W-2s via Email')"
      :open.sync="showSendEmailDialog"
      size="lg"
      :appendToBody="true"
      @close="showSendEmailDialog = false"
    >
      <BaseForm
        v-bind="$attrs"
        :save-text="$t('Send')"
        :loading="loading"
        layout="modal"
        :focusOnFirstInput="false"
        :showCancel="true"
        :submitDisabled="submitDisabled"
        @cancel="showSendEmailDialog = false"
        @submit="sendEmail"
      >
        <BaseFilterRow
          :title="$t('Type')"
          class="col-span-full"
        >
          <EmployeeW2sTypeSelect
            v-model="model.type"
            class="col-span-6"
            rules="required"
            @change="model.copies = []"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Copies')"
          class="col-span-full"
        >
          <EmployeeW2sCopiesSelect
            v-model="model.copies"
            :type="model.type"
            :show-employee-copies-only="true"
            class="col-span-6"
            rules="required"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Tax Year')"
          class="col-span-full"
        >
          <div class="text-gray-600 text-sm">{{ tax_year }}</div>
        </BaseFilterRow>
        <div class="col-span-6">
          <h4>
            {{ $t('Selected Employees') }}
          </h4>
          <div class="text-gray-600 text-sm mb-4">
            ({{ $t('When all selected, only employees with valid email addresses will be sent') }})
          </div>
          <AgDataTable
            :pagination="false"
            :tooltipShowDelay="0"
            :data="data"
            :columns="columns"
            domLayout="autoHeight"
          >
            <template #employee.code="{row}">
              <EmployeeLink :data="row.employee" :show-name="false"/>
            </template>
            <template #employee.address="{row}">
              <BaseAddressLink
                :value="row.employee?.address"
                class="mx-2"
              />
            </template>
          </AgDataTable>
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from 'vue'
import axios from 'axios'
import i18n from "@/i18n";
import {error} from '@/components/common/NotificationPlugin'
import {MailIcon} from 'vue-feather-icons'
import EmployeeW2sCopiesSelect from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2sCopiesSelect.vue";
import {EmployeeW2SOptions} from "@/modules/payroll/components/reports/util";
import {Vendor1099sReportOptions} from "@/modules/accounts-payable/components/reports/util";
import EmployeeW2sTypeSelect from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2sTypeSelect.vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  tax_year: {
    type: [Number, String],
    required: true,
  },
})

const showSendEmailDialog = ref(false)
const loading = ref(false)

const model = ref({
  tax_year: props.tax_year,
  type: EmployeeW2SOptions.Types.FourUp,
  copies: [
    EmployeeW2SOptions.Copies.A,
  ],
  recipients: [] as string[],
})

async function onSendClick() {
  showSendEmailDialog.value = true
}

const submitDisabled = computed(() => {
  return props.data.filter((row: any) => row.employee.email).length === 0
})

const columns = computed(() => {
  return [
    {
      label: i18n.t('Code'),
      prop: 'employee.code',
      minWidth: 160,
    },
    {
      label: i18n.t('Name'),
      prop: 'employee.name',
      minWidth: 150,
      maxWidth: 320,
    },
    {
      label: i18n.t('Address'),
      prop: 'employee.address',
      minWidth: 350,
    },
    {
      label: i18n.t('Email'),
      prop: 'employee.email',
      minWidth: 250,
      maxWidth: 350,
    }
  ]
})

const emit = defineEmits(['save', 'cancel'])

function getRecipients() {
  return props.data.map((row: any) => {
    return row.employee.id
  })
}

async function sendEmail() {
  try {
    loading.value = true
    const data = {
      tax_year: model.value.tax_year,
      type: model.value.type,
      copies: model.value.copies,
      recipients: getRecipients(),
    }
    await axios.post(`/restify/employees/actions?action=send-w2s-mail`, data)
    // success(i18n.t('Emails are queued to be sent. The selected recipients will receive their email in a couple of minutes.'))
    showSendEmailDialog.value = false
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not send emails'))
  } finally {
    loading.value = false
  }
}
</script>
