<template>
  <BaseSelect
      ref="select"
      v-focus
      v-model="selectedValue"
      :valueKey="valueKey"
      :labelKey="labelKey"
      :options="options"
      :label-format="labelFormat"
      :allow-create="params.allowCreate"
      :clearable="params.clearable"
      inline-errors
      class="w-full"
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>

  import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

  export default {
    name: 'BaseSelectCellEditor',
    mixins: [selectEditorMixin],
    computed: {
      options() {
        return this.params?.options || []
      },
      labelKey() {
        return this.params?.labelKey
      },
      labelFormat() {
        return this.params?.labelFormat
      },
      valueKey() {
        return this.params?.valueKey
      },
    },
  }
</script>
