import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import authModule from "@/modules/auth/store/authModule";
import userModule from "@/modules/settings/store/userModule";
import themeModule from "@/modules/dashboard/store/themeModule";
import companyModule from "@/modules/settings/store/companyModule";
import fileImportModule from "@/modules/common/store/fileImportModule";
import tableModule from "@/modules/common/store/tableModule";
import generalLedgerModule from "@/modules/ledger/store/generalLedgerModule";
import accountsPayableModule from "@/modules/accounts-payable/store/accountsPayableModule";
import accountsReceivableModule from "@/modules/accounts-receivable/store/accountsReceivableModule";
import payrollModule from "@/modules/payroll/store/payrollModule";
import timesheetsModule from "@/modules/payroll/store/timesheetsModule";
import settingsModule from "@/modules/settings/store/settingsModule";
import jobModule from "@/modules/job-costing/store/jobModule";
import listsModule from "@/modules/common/store/lists/listsModule";
import inventoryManagement from "@/modules/purchasing-inventory/store/inventory-management";
import serviceBillingModule from "@/modules/service-billing/store/serviceBillingModule";
import inheritanceModule from "@/modules/common/store/inheritanceModule";
import gridSelect from "@/components/grid-table/store/gridSelect";
import dataGridConfig from "@/modules/common/store/dataGridConfig";
import dashboardModule from "@/modules/dashboard/store/dashboardModule";
import notesModule from "@/modules/dashboard/store/notesModule";
import equipmentModule from "@/modules/equipment/store/equipmentModule";

Vue.use(Vuex)

localStorage.removeItem('vuex-multi-tab')
export default new Vuex.Store({
  plugins: [
    /* This caused storage limitations in the browser. It seems there's a limit of 10mb to store in localStorage
    createMultiTabState({
      statesPaths: [
        'auth',
        'globalLists',
        'settings',
      ]
    }),*/
    createPersistedState({
      paths: [
        'auth',
        'theme',
        'fileImport',
        'table',
        `settings`,
        `payroll.defaultChecksTemplate`,
        `company.settings`,
        `company.activeCompany`,
        `company.currentFiscalYear`,
        `company.periods`,
      ]
    }),
    // createDbPersistedState({
    //   paths: [
    //     `company.accounts`,
    //     `company.subAccounts`,
    //     `company.banks`,
    //     `company.periods`,
    //     `company.users`,
    //     `company.fiscalYears`,
    //     `globalLists.${globalResources.Jobs}`,
    //     `globalLists.${globalResources.LineItems}`,
    //     `globalLists.${globalResources.IncomeLineItems}`,
    //     `globalLists.${globalResources.CostLineItems}`,
    //     `globalLists.${globalResources.MasterLineItems}`,
    //     `globalLists.${globalResources.JobCostTypes}`,
    //     `globalLists.${globalResources.JobIncomeTypes}`,
    //     `globalLists.${globalResources.Vendors}`,
    //     `globalLists.${globalResources.Customers}`,
    //     `globalLists.${globalResources.Employees}`,
    //     `globalLists.${globalResources.SalesTaxDistricts}`,
    //     `globalLists.${globalResources.ServiceBillingTypes}`,
    //     `globalLists.${globalResources.ServiceBillingCodes}`,
    //     `globalLists.${globalResources.WorkOrders}`,
    //     `globalLists.${globalResources.Materials}`,
    //     `globalLists.${globalResources.Equipments}`,
    //     `globalLists.${globalResources.EquipmentTypes}`,
    //     `globalLists.${globalResources.EquipmentMaintenances}`,
    //     `globalLists.${globalResources.CompanyAddresses}`,
    //     `globalLists.${globalResources.WaiverReleaseTemplates}`,
    //     `globalLists.${globalResources.TransactionsForCostPlus}`,
    //     `globalLists.${globalResources.Users}`,
    //     `globalLists.${globalResources.CraftCodes}`,
    //     `globalLists.${globalResources.PayCodes}`,
    //     `globalLists.${globalResources.BenefitCodes}`,
    //     `globalLists.${globalResources.DeductionCodes}`,
    //     `globalLists.${globalResources.DirectDepositCodes}`,
    //     `globalLists.${globalResources.GeneralCodes}`,
    //     `globalLists.${globalResources.TaxCodes}`,
    //     `globalLists.${globalResources.StateTaxes}`,
    //     `globalLists.${globalResources.LocalTaxes}`,
    //     `globalLists.${globalResources.UnionCodes}`,
    //     `globalLists.${globalResources.BillingRateTypes}`,
    //     `globalLists.${globalResources.WTwoCodes}`,
    //     `globalLists.${globalResources.AllCraftsAndLevels}`,
    //   ]
    // })
  ],
  modules: {
    auth: authModule,
    user: userModule,
    company: companyModule,
    dashboard: dashboardModule,
    notes: notesModule,
    fileImport: fileImportModule,
    theme: themeModule,
    table: tableModule,
    generalLedger: generalLedgerModule,
    settings: settingsModule,
    accountsPayable: accountsPayableModule,
    accountsReceivable: accountsReceivableModule,
    payroll: payrollModule,
    timesheets: timesheetsModule,
    jobCosting: jobModule,
    globalLists: listsModule,
    inventoryManagement: inventoryManagement,
    serviceBilling: serviceBillingModule,
    sibling: inheritanceModule,
    gridSelect: gridSelect,
    dataGridConfig: dataGridConfig,
    equipmentManagement: equipmentModule,
  }
})
