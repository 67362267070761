<template>
  <div class="h-full">
    <AgDataTable
      :key="status"
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-entity-in-new-tab="addEntityInNewTab"
      :addEntityUrlQuery="addEntityUrlQuery"
      :add-text="$t('New work order')"
      :per-page="$pagination.list"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :groupDefaultExpanded="1"
      :groupRowRendererParams="groupRowRendererParams"
      groupDisplayType="groupRows"
      add-entity-url-query="/service-billing/work-orders/add"
      base-entity-path="/service-billing/work-orders"
      default-sort="-date"
      permission="work_orders"
      actions="search,refresh,view"
      ref="table"
      :reports="tableReports"
      @data-fetch="tableData = $event"
    >
      <template #dropdown-actions>
        <!--TODO Handle bulk approval when backend is done-->
        <TableActionItem
          :title="$t('Close Work Orders')"
          :icon="LockIcon"
          icon-class="bg-primary-50 text-primary-500"
          @click="showCloseWorkOrdersDialog = true"
        />
      </template>
      <template #attributes.number="{row}">
        <WorkOrderLink :data="row?.attributes" :show-description="false"/>
      </template>
      <template #attributes.description="{row}">
      <span class="truncate">
        {{ row.attributes.description }}
      </span>
      </template>
      <template
        v-if="workSiteId"
        #extra-actions="{row}">
        <NotesButton
          :params="{
            id: row.id,
            resourceName: RestifyResources.WorkOrders,
            title: row.attributes.number
          }"
        />
      </template>
      <template #worksite="{row}">
        <WorksiteLink
          :data="row.relationships.workSite"
        />
      </template>
      <template #attributes.customer_id="{row}">
        <CustomerLink
          :data="row.relationships.customer"
          :show-name="true"
        />
      </template>
    </AgDataTable>
    <CloseOldWorkOrdersDialog
      v-if="showCloseWorkOrdersDialog"
      :open.sync="showCloseWorkOrdersDialog"
      @close="showCloseWorkOrdersDialog = false"
      @save="onCloseWorkOrders"
    />
  </div>
</template>
<script>
import { ExternalLinkIcon, LockIcon, UserIcon } from 'vue-feather-icons'
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import NotesButton from "@/modules/dashboard/components/NotesButton.vue";
import { RestifyResources } from "@/components/form/util";
import WorkOrderGroupRow from "@/modules/service-billing/components/WorkOrderGroupRow.vue";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { workOrderStatusOptions } from "@/enum/enums";
import CloseOldWorkOrdersDialog from "@/modules/service-billing/components/workorder/CloseOldWorkOrdersDialog.vue";
import { WOBilledStatus } from "@/modules/service-billing/components/reports/sortByOptions";

export default {
  components: {
    CloseOldWorkOrdersDialog,
    NotesButton,
    UserIcon,
    LockIcon,
    ExternalLinkIcon,
    WorkOrderGroupRow,
  },
  props: {
    workSiteId: {
      type: String,
    },
    status: {
      type: String,
    },
    billed: {
      type: Boolean,
    },
    customerId: {
      type: String,
    }
  },
  data() {
    return {
      url: '/restify/work-orders',
      addEntityInNewTab: true,
      showCloseWorkOrdersDialog: false,
      tableData: [],
      LockIcon,
    }
  },
  computed: {
    RestifyResources() {
      return RestifyResources
    },
    addEntityUrlQuery() {
      if (this.customerId) {
        return `/service-billing/work-orders/add?customer_id=${this.customerId}`
      }
      return `/service-billing/work-orders/add`
    },
    urlParams() {
      const filters = {
        related: `${entityPreviewFields.Customer},${entityPreviewFields.Worksite}`,
        work_site_id: this.workSiteId,
        status: this.status,
        billed: this.billed,
        customer_id: this.customerId
      }
      if (!this.status) {
        delete filters.status
      }
      return filters
    },
    columns() {
      const result = [
        {
          label: this.$t('Number'),
          prop: 'attributes.number',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          minWidth: 200,
          flex: 1,
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          minWidth: 100,
          maxWidth: 120,
          editable: true,
          cellEditor: cellEditors.Status,
          cellEditorParams: {
            options: workOrderStatusOptions,
          },
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'serviceBilling/patchWorkOrder',
            })(params)
          },
        },
        {
          label: this.$t('Date'),
          prop: 'attributes.date',
          component: 'FormattedDate',
          minWidth: 120,
          maxWidth: 130,
        },
        {
          label: this.$t('Quoted Minimum Cost'),
          prop: 'attributes.minimum_cost_quoted_amount',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
          hide: true,
        },
        {
          label: this.$t('Quoted Estimated Cost'),
          prop: 'attributes.estimated_cost_quoted_amount',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
          hide: true,
        },
      ]
      if (!this.workSiteId) {
        result.splice(1, 0, {
          label: this.$t('Worksite'),
          prop: 'worksite',
          minWidth: 250,
          maxWidth: 300,
        })
      }
      result.splice(1, 0, {
        label: this.$t('Customer'),
        prop: 'attributes.customer_id',
        minWidth: 100,
        maxWidth: 200,
        rowGroup: true,
        hide: true,
      })
      return result
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'WorkOrderGroupRow',
        suppressCount: true,
        wrapText: true,
        autoHeight: true,
      }
    },
    tableReports() {
      if (!this.$can('service_billing_reports')) {
        return []
      }

      const routeMeta = this.$route.meta

      let wo_billed_status = WOBilledStatus.All

      if (routeMeta.billed) {
        wo_billed_status = WOBilledStatus.Billed
      } else if (routeMeta.billed === false) {
        wo_billed_status = WOBilledStatus.Unbilled
      }
      const queryParams = new URLSearchParams({
        wo_billed_status,
        auto_run: false,
      })

      if (routeMeta.status) {
        queryParams.set('work_order_statuses', routeMeta.status)
      }
      
      const queryParamsStr = queryParams.toString()

      return [
        {
          title: this.$t('Work Order Listing'),
          path: `/service-billing/reports/work-order-listing?${queryParamsStr}`,
        },
        {
          title: this.$t('Work Order Detail'),
          path: `/service-billing/reports/work-order-detail?${queryParamsStr}`,
        },
        {
          title: this.$t('Work Order Profit Summary'),
          path: `/service-billing/reports/work-order-profit-summary?${queryParamsStr}`,
        },
        {
          title: this.$t('Service Billing History'),
          path: `/service-billing/reports/service-billing-history?${queryParamsStr}`,
        },
      ]
    }
  },
  methods: {
    onCloseWorkOrders() {
      this.showCloseWorkOrdersDialog = false
      this.refreshData()
    },
    refreshData() {
      this.$refs.table?.refresh()
    }
  }
}
</script>
