<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :appendToBody="true"
    :title="$t('Invite User')"
  >
    <base-form
      :loading="loading"
      :show-cancel="true"
      :save-text="$t(`Invite User`)"
      layout="vertical"
      @cancel="$emit('close')"
      @submit="attachUser"
    >
      <div class="mb-4 col-span-6 prose">
        <p>
          The following action will create a user with the Employee Role and send a registration confirmation email to
          <b>{{ employeeEmail }}</b> for {{ employee?.attributes?.name }}.
        </p>
      </div>
      <div class="col-span-6 mb-4">
        <base-input
          :label="$t('Platform to invite')"
          :tip="$t('Based on the platform selected, the user will receive an email with the registration link to the selected platform.')"
          label-class="text-base"
        >
          <template #container>
            <base-radio
              v-for="option in platformTypes"
              v-model="model.platform"
              :label="option.value"
              :key="`radio-status-${option.value}`"
              :active="option.value === model.platform"
              name="platform">
              {{ option.label }}
            </base-radio>
          </template>
        </base-input>
        <div class="my-4 col-span-6 prose">
          <p>
            You can always change the user's role by accessing <router-link to="/settings/company-users">Company Users</router-link> menu.
          </p>
        </div>
      </div>
    </base-form>
  </BaseFormDialog>
</template>
<script>
import axios from "axios";

const PlatformTypes = {
  Cloud: 'cloud',
  TimeCard: 'timelog',
}

export default {
  props: {
    employee: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      model: {
        platform: PlatformTypes.TimeCard,
      },
    }
  },
  computed: {
    platformTypes() {
      return [
        {
          label: this.$t('Cloud Application'),
          value: PlatformTypes.Cloud,
        },
        {
          label: this.$t('Timesheets Application'),
          value: PlatformTypes.TimeCard,
        }
      ]
    },
    employeeEmail() {
      return this.employee?.attributes?.email || ''
    }
  },
  methods: {
    async attachUser() {
      const employee = this.employee

      try {
        this.loading = true
        const payload = {
          repositories: [employee.id],
          platform: this.model.platform,
        }
        await axios.post(`/restify/employees/actions?action=attach-users-to-employees`, payload)
        this.$emit('save')
      } catch (err) {
        this.$error('An error occurred while trying to send the registration emails')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
