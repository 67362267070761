<template>
  <ValidationObserver>
    <BaseForm
      :loading="loading"
      :save-text="isDeleteAction ? $t('Delete') : $t('Copy')"
      grid-classes="grid grid-cols-4 gap-x-3"
      layout="vertical"
      submit-button-type="button"
      @submit="onSubmit"
    >
      <BaseSelect
        v-model="action"
        :options="actionOptions"
        :label="$t('Select Action')"
        :name="$t('Select Action')"
        :placeholder="$t('Select Action')"
        id="action"
        rules="required"
        @change="onChangeAction"
      />
      <template v-if="action === ACTION_COPY_JOB">
        <JobSelect
          v-model="model.from"
          :label="$t('Job Number to Copy From')"
          :name="$t('Job Number')"
          :add-entity="false"
          :url-params="jobUrlParams"
          :exclude-ids="[model.to]"
          id="from_job"
          rules="required"
        />
        <JobSelect
          v-model="model.to"
          :label="$t('Job Number to Copy To')"
          :name="$t('Job Number')"
          :add-entity="false"
          :exclude-ids="[model.from]"
          id="to_job"
          rules="required"
        />
        <BaseDatePicker
          v-model="model.start_date"
          :label="$t('Start Date')"
          :name="$t('Start Date')"
          id="start_date"
          rules="required"
        />
      </template>
      <template v-if="action === ACTION_COPY_UNION">
        <UnionSelect
          v-model="model.from"
          :label="$t('Union Code to Copy From')"
          :name="$t('Union Code')"
          :add-entity="false"
          :exclude-ids="[model.to]"
          id="from_union"
          rules="required"
        />
        <UnionSelect
          v-model="model.to"
          :label="$t('Union Code to Copy To')"
          :name="$t('Union Code')"
          :add-entity="false"
          :exclude-ids="[model.from]"
          id="to_union"
          rules="required"
        />
        <BaseDatePicker
          v-model="model.start_date"
          :label="$t('Start Date')"
          :name="$t('Start Date')"
          id="start_date"
          rules="required"
        />
      </template>
      <template v-if="action === ACTION_UNION_TO_JOB">
        <UnionSelect
          v-model="model.from"
          :label="$t('Union Code to Copy From')"
          :name="$t('Union Code')"
          :add-entity="false"
          id="_from_union"
          rules="required"
        />
        <JobSelect
          v-model="model.to"
          :label="$t('Job Number to Copy To')"
          :name="$t('Job Number')"
          :add-entity="false"
          id="_to_job"
          rules="required"
        />
        <BaseDatePicker
          v-model="model.start_date"
          :label="$t('Start Date')"
          :name="$t('Start Date')"
          id="start_date"
          rules="required"
        />
      </template>
      <template v-if="action === ACTION_JOB_TO_UNION">
        <JobSelect
          v-model="model.from"
          :label="$t('Job Number to Copy From')"
          :name="$t('Job Number')"
          :add-entity="false"
          :url-params="jobUrlParams"
          id="_from_job"
          rules="required"
        />
        <UnionSelect
          v-model="model.to"
          :label="$t('Union Code to Copy To')"
          :name="$t('Union Code')"
          :add-entity="false"
          id="to_union_"
          rules="required"
        />
        <BaseDatePicker
          v-model="model.start_date"
          :label="$t('Start Date')"
          :name="$t('Start Date')"
          id="start_date"
          rules="required"
        />
      </template>
      <template v-if="action === ACTION_DELETE_JOB">
        <JobSelect
          v-model="model.from"
          :label="$t('Job Number of Rates to Delete')"
          :name="$t('Job Number')"
          :add-entity="false"
          :url-params="jobUrlParams"
          id="from_job_"
          rules="required"
        />
      </template>
      <template v-if="action === ACTION_DELETE_UNION">
        <UnionSelect
          v-model="model.from"
          :label="$t('Union Code of Rates to Delete')"
          :name="$t('Union Code')"
          :add-entity="false"
          id="_to_union"
          rules="required"
        />
      </template>
    </BaseForm>
  </ValidationObserver>
</template>
<script>
import axios from 'axios'

const ACTION_COPY_JOB = 'copy-job'
const ACTION_COPY_UNION = 'copy-union'
const ACTION_UNION_TO_JOB = 'union-to-job'
const ACTION_JOB_TO_UNION = 'job-to-union'
const ACTION_DELETE_JOB = 'delete-job'
const ACTION_DELETE_UNION = 'delete-union'

const BACKEND_EPS = {
  [ACTION_COPY_JOB]: '/restify/job-rates/actions?action=copy-job-rates-to-job',
  [ACTION_COPY_UNION]: '/restify/union-rates/actions?action=copy-union-rates-to-union',
  [ACTION_JOB_TO_UNION]: '/restify/job-rates/actions?action=copy-job-rates-to-union',
  [ACTION_UNION_TO_JOB]: '/restify/union-rates/actions?action=copy-union-rates-to-job',
}

export default {
  data() {
    return {
      loading: false,
      action: ACTION_COPY_JOB,
      jobUrlParams: {
        having_job_rates: true,
      },
      model: {
        from: undefined,
        to: undefined,
        start_date: null,
      },
      actionOptions: [
        {
          label: this.$t('Copy Job Rates to Another Job'),
          value: ACTION_COPY_JOB,
        },
        {
          label: this.$t('Copy Union Rates to Another Union'),
          value: ACTION_COPY_UNION,
        },
        {
          label: this.$t('Copy Union Rates to a Job'),
          value: ACTION_UNION_TO_JOB,
        },
        {
          label: this.$t('Copy Job Rates to a Union'),
          value: ACTION_JOB_TO_UNION,
        },
        {
          label: this.$t('Delete Selected Job Rates'),
          value: ACTION_DELETE_JOB,
        },
        {
          label: this.$t('Delete Selected Union Rates'),
          value: ACTION_DELETE_UNION,
        },
      ],
      ACTION_COPY_JOB,
      ACTION_COPY_UNION,
      ACTION_UNION_TO_JOB,
      ACTION_JOB_TO_UNION,
      ACTION_DELETE_JOB,
      ACTION_DELETE_UNION,
    }
  },
  computed: {
    isDeleteAction() {
      return [
        ACTION_DELETE_JOB,
        ACTION_DELETE_UNION
      ].includes(this.action)
    }
  },
  methods: {
    async onSubmit() {
      try {
        const title = this.isDeleteAction
          ? this.$t('Delete rates')
          : this.$t('Copy rates')

        const buttonText = this.isDeleteAction
          ? this.$t('Confirm Delete')
          : this.$t('Confirm')

        const confirmed = await this.$confirm({
          title,
          description: this.$t('Are you sure you want run the selected task?'),
          buttonText,
        })

        if (!confirmed) {
          return
        }
        this.loading = true

        if (this.action === ACTION_DELETE_JOB) {
          await axios.post(`/restify/jobs/${this.model.from}/actions?action=delete-job-rates-from-job`)
        }
        else if (this.action === ACTION_DELETE_UNION) {
          await axios.post(`/restify/unions/${this.model.from}/actions?action=delete-union-rates-from-union`)
        }
        else {
          await axios.post(`${BACKEND_EPS[this.action]}`, this.model)
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    onChangeAction() {
      this.model = {
        from: null,
        to: null,
        start_date: null,
      }
    },
  },
}
</script>
