<template>
  <div>
    <BaseDataTable
      :url="url"
      :key="type"
      :columns="tableColumns"
      :url-params="urlParams"
      :add-text="$t(`New ${type} line item`)"
      :add-entity-in-new-tab="addEntityInNewTab"
      :default-match-filters="defaultMatchFilters"
      :add-default-columns="listView"
      :group-by="selectedView === viewModes.Summary ? groupByType.Job : undefined"
      :can-manage-columns="selectedView !== viewModes.Summary"
      :hide-actions="selectedView === viewModes.Summary ? 'view,edit' : undefined"
      :show-actions-column="selectedView !== viewModes.Summary"
      entity="line-items"
      actions="search,refresh,view,import,export,print"
      import-url="/job-costing/line-items/import"
      default-sort="-updated_at"
      permission="line_items"
      ref="table"
      class="line-items-table"
      :deleteCustom="true"
      @delete="onDeleteLineItem"
      @add="$router.push(`${type}-line-items/add`)"
    >
      <template #additional-actions-before>
        <div class="w-48">
          <BaseSelect
            v-model="selectedView"
            :options="viewOptions"
            inline-errors
            @change="onChangeViewMode"
          />
        </div>
      </template>
      <template #header="{row}">
        <div class="w-1/2">
          <JobLink
            :data="get(row, 'header.attributes')"
            :parent-id="row.id"
            :show-description="true"
          />
        </div>
      </template>
      <template #job="{row}">
        <JobLink
          :data="get(row, 'relationships.job')"
          :label="get(row, 'relationships.job.attributes.number')"
          :parent-id="row.id"
        />
      </template>

      <template #attributes.amount="{row}">
        <LineItemSiblingBudgetsDiffCell
          :row="row"
          :line-item-type="type"
          :show-difference="selectedView === viewModes.CostVsIncome"
          amount-key="amount"
        />
      </template>

      <template #attributes.amount_to_date="{row}">
        <LineItemSiblingBudgetsDiffCell
          :row="row"
          :line-item-type="type"
          :show-difference="selectedView === viewModes.CostVsIncome"
          amount-key="amount_to_date"
        />
      </template>

      <template #budgets="{row}">
        <LineItemSummary
          :data="row"
          :show-budgets-only="true"
          :no-borders="true"
          :show-empty-budgets="false"
        />
      </template>
    </BaseDataTable>
    <DeleteResourcePreflightDialog
      v-if="showDeleteLineItemDialog"
      :open.sync="showDeleteLineItemDialog"
      :resource="lineItemToDelete"
      :resourceName="$globalResources.LineItems"
      @deleted="onLineItemDeleted"
      @close="showDeleteLineItemDialog = false"
    />
  </div>
</template>
<script>
import { DollarSignIcon, ListIcon } from 'vue-feather-icons'
import LineItemSummary from '@/modules/job-costing/components/line-items/LineItemSummary';
import { groupByType } from '@/components/table/utils/groupByTypes';
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import LineItemSiblingBudgetsDiffCell from '@/modules/job-costing/components/line-items/LineItemSiblingBudgetsDiffCell'

const viewModes = {
  List: 'list',
  Summary: 'summary',
  CostVsIncome: 'cost-vs-income',
}
export default {
  components: {
    LineItemSummary,
    LineItemSiblingBudgetsDiffCell,
  },
  data() {
    return {
      viewModes,
      groupByType,
      viewOptions: [
        {
          label: this.$t('List'),
          value: viewModes.List,
          icon: ListIcon,
        },
        {
          label: this.$t('Summary'),
          value: viewModes.Summary,
          icon: DollarSignIcon,
        },
        {
          label: this.$t('Cost vs  Income'),
          value: viewModes.CostVsIncome,
          icon: DollarSignIcon,
        }
      ],
      selectedView: viewModes.List,
      url: '/restify/line-items',
      addEntityInNewTab: true,
      lineItemToDelete: null,
      showDeleteLineItemDialog: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Phase Code'),
          prop: 'attributes.phase_code',
          minWidth: 40,
          maxWidth: 40,
        },
        {
          label: this.$t('Cost Code'),
          prop: 'attributes.cost_code',
          minWidth: 40,
          maxWidth: 40,
        },
        {
          label: this.$t('Change Order'),
          prop: 'attributes.change_order',
          minWidth: 60,
          maxWidth: 60,
          align: 'center',
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          component: 'EntityLink',
          entityKey: 'id',
          redirectTo: `/job-costing/${this.type}-line-items/{ID}/view`,
          maxWidth: 200,
        },
        {
          label: this.$t('Job'),
          prop: 'job',
          minWidth: 120,
          maxWidth: 160,
        },
        {
          label: this.$t('Total Budget'),
          prop: 'attributes.amount',
          align: 'right',
        },
        {
          label: this.$t('Total Actual'),
          prop: 'attributes.amount_to_date',
          align: 'right',
        },
      ]
    },
    summaryColumns() {
      return [
        {
          label: this.$t('Phase Code'),
          prop: 'attributes.phase_code',
          minWidth: 45,
          maxWidth: 45,
        },
        {
          label: this.$t('Cost Code'),
          prop: 'attributes.cost_code',
          minWidth: 40,
          maxWidth: 40,
        },
        {
          label: this.$t('Change Order'),
          prop: 'attributes.change_order',
          minWidth: 40,
          maxWidth: 40,
          align: 'center',
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          component: 'EntityLink',
          entityKey: 'id',
          redirectTo: `/job-costing/${this.type}-line-items/{ID}/view`,
          maxWidth: 200,
        },
        {
          label: this.$t('Budgets'),
          prop: 'budgets',
          minWidth: 300,
        },
      ]
    },
    type() {
      return this.$route.path.includes('cost-line-items') ? 'cost' : 'income'
    },
    urlParams() {
      const related = this.costVsIncomeView ? `${entityPreviewFields.Job},${entityPreviewFields.LineItemSibling}` : entityPreviewFields.Job
      return {
        related,
        type: this.type,
      }
    },
    listView() {
      return [viewModes.List, viewModes.CostVsIncome].includes(this.selectedView)
    },
    tableColumns() {
      return this.listView ? this.columns : this.summaryColumns
    },
    costVsIncomeView() {
      return this.selectedView === viewModes.CostVsIncome
    },
    defaultMatchFilters() {
      if (!this.costVsIncomeView) {
        return {
          for_open_jobs: true,
        }
      }
      return {
        for_open_jobs: true,
        having_sibling: true,
      }
    },
  },
  methods: {
    async onChangeViewMode() {
      await this.$nextTick()
      this.$refs.table.refresh()
    },
    onDeleteLineItem(lineItem) {
      this.lineItemToDelete = lineItem
      this.showDeleteLineItemDialog = true
    },
    onLineItemDeleted() {
      this.showDeleteLineItemDialog = false
      this.$refs.table.refresh()
    },
  }
}
</script>

<style lang="scss">
  .line-items-table .td-cell {
    vertical-align: top;

    th {
      z-index: 0;
    }
  }
</style>
