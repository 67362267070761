<template>
  <div class="sm:rounded-md overflow-hidden">
    <ReleaseRetentionFilters
      :module="modules.AccountsReceivable"
      @save="onFilter"
    />

    <ReleaseRetentionTable
      v-if="filters"
      :filters="filters"
      :module="modules.AccountsReceivable"
      class="mt-4"
    />
  </div>
</template>
<script>
import ReleaseRetentionTable from '@/modules/common/components/release-retention/ReleaseRetentionTable.vue'
import ReleaseRetentionFilters
  from "@/modules/accounts-payable/components/subcontract-invoices/ReleaseRetentionFilters.vue";
import { modules } from "@/modules/common/components/settings/util";

export default {
  components: {
    ReleaseRetentionTable,
    ReleaseRetentionFilters,
  },
  data() {
    return {
      modules,
      filters: null
    }
  },
  methods: {
    onFilter(filters) {
      this.filters = {
        ...filters,
      }
    }
  }
}
</script>
