import i18n from '@/i18n'
import store from '@/store'
import { get } from 'lodash'
import { globalResources } from "@/components/form/util";
import {
  getAddlSourceName,
  getSourceName,
} from '@/components/grid-table/utils/cost-center-cell';
import { composeLineItemName } from '@/utils/utils';
import { compareValueFormatter, FormatTypes } from "@/modules/purchasing-inventory/components/purchase-orders/util";
import { sourceTypes } from "@/modules/service-billing/util/service-billing";

const TransactionTypes = {
  Expense: 'expense',
  JobCost: 'job-costed',
  CustomerBilled: 'customer-billed',
  NA: 'n/a',
}

const TransactionTypeLabels = {
  [TransactionTypes.Expense]: 'Expense',
  [TransactionTypes.JobCost]: 'Job Cost',
  [TransactionTypes.CustomerBilled]: 'Customer Billed',
  [TransactionTypes.NA]: 'N/A',
}

const DocumentType = {
  Invoice: 'invoice',
  InvoicePayment: 'invoice-payment',
  Billing: 'billing',
  BillingPayment: 'billing-payment',
}

export const types = {
  Transaction: 'transaction',
  Billing: 'billing',
  BillingPayment: 'billing-payment',
  EquipmentHistory: 'equipment-history',
  Invoice: 'invoice',
  InvoicePayments: 'invoice-payment',
  JobHistory: 'job-history',
  MaterialHistory: 'material-history',
  Payroll: 'payroll',
  PayrollBatch: 'payroll-batch',
  PurchaseOrder: 'purchase-order',
  PurchaseOrderAdjustment: 'purchase-order-adjustment',
  ServiceInvoice: 'service-invoice',
  AccountsReceivableInit: 'accounts-receivable-init',
  AccountsPayableInit: 'accounts-payable-init',
  JobCostInit: 'job-costing-init',
  ServiceBillingHistory: 'service-billing-history',
}

export const relationships = {
  [types.ServiceInvoice]: 'workOrder,customer,district',
  [types.Transaction]: 'account,subaccount',
  [types.Billing]: 'job',
  [types.PurchaseOrder]: 'vendor',
  [types.PurchaseOrderAdjustment]: 'purchaseOrder',
  [types.Invoice]: 'vendor',
  [types.Payroll]: 'employee,timecard,bank',
  [types.EquipmentHistory]: 'customer,business,source,addlSource,equipment',
  [types.MaterialHistory]: 'business,source,addlSource,material',
  [types.ServiceBillingHistory]: 'customer,business',
  default: '',
}

export function getColumns(type, vm) {
  const columns = {
    [types.ServiceInvoice]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'attributes.number',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/service-billing/service-invoices/{ID}/view',
      },
      {
        headerName: i18n.t('Work Order'),
        field: 'relationships.workOrder.attributes.number',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/service-billing/work-orders/{ID}/view',
        entityKey: 'attributes.work_order_id',
      },
      {
        headerName: i18n.t('Customer'),
        field: 'relationships.customer.attributes.name',
        minWidth: 80,
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/accounts-receivable/customers/{ID}/view',
        entityKey: 'attributes.customer_id',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('District'),
        field: 'relationships.district.attributes.code',
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Start Date'),
        field: 'attributes.work_start_date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('End Date'),
        field: 'attributes.work_end_date',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Description'),
        data: 'attributes.description',
        minWidth: 100,
        maxWidth: 120,
      },
    ],
    [types.Billing]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'billing_number',
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Job'),
        field: 'relationships.job.attributes.number',
        minWidth: 100,
        maxWidth: 150,
        component: 'EntityLink',
        redirectTo: '/job-costing/jobs/{ID}/view',
        entityKey: 'attributes.job_id',
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        align: 'center',
        maxWidth: 100,
        component: 'Status',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Tax'),
        field: 'attributes.sales_tax_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Retention'),
        field: 'attributes.retention_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Open'),
        field: 'attributes.open_amount',
        align: 'right',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
    ],
    [types.BillingPayment]: [
      {
        headerName: i18n.t('Payment Date'),
        field: 'attributes.date',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/payments/batches/{ID}/view',
        entityKey: 'attributes.journal_id',
        formatter: vm.$formatDate,
      },
      {
        headerName: i18n.t('Payment Reference'),
        field: 'attributes.number',
        maxWidth: 160,
      },
      {
        headerName: i18n.t('Bank'),
        field: 'attributes.bank_id',
        maxWidth: 150,
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.net_amount',
        align: 'right',
        minWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        align: 'right',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Created At'),
        field: 'attributes.created_at',
        align: 'right',
        minWidth: 100,
        maxWidth: 120,
        component: 'FormattedDate',
      },
    ],
    [types.Transaction]: [
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Source'),
        field: 'attributes.source_id',
        minWidth: 80,
        maxWidth: 100,
        component: 'SourceLink',
      },
      {
        headerName: i18n.t('Line Item'),
        field: 'attributes.addl_source_id',
        minWidth: 80,
        maxWidth: 100,
        component: 'AddlSourceLink',
      },
      {
        headerName: i18n.t('Account'),
        field: 'relationships.account.attributes.number',
        minWidth: 50,
        maxWidth: 80,
        component: 'AccountLink',
      },
      {
        headerName: i18n.t('Sub Account'),
        field: 'relationships.subaccount.attributes.number',
        minWidth: 40,
        maxWidth: 80,
      },
      {
        headerName: i18n.t('Qty'),
        field: 'attributes.meta.quantity',
        align: 'center',
        minWidth: 60,
        maxWidth: 80,
      },
      {
        headerName: i18n.t('Unit'),
        field: 'attributes.meta.unit_price',
        minWidth: 80,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.amount',
        align: 'right',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Ref#'),
        field: 'attributes.reference_no',
        align: 'center',
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Reference Date'),
        field: 'attributes.reference_date',
        align: 'right',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedDate',
      },
    ],
    [types.InvoicePayments]: [
      {
        headerName: i18n.t('Payment Date'),
        field: 'attributes.date',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/payments/batches/{ID}/view',
        entityKey: 'attributes.journal_id',
        formatter: vm.$formatDate,
      },
      {
        headerName: i18n.t('Payment Reference'),
        field: 'attributes.number',
        maxWidth: 160,
      },
      {
        headerName: i18n.t('Bank'),
        field: 'attributes.bank_id',
        maxWidth: 150,
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        align: 'right',
        minWidth: 100,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        align: 'right',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Created At'),
        field: 'attributes.created_at',
        align: 'right',
        minWidth: 100,
        maxWidth: 120,
        component: 'FormattedDate',
      },
    ],
    [types.EquipmentHistory]: [
      {
        headerName: i18n.t('Equipment'),
        field: 'attributes.equipment_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'EquipmentLink'
      },
      {
        headerName: i18n.t('Trans Type'),
        field: 'attributes.transaction_type',
        minWidth: 100,
        maxWidth: 180,
        valueFormatter: params => {
          return TransactionTypeLabels[params.value] || params.value
        },
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 80,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        label: i18n.t('Employee Vend/Cust'),
        prop: 'relationships.business.code',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Account'),
        field: 'attributes.account',
        minWidth: 100,
        maxWidth: 120,
        component: 'AccountLink',
        cellRendererParams: {
          showDescription: false,
        },
      },
      {
        headerName: i18n.t('Job'),
        field: 'relationships.source.number',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Addl Source'),
        field: 'relationships.addlSource.description',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Hours'),
        field: 'attributes.hours',
        minWidth: 80,
        maxWidth: 100,
        align: 'right',
        valueFormatter: params => {
          return params.value ? params.value.toFixed(2) : ''
        },
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('$ Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.MaterialHistory]: [
      {
        headerName: i18n.t('Material'),
        field: 'attributes.material_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'MaterialLink'
      },
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 200,
        maxWidth: 230,
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 80,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        label: i18n.t('Employee Vend/Cust'),
        prop: 'relationships.business.code',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Source'),
        field: 'attributes.source_id',
        minWidth: 100,
        maxWidth: 120,
        component: 'SourceLink',
      },
      {
        headerName: i18n.t('Addl Source'),
        field: 'relationships.addlSource.description',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        headerName: i18n.t('Quantity'),
        field: 'attributes.meta.quantity',
        minWidth: 80,
        maxWidth: 100,
        align: 'right',
        valueFormatter: params => {
          if (!params.value) {
            return ''
          }

          const um = get(params, 'data.attributes.meta.um') || ''
          return `${params.value.toFixed(2)} ${um}`
        },
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.ServiceBillingHistory]: [
      {
        headerName: i18n.t('Customer'),
        field: 'attributes.customer_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'CustomerLink'
      },
      {
        headerName: i18n.t('Transaction Type'),
        field: 'attributes.transaction_type',
        minWidth: 100,
        maxWidth: 180,
        valueFormatter: params => {
          return TransactionTypeLabels[params.value] || params.value
        },
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 100,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        label: i18n.t('Employee Vend/Cust'),
        prop: 'attributes.business_id',
        minWidth: 150,
        maxWidth: 200,
      },
      {
        headerName: i18n.t('Account'),
        field: 'attributes.account',
        minWidth: 100,
        maxWidth: 120,
        component: 'AccountLink',
        cellRendererParams: {
          showDescription: false,
        },
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.JobHistory]: [
      {
        headerName: i18n.t('Job'),
        field: 'attributes.job_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'JobLink'
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.job_type_id',
        minWidth: 40,
        maxWidth: 70,
        component: 'JobTypeLink'
      },
      {
        headerName: i18n.t('Line Item'),
        field: 'attributes.line_item_id',
        minWidth: 250,
        maxWidth: 400,
        component: 'LineItemLink'
      },
      {
        headerName: i18n.t('Source'),
        field: 'attributes.source_id',
        minWidth: 80,
        maxWidth: 160,
        component: 'SourceLink'
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.reference_date',
        minWidth: 80,
        maxWidth: 110,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 100,
        maxWidth: 250,
      },
      {
        headerName: i18n.t('Qty'),
        field: 'attributes.meta.quantity',
        minWidth: 80,
        maxWidth: 100,
        valueFormatter: params => {
          const { meta, quantity } = params?.data?.attributes || {}
          return `${meta?.quantity || quantity || 0} ${meta?.um || ''}`
        },
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Unit Price'),
        field: 'attributes.meta.unit_price',
        minWidth: 80,
        maxWidth: 100,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('$ Amount'),
        field: 'attributes.amount',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedPrice',
        align: 'right',
        aggFunc: 'sum',
      },
    ],
    [types.PurchaseOrder]: [
      {
        headerName: 'Vendor',
        field: 'relationships.vendor.attributes.code',
        minWidth: 80,
        maxWidth: 80,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/vendors/{ID}/view',
        entityKey: 'attributes.vendor_id',
      },
      {
        headerName: i18n.t('PO #'),
        field: 'attributes.number',
        maxWidth: 100,
        component: 'EntityLink',
        redirectTo: '/purchasing-inventory/purchase-orders/{ID}/view',
      },
      {
        headerName: i18n.t('Order Date'),
        field: 'attributes.date',
        maxWidth: 120,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Status'),
        field: 'attributes.status',
        maxWidth: 100,
        component: 'Status',
      },
      {
        headerName: i18n.t('Delivery Date'),
        field: 'attributes.delivery_date',
        maxWidth: 120,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.amount',
        align: 'right',
        maxWidth: 200,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Invoiced Amount'),
        field: 'attributes.invoiced_amount',
        align: 'right',
        maxWidth: 200,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Freight Amount'),
        field: 'attributes.freight_amount',
        align: 'right',
        maxWidth: 200,
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Contact'),
        field: 'attributes.vendor_contact_name',
        align: 'right',
        minWidth: 80,
        maxWidth: 150,
      },
    ],
    [types.PurchaseOrderAdjustment]: [
      {
        label: i18n.t('PO Adjustment #'),
        prop: 'relationships.purchaseOrder.attributes.number',
        minWidth: 60,
        maxWidth: 120,
        component: 'EntityLink',
        entityKey: 'attributes.id',
        redirectTo: '/purchasing-inventory/purchase-orders-adjustments/{ID}/view',
      },
      {
        label: i18n.t('Original PO #'),
        prop: 'relationships.purchaseOrder.attributes.number',
        minWidth: 60,
        maxWidth: 120,
        component: 'EntityLink',
        entityKey: 'relationships.purchaseOrder.id',
        redirectTo: '/purchasing-inventory/purchase-orders/{ID}/view',
      },
      {
        label: i18n.t('Delivery Date'),
        prop: 'attributes.delivery_date',
        component: 'FormattedDate',
        minWidth: 100,
        maxWidth: 120,
      },
      {
        label: i18n.t('Status'),
        prop: 'attributes.status',
        align: 'center',
        minWidth: 100,
        maxWidth: 160,
        component: 'Status',
      },
      {
        label: i18n.t('Created By'),
        prop: 'attributes.created_by',
        minWidth: 200,
        maxWidth: 250,
        component: 'UserLink',
      },
      {
        label: i18n.t('Amount'),
        prop: 'attributes.amount',
        align: 'right',
        minWidth: 250,
        maxWidth: 500,
        valueFormatter: params => {
          return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.amount', FormatTypes.Price, 'attributes.meta.prior.amount')
        },
        aggFunc: 'sum',
      },
      {
        label: i18n.t('Invoiced Amount'),
        prop: 'relationships.purchaseOrder.attributes.invoiced_amount',
        align: 'right',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 150,
        aggFunc: 'sum',
      },
      {
        label: i18n.t('Sales Tax Amount'),
        prop: 'attributes.sales_tax_amount',
        align: 'right',
        minWidth: 200,
        maxWidth: 300,
        valueFormatter: params => {
          return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.sales_tax_amount', FormatTypes.Price, 'attributes.meta.prior.sales_tax_amount')
        },
        aggFunc: 'sum',
      },
      {
        label: i18n.t('Freight Amount'),
        prop: 'attributes.freight_amount',
        align: 'right',
        minWidth: 200,
        maxWidth: 300,
        valueFormatter: params => {
          return compareValueFormatter(params, 'relationships.purchaseOrder.attributes.freight_amount', FormatTypes.Price, 'attributes.meta.prior.freight_amount')
        },
        aggFunc: 'sum',
      },
    ],
    [types.Invoice]: [
      {
        headerName: i18n.t('Invoice #'),
        field: 'attributes.number',
        maxWidth: 160,
        component: 'EntityLink',
        redirectTo: '/accounts-payable/invoices/{ID}/view',
      },
      {
        headerName: i18n.t('Vendor'),
        field: 'relationships.vendor.attributes.code',
        component: 'EntityLink',
        redirectTo: '/accounts-payable/vendors/{ID}/view',
        entityKey: 'attributes.vendor_id',
      },
      {
        headerName: i18n.t('Date'),
        field: 'attributes.date',
        minWidth: 100,
        maxWidth: 150,
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Created By'),
        field: 'attributes.created_by',
        minWidth: 100,
        maxWidth: 150,
        component: 'UserLink',
      },
      {
        headerName: i18n.t('Amount'),
        field: 'attributes.gross_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',

      },
      {
        headerName: i18n.t('Discount'),
        field: 'attributes.discount_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Open'),
        field: 'attributes.open_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        maxWidth: 140,
        align: 'right',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Posted At'),
        field: 'attributes.posted_at',
        component: 'FormattedDate',
        align: 'right',
      },
    ],
    [types.PayrollBatch]: [
      {
        headerName: i18n.t('Year'),
        field: 'attributes.year',
        component: 'EntityLink',
        redirectTo: '/payroll/batches/{ID}/summary',
      },
      {
        headerName: i18n.t('Period End Date'),
        field: 'attributes.period_end_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Checks Info'),
        field: 'attributes.counts.normal',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Payrolls'),
        field: 'attributes.counts.payrolls',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Employees'),
        field: 'attributes.counts.employees',
        align: 'center',
        aggFunc: 'sum',
      },
      {
        headerName: i18n.t('Gross Pay'),
        field: 'attributes.totals.gross_pay',
        component: 'FormattedPrice',
        aggFunc: 'sum',
      },
    ],
    [types.Payroll]: [
      {
        headerName: i18n.t('Employee'),
        field: 'relationships.employee.attributes.code',
        component: 'EntityLink',
        redirectTo: '/payroll/employees/{ID}/view',
        entityKey: 'attributes.employee_id',
      },
      {
        headerName: i18n.t('Rate'),
        field: 'attributes.employee_regular_rate',
        align: 'right',
        component: 'FormattedPrice',
      },
      {
        headerName: i18n.t('Start Date'),
        field: 'attributes.period_start_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('End Date'),
        field: 'attributes.period_end_date',
        component: 'FormattedDate',
      },
      {
        headerName: i18n.t('Bank'),
        field: 'relationships.bank.attributes.name',
        component: 'BankLink',
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        component: 'Status',
      },
    ],
    [types.AccountsReceivableInit]: [
      {
        headerName: i18n.t('Period'),
        field: 'attributes.period',
        minWidth: 50,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Fiscal Year'),
        field: 'attributes.fiscal_year',
        minWidth: 60,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Entries'),
        field: 'attributes.entries_count',
        minWidth: 50,
        maxWidth: 100,
      },
      {
        headerName: i18n.t('Status'),
        field: 'attributes.status',
        component: 'StatusLink',
        minWidth: 100,
        flex: 1,
      },
    ],
    default: [],
  }

  return columns[type]
}

const DocumentTypeLabels = {
  [DocumentType.Invoice]: i18n.t('Invoice #'),
  [DocumentType.InvoicePayment]: i18n.t('Invoice Payment #'),
  [DocumentType.Billing]: i18n.t('Billing #'),
  [DocumentType.BillingPayment]: i18n.t('Invoice Payment #'),
}

function getUser(userId) {
  const allUsers = store?.getters['globalLists/getResourceList'](globalResources.Users) || []
  return allUsers.find(user => user.id === userId)
}

function getBank(bankId) {
  const allBanks = store.state.company.banks || []
  return allBanks.find(bank => bank.id === bankId)
}

function getEquipment(equipmentId) {
  const allEquipments = store?.getters['globalLists/getResourceList'](globalResources.Equipments) || []
  return allEquipments.find(equipment => equipment.id === equipmentId)
}

function getJob(jobId) {
  const allJobs = store?.getters['globalLists/getResourceList'](globalResources.Jobs) || []
  return allJobs.find(job => job.id === jobId)
}

function getCustomer(customerId) {
  const allCustomers = store?.getters['globalLists/getResourceList'](globalResources.Customers) || []
  return allCustomers.find(customer => customer.id === customerId)
}

function getPeriodName(period) {
  return store.getters['company/getPeriodName'](period)
}

function getJobType(jobTypeId) {
  const allTypes = store.state.globalLists.jobTypes || []

  return allTypes.find(type => type.id === jobTypeId)
}

function getLineItem(lineItemId) {
  const allLineItems = store?.getters['globalLists/getResourceList'](globalResources.LineItems) || []
  return allLineItems.find(lineItem => lineItem.id === lineItemId)
}

export const printDataMappers = {
  journal: (journal) => {
    const created_by_name = getUser(journal.created_by)?.name || ''
    return {
      name: journal.name,
      created_by_name,
      action: journal.action,
      fiscal_year: journal.fiscal_year,
      period: getPeriodName(journal.period),
      posted_at: journal.posted_at,
    }
  },
  [types.Transaction]: {
    prop: 'transactions',
    mapper: (transaction) => {
      const addl_source_name = getAddlSourceName(transaction) || ''
      const source_name = getSourceName(transaction, false, true)
      const account = get(transaction, 'relationships.account.attributes') || {}
      const business = get(transaction, 'relationships.business') || {}
      const business_name = business?.code || ''
      const account_name = account
        ? `${account.number} (${account.description})`
        : ''

      const subaccount_name = get(transaction, 'relationships.subaccount.attributes.number') || ''
      
      const meta_quantity = get(transaction, 'attributes.meta.quantity') || 0
      const meta_unit_price = get(transaction, 'attributes.meta.unit_price') || 0

      const credit_amount = Math.abs(get(transaction, 'attributes.credit_amount'))
      const debit_amount = Math.abs(get(transaction, 'attributes.debit_amount'))

      const document_type = get(transaction, 'attributes.document_type')

      const prependDescription = DocumentTypeLabels[document_type] || ''
      const reference_no = get(transaction, 'attributes.reference_no') || ''

      const reference_description = [
        prependDescription,
        reference_no,
      ].filter(Boolean).join(' ')

      return {
        ...transaction.attributes,
        reference_description,
        addl_source_name,
        source_name,
        account_name,
        business_name,
        subaccount_name,
        meta_quantity,
        meta_unit_price,
        credit_amount,
        debit_amount,
      }
    },
  },
  [types.InvoicePayments]: {
    prop: 'payments',
    mapper: (invoicePayment) => {
      const created_by_name = getUser(invoicePayment.attributes.created_by)?.name || ''
      const bank_name = getBank(invoicePayment.attributes.bank_id)?.name || ''
      const payment = {
        ...invoicePayment.attributes,
        created_by_name,
        bank_name,
      }

      return payment
    },
  },
  [types.Invoice]: {
    prop: 'invoices',
    mapper: (invoice) => {
      const created_by_name = getUser(invoice.attributes.created_by)?.name || ''
      const vendor = get(invoice, 'relationships.vendor') || {}
      const vendor_code = vendor?.attributes?.code || ''

      return {
        ...invoice.attributes,
        created_by_name,
        vendor_code,
      }
    },
  },
  [types.EquipmentHistory]: {
    prop: 'equipment_histories',
    mapper: (equipmentHistory) => {
      const equipment = getEquipment(equipmentHistory?.attributes?.equipment_id)

      const equipment_name = equipment
        ? `${equipment.code || ''} (${equipment.description || ''})`
        : ''

      const transaction_type = get(equipmentHistory, 'attributes.transaction_type')
      const transaction_type_label = TransactionTypeLabels[transaction_type] || transaction_type
      const addl_source_name = getAddlSourceName(equipmentHistory) || ''
      const source_name = getSourceName(equipmentHistory, false, true) || ''
      const business = get(equipmentHistory, 'relationships.business') || {}
      const business_code = business?.code || ''

      return {
        ...equipmentHistory.attributes,
        equipment_name,
        transaction_type_label,
        addl_source_name,
        source_name,
        business_code,
      }
    },
  },
  [types.ServiceBillingHistory]: {
    prop: 'service_billing_histories',
    mapper: (serviceBillingHistory) => {
      const customer = get(serviceBillingHistory, 'relationships.customer') || {}
      const customer_code = customer?.attributes?.code
      const transaction_type = get(serviceBillingHistory, 'attributes.transaction_type')
      const transaction_type_label = TransactionTypeLabels[transaction_type] || transaction_type
      const business = get(serviceBillingHistory, 'relationships.business') || {}
      const business_code = business?.code || ''

      return {
        ...serviceBillingHistory.attributes,
        customer_code,
        transaction_type_label,
        business_code,
      }
    },
  },
  [types.Billing]: {
    prop: 'billings',
    mapper: (billing) => {
      const job_number = get(billing, 'relationships.job.attributes.number') || ''
      const created_by_name = getUser(billing.attributes.created_by)?.name || ''

      const remapped ={
        ...billing.attributes,
        created_by_name,
        job_number,
      }

      delete remapped.telemetry
      return remapped
    }
  },
  [types.BillingPayment]: {
    prop: 'billing_payments',
    mapper: (billingPayment) => {
      const created_by_name = getUser(billingPayment.attributes.created_by)?.name || ''
      const bank_name = getBank(billingPayment.attributes.bank_id)?.name || ''
      const payment = {
        ...billingPayment.attributes,
        created_by_name,
        bank_name,
      }

      return payment
    },
  },
  [types.JobHistory]: {
    prop: 'job_histories',
    mapper: (jobHistory) => {
      const job = getJob(jobHistory.attributes.job_id)
      const type_abbr = getJobType(jobHistory.attributes.job_type_id)?.abbr || ''

      const source_name = getSourceName(jobHistory, false, true) || ''
      const lineItem = getLineItem(jobHistory.attributes.line_item_id) || {}
      const line_item_descritpion = composeLineItemName(lineItem, true)

      const job_description = `${job?.number || ''} (${job?.description || ''})`

      const meta_quantity = get(jobHistory, 'attributes.meta.quantity') || 0
      const meta_unit_price = get(jobHistory, 'attributes.meta.unit_price') || 0
      const meta_um = get(jobHistory, 'attributes.meta.um') || ''

      const qty_formatted = `${meta_quantity} ${meta_um}`

      return {
        ...jobHistory.attributes,
        line_item_descritpion,
        source_name,
        type_abbr,
        job_description,
        meta_quantity,
        meta_unit_price,
        meta_um,
        qty_formatted,
      }
    },
  },
  [types.PayrollBatch]: {
    prop: 'payroll_batches',
    mapper: (payrollBatch) => {
      const counts = get(payrollBatch, 'attributes.counts') || {}
      const counts_checks = counts.normal || 0
      const counts_payrolls = counts.payrolls || 0
      const counts_employees = counts.employees || 0

      const totals = get(payrollBatch, 'attributes.totals') || {}

      const totals_gross_pay = totals.gross_pay || 0

      return {
        ...payrollBatch.attributes,
        counts_checks,
        counts_payrolls,
        counts_employees,
        totals_gross_pay,
      }
    },
  },
  [types.Payroll]: {
    prop: 'payrolls',
    mapper: (payroll) => {
      const employee = get(payroll, 'relationships.employee') || {}
      const employee_code = employee?.attributes?.code || ''
      const bank = get(payroll, 'relationships.bank') || {}
      const bank_name = bank?.attributes?.name || ''

      const remapped = {
        ...payroll.attributes,
        employee_code,
        bank_name,
      }

      delete remapped.totals
      delete remapped.ytd_totals
      delete remapped.telemetry
      delete remapped.meta
      delete remapped.time_off

      return remapped
    }
  },
  [types.MaterialHistory]: {
    prop: 'material_histories',
    mapper: (materialHistory) => {
      const material = get(materialHistory, 'relationships.material') || {}
      const material_description = material
        ? `${material.attributes.code || ''} (${material.attributes.description || ''})`
        : ''

      const business_code = get(materialHistory, 'relationships.business.code') || ''
      const source = get(materialHistory, 'relationships.source') || {}

      const source_prefixes = {
        [sourceTypes.PurchaseOrder]: 'PO #'
      }

      const source_type = get(materialHistory, 'attributes.source_type')

      const source_description = source?.number
        ? `${source_prefixes[source_type] || ''}${source.number}`
        : ''

      const addl_source_name = getAddlSourceName(materialHistory) || ''

      const {
        quantity,
        um,
      } = get(materialHistory, 'attributes.meta') || {}

      const remapped = {
        ...materialHistory.attributes,
        material_description,
        business_code,
        source_description,
        addl_source_name,
        quantity: quantity ? 
          `${quantity.toFixed(2)} ${um || ''}` :
          '',
      }

      delete remapped.meta

      return remapped
    }
  },
  [types.PurchaseOrder]: {
    prop: 'purchase_orders',
    mapper: (purchaseOrder) => {
      const vendor_code = get(purchaseOrder, 'relationships.vendor.attributes.code') || ''

      const remapped = {
        ...purchaseOrder.attributes,
        vendor_code,
      }

      delete remapped.telemetry
      delete remapped.meta

      return remapped
    }
  },
  [types.PurchaseOrderAdjustment]: {
    prop: 'purchase_order_adjustments',
    mapper: (purchaseOrderAdjustment) => {
      const purchaseOrder = get(purchaseOrderAdjustment, 'relationships.purchaseOrder') || {}
      const po_adjustment_number = purchaseOrder?.attributes?.number || ''
      const original_po_number = purchaseOrder?.attributes?.number || ''

      const created_by_name = getUser(purchaseOrderAdjustment.attributes.created_by)?.name || ''

      const invoiced_amount = get(purchaseOrder, 'attributes.invoiced_amount') || 0

      const amount_formatted = compareValueFormatter({
        data: purchaseOrderAdjustment,
        value: get(purchaseOrderAdjustment, 'attributes.amount'),
      }, 'relationships.purchaseOrder.attributes.amount', FormatTypes.Price, 'attributes.meta.prior.amount')

      const sales_tax_formatted = compareValueFormatter({
        data: purchaseOrderAdjustment,
        value: get(purchaseOrderAdjustment, 'attributes.sales_tax_amount'),
      }, 'relationships.purchaseOrder.attributes.sales_tax_amount', FormatTypes.Price, 'attributes.meta.prior.sales_tax_amount')

      const freight_amount_formatted = compareValueFormatter({
        data: purchaseOrderAdjustment,
        value: get(purchaseOrderAdjustment, 'attributes.freight_amount'),
      }, 'relationships.purchaseOrder.attributes.freight_amount', FormatTypes.Price, 'attributes.meta.prior.freight_amount')


      const remapped = {
        ...purchaseOrderAdjustment.attributes,
        po_adjustment_number,
        original_po_number,
        created_by_name,
        invoiced_amount,
        amount_formatted,
        sales_tax_formatted,
        freight_amount_formatted,
      }

      delete remapped.telemetry
      delete remapped.meta

      return remapped
    }
  },
  [types.AccountsPayableInit]: {
    prop: 'ap_inits',
    mapper: (accountsPayableInit) => {
      const period_name = getPeriodName(accountsPayableInit.attributes.period)
      return {
        ...accountsPayableInit.attributes,
        period_name,
      }
    },
  },
  [types.AccountsReceivableInit]: {
    prop: 'ar_inits',
    mapper: (accountsReceivableInit) => {
      const period_name = getPeriodName(accountsReceivableInit.attributes.period)
      return {
        ...accountsReceivableInit.attributes,
        period_name,
      }
    },
  },
  [types.JobCostInit]: {
    prop: 'jc_inits',
    mapper: (jobCostingInit) => {
      const period_name = getPeriodName(jobCostingInit.attributes.period)
      return {
        ...jobCostingInit.attributes,
        period_name,
      }
    },
  },
}
