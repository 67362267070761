<template>
  <div>
    <BaseDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :is-expandable="true"
      :delete-action="deleteJobRates"
      :add-entity-in-new-tab="true"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New job rate')"
      ref="table"
      view-entity-url-query="/payroll/rates/job-rates"
      default-sort="-updated_at"
      actions="search, refresh, view"
      permission="jobs"
      @add="$router.push('/payroll/rates/job-rates/add')"
      @edit="onView"
    >
      <template #job="{ row }">
        <JobLink :data="row"/>
      </template>
      <template #expand-content="{ row }">
        <JobRatesTable :job="row" />
      </template>
      <template #customer="{row}">
        <CustomerLink :data="get(row, 'relationships.customer.attributes')"/>
      </template>
    </BaseDataTable>
    <BaseFormDialog
      v-if="showDialog"
      :title="$t('Job')"
      :open.sync="showDialog"
      size="xl"
      @close="onEventTrigger"
    >
      <BaseDetailCard
        :title="$t('Job Details')"
        :key="jobToView.id"
        :entity="jobToView"
        :can-expand="false"
        :expanded="true"
        class="m-4"
        entity-name="job"
      >
        <BaseDetailCardRow :title="$t('Job')">
          {{ jobToView.attributes.number }} ({{ jobToView.attributes.description }})
        </BaseDetailCardRow>
        <BaseDetailCardRow
          :title="$t('Rates')"
          stripe
        >
          <StatusBadge :status="Number(jobToView.attributes.rates_count)"/>
        </BaseDetailCardRow>
      </BaseDetailCard>
      <BaseDetailCard
        :title="$t('Rates')"
        :entity="jobToView"
        :can-expand="true"
        :expanded="true"
        class="m-4"
        entity-name="union"
      >
        <JobRatesTable :job="jobToView" />
      </BaseDetailCard>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries.vue'
import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";
import JobRatesTable from '@/modules/payroll/components/rates/JobRatesTable'

  export default {
    components: {
      UnionAndJobRateEntries,
      JobRatesTable,
    },
    data() {
      return {
        url: '/restify/jobs',
        urlParams: {
          related: entityPreviewFields.Customer,
        },
        addEntityInNewTab: true,
        showDialog: false,
        jobToView: {},
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
          },
          {
            label: this.$t('Customer'),
            prop: 'customer',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Rates'),
            prop: 'attributes.rates_count',
            component: 'Count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            minWidth: 100,
            maxWidth: 100,
            component: 'Status',
          },
        ]
      },
      defaultMatchFilters() {
        return getDefaultMatchFilter('show_inactive_jobs', {
          status: 'open',
          having_job_rates: true,
        })
      },
    },
    methods: {
      onView(row) {
        this.showDialog = true
        this.jobToView = row
      },
      onEventTrigger() {
        this.jobToView = null
        this.showDialog = false
        this.refreshTable()
      },
      async deleteJobRates(row) {
        try {
          await axios.post(`/restify/jobs/${row.id}/actions?action=delete-job-rates-from-job`)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.refreshTable()
        }
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
    },
  }
</script>
