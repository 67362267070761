<template>
  <div class="h-full">
    <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="finalUrlParams"
      :add-entity-in-new-tab="addEntityInNewTab"
      :add-entity-url-query="addEntityUrlQuery"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New job')"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :disable-col-flex="true"
      entity="jobs"
      :import-url="importUrl"
      default-sort="-updated_at"
      actions="search,refresh,view,import,export,print"
      ref="table"
      permission="jobs"
      :deleteCustom="true"
      @delete="jobToDelete = $event; showDeleteJobDialog = true"
      @add="$router.push('/job-costing/jobs/add')"
      @grid-ready="grid = $event"
    >
      <template #additional-actions-before>
        <div class="w-48">
          <BaseSelect
            v-model="selectedView"
            :options="viewOptions"
            inline-errors
          />
        </div>
      </template>
      <template #attributes.number="{row}">
        <JobLink :data="row" :label="row.attributes.number"/>
      </template>
      <template #relationships.customer.attributes.code="{row}">
        <template v-if="row.attributes.customer_id">
          <CustomerLink :data="get(row, 'relationships.customer.attributes')" :show-code="false"/>
        </template>
        <template v-else>

        </template>
      </template>
      <template #address="{row}">
        <AddressLink
          v-if="row?.relationships?.address"
          :row="row?.relationships?.address"/>
      </template>
      <template #relationships.salesPerson.attributes.code="{row}">
        <template v-if="row.attributes.sales_person_id">
          <EmployeeLink :data="get(row, 'relationships.salesPerson.attributes')" :show-name="false"/>
        </template>
        <template v-else>

        </template>
      </template>
    </AgDataTable>
    <DeleteResourcePreflightDialog
      v-if="showDeleteJobDialog"
      :open.sync="showDeleteJobDialog"
      :resource="jobToDelete"
      :resourceName="$globalResources.Jobs"
      @deleted="onJobDeleted"
      @close="showDeleteJobDialog = false"
    >
      <template #extra-info>
        <div>
          <p>{{ $t(`By deleting this job, the following information will be also deleted:`) }}</p>
          <ul class="list-disc list-inside my-2">
            <li>{{ $t(`Job Line Items`) }}</li>
            <li>{{ $t(`Line Item Budgets`) }}</li>
            <li>{{ $t(`Job Phase Titles`) }}</li>
          </ul>
        </div>
      </template>
    </DeleteResourcePreflightDialog>
  </div>
</template>
<script>
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { jobStatusOptions } from "@/modules/job-costing/enum/jobs";
import AddressLink from "@/components/table/cells/AddressLink.vue";
import { DollarSignIcon, ListIcon } from "vue-feather-icons";

const viewModes = {
  List: 'list',
  BudgetVsActual: 'budget-vs-actual',
}

export default {
  components: {
    AddressLink
  },
  props: {
    urlParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    customerId: {
      type: String,
    }
  },
  data() {
    return {
      url: '/restify/jobs',
      addEntityInNewTab: true,
      showDeleteJobDialog: false,
      grid: null,
      selectedView: viewModes.List,
    }
  },
  computed: {
    viewOptions() {
      return [
        {
          label: this.$t('List'),
          value: viewModes.List,
          icon: ListIcon,
        },
        {
          label: this.$t('Budget vs Actual'),
          value: viewModes.BudgetVsActual,
          icon: DollarSignIcon,
        }
      ]
    },
    importUrl() {
      if (this.customerId) {
        return `/job-costing/jobs/import?customer_id=${this.customerId}`
      }
      return `/job-costing/jobs/import`
    },
    addEntityUrlQuery() {
      if (this.customerId) {
        return `/job-costing/jobs/add?customer_id=${this.customerId}`
      }
      return `/job-costing/jobs/add`
    },
    finalUrlParams() {
      return {
        related: `address,${entityPreviewFields.JobCategory},${entityPreviewFields.Customer},${entityPreviewFields.SalesPerson}`,
        customer_id: this.customerId,
        ...(this.urlParams || {}),
      }
    },
    columns() {
      const columnMap = {
        [viewModes.List]: this.listColumns,
        [viewModes.BudgetVsActual]: this.budgetVsActualColumns,
      }
      return columnMap[this.selectedView] || this.listColumns
    },
    budgetVsActualColumns() {
      return [
        {
          headerName: this.$t('Job'),
          field: 'attributes.number',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 150,
          flex: 1,
        },
        {
          headerName: this.$t('Labor Hours (Budget/Actual)'),
          field: 'attributes.total_lab_quantity',
          valueFormatter: params => {
            const { total_lab_quantity, total_lab_quantity_to_date } = params.data?.attributes || {}
            return `${total_lab_quantity || 0} / ${total_lab_quantity_to_date || 0}`
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Equipment Hours (Budget/Actual)'),
          field: 'attributes.total_eqp_quantity',
          valueFormatter: params => {
            const { total_eqp_quantity, total_eqp_quantity_to_date } = params.data?.attributes || {}
            return `${total_eqp_quantity || 0} / ${total_eqp_quantity_to_date || 0}`
          },
          minWidth: 100,
          maxWidth: 200,
        },
      ]
    },
    listColumns() {
      return [
        {
          headerName: this.$t('Job'),
          field: 'attributes.number',
          minWidth: 80,
          maxWidth: 200,
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 150,
          maxWidth: 350,
          headerClass: cellClasses.HeaderEditable,
          editable: true,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'jobCosting/patchJob',
            })(params)
          },
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
          editable: true,
          cellEditor: cellEditors.Status,
          cellEditorParams: {
            options: jobStatusOptions,
          },
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'jobCosting/patchJob',
            })(params)
          },
        },
        {
          headerName: this.$t('Address'),
          field: 'address',
          minWidth: 100,
          maxWidth: 240,
          component: 'AddressLink',
        },
        {
          headerName: this.$t('Customer'),
          field: 'relationships.customer.attributes.code',
          minWidth: 100,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Salesperson'),
          field: 'relationships.salesPerson.attributes.code',
          minWidth: 100,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Category'),
          field: 'relationships.category.attributes.code',
          minWidth: 100,
          maxWidth: 200,
          hide: true,
        },
        {
          headerName: this.$t('Updated At'),
          field: 'attributes.updated_at',
          component: 'FormattedDate',
          minWidth: 100,
          maxWidth: 200,
        },
      ]
    },
    defaultMatchFilters() {
      return this.jobStatusFilter
    },
  },
  methods: {
    onJobDeleted() {
      this.showDeleteJobDialog = false
      this.$refs.table.refresh()
    },
  }
}
</script>
