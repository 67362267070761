<template>
  <div class="payroll-stubs h-full flex flex-col">
    <AgDataTable
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :actions="'search,refresh'"
        :disable-col-flex="true"
        :enableFillHandle="true"
        :enableRangeSelection="true"
        :selected-rows.sync="selectedRows"
        :per-page="$pagination.list"
        ref="table"
        :transform-data="transformData"
        @data-fetch="employees = $event"
    >
      <template #additional-actions-before>
        <div class="mb-2 mr-2 flex space-x-4">
          <BaseInput
            v-model="model.tax_year"
            class="col-span-3"
            :label="$t('Tax Year')"
            :name="$t('Tax Year')"
            :placeholder="$t('Tax Year (YYYY)')"
            type="number"
            :inline-errors="true"
            rules="required|min_value:2014"
            @change="refreshTable"
          />
          <div class="mt-6">
            <EmployeeW2SendBulkEmailForm
              v-if="model.tax_year"
              :data="selectedRows"
              :tax_year="model.tax_year"
            />
          </div>
        </div>
      </template>
      <template #extra-actions="{ row }">
        <TableDownloadButton
          :loading="row.loading"
          @click="onView(row)"
        />
        <EmployeeW2SendBulkEmailForm
          v-if="row.is_selected"
          :data="[row]"
          :tax_year="model.tax_year"
        >
          <template #activator="{ open }">
            <BaseButton
              variant="primary-link"
              size="xs"
              class="-ml-4"
              @click="open"
            >
                <span class="flex items-center">
                  <MailIcon class="w-4 h-4" />
                </span>
            </BaseButton>
          </template>
        </EmployeeW2SendBulkEmailForm>
      </template>
      <template #employee.code="{row}">
        <EmployeeLink :data="row.employee" :show-name="false"/>
      </template>
      <template #employee.address="{row}">
        <BaseAddressLink
          :value="row.employee?.address"
          class="mx-2"
        />
      </template>
      <template #warnings="{ row }">
        <div class="flex flex-col space-y-1 p-1">
          <BaseAlert
            v-for="warning in row.warnings"
            :key="warning"
            type="warning"
            class="!p-2"
            content-wrapper-class="truncate"
          >
            <div :title="warning" class="truncate">{{ warning }}</div>
          </BaseAlert>
        </div>
      </template>
      <template #has_errors="{row}">
        <div>
          <StatusBadge v-if="row.has_errors" type="danger">
            {{ $t('Yes') }}
          </StatusBadge>
          <StatusBadge v-else type="success">
            {{ $t('No') }}
          </StatusBadge>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import { MailIcon, Trash2Icon } from 'vue-feather-icons'
  import TableActionItem from '@/components/table/actions/TableActionItem'
  import { defineComponent } from 'vue'
  import EmployeeUserStatus from "@/modules/payroll/components/employee/EmployeeUserStatus.vue";
  import { editableTableProps, TableActions } from "@/components/ag-grid/tableUtils";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import { $modules } from "@/enum/enums";
  import EmployeeW2SendBulkEmailForm from "@/modules/payroll/components/employee-w2s/EmployeeW2SendBulkEmailForm.vue";
  import axios from "axios";
  import { EmployeeW2SOptions } from "@/modules/payroll/components/reports/util";
  import { downloadFile, downloadFileLocally } from "@/modules/common/util/downloadFileLocally";
  import { getEmployeeFullName } from "@/modules/payroll/utils/employeeUtils";

  export default defineComponent({
    components: {
      EmployeeW2SendBulkEmailForm,
      EmployeeUserStatus,
      MailIcon,
      Trash2Icon,
      TableActionItem,
    },
    data() {
      const taxYear = this.$settings($modules.PR, 'calendar_year') || new Date().getFullYear()
      return {
        model: {
          filter_untaxed_employees: true,
          tax_year: taxYear - 1,
        },
        employees: [],
        selectedRows: [],
        columns: [
          {
            label: this.$t('Code'),
            prop: 'employee.code',
            component: 'EntityLink',
            redirectTo: '/payroll/employees/{ID}/view',
            minWidth: 160,
            showDisabledCheckboxes: true,
            checkboxSelection: params => {
              const canUpdateEmployees = this.$can('employees_update')
              return canUpdateEmployees && this.getEmail(params.data) && !params.data.has_errors
            },
            headerCheckboxSelection: true,
          },
          {
            label: this.$t('Name'),
            prop: 'employee.name',
            minWidth: 150,
            maxWidth: 320,
          },
          {
            label: this.$t('Address'),
            prop: 'employee.address',
            minWidth: 350,
          },
          {
            label: this.$t('Email'),
            prop: 'employee.email',
            minWidth: 250,
            maxWidth: 350,
            editable: true,
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params) => {
              return valueSetterWithUpdate({
                path: 'employee',
                storeAction: 'payroll/patchEmployee',
              })(params)
            },
          },
          {
            label: this.$t('Warnings'),
            prop: 'warnings',
            minWidth: 300,
            autoHeight: true,
            wordWrap: true,
          },
          {
            label: this.$t('Errors'),
            prop: 'has_errors',
            minWidth: 150,
          },
        ],
      }
    },
    computed: {
      TableActions() {
        return TableActions
      },
      editableTableProps() {
        return editableTableProps
      },
      url() {
        return '/reports/report-4884'
      },
      urlParams() {
        return this.model
      },
      actions() {
        const actions = ['search','refresh','view']
        return actions.join(',')
      },
    },
    methods: {
      getEmail(row) {
        return this.get(row, 'employee.email', '')
      },
      getEmployee(row) {
        return this.get(row, 'employee', {})
      },
      refreshTable() {
        this.showFormDialog = false
        this.$refs.table.refresh()
      },
      transformData(data) {
        return data.items.map(item => {
          return {
            id: item.employee?.id,
            ...item,
          }
        })
      },
      async onView(row) {
        try {
          this.$set(row, 'loading', true)
          const res = await axios.post(`/restify/employees/actions?action=print-w2s`, {
            tax_year: this.model.tax_year,
            employee_code_from: row.employee?.code,
            employee_code_to: row.employee?.code,
            type: EmployeeW2SOptions.Types.FourUp,
            filter_untaxed_employees: true,
            copies: [EmployeeW2SOptions.Copies.A],
          }, {
            responseType: 'blob',
          })
          const employeeName = getEmployeeFullName(row.employee)
          downloadFileLocally(res, `${employeeName}-W2s-${this.model.tax_year}.pdf`)
        } finally {
          this.$set(row, 'loading', false)
        }
      }
    },
  })
</script>
<style lang="scss">
</style>
