<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :name="$t('Type')"
    :add-entity="false"
    :options="typeOptions"
    :collapse-tags="true"
  />
</template>
<script>
import { EmployeeW2SOptions } from "@/modules/payroll/components/reports/util";

export default {
  computed: {
    typeOptions() {
      const options = [
        {
          label: this.$t(`Traditional`),
          value: EmployeeW2SOptions.Types.Traditional,
        },
        {
          label: this.$t(`4UP`),
          value: EmployeeW2SOptions.Types.FourUp
        },
      ]
      return options.filter(option => !option.showIf || option.showIf())
    }
  }
}
</script>
<style>
</style>
