<template>
  <div>
    <portal to="po-summary">
      <div class="flex items-center">
        <div class="summary">
          {{ $t('Amount') }}
          <span>
            {{ comparePriceFormatter(summaries.prev_amount, summaries.amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Tax') }}
          <span>
            {{ comparePriceFormatter(summaries.prev_sales_tax_amount, summaries.sales_tax_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Freight') }}
          <span>
            {{ comparePriceFormatter(summaries.prev_freight_amount, summaries.freight_amount) }}
          </span>
        </div>
        <div class="summary">
          {{ $t('Total') }}
          <span>
            {{ comparePriceFormatter(summaries.prev_extended_amount, summaries.extended_amount) }}
          </span>
        </div>
      </div>
    </portal>
    <AgDataTable
      v-bind="editableTableProps"
      :copy-previous-row-enabled-by-default="hasSpecialUrlParams"
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-text="$t('New entry')"
      :data="entries"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :mapDuplicateRow="mapDuplicateRow"
      :default-filters="false"
      :readOnly="readOnly"
      :show-cells-legend="!readOnly"
      :groupIncludeTotalFooter="true"
      :no-borders="readOnly"
      :actions="tableActions"
      :resizable="false"
      :get-row-class="getRowClass"
      suppressColumnReordering
      ref="gridTable"
      id="gridTable"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
      @grid-ready="grid = $event"
    >
      <template #action="{row}">
        <div class="w-4 h-4 rounded" :class="getActionClass(row?.action)"/>
      </template>
      <template #cells-legend-after>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-gray-200"></div>
          <div>{{ $t('No action on the row') }}</div>
        </div>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-blue-200"></div>
          <div>{{ $t('P.O Entry will be updated') }}</div>
        </div>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-green-200"></div>
          <div>{{ $t('New P.O Entry will be created') }}</div>
        </div>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-orange-200"></div>
          <div>{{ $t('P.O Entry will be deleted') }}</div>
        </div>
      </template>
    </AgDataTable>
    <span v-if="!readOnly" class="text-gray-500 text-sm">
        {{ $t('To change the left side of an uninvoiced P.O Entry, you have to delete the entry and create a new one.') }}
    </span>
    <div class="tip mt-2 flex space-x-2 ml-1" v-if="data.instructions">
      <span class="font-medium leading-normal text-gray-400 mr-2">{{ $t('Special Instructions:') }}</span>
      <span>{{ data.instructions }}</span>
    </div>
  </div>
</template>
<script>
import { getCostCenterFields, hasSpecialUrlParams, onChangeCostCenter } from '@/modules/common/util/costCenterUtils'
import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
import { editableTableProps, getTableData, storeBatchEntriesProgress } from '@/components/ag-grid/tableUtils'
import { cellClasses, getCellClasses, requiredValueSetter, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
import {
  additionalSourceCol,
  allCostCenterOptions,
  costCenterCol,
  descriptionCol,
  isSourceEditable,
  quantityCol,
  sourceCol,
  typeCol,
  updateCostCenterHeaderNames,
} from '@/components/ag-grid/columns/costCenterColumns'
import { addressTypes, resourceStatuses } from '@/enum/enums'
import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
import cloneDeep from "lodash/cloneDeep";
import {
  comparePriceFormatter,
  compareValueFormatter
} from "@/modules/purchasing-inventory/components/purchase-orders/util";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";


const ResourceEndpoint = '/restify/purchase-order-adjustment-entries'

const summaryFields = {
  Quantity: 'quantity',
  UnitPrice: 'unit_rate',
  ExtendedAmount: 'amount',
}

const ActionTypes = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  None: 'none',
}

const FormatTypes = {
  Text: 'text',
  Quantity: 'quantity',
  Price: 'price'
}

const computedFields = [summaryFields.UnitPrice, summaryFields.ExtendedAmount, summaryFields.Quantity]

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    vendor_id: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    poEntries: {
      type: Array,
      default: () => [],
    },
    isPosted: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      editableTableProps,
      grid: null,
      entries: [],
    }
  },
  computed: {
    url() {
      return !this.data.id ? '' : ResourceEndpoint
    },
    urlParams() {
      return !this.data.id ? {} : {
        purchase_order_adjustment_id: this.data.id,
        related: 'purchaseOrderEntry',
        sort: 'order',
        perPage: 200,
      }
    },
    hasSpecialUrlParams() {
      return hasSpecialUrlParams(this.$route.query)
    },
    tableActions() {
      return this.readOnly ? '' : 'add'
    },
    columns() {
      return [
        {
          field: 'action',
          headerName: ' ',
          cellClass: cellClasses.ReadOnly,
        },
        {
          ...costCenterCol,
          cellEditorParams: {
            options: allCostCenterOptions,
          },
          valueSetter: params => {
            params.data.cost_center = params.newValue
            const isGA = params.data?.cost_center === costCenterTypes.GeneralAndAdministrative
            params.data.address_id = isGA ? this.companyPrimaryAddressId : null
            params.data = onChangeCostCenter(params.data)
            params.node.setData(params.data)
            return true
          },
          editable: this.isCostCenterEditable,
          cellClass: this.entryCostCenterCellClass,
        },
        {
          ...sourceCol,
          headerName: this.readOnly ? this.$t('Source') : ' ',
          minWidth: 80,
          maxWidth: 100,
          editable: params => this.isSourceEditable(params),
          suppressNavigable: params => !this.isSourceEditable(params),
          cellRendererParams: {
            target: '_blank',
            showDescription: false,
          },
          cellClass: params => {
            if (params.node.footer || this.readOnly) {
              return ''
            }

            if (!isSourceEditable(params)) {
              return cellClasses.ReadOnly
            }

            const hasValue = params.data?.source_id
            return hasValue ? '' : cellClasses.Invalid
          }
        },
        {
          ...typeCol,
          headerName: this.readOnly ? this.$t('Type') : ' ',
          editable: params => this.isSourceEditable(params),
          suppressNavigable: params => !this.isSourceEditable(params),
          cellClass: params => {
            if (params.node.footer || this.readOnly) {
              return ''
            }

            const isEditable = this.isSourceEditable(params)
            if (!isEditable) {
              return cellClasses.ReadOnly
            }

            const hasValue = params.data?.type_id
            return hasValue ? '' : cellClasses.Invalid
          },
        },
        {
          ...additionalSourceCol({
            readOnly: this.readOnly,
          }),
          cellRendererParams: {
            target: '_blank',
            showDescription: false,
          },
          headerName: this.readOnly ? this.$t('Line Item') : ' ',
          minWidth: 130,
          maxWidth: 150,
          cellEditorParams: params => {
            const { cost_center } = params.data

            const resourceMapping = {
              [costCenterTypes.Equipment]: this.$globalResources.EquipmentMaintenances,
            }

            const resourceName = resourceMapping[cost_center]

            return {
              resourceName,
              filterMethod: (option) => {
                if (cost_center !== costCenterTypes.Job) {
                  return true
                }
                const isSubType = this.isSubContractType(params)
                if (!isSubType) {
                  return true
                }
                // show only line items without a subcontractor vendor or with the same vendor id as the invoice
                return !option.vendor_id || option.vendor_id === this.vendor_id
              },
            }
          },
        },
        {
          headerName: this.$t('Address'),
          field: 'address_id',
          component: 'LocationLink',
          cellEditor: this.$cellEditors.AddressSelect,
          cellEditorParams: (params) => {
            const cost_center = params.data?.cost_center
            let address_type = addressTypes.Warehouse
            let entityId = params.data?.source_id
            if (cost_center === costCenterTypes.Job) {
              address_type = addressTypes.Job
            } else if (cost_center === costCenterTypes.Equipment || cost_center === costCenterTypes.WorkOrder) {
              entityId = this.$currentCompany.id
            }
            return {
              addressable_id: entityId,
              address_type: address_type,
              formParams: {
                addressType: address_type,
                entityId,
              },
              onValueChanged: (params, newValue, fullValue) => {
                if (!params.data.relationships) {
                  params.data.relationships = {}
                }
                params.data.relationships.address = fullValue
                params.node.setData(params.data)
              }
            }
          },
          editable: this.isCostCenterEditable,
          cellClass: params => {
            const cls = getCellClasses(params)
            if (cls === '') {
              return this.entryCostCenterCellClass(params)
            }
            return cls
          },
          minWidth: 160,
          maxWidth: 220,
        },
        {
          headerName: this.$t('Material'),
          field: 'material_id',
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          component: 'MaterialLink',
          cellEditorParams: {
            resourceName: this.$globalResources.Materials,
            target: '_blank',
          },
          cellClass: params => {
            if (params.node.footer) {
              return ''
            }

            const hasValue = params.data?.material_id
            const hasPOEntry = params.data?.purchase_order_entry_id
            const isInventoryCostCenter = params.data?.cost_center === costCenterTypes.Inventory

            if (!hasValue && isInventoryCostCenter) {
              return cellClasses.Invalid
            }

            if (hasPOEntry) {
              return cellClasses.ReadOnly
            }

            return ''
          },
          valueSetter: params => {
            params.data.material_id = params.newValue
            this.onChangeMaterial(params)
            return true
          },
          editable: this.isCostCenterEditable,
          minWidth: 100,
          maxWidth: 150,
        },
        {
          ...descriptionCol,
          wrapText: true,
          autoHeight: true,
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.description', FormatTypes.Text, 'meta.prior.description')
          },
          suppressKeyboardEvent: params => {
            let isTabKey = params.event.key === 'Tab'
            if (isTabKey) {
              params.api.stopEditing()
            }
          },
        },
        {
          headerName: this.$t('Fixed price'),
          field: 'is_fixed_price',
          minWidth: 80,
          maxWidth: 90,
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          valueSetter: params => {
            const value = !!params.newValue
            params.data.is_fixed_price = value
            if (!value) {
              params.data.unit_rate = 0
              params.data.quantity = 0
            }
            return true
          },
          editable: this.isCostCenterEditable,
          cellClass: this.entryCostCenterCellClass,
        },
        {
          ...quantityCol,
          minWidth: 120,
          maxWidth: 200,
          valueSetter: params => {
            const isValid = requiredValueSetter(params, 0, (newQuantity) => {
              const newAmount = +(newQuantity || 0) * +params.data.unit_rate
              return this.amountValidator(params, newAmount)
            })
            if (!isValid) {
              return false
            }

            this.onCellValueChanged(params)
            return true
          },
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.quantity', FormatTypes.Quantity, 'meta.prior.quantity')
          },
          editable: this.isQuantityEditable,
          cellClass: this.fixedPriceCellClass,
        },
        {
          ...quantityCol,
          headerName: this.$t('Invoiced Qty'),
          field: 'purchaseOrderEntry.attributes.quantity_invoiced',
          editable: false,
          valueGetter: this.fieldValueGetter,
          maxWidth: 80,
          cellClass: cellClasses.ReadOnly,
          hide: this.readOnly,
        },
        {
          headerName: this.$t('Unit'),
          field: 'um',
          minWidth: 80,
          maxWidth: 90,
          editable: this.isQuantityEditable,
          cellClass: this.fixedPriceCellClass,
        },
        {
          headerName: this.$t('Unit Price'),
          field: 'unit_rate',
          cellEditor: this.$cellEditors.Numeric,
          valueGetter: this.fieldValueGetter,
          valueSetter: params => {
            const isValid = requiredValueSetter(params, 0, (newValue) => {
              const newAmount = +(params.data.quantity || 0) * newValue
              return this.amountValidator(params, newAmount)
            })
            if (!isValid) {
              return false
            }

            this.onCellValueChanged(params)
            return true
          },
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.unit_rate', FormatTypes.Price, 'meta.prior.unit_rate')
          },
          minWidth: 140,
          maxWidth: 200,
          editable: this.isQuantityEditable,
          cellClass: this.fixedPriceCellClass,
        },
        {
          headerName: this.$t('Amount'),
          field: 'amount',
          cellEditor: this.$cellEditors.Numeric,
          valueGetter: this.fieldValueGetter,
          valueSetter: params => {
            const isValid = requiredValueSetter(params, 0, (newAmount) => {
              return this.amountValidator(params, newAmount)
            })
            if (!isValid) {
              return false
            }

            this.onCellValueChanged(params)
            return true
          },
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.amount', FormatTypes.Price, 'meta.prior.amount')
          },
          editable: !this.readOnly,
          minWidth: 200,
          maxWidth: 300,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Sales Tax'),
          field: 'sales_tax_amount',
          cellEditor: this.$cellEditors.Numeric,
          valueGetter: this.fieldValueGetter,
          valueSetter: requiredValueSetter,
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.sales_tax_amount', FormatTypes.Price, 'meta.prior.sales_tax_amount')
          },
          editable: !this.readOnly,
          minWidth: 200,
          maxWidth: 300,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Freight'),
          field: 'freight_amount',
          cellEditor: this.$cellEditors.Numeric,
          valueGetter: this.fieldValueGetter,
          valueSetter: requiredValueSetter,
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.freight_amount', FormatTypes.Price, 'meta.prior.freight_amount')
          },
          editable: !this.readOnly,
          suppressKeyboardEvent: stopEditingOnTab,
          minWidth: 200,
          maxWidth: 300,
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Invoiced Amount'),
          field: 'purchaseOrderEntry.attributes.invoiced_amount',
          component: 'FormattedPrice',
          editable: false,
          minWidth: 150,
          maxWidth: 200,
          aggFunc: 'sum',
          cellClass: cellClasses.ReadOnly,
          hide: this.readOnly,
        },
        {
          headerName: this.$t('Extended Amount'),
          field: 'extended_amount',
          cellEditor: this.$cellEditors.Numeric,
          valueGetter: params => {
            const { amount, freight_amount, sales_tax_amount } = params?.data || {}
            return +amount + +freight_amount + +sales_tax_amount
          },
          valueFormatter: params => {
            return compareValueFormatter(params, 'purchaseOrderEntry.attributes.extended_amount', FormatTypes.Price, 'meta.prior.extended_amount')
          },
          cellClass: cellClasses.ReadOnly,
          minWidth: 200,
          maxWidth: 300,
          aggFunc: 'sum',
        },
        {
          ...getDeleteColumn({
            url: ResourceEndpoint,
            hide: params => {
              const { invoiced_amount } = params.data?.purchaseOrderEntry?.attributes || {}
              const hasPoEntry = params?.data?.purchase_order_entry_id
              return hasPoEntry && invoiced_amount || this.readOnly
            },
            getEditorParams: params => {
              let title = this.$t('Delete PO Adjustment Entry')
              let description = this.$t('Are you sure you want to delete this PO Adjustment Entry?')
              let url = ResourceEndpoint
              let skipRowDelete = false
              let buttonText = this.$t('Delete')
              let onConfirm

              const { invoiced_amount } = params.data?.purchaseOrderEntry?.attributes || {}
              const hasPoEntry = params?.data?.purchase_order_entry_id
              if (hasPoEntry && !invoiced_amount) {
                url = ''
                skipRowDelete = true
                description = this.$t('Deleting this entry will also delete the related Purchase Order Entry. Are you sure?')
                onConfirm = () => {
                  params.data.action = ActionTypes.Delete
                  params.data.dirty = true
                  params.node.setData(params.data)
                }
              }
              if (hasPoEntry && invoiced_amount) {
                url = ''
                skipRowDelete = true
                title = this.$t('Entry Invoiced')
                description = this.$t('This entry has been invoiced and cannot be deleted. You can only update it.')
                buttonText = this.$t('Ok')
              }
              return {
                url,
                title,
                description,
                skipRowDelete,
                buttonText,
                onConfirm,
              }
            }
          }),
        },
      ]
    },
    companyPrimaryAddressId() {
      return this.$store.getters['company/getActiveCompanyPrimaryAddressId']
    },
    summaries() {
      const data = {
        amount: 0,
        sales_tax_amount: 0,
        freight_amount: 0,
        extended_amount: 0,
        prev_amount: this.purchaseOrder?.amount || 0,
        prev_sales_tax_amount: this.purchaseOrder?.sales_tax_amount || 0,
        prev_freight_amount: this.purchaseOrder?.freight_amount || 0,
        prev_extended_amount: this.purchaseOrder?.extended_amount || 0,
      }

      this.entries
        .filter(entry => entry.action !== ActionTypes.Delete)
        .forEach(entry => {
          data.amount += +entry.amount
          data.sales_tax_amount += +entry.sales_tax_amount
          data.freight_amount += +entry.freight_amount
        })

      data.extended_amount = data.amount + data.sales_tax_amount + data.freight_amount

      return data
    },
    getJobSubcontractTypeId() {
      return this.$store.getters['globalLists/getJobSubcontractTypeId']
    },
  },
  methods: {
    comparePriceFormatter,
    amountValidator(params, newAmount) {
      newAmount = +params.data.freight_amount + +params.data.sales_tax_amount + +newAmount
      const invoicedAmount = this.getInvoicedAmount(params)
      if (newAmount < invoicedAmount) {
        this.$error(this.$t(`Amount ${this.$formatPrice(newAmount)} cannot be less than the invoiced amount ${this.$formatPrice(invoicedAmount)}`))
        return true
      }
      return true
    },
    getInvoicedAmount(params) {
      return this.get(params.data, 'purchaseOrderEntry.attributes.invoiced_amount', 0)
    },
    isCostCenterEditable(params) {
      if (this.readOnly) {
        return true
      }
      return !params.data?.purchase_order_entry_id
    },
    entryCostCenterCellClass(params) {
      const hasPoEntry = params.data?.purchase_order_entry_id
      if (params.node.footer || this.readOnly) {
        return ''
      }

      const isEditable = !hasPoEntry
      if (!isEditable) {
        return cellClasses.ReadOnly
      }

      return ''
    },
    getActionClass(action) {
      const map = {
        [ActionTypes.None]: 'bg-gray-200',
        [ActionTypes.Create]: 'bg-green-200',
        [ActionTypes.Update]: 'bg-blue-200',
        [ActionTypes.Delete]: 'bg-orange-200',
      }
      return map[action]
    },
    fieldValueGetter(params) {
      const field = params?.colDef?.field
      return params?.data ? +this.get(params.data, field, 0) : 0
    },
    fixedPriceCellClass(params) {
      if (params?.data?.is_fixed_price) {
        return `${cellClasses.ReadOnly} !text-gray-100`
      }
    },
    isQuantityEditable(params) {
      return !params?.data?.is_fixed_price
    },
    async storeProgress(purchase_order_adjustment_id) {
      const gridApi = this.grid.api
      await storeBatchEntriesProgress(gridApi, 'purchase-order-adjustment-entries', {
        purchase_order_adjustment_id,
      })
      this.refreshData()
    },
    refreshData() {
      this.$refs.gridTable.refresh()
    },
    isSourceEditable(params) {
      if (!params.data?.cost_center) {
        return false
      }
      const invoicedAmount = this.getInvoicedAmount(params)
      if (invoicedAmount > 0) {
        return false
      }
      return ![costCenterTypes.GeneralAndAdministrative, costCenterTypes.Inventory].includes(params.data.cost_center)
    },
    isSubContractType(params) {
      return params.data?.type_id === this.getJobSubcontractTypeId
    },
    onChangeMaterial(params) {
      let entry = params.data
      const { um, standard_unit_rate, description } = this.getMaterialResource(entry.material_id)
      entry.um = um
      entry.unit_rate = standard_unit_rate
      entry.description = description

      params.node.setData(entry)
    },
    getMaterialResource(id) {
      return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Materials, id) || {
        um: '',
        standard_unit_rate: 0,
      }
    },
    mapData(data) {
      this.entries = data.map(item => {
        return {
          ...item.attributes,
          is_fixed_price: item?.attributes?.is_fixed_price || false,
          id: item.id,
          ...item.relationships,
          status: this.isPosted ? resourceStatuses.Posted : resourceStatuses.Pending,
          action: item.attributes.action || ActionTypes.Update,
        }
      })
      const entriesToMap = this.poEntries.filter(entry => !this.entries.find(item => item.purchase_order_entry_id === entry.id))
      const poEntries = entriesToMap.map(item => {
        return {
          ...item.attributes,
          id: undefined,
          dirty: true,
          _localId: item.id,
          purchase_order_entry_id: item.id,
          purchaseOrderEntry: cloneDeep(item),
          action: ActionTypes.Update,
          status: resourceStatuses.Pending,
          ...item.relationships,
        }
      })
      this.entries = [...poEntries, ...this.entries]
      return this.entries
    },
    getRowClass(params) {
      if (params?.data?.action === ActionTypes.Delete) {
        return '!opacity-30 !line-through'
      }
    },
    onCellFocused(params) {
      if (this.readOnly) {
        return
      }

      updateCostCenterHeaderNames(params)
      this.entries = getTableData(this.grid?.api)
    },
    onCellValueChanged(params) {
      const field = params?.colDef?.field
      let entry = params.data
      entry[field] = params.newValue

      if (!entry.quantity || !computedFields.includes(field)) {
        params.node.setData(entry)
        return true
      }

      if ([summaryFields.UnitPrice, summaryFields.Quantity].includes(field)) {
        entry[summaryFields.ExtendedAmount] = entry.quantity * entry.unit_rate
        entry.sales_tax_amount = parseFloat(entry.amount / 100 * this.data.sales_tax_percent).toFixed(2)
        params.node.setData(entry)
        return true
      }

      entry[summaryFields.UnitPrice] = parseFloat(entry.amount / entry.quantity).toFixed(2)

      if (this.data.sales_tax_percent) {
        entry.sales_tax_amount = parseFloat(entry.amount / 100 * this.data.sales_tax_percent).toFixed(2)
      }

      params.node.setData(entry)
      return true
    },
    getEmptyEntry() {
      this.tryCollapseFormHeader()
      const order = this.entries.length + 1
      const { source_id, cost_center } = getCostCenterFields(this.$route.query)

      return {
        order,
        action: ActionTypes.Create,
        material_id: null,
        purchase_order_id: this.data.id,
        purchase_order_entry_id: null,
        purchase_order_adjustment_id: null,
        source_id,
        source_type: null,
        addl_source_id: null,
        addl_source_type: null,
        type_id: null,
        type_type: null,
        cost_center,
        um: null,
        is_fixed_price: this.$settings(this.$modules.IV, 'is_po_entry_fixed_price'),
        extended_amount: 0,
        unit_rate: 0,
        quantity: 0,
        quantity_received: 0,
        amount: 0,
        sales_tax_amount: 0,
        freight_amount: 0,
        description: null,
        address_id: this.companyPrimaryAddressId,
        _localId: crypto.randomUUID(),
        purchaseOrderEntry: {},
      }
    },
    mapDuplicateRow(row) {
      const deleteProps = [
        'action',
        'purchase_order_entry_id',
        'purchaseOrderEntry',
      ]

      deleteProps.forEach(prop => {
        delete row[prop]
      })

      row.action = ActionTypes.Create

      return row
    },
    tryCollapseFormHeader() {
      this.$emit('on-collapse-form-header')
    },
  },
  watch: {
    'poEntries': {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.mapData([], value)
      }
    }
  }
}
</script>
