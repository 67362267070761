<template>
  <div>
    <portal to="reconciliation-summary">
      <reconciliation-summary
          :entries="entries"
          :reconciliation="data"
          :loading="entriesLoading"
          :account-balance="accountBalance"
          ref="reconciliationSummary"
      />
    </portal>
    <AgDataTable
        :columns="columns"
        :compact="true"
        :data-loading="entriesLoading"
        :data="filteredData"
        :read-only="readOnly"
        :no-borders="readOnly"
        :pagination="entries.length > perPage"
        :enableFillHandle="true"
        :enableRangeSelection="true"
        :local-search="true"
        :default-filters="false"
        :show-pagination="false"
        :per-page="perPage"
        actions="search"
        default-sort="updated_at"
        class="flex-1 h-full"
        id="gridTable"
        ref="table"
        domLayout="autoHeight"
        @grid-ready="grid = $event"
    >
      <template #additional-actions-before>
        <div class="flex items-center space-x-4">
          <div v-for="option in typeOptionsForFilter" :key="option.value" class="-mb-4">
            <BaseBadge
              class="!rounded-md cursor-pointer"
              :type="filters.type === option.value ? 'info': 'default'"
              @click.native="setFilterType(option)"
            >
              <div class="flex flex-col">
                <span>{{ option.label }}</span>
                <span class="font-bold">{{ option.amount }}</span>
              </div>
            </BaseBadge>
          </div>
          <BaseSelect
            v-model="filters.status"
            :label="$t('Transaction Status')"
            :inline-errors="true"
            :options="statusOptionsForFilter"
            class="min-w-[250px]"
          />
        </div>
      </template>
      <template #period="{row}">
        <div class="flex items-center space-x-2">
          <span>{{ row.period }}</span>
          <template v-if="isNewTransaction(row)">
            <div class="w-4 cursor-help" :title="$t('New transaction that has not been reconciled.')">
              <IconInfo/>
            </div>
          </template>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import axios from 'axios'
  import { reconciliationTransactionTypes, resourceStatuses, transactionStatuses } from '@/enum/enums'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import ReconciliationSummary from '@/modules/ledger/components/ReconciliationSummary'
  import { getTableNodes } from "@/components/ag-grid/tableUtils";
  import sumBy from "lodash/sumBy";
  import { formatPrice } from "@/plugins/formatPrice";

  const ResourceEndpoint = '/restify/reconciliation-entries'

  export default {
    components: {
      ReconciliationSummary,
    },
    props: {
      urlParams: {
        type: Object,
        default: () => ({}),
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      accountBalance: {
        type: Number,
        default: 0,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      detailsPage: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        grid: null,
        entriesLoading: false,
        perPage: 200,
        entries: [],
        originalEntryMap: [],
        filters: {
          status: transactionStatuses.All,
          type: reconciliationTransactionTypes.All,
        },
        statusOptions: [
          {
            label: this.$t('Reconciled'),
            value: transactionStatuses.Reconciled,
          },
          {
            label: this.$t('Outstanding'),
            value: transactionStatuses.Outstanding,
          },
          {
            label: this.$t('Variance'),
            value: transactionStatuses.Variance,
          },
        ],
        typeOptions: [
          {
            label: this.$t('All'),
            value: reconciliationTransactionTypes.All,
          },
          {
            label: this.$t('Deposits & Credits'),
            value: reconciliationTransactionTypes.Credits,
          },
          {
            label: this.$t('Withdrawals & Debits'),
            value: reconciliationTransactionTypes.Debits,
          },
          {
            label: this.$t('Outstanding'),
            value: reconciliationTransactionTypes.NotReconciled,
          },
        ]
      }
    },
    computed: {
      statusOptionsForFilter() {
        return [
          {
            label: this.$t('All') + ` (${this.getEntryCountForStatus(transactionStatuses.All)})`,
            value: transactionStatuses.All,
          },
          {
            label: this.$t('Reconciled') + ` (${this.getEntryCountForStatus(transactionStatuses.Reconciled)})`,
            value: transactionStatuses.Reconciled,
          },
          {
            label: this.$t('Outstanding') + ` (${this.getEntryCountForStatus(transactionStatuses.Outstanding)})`,
            value: transactionStatuses.Outstanding,
          },
          {
            label: this.$t('Outstanding with voids') + ` (${this.getEntryCountForStatus(transactionStatuses.OutstandingWithVoid)})`,
            value: transactionStatuses.OutstandingWithVoid,
          },
          {
            label: this.$t('Variance') + ` (${this.getEntryCountForStatus(transactionStatuses.Variance)})`,
            value: transactionStatuses.Variance,
          },
        ]
      },
      typeOptionsForFilter() {
        return [
          {
            label: this.$t('Deposits & Credits'),
            amount: formatPrice(this.getEntryAmountForType(reconciliationTransactionTypes.Credits)),
            value: reconciliationTransactionTypes.Credits,
          },
          {
            label: this.$t('Withdrawals & Debits'),
            amount: formatPrice(this.getEntryAmountForType(reconciliationTransactionTypes.Debits)),
            value: reconciliationTransactionTypes.Debits,
          },
        ]
      },
      columns() {
        return [
          {
            headerName: this.$t('Period'),
            field: 'period',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 120,
            maxWidth: 200,
            sortable: true,
          },
          {
            headerName: this.$t('Year'),
            field: 'fiscal_year',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 80,
            maxWidth: 90,
            sortable: true,
          },
          {
            headerName: this.$t('Journal'),
            field: 'journal.name',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 80,
            maxWidth: 120,
            sortable: true,
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 180,
            maxWidth: 220,
            sortable: true,
          },
          {
            headerName: this.$t('Vendor / Employee'),
            field: 'business.name',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 150,
            maxWidth: 220,
            sortable: true,
          },
          {
            headerName: this.$t('Ref No.'),
            field: 'reference_no',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 80,
            maxWidth: 120,
            sortable: true,
          },
          {
            headerName: this.$t('Ref Date'),
            field: 'reference_date',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            minWidth: 100,
            maxWidth: 120,
            sortable: true,
          },
          {
            headerName: this.$t('Prior Per Bank'),
            field: 'prior_per_bank_amount',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            component: 'FormattedPrice',
            minWidth: 70,
            maxWidth: 100,
            sortable: true,
          },
          {
            headerName: this.$t('Amount'),
            field: 'amount',
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            component: 'FormattedPrice',
            minWidth: 120,
            maxWidth: 140,
            sortable: true,
          },
          {
            headerName: this.$t('Status'),
            field: 'status',
            minWidth: 100,
            maxWidth: 120,
            editable: true,
            cellEditor: this.$cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.statusOptions,
            },
            valueGetter: params => {
              if (params.data?.status === transactionStatuses.Unknown) {
                return transactionStatuses.Outstanding
              }
              return params.data?.status
            },
            valueFormatter: params => {
              const op = this.statusOptions.find(o => o.value === params.value)
              return op?.label
            },
            valueSetter: params => {
              if (!params.newValue) {
                params.data.status = params.data.prior_status
              } else {
                params.data.status = params.newValue
              }
              this.computePerBankAndOutstandingAmounts(params)
              return true
            },
            cellClass: params => {
              if (params.data.status === transactionStatuses.Variance) {
                return 'text-red-500'
              }
              if (params.data.status === transactionStatuses.Reconciled) {
                return cellClasses.Highlight
              }
            },
            sortable: true,
          },
          {
            headerName: this.$t('Per Bank'),
            field: 'per_bank_amount',
            component: 'FormattedPrice',
            minWidth: 120,
            maxWidth: 140,
            editable: params => params.data.status === transactionStatuses.Variance,
            cellClass: params => {
              if (this.readOnly) {
                return ''
              }

              return params.data.status === transactionStatuses.Variance ? '' : cellClasses.ReadOnly
            },
            valueSetter: params => {
              params.data.per_bank_amount = params.newValue

              const outstanding_amount = (+params.data.amount) - (+params.newValue)
              if (outstanding_amount === 0) {
                params.data.status = transactionStatuses.Reconciled
              }
              params.data.outstanding_amount = outstanding_amount
              return true
            },
            sortable: true,
          },
          {
            headerName: this.$t('Outstanding'),
            field: 'outstanding_amount',
            component: 'FormattedPrice',
            minWidth: 120,
            maxWidth: 140,
            cellClass: this.readOnly ? '' : cellClasses.ReadOnly,
            sortable: true,
          },
        ]
      },
      getUrl() {
        if (this.isPendingReconciliation) {
          return '/restify/transactions/reconciliation'
        }
        return '/restify/reconciliation-entries'
      },
      getUrlParams() {
        const params = {
          ...this.urlParams,
        }
        if (this.reconciliationId) {
          params.reconciliation_id = this.reconciliationId
          delete params.status
        }
        if (!this.isPendingReconciliation) {
          params.related = 'transaction,transaction.business[id|code|name]'
        }
        return params
      },
      reconciliationId() {
        return this.$route.params.id || this.data.id
      },
      isPendingReconciliation() {
        const status = this.data?.status || this.data?.attributes?.status
        return !this.reconciliationId || status === resourceStatuses.Pending
      },
      filteredData() {
        const status = this.filters.status
        const filteredEntries = this.filterByStatus(this.entries, status)
        return this.filterByType(filteredEntries, this.filters.type)
      },
    },
    methods: {
      setFilterType(option) {
        const currentType = this.filters.type
        if (currentType === option.value) {
          this.filters.type = reconciliationTransactionTypes.All
        } else {
          this.filters.type = option.value
        }
      },
      hasMatchingOutstandingReverseTransaction(row) {
        const entry = this.entries.find(entry => {
          return entry.id !== row.id
            && row.amount === -entry.amount
            && entry.status === transactionStatuses.Outstanding && row.status === transactionStatuses.Outstanding
            && entry.reference_no === row.reference_no
            && entry.description === row.description
        })
        return entry
      },
      getEntryCountForStatus(status) {
        return this.filterByStatus(this.entries, status)?.length
      },
      getEntryAmountForType(type, status = transactionStatuses.Reconciled) {
        let entries = this.filterByStatus(this.entries, status)
        if (status === transactionStatuses.Outstanding) {
          entries = this.entries
        }
        return sumBy(this.filterByType(entries, type), 'amount')
      },
      filterByStatus(data = [], status = transactionStatuses.All) {
        if (status === transactionStatuses.All)  {
          return data
        }
        return data.filter(row => {
          if (status === transactionStatuses.OutstandingWithVoid) {
            return this.hasMatchingOutstandingReverseTransaction(row)
          }
          return row.status === status
        })
      },
      filterByType(data = [], type = reconciliationTransactionTypes.All) {
        if (type === reconciliationTransactionTypes.All)  {
          return data
        }
        return data.filter(row => {
          if (type === reconciliationTransactionTypes.Debits) {
            return row.amount < 0
          } else if (type === reconciliationTransactionTypes.Credits) {
            return row.amount > 0
          } else if (type === reconciliationTransactionTypes.NotReconciled) {
            return row.status !== transactionStatuses.Reconciled
          }
        })
      },
      isNewTransaction(row) {
        return !row.reconciliation_id && (new Date(row.updated_at).getTime() >  new Date(this.data.updated_at).getTime())
      },
      getSummary() {
        return this.$refs.reconciliationSummary?.getSummary()
      },
      computePerBankAndOutstandingAmounts(params) {
        const { newValue } = params

        if (newValue === transactionStatuses.Reconciled) {
          params.data.per_bank_amount = params.data.amount - params.data.prior_per_bank_amount
          params.data.outstanding_amount = 0
        } else {
          params.data.outstanding_amount = params.data.amount - params.data.prior_per_bank_amount
          params.data.per_bank_amount = 0
        }
      },
      async getTransactions() {
        const data = await axios.get('/restify/transactions/reconciliation', {
          params: this.getUrlParams,
        })
        const { fiscal_year, period } = this.data
        if (!fiscal_year || !period) {
          return data
        }
        return data.filter(t => {
          const isBeforeYear = t.fiscal_year < fiscal_year
          if (isBeforeYear) {
            return true
          }
          return t.period <= period
        })
      },
      async getReconciliationEntries() {
        if (!this.reconciliationId) {
          return {
            data: []
          }
        }
        return axios.get('/restify/reconciliation-entries', {
          params: {
            ...this.getUrlParams,
            perPage: 2000,
            account_number: undefined,
          },
        })
      },
      async getData() {
        try {
          this.entriesLoading = true
          this.$emit('entries-loading', true)
          if (this.isPendingReconciliation) {
            const [transactions, entries] = await Promise.all([
              this.getTransactions(),
              this.getReconciliationEntries(),
            ])
            this.mapTransactionsToEntries(transactions, entries?.data)
            this.originalEntryMap = entries?.data.map(entry => {
              const transaction_id = this.entries.find(e => e.id === entry.id)?.transaction_id
              return {
                id: entry.id,
                transaction_id,
              }
            })
          } else {
            const entries = await this.getReconciliationEntries()
            this.mapReconciliationEntries(entries?.data)
          }
          this.$store.commit('generalLedger/SET_CURRENT_RECONCILIATION_ENTRIES', this.entries || [])
          this.$emit('fetch-data', this.entries)
        } finally {
          this.entriesLoading = false
          this.$emit('entries-loading', false)
        }
      },
      getOutstandingAmount(transaction, entry) {
        let outstanding = entry.attributes?.outstanding_amount || transaction.outstanding_amount || 0
        if (entry?.id) {
          outstanding = entry.attributes?.outstanding_amount
        }
        const status = entry.attributes?.status || transaction.status
        if (status === transactionStatuses.Unknown && !outstanding) {
          return entry.attributes?.amount || transaction.amount
        }
        return outstanding
      },
      mapReconciliationEntries(entries = []) {
        this.entries = entries.map(entry => {
          const transaction = entry.relationships?.transaction?.attributes || { }
          const business = entry.relationships?.['transaction.business']?.business || { code: '' }
          return {
            ...transaction,
            ...entry.attributes,
            business,
            outstanding_amount: this.getOutstandingAmount(transaction, entry),
            period: this.getPeriodName(transaction.period),
            period_number: transaction.period,
            reference_date: this.$formatDate(transaction.reference_date),
            transaction_id: entry.transaction_id,
            id: entry.id,
            _localId: crypto.randomUUID(),
          }
        })
      },
      mapTransactionsToEntries(transactions, entries = []) {
        this.entries = transactions.map(transaction => {
          const entry = entries.find(entry => entry.attributes.transaction_id === transaction.id) || {}
          let business = transaction?.business
          const journal = transaction?.journal
          business = business || journal || { code: '' }

          return {
            ...transaction,
            ...(entry.attributes || {}),
            outstanding_amount: this.getOutstandingAmount(transaction, entry),
            period: this.getPeriodName(transaction.period),
            period_number: transaction.period,
            reference_date: this.$formatDate(transaction.reference_date),
            transaction_id: transaction.id,
            id: entry?.id || '',
            business,
            prior_per_bank_amount: transaction.per_bank_amount,
            prior_status: transaction.status,
            _localId: crypto.randomUUID(),
          }
        })
      },
      async storeProgress(reconciliation_id, includeAllentries = false) {

        let dirtyEntries = this.entries.filter(entry => entry.dirty)
        if (includeAllentries)  {
          dirtyEntries = this.entries
        }
        const entriesWithoutId = this.entries.filter(entry => !entry.id && !entry.dirty)

        const entriesToUpdate = []
        const entriesToStore = []

        dirtyEntries.forEach(entry => {
          if (entry.id) {
            entriesToUpdate.push(entry)
          } else {
            entry = this.composeModel(entry, reconciliation_id)
            entriesToStore.push(entry)
          }
        })

        entriesWithoutId.forEach(entry => {
          entry = this.composeModel(entry, reconciliation_id)
          entriesToStore.push(entry)
        })

        const entriesWithoutTransactionId = this.originalEntryMap.filter(entry => !entry.transaction_id && entry.id)
        const entriesToDelete = entriesWithoutTransactionId.map(entry => entry.id)

        const promises = []

        if (entriesToUpdate.length) {
          promises.push(axios.post(`${ResourceEndpoint}/bulk/update`, entriesToUpdate))
        }

        if (entriesToDelete.length) {
          promises.push(axios.delete(`${ResourceEndpoint}/bulk/delete`, {
            data: entriesToDelete
          }))
        }

        if (entriesToStore.length) {
          promises.push(axios.post(`${ResourceEndpoint}/bulk`, entriesToStore))
        }

        await Promise.all(promises)
        await this.refreshEntriesTable()
      },
      composeModel(entry, reconciliation_id) {
        return {
          id: entry.id || '',
          status: entry.status,
          transaction_id: entry.transaction_id,
          per_bank_amount: entry.per_bank_amount,
          outstanding_amount: entry.outstanding_amount,
          reconciliation_id,
        }
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      async refreshEntriesTable() {
        await this.getData()
      },
      applyMatchingTransactions(transactions) {
        transactions = transactions.map(t => {
          return {
            ...t,
            reconciled: false,
          }
        })
        const nodes = getTableNodes(this.grid?.api)
        nodes.forEach(node => {
          const matchingTransaction = this.findMatchingTransaction(node, transactions)
          if (matchingTransaction && node.data.status !== transactionStatuses.Reconciled && !matchingTransaction.reconciled) {
            node.data.dirty = true
            node.data.status = transactionStatuses.Reconciled
            node.data.per_bank_amount = node.data.amount - node.data.prior_per_bank_amount
            node.data.outstanding_amount = 0
            node.setData(node.data)
            matchingTransaction.reconciled = true
          }
        })
        return this.transformTransactions(transactions)
      },
      transformTransactions(transactions) {
        return transactions.map(t => {
          const typeMap = {
            c: 'credit',
            d: 'debit',
          }
          return {
            reference: t.r,
            amount: t.a,
            date: t.d,
            type: typeMap[t.t],
            reconciled: t.reconciled ? 'Yes' : 'No',
          }
        })
      },
      findMatchingTransaction(node, transactions) {
        /**
         * The AI parsing uses very short property names to reduce the output size
         * Field mappings below
         * r -> reference
         * a -> amount
         * d -> date
         * t -> type (d|c) d -> debit, c -> credit
         */
        const { reference_date, reference_no, amount } = node.data
        const matchingTransactions = transactions.filter(t => {
        return (t.d === reference_date && t.r === reference_no && t.a === amount)
          || t.r === reference_no && t.a === amount
          || t.d === reference_date && t.a === amount
        })
        let transaction
        // We match if we found exactly one matching transaction
        if (matchingTransactions.length === 1) {
          transaction = matchingTransactions[0]
        }
        if (!transaction) {
          const amountMatchingTransactions = transactions.filter(t => t.a === amount)
          // We match only if we found exactly one matching transaction
          if (amountMatchingTransactions.length === 1) {
            return amountMatchingTransactions[0]
          }
        }
        return transaction
      }
    },
    async mounted() {
      await this.getData()
    },
    watch: {
      'data.fiscal_year'() {
        this.getData()
      },
      'data.period'() {
        this.getData()
      },
      'data.status'() {
        this.getData()
      }
    }
  }
</script>
