<template>
  <div>
    <TableActionsDropdown :button-text="$t('More actions')">
      <TableActionItem>
        <ReportPrintAction/>
      </TableActionItem>
      <template v-if="isPosted">
        <TableActionItem v-if="batch.attributes?.show_in_my_checks">
          <BaseButton
            variant="primary-link"
            :loading="hideLoading"
            @click="hideFromMyChecks">
            <EyeOffIcon class="w-4 h-4 mr-2"/>
            {{ $t('Hide from My Checks') }}
          </BaseButton>
        </TableActionItem>
        <TableActionItem v-if="!batch.attributes?.show_in_my_checks">
          <BaseButton
            variant="primary-link"
            :loading="unHideLoading"
            @click="showInMyChecks">
            <EyeIcon class="w-4 h-4 mr-2"/>
            {{ $t('Show in My Checks') }}
          </BaseButton>
        </TableActionItem>
      </template>
      <TableActionItem v-if="$isAuthorized('authorizedToVoid', batch)">
        <VoidPayrollBatch
          :batch-summary="batch"
          :payroll-data="payrollData"
          @submit="$emit('refresh')"
        />
      </TableActionItem>
    </TableActionsDropdown>
  </div>
</template>
<script>
import VoidPayrollBatch from "@/modules/payroll/components/payroll/VoidPayrollBatch.vue";
import DownloadAction from "@/components/table/actions/download/DownloadAction.vue";
import TableActionsDropdown from "@/components/table/actions/TableActionsDropdown.vue";
import ReportPrintAction from "@/components/table/ReportPrintAction.vue";
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
import axios from "axios";
import { resourceStatuses } from "@/enum/enums";

export default {
  components: {
    ReportPrintAction,
    TableActionsDropdown,
    DownloadAction,
    VoidPayrollBatch,
    EyeOffIcon,
    EyeIcon,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
    payrollData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      hideLoading: false,
      unHideLoading: false,
    }
  },
  computed: {
    isPosted() {
      return this.batch?.attributes?.status === resourceStatuses.Posted
    }
  },
  methods: {
    async hideFromMyChecks() {
      const confirmed = await this.$confirm({
        title: this.$t('Hide batch from My Checks'),
        description: this.$t('The following action will hide the batch and its payrolls from My Checks list. Employees will not be able to see the batch payrolls in their My Checks list.'),
        buttonText: this.$t('Hide from My Checks List'),
      })
      if (!confirmed) {
        return
      }
      try {
        this.hideLoading = true
        await axios.post(`/restify/payroll-batches/${this.batch.id}/actions?action=hide-from-my-checks`)
        this.$emit('refresh')
      } finally {
        this.hideLoading = false
      }
    },
    async showInMyChecks() {
      const confirmed = await this.$confirm({
        title: this.$t('Show batch in My Checks'),
        description: this.$t('The following action will show the batch and its payrolls in My Checks list. Employees will be able to see the batch payrolls in their My Checks list.'),
        buttonText: this.$t('Show in My Checks List'),
      })
      if (!confirmed) {
        return
      }
      try {
        this.unHideLoading = true
        await axios.post(`/restify/payroll-batches/${this.batch.id}/actions?action=unhide-from-my-checks`)
        this.$emit('refresh')
      } finally {
        this.unHideLoading = false
      }
    }
  }
}
</script>
