<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('delete resource title', { resourceName: resourceLabel })"
    :append-to-body="true"
    size="sm"
  >
    <BaseForm
      :saveText="$t('Confirm')"
      :showCancel="true"
      :loading="loading"
      :submitVariant="$promptType.Danger"
      :submitDisabled="!canDelete"
      layout="modal"
      @submit="onDeleteConfirmed"
      @cancel="$emit('close')"
    >
      <div class="col-span-6 text-gray-800 text-sm">
        <p>{{ alertMessage }}</p>
        <div v-if="canDelete">
          <slot name="extra-info" />
          <span> {{ $t('delete resource confirmation', { resourceName: resourceLabel })  }}</span>
        </div>
        <BaseAlert
          v-else-if="preflightError"
          :type="$promptType.Danger"
        >
          <p>{{ $t('Error while checking if resource can be deleted.') }}</p>
          <p>{{ preflightError?.message || $t('Something went wrong, please try again later.') }}</p>
          <BaseButton
            class="mt-2"
            variant="white"
            :loading="checkingCanDelete"
            @click="checkCanDelete"
            size="xs"
          >
            {{ $t('Check Again') }}
          </BaseButton>
        </BaseAlert>
      </div>
      <div
        v-if="hasUsages"
        class="col-span-6 text-gray-800 text-sm"
      >
        <p>{{ $t('resource has usages', { resourceName: resourceLabel }) }}</p>
        <p>{{ $t(`To allow deletion you must first remove it from all places where it's used.`)  }}</p>
        <BaseButton
          class="mt-2"
          variant="white"
          :loading="checkingCanDelete"
          @click="checkCanDelete"
          size="xs"
        >
          <RepeatIcon class="mr-2 w-3 h-3" />
          {{ $t('Check Again') }}
        </BaseButton>
        <div class="mt-2 space-y-1">
          <ResourceUsage
            v-for="usage in ResourceUsages"
            :key="usage.id"
            :usage="usage"
          />
        </div>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import axios from 'axios'
import { globalResources } from "@/components/form/util";
import i18n from '@/i18n';
import ResourceUsage from '@/modules/common/components/ResourceUsage.vue'
import {
  ResourceUsageType,
} from '@/modules/common/types/common'
import { RepeatIcon } from 'vue-feather-icons'

const resourcesConfig = {
  [globalResources.LineItems]: {
    label: i18n.t('Line Item'),
    preflightUrl: '/restify/line-items/{id}/actions?action=delete-line-item-preflight',
    deleteUrl: '/restify/line-items/{id}',
  },
  [globalResources.Jobs]: {
    label: i18n.t('Job'),
    preflightUrl: '/restify/jobs/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/jobs/{id}',
  },
  [globalResources.JobCostTypes]: {
    label: i18n.t('Job Cost Type'),
    preflightUrl: '/restify/job-types/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/job-types/{id}',
  },
  [globalResources.JobIncomeTypes]: {
    label: i18n.t('Job Income Type'),
    preflightUrl: '/restify/job-types/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/job-types/{id}',
  },
  [globalResources.JobCategories]: {
    label: i18n.t('Job Category'),
    preflightUrl: '/restify/job-categories/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/job-categories/{id}',
  },
  [globalResources.Customers]: {
    label: i18n.t('Customer'),
    preflightUrl: '/restify/customers/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/customers/{id}',
  },
  [globalResources.SalesTaxDistricts]: {
    label: i18n.t('Sales Tax District'),
    preflightUrl: '/restify/districts/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/districts/{id}',
  },
  [globalResources.Vendors]: {
    label: i18n.t('Vendor'),
    preflightUrl: '/restify/vendors/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/vendors/{id}',
  },
  [globalResources.Materials]: {
    label: i18n.t('Material'),
    preflightUrl: '/restify/materials/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/materials/{id}',
  },
  [globalResources.MaterialClasses]: {
    label: i18n.t('Material Class'),
    preflightUrl: '/restify/material-classes/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/material-classes/{id}',
  },
  [globalResources.Equipments]: {
    label: i18n.t('Equipment'),
    preflightUrl: '/restify/equipment/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/equipment/{id}',
  },
  [globalResources.EquipmentClasses]: {
    label: i18n.t('Equipment Class'),
    preflightUrl: '/restify/equipment-classes/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/equipment-classes/{id}',
  },
  [globalResources.EquipmentTypes]: {
    label: i18n.t('Equipment Cost Type'),
    preflightUrl: '/restify/equipment-types/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/equipment-types/{id}',
  },
  [globalResources.EquipmentMaintenances]: {
    label: i18n.t('Maintenance Code'),
    preflightUrl: '/restify/maintenance-codes/{id}/actions?action=delete-preflight',
    deleteUrl: '/restify/maintenance-codes/{id}',
  }
}

export default {
  components: {
    ResourceUsage,
    RepeatIcon,
  },
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    resourceId: {
      type: String,
    },
    resource: {
      type: Object,
    },
  },
  data() {
    return {
      checkingCanDelete: false,
      ResourceUsages: [] as ResourceUsageType[],
      loading: false,
      preflightError: null as null | any,
    }
  },
  computed: {
    alertMessage() {
      if (this.checkingCanDelete) {
        return this.$t('checking resource can be deleted', {
          resourceName: this.resourceLabel
        })
      }

      return ''
    },
    hasUsages() {
      return this.ResourceUsages.length
    },
    canDelete() {
      if (this.checkingCanDelete) {
        return false
      }

      return !this.hasUsages && !this.preflightError
    },
    resourceEntity() {
      return this.resource || {
        id: this.resourceId
      }
    },
    resourceConfig() {
      return resourcesConfig[this.resourceName]
    },
    resourceLabel() {
      return this.resourceConfig.label
    },
    preflightUrl() {
      return this.resourceConfig.preflightUrl.replace('{id}', this.resourceEntity.id)
    },
    deleteUrl() {
      return this.resourceConfig.deleteUrl.replace('{id}', this.resourceEntity.id)
    }
  },
  methods: {
    async checkCanDelete() {
      try {
        this.preflightError = null
        this.checkingCanDelete = true
        this.ResourceUsages = []
        const { data } = await axios.post(this.preflightUrl)

        this.ResourceUsages = data
      }
      catch (err: any) {
        this.preflightError = err
      }
      finally {
        this.checkingCanDelete = false
      }
    },
    async onDeleteConfirmed() {
      try {
        this.loading = true
        await axios.delete(this.deleteUrl)
        this.$emit('deleted', this.resource)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.checkCanDelete()
  }
}
</script>
