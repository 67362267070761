<template>
  <div class="flex items-center space-x-2">
    <span>{{ usageToDisplay.description }}</span>
    <a
      v-if="usageToDisplay.link"
      :href="usageToDisplay.link"
      target="_blank"
      class="text-blue-500"
    >
      {{ $t('View') }}
    </a>
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n';
import { computed, PropType } from 'vue';
import { formatPrice } from '@/plugins/formatPrice.js'
import { billingAbbrToType } from "@/modules/accounts-receivable/pages/billings/billings";
import {
  ResourceUsageType,
  ResourceUsedOnTypes,
} from '@/modules/common/types/common.d'

const props = defineProps({
  usage: {
    type: Object as PropType<ResourceUsageType>,
    required: true
  },
})

type UsageToDisplay = {
  description: string,
  link: string,
}

const usageToDisplay = computed<UsageToDisplay>(() => {
  switch(props.usage.type) {
    case ResourceUsedOnTypes.JobCostingInit:
      return {
        description: i18n.t('Job Costs Init') + `: ${props.usage.description}`,
        link: `/job-costing/settings/job-cost-init/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.AccountsReceivableInit:
      return {
        description: i18n.t('Accounts Receivable Init'),
        link: `/accounts-receivable/settings/ar-initialize/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.AccountsPayableInit:
      return {
        description: i18n.t('Accounts Payable Init'),
        link: `/accounts-payable/settings/ap-initialize/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Billing:
      // @ts-ignore
      const type = billingAbbrToType[props.usage.billing_type]
      return {
        description: i18n.t('Billing') + `: #${props.usage.description}`,
        link: `/accounts-receivable/billings/${type}/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.BillingPayment:
      return {
        description: i18n.t('Billing Payment') + `: #${props.usage.description}`,
        link: `/accounts-receivable/payments/batches/${props.usage.batch_id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Invoice:
      return {
        description: i18n.t('Invoice') + `: #${props.usage.description}`,
        link: `/accounts-payable/invoices/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.InvoicePayment:
      return {
        description: i18n.t('Invoice Payment') + `: #${props.usage.description}`,
        link: `/accounts-payable/payments/batches/${props.usage.batch_id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.RecurringInvoice:
      return {
        description: i18n.t('Recurring Payable') + `: ${props.usage.description || ''}`,
        link: `/accounts-payable/recurring-invoices/${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Payroll:
      return {
        description: i18n.t('Payroll Register') + `: ${props.usage.description}`,
        link: `/payroll/batches/${props.usage.batch_id}/summary`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Task:
      return {
        description: i18n.t('Task') + `: ${props.usage.description}`,
        link: `/payroll/timesheets/tasks?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Timecard:
      return {
        description: i18n.t('Timecard') + `: #${props.usage.description}`,
        link: `/payroll/timecard-batches/${props.usage.batch_id}/timecards/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.TimesheetEntry:
      return {
        description: i18n.t('Timesheet Entry') + `: ${props.usage.description}`,
        link: `/payroll/timesheets/list?timesheetEntryId=${props.usage.id}&view_type=edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.PurchaseOrder:
      return {
        description: i18n.t('Purchase Order') + `: #${props.usage.description}`,
        link: `/purchasing-inventory/purchase-orders/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.PurchaseOrderAdjustment:
      return {
        description: i18n.t('Purchase Order Adjustment'),
        link: `/purchasing-inventory/purchase-orders-adjustments/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.LineItem:
      // TODO BE -> return line_item_type
      // TODO BE -> improve line item description "phase / cost code / change org (description)"
      const type_path = props.usage.line_item_type === 'income'
          ? 'income-line-items'
          : 'cost-line-items'
      return {
        description: i18n.t('Line Item') + `: ${props.usage.description}`,
        link: `/job-costing/${type_path}/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.JobPreNotice:
      return {
        description: i18n.t('Job Pre Notice'),
        link: `/job-costing/settings/pre-notice/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Employee:
      return {
        description: i18n.t('Employee') + `: ${props.usage.description}`,
        link: `/payroll/employees/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Job:
      return {
        description: i18n.t('Job') + `: ${props.usage.description}`,
        link: `/job-costing/jobs/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Vendor:
      return {
        description: i18n.t('Vendor') + `: ${props.usage.description}`,
        link: `/accounts-payable/vendors/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Vendor1099:
      return {
        description: i18n.t('Vendor 1099') + `: ${props.usage.description}`,
        link: `/accounts-payable/vendor-1099s/${props.usage.batch_id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Customer:
      return {
        description: i18n.t('Customer') + `: ${props.usage.description}`,
        link: `/accounts-receivable/customers/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Account:
      return {
        description: i18n.t('Account') + `: ${props.usage.description}`,
        link: `/ledger/accounts/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.BenefitCode:
      return {
        description: i18n.t('Benefit Code') + `: ${props.usage.description}`,
        link: `/payroll/codes/benefit-codes?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.PayCode:
      return {
        description: i18n.t('Pay Code') + `: ${props.usage.description}`,
        link: `/payroll/codes/pay-codes?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.TaxCode:
      return {
        description: i18n.t('Tax Code') + `: ${props.usage.description}`,
        link: `/payroll/codes/tax-codes`
      } as UsageToDisplay
    case ResourceUsedOnTypes.DeductionCode:
      return {
        description: i18n.t('Deduction Code') + `: ${props.usage.description}`,
        link: `/payroll/codes/deduction-codes?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.RetirementPlan:
      return {
        description: i18n.t('Retirement Plan') + `: ${props.usage.description}`,
        link: `/payroll/codes/retirement-plans?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Proposal:
      return {
        description: i18n.t('Proposal') + `: ${props.usage.description}`,
        link: ''
      } as UsageToDisplay
    case ResourceUsedOnTypes.JobRate:
      // @ts-ignore
      return {
        description: i18n.t('Job Rate') + `: ${formatPrice(props.usage.description)}`,
        link: `/payroll/rates/job-rates`
      } as UsageToDisplay
    case ResourceUsedOnTypes.UnionRate:
      // @ts-ignore
      return {
        description: i18n.t('Union Rate') + `: ${formatPrice(props.usage.description)}`,
        link: `/payroll/rates/unions`
      } as UsageToDisplay
    case ResourceUsedOnTypes.TimeOffRate:
      // @ts-ignore
      return {
        description: i18n.t('Time Off Rate') + `: ${formatPrice(props.usage.description)}`,
        link: `/payroll/rates/time-off-rates`
      } as UsageToDisplay
    case ResourceUsedOnTypes.CustomerRetention:
      return {
        description: i18n.t('Customer Retention'),
        link: ''
      } as UsageToDisplay
    case ResourceUsedOnTypes.VendorRetention:
      return {
        description: i18n.t('Vendor Retention'),
        link: ''
      } as UsageToDisplay
    case ResourceUsedOnTypes.WorkSite:
      return {
        description: i18n.t('Worksite') + `: ${props.usage.description || ''}`,
        link: `/service-billing/worksites/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.WorkOrder:
      return {
        description: i18n.t('Work Order') + `: ${props.usage.description || ''}`,
        link: `/service-billing/work-orders/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.WorkItem:
      return {
        description: i18n.t('Work Item') + `: ${props.usage.description || ''}`,
        link: `/estimates/work-items?id=${props.usage.id}`
      } as UsageToDisplay
    case ResourceUsedOnTypes.CustomerBillingRate:
      return {
        description: i18n.t('Customer Billing Rate') + `: ${props.usage.description || ''}`,
        link: `/accounts-receivable/settings/billing-rates`
      } as UsageToDisplay
    case ResourceUsedOnTypes.ReceiveMaterial:
      return {
        description: i18n.t('Receive Material'),
        link: ``
      } as UsageToDisplay
    case ResourceUsedOnTypes.Material:
      return {
        description: i18n.t('Material') + `: ${props.usage.description || ''}`,
        link: `/purchasing-inventory/materials/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.Equipment:
      return {
        description: i18n.t('Equipment') + `: ${props.usage.description || ''}`,
        link: `/equipment/list/${props.usage.id}/view`
      } as UsageToDisplay
    case ResourceUsedOnTypes.EquipmentAdjustment:
      return {
        description: i18n.t('Equipment Adjustment') + `: ${props.usage.description || ''}`,
        link: `/equipment/initialize-equipment/${props.usage.id}/edit`
      } as UsageToDisplay
    case ResourceUsedOnTypes.EquipmentMaintenance:
      return {
        description: i18n.t('Equipment Maintenance') + `: ${props.usage.description || ''}`,
        link: ``
      } as UsageToDisplay
    case ResourceUsedOnTypes.EquipmentDepreciation:
      return {
        description: i18n.t('Equipment Depreciation') + `: ${props.usage.description || ''}`,
        link: ``
      } as UsageToDisplay
    default:
      return {
        description: `${props.usage.type} - ${props.usage.description}`,
        link: ''
      } as UsageToDisplay
  }
})

</script>
