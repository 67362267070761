<template>
  <base-form
    :loading="loading"
    :showCancel="showCancel"
    :showBack="showBack"
    :save-text="$t('Setup Bank Direct Deposit Information')"
    :update-text="$t('Update Bank Direct Deposit Information')"
    layout="vertical"
    grid-classes="grid grid-cols-12 gap-x-3"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <base-input
      v-model="model.company_assigned_id"
      :label="$t('Company I.D. #')"
      :placeholder="$t('I.D. # assigned by the bank for your company')"
      :tip="$t('Enter an I.D. # assigned by the bank for your company (for example your company tax I.D. #).')"
      rules="required"
      class="col-span-12 md:col-span-2"
    />
    <base-input
      v-model="model.company_name"
      :label="$t('Company Name')"
      :placeholder="$t('Company Name')"
      class="col-span-12 md:col-span-3"
      rules="max:23"
    />
    <base-input
      v-model="model.company_abbr"
      :label="$t('Company Abbr')"
      :placeholder="$t('Company Abbr')"
      class="col-span-12 md:col-span-2"
      rules="max:16"
    />

    <base-input
      v-model="model.bank_name"
      :label="$t('Bank Name')"
      :placeholder="$t('Bank Name')"
      class="col-span-12 md:col-span-2"
      rules="max:23"
    />
    <div class="col-span-12 my-2"/>
    <base-select
      v-model="model.origin_format"
      :options="originFormatOptions"
      :label="$t('Immediate Origin Format')"
      :placeholder="$t('Immediate Origin Format')"
      :tip="$t('Select either Routing # or Tax I.D. depending on the definition your bank requires in the NACHA batch file Immediate Origin field.\n')"
      class="col-span-12 md:col-span-2"
      @change="onOriginFormatChange"
    />
    <base-select
      v-model="model.origin_char_option"
      :options="originCharOptions"
      :label="$t('Immediate Origin Char Option')"
      :placeholder="$t('Immediate Origin Char Option')"
      class="col-span-12 md:col-span-2"
    />
    <base-input
      v-model="model.destination_routing_number"
      :label="$t('Immediate Destination')"
      :placeholder="$t('Transit number')"
      :tip="$t('Enter the transit number of the bank where the funds will be deposited.')"
      class="col-span-12 md:col-span-3"
      rules="required|routingNumber"
    />
    <base-input
      v-model="model.origin_routing_number"
      :label="$t('Immediate Origin')"
      :placeholder="$t('Transit number')"
      :tip="$t('Enter the transit routing # supplied by the bank for this field\n')"
      class="col-span-12 md:col-span-3"
      :rules="originRoutingNumberRules"
    />
    <div class="col-span-12 my-2"/>
    <base-input
      v-model="model.originating_dfi_id"
      :label="$t('Originating DFI Identification')"
      :placeholder="$t('Originating DFI Identification')"
      class="col-span-12 md:col-span-3"
    />
    <base-input
      v-model="model.reference_code"
      :label="$t('Reference Code')"
      :placeholder="$t('Reference Code')"
      class="col-span-12 md:col-span-3"
    />
    <base-select
      v-model="model.scope"
      :options="scopeOptions"
      :label="$t('Default For')"
      class="col-span-12 md:col-span-2"
    />
    <base-select
      v-model="model.company_date_format"
      :options="dateFormatOptions"
      :label="$t('Company Descriptive Date Format')"
      class="col-span-12 md:col-span-2"
    />
    <div class="col-span-12"/>
    <base-textarea
      v-model="model.transactions_description"
      v-uppercase
      :label="$t('Transactions Description')"
      :placeholder="$t('Transactions Description')"
      :rows="1"
      rules="required"
      class="col-span-12 md:col-span-3"
    />
    <base-textarea
      v-model="model.company_discretionary_data"
      :label="$t('Company Discretionary Data')"
      :placeholder="$t('Company Discretionary Data')"
      :rows="1"
      class="col-span-12 md:col-span-3"
    />
    <base-select
      v-model="model.service_class_code"
      :options="serviceClassCodeOptions"
      :label="$t('Service Class Code')"
      class="col-span-12 md:col-span-3"
      rules="required"
    />
    <base-select
      v-model="model.standard_entry_class_code"
      :options="standardEntryCodeOptions"
      :label="$t('Standard Entry Class Code')"
      class="col-span-12 md:col-span-3"
      rules="required"
    />
    <div class="col-span-12">
      <base-checkbox
        v-model="model.balanced_batch_file_required"
        :label="$t('Does This Bank Require a BALANCED Batch File (i.e. Balancing Debit Entry)')"
        id="balanced_batch_file_required"
      />
    </div>
    <div class="col-span-12 md:mb-5">
      <base-checkbox
        v-model="model.use_crlf"
        :label="$t('Use CR / LF Record Delimiter')"
        id="use_crlf"
      />
    </div>
    <base-textarea
      v-model="model.debit_bank_name"
      :label="$t('Debit Bank Name')"
      :placeholder="$t('Debit Bank Name')"
      :rows="1"
      class="col-span-12 md:col-span-3"
      rules="max:50"
    />
    <base-input
      v-model="model.debit_routing_number"
      :label="$t('Bank Transit Routing Number')"
      :placeholder="$t('Bank Transit Routing Number')"
      class="col-span-12 md:col-span-3"
      :rules="debitRoutingNumber"
    />
    <base-select
      v-model="model.debit_account_type"
      :options="bankAccountTypes"
      :label="$t('Account Type')"
      :placeholder="$t('Select Account Type')"
      class="col-span-12 md:col-span-3"
      clearable
    />
    <base-input
      v-model="model.debit_account_number"
      :label="$t('Account Number')"
      :placeholder="$t('Account Number')"
      class="col-span-12 md:col-span-3"
    />
  </base-form>
</template>
<script lang="ts">
import axios from 'axios'
import get from 'lodash/get'
import { defineComponent } from 'vue'
import {$modules, bankAccountTypes} from '@/enum/enums'
import { Bank, BankDirectDeposit } from "@/modules/common/types/models";

const bankDirectDepositScope = {
  AP: 'accounts-payable',
  PR: 'payroll',
}

const bankDirectDepositServiceClassCode = {
  DebitsCredits: '200',
  Credits: '220',
  Debits: '225',
}

const bankDirectDepositStandardEntryCode = {
  PPD: 'ppd',
  CCD: 'ccd',
}

const originFormats = {
  RoutingNumber: 'routing-number',
  TaxId: 'tax-id',
}

const originCharOptions = {
  Blank: 'blank',
  Num1: 'num-1',
}

const dateFormats = {
  Alpha: 'alpha',
  Ymd: 'ymd',
}
export default defineComponent({
  data() {
    return {
      loading: false as boolean,
      showCancel: true as boolean,
      showBack: true as boolean,
      bankAccountTypes,
      model: {
        bank_id: '',
        scope: bankDirectDepositScope.PR,
        company_assigned_id: '',
        origin_routing_number: null,
        destination_routing_number: null,
        originating_dfi_id: null,
        reference_code: null,
        transactions_description: '',
        company_discretionary_data: '',
        service_class_code: bankDirectDepositServiceClassCode.DebitsCredits,
        standard_entry_class_code: bankDirectDepositStandardEntryCode.PPD,
        balanced_batch_file_required: false,
        use_crlf: false,
        debit_bank_name: null,
        debit_routing_number: null,
        debit_account_number: null,
        debit_account_type: null,
        origin_format: originFormats.RoutingNumber,
        origin_char_option: originCharOptions.Blank,
        company_date_format: dateFormats.Alpha,
        company_name: null,
        company_abbr: null,
        bank_name: null,
      } as BankDirectDeposit,
      originFormatOptions: [
        {
          label: this.$t('Routing Number'),
          value: originFormats.RoutingNumber,
        },
        {
          label: this.$t('Tax ID'),
          value: originFormats.TaxId,
        },
      ],
      originCharOptions: [
        {
          label: this.$t('Blank'),
          value: originCharOptions.Blank,
        },
        {
          label: this.$t('Num 1'),
          value: originCharOptions.Num1,
        },
      ],
      dateFormatOptions: [
        {
          label: this.$t('Alpha'),
          value: dateFormats.Alpha,
        },
        {
          label: this.$t('YMD'),
          value: dateFormats.Ymd,
        }
      ],
      scopeOptions: [
        {
          label: this.$t('Accounts Payable'),
          value: bankDirectDepositScope.AP,
        },
        {
          label: this.$t('Payroll'),
          value: bankDirectDepositScope.PR,
        },
      ],
      serviceClassCodeOptions: [
        {
          label: this.$t('200 (debits and credits)'),
          value: bankDirectDepositServiceClassCode.DebitsCredits,
        },
        {
          label: this.$t('220 (credits only)'),
          value: bankDirectDepositServiceClassCode.Credits,
        },
        {
          label: this.$t('225 (debits only)'),
          value: bankDirectDepositServiceClassCode.Debits,
        },
      ],
      standardEntryCodeOptions: [
        {
          label: this.$t('PPD (prearranged payment and deposit entry)'),
          value: bankDirectDepositStandardEntryCode.PPD,
        },
        {
          label: this.$t('CCD (corporate trade exchange entry)'),
          value: bankDirectDepositStandardEntryCode.CCD,
        },
      ],
    }
  },
  computed: {
    bank(): Bank {
      return this.$store.state.settings.currentBank
    },
    directDeposit() {
      // @ts-ignore
      return get(this.bank, 'relationships.directDeposit', {})
    },
    debitRoutingNumber() {
      if (this.model.origin_format === originFormats.RoutingNumber) {
        return 'routingNumber|max:9'
      } else if (this.model.origin_format === originFormats.TaxId) {
        return 'max:9'
      }
    },
    originChar() {
      if (this.model.origin_char_option === originCharOptions.Blank) {
        return ' '
      } else if (this.model.origin_char_option === originCharOptions.Num1) {
        return '1'
      }
    },
    originRoutingNumberRules() {
      if (this.model.origin_format === originFormats.RoutingNumber) {
        return 'routingNumber'
      }
    }
  },
  methods: {
    onOriginFormatChange(value: string) {
      if (value === originFormats.TaxId) {
        const federalEmployeeId = this.$settings($modules.PR, 'federal_employer_id') as string
        this.model.origin_routing_number = federalEmployeeId.replaceAll('-', '')
      } else {
        this.model.origin_routing_number = null
      }
    },
    onCancel() {
      return this.$router.push('/settings/banks')
    },
    async onSubmit() {
      try {
        const bank_id = this.bank.id
        const data = {
          ...this.model,
          bank_id,
        }
        this.loading = true
        this.model.bank_id = bank_id
        if (this.model.id) {
          await axios.put(`/restify/bank-direct-deposits/${this.model.id}`, data)
        } else {
          await axios.post('/restify/bank-direct-deposits', data)
        }
        this.$success(this.$t('Bank Direct Deposit updated'))
        await this.$store.dispatch('settings/getBank', bank_id)

      } catch (err: any) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not setup the Bank Direct Deposit'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    directDeposit: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
        if (!this.model.company_name) {
          this.model.company_name = this.$currentCompany?.name
        }
        if (!this.model.company_abbr) {
          this.model.company_abbr = this.$currentCompany?.abbr
        }
      },
    },
    bank: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        if (!this.model.bank_name) {
          this.model.bank_name = value?.attributes?.name
        }
      },
    }
  },
})
</script>
