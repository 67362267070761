<template>
  <div v-if="!batch?.attributes?.show_in_my_checks">
    <BaseTooltip>
      <template #content>
        <div>
          {{
            $t('This payroll batch is hidden from My Checks List. Employees will not see the payrolls from this batch under their My Checks lists.')
          }}
        </div>
      </template>
      <StatusBadge :status="$t('Hidden from My Checks')"></StatusBadge>
    </BaseTooltip>
  </div>
</template>
<script>

export default {
  props: {
    batch: {
      type: Object,
      required: true
    }
  }
}
</script>
