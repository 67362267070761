<template>
  <div>
    <BaseDataTable
      :columns="columns"
      :url="url"
      :add-text="$t('New job category')"
      actions="search,refresh,view,import,export"
      import-url="/job-costing/settings/categories/import"
      ref="table"
      permission="job_categories"
      :add-entity-in-new-tab="addEntityInNewTab"
      :deleteCustom="true"
      @delete="showDeleteDialog = true; itemToDelete = $event"
      @add="$router.push('/job-costing/settings/categories/add')"
    >
      <template #attributes.code_edit_option="{ row }">
        <div class="truncate">
          {{ getOptionLabel(codeEditOptions, row.attributes.code_edit_option) }}
        </div>
      </template>
      <template #attributes.type_rate="{row}">
        {{ getOptionLabel(rateTypeOptions, row.attributes.type_rate) }}
      </template>
      <template #attributes.rate="{row}">
        <template v-if="row.attributes.type_rate === rateTypes.Percent">
          {{ $formatPercent(row.attributes.rate / 100) }}
        </template>
        <template v-else>
          {{ $formatPrice(row.attributes.rate) }}
        </template>
      </template>
    </BaseDataTable>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.JobCategories"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script>
  import { codeEditOptions, rateTypeOptions, rateTypes } from "@/modules/job-costing/enum/jobCategories";

  export default {
    data() {
      return {
        url: '/restify/job-categories',
        addEntityInNewTab: true,
        codeEditOptions,
        rateTypeOptions,
        rateTypes,
        showDeleteDialog: false,
        itemToDelete: null,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Category'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/job-costing/settings/categories/{ID}/edit',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 200,
          },
          {
            label: this.$t('Code Edit Option'),
            prop: 'attributes.code_edit_option',
            minWidth: 150,
            maxWidth: 250,
          },
          {
            label: this.$t('Rate Type'),
            prop: 'attributes.type_rate',
          },
          {
            label: this.$t('Rate Value'),
            prop: 'attributes.rate',
          },
        ]
      },
    },
    methods: {
      onItemDeleted() {
        this.showDeleteDialog = false;
        this.$refs.table.refresh();
      }
    }
  }
</script>
