<template>
  <BaseAlert
    v-if="showWarning"
    type="warning"
    class="mb-4">
    <div>
      {{
        $t('The sales tax percentage on this billing does not match the sales tax percentage on the billing profile. This can be caused by line items that are exempt from sales tax or manually adjusted sales tax entries.')
      }}
    </div>
    <div>{{ $t('Billing sales tax') }}: {{ $formatPercent(billingTaxPercentage) }}</div>
    <div>{{ $t('Distribution sales tax') }}: {{ $formatPercent(computedSalesTaxPercentage) }}</div>
  </BaseAlert>
</template>
<script>
export default {
  props: {
    grossAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    salesTaxAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    billing: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    billingTaxPercentage() {
      return this.billing.sales_tax_percent || this.billing.meta?.sales_tax_percent || 0
    },
    computedSalesTaxPercentage() {
      if (!this.grossAmount) {
        return 0
      }
      return this.round(this.salesTaxAmount / this.grossAmount * 100)
    },
    showWarning() {
      if (!this.computedSalesTaxPercentage || isNaN(this.computedSalesTaxPercentage)) {
        return false
      }
      if (!this.billingTaxPercentage || isNaN(this.billingTaxPercentage)) {
        return false
      }
      return this.computedSalesTaxPercentage !== this.billingTaxPercentage
    }
  }
}
</script>
