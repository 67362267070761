<template>
  <base-date-picker
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('Payroll payment date')"
    :name="$t('Payroll payment date')"
    :palaceholder="$t('Payroll payment date')"
    :disabled="disabled"
    :picker-options="{
        cellClassName,
        disabledDate,
    }"
    id="period_end_date"
    rules="required"
  />
</template>
<script>
import parseISO from 'date-fns/parseISO'
import parse from "date-fns/parse";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { getTimeCardPaymentDate } from "@/modules/payroll/utils/timeCardUtils";
import { $modules } from "@/enum/enums";
import format from "date-fns/format";

export default {
  props: {
    value: {
      type: [String, Object],
    },
    disabled: Boolean,
    periodEndDate: {
      type: String,
    }
  },
  computed: {
    payroll_period_payment_day() {
      return this.$settings(this.$modules.PR, 'payroll_period_payment_day')
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        if (this.isBeforePeriodEndDate(value)) {
          return
        }
        const year = this.getYear(value)
        if (!this.isCalendarYearOpen(year)) {
          const oldValue = this.value
          this.$nextTick(() => {
            this.$emit('input', oldValue)
          })
          return
        }
        this.suggestPayrollSetting(value)
        this.$emit('input', value)
        this.$emit('year-change', this.getYear(value))
      }
    },
    payrollCalendarYear() {
      return this.$settings($modules.PR, 'calendar_year')
    },
  },
  methods: {
    async suggestPayrollSetting(paymentDateStr) {
      if (this.payroll_period_payment_day !== null || !paymentDateStr) {
        return
      }
      const paymentDate = parse(paymentDateStr, ApiDateFormat, new Date())
      const day = format(paymentDate, 'EEEE')
      const confirm  = await this.$confirm({
        title: this.$t('No Payment Day provided in Payroll Settings'),
        description: this.$t(`Do you want to set the Payment Day to ${day} for future use?`),
        buttonText: this.$t(`Set Payment Day to ${day}`),
        cancel: this.$t('No'),
      })
      if (!confirm) {
        return
      }
      await this.$store.dispatch('company/updateSettings', {
        module: $modules.PR,
        value: {
          payroll_period_payment_day: paymentDate.getDay()
        }
      })
    },
    getNextPaymentDate() {
      return getTimeCardPaymentDate(this.periodEndDate)
    },
    getYear(date) {
      if (!date) {
        return null
      }
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      return date.getFullYear()
    },
    disabledDate(date) {
      if (!this.periodEndDate) {
        return false
      }
      const endDate = parse(this.periodEndDate, ApiDateFormat, new Date())
      return date < endDate
    },
    isCalendarYearOpen(year) {
      if (!year) {
        return true
      }
      if (year > this.payrollCalendarYear) {
        this.$error(this.$t(`Payroll calendar year is not open. Please select a valid year or open the calendar year. ${year}`))
        return false
      }
      return true
    },
    isBeforePeriodEndDate(date) {
      if (!this.periodEndDate) {
        return false
      }
      let endDate = this.periodEndDate
      let paymentDate = date
      if (typeof endDate === 'string') {
        endDate = parseISO(endDate)
      }
      if (typeof paymentDate === 'string') {
        paymentDate = parseISO(paymentDate)
      }
      if (paymentDate < endDate) {
        this.$error(this.$t('Payroll payment date should be after the period end date'))
        return true
      }
      return false
    },
    isCorrectPaymentDate(date) {
      if (this.payroll_period_payment_day === null || this.payroll_period_payment_day === undefined) {
        return true
      }
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      const day = date.getDay()
      return day === this.payroll_period_payment_day
    },
    cellClassName(date) {
      if (this.payroll_period_payment_day === null) {
        return
      }
      if (this.isCorrectPaymentDate(date)) {
        return 'text-primary-500'
      }
    },
  },
  watch: {
    'periodEndDate': {
      immediate: true,
      async handler() {
        await this.$nextTick()
        this.model = this.getNextPaymentDate()
      }
    }
  }
}
</script>
