<template>
  <AgDataTable
      :url="url"
      :url-params="urlParams"
      :read-only="readOnly"
      :add-text="$t('New rate')"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :compact="true"
      :data="entries"
      :columns="columns"
      :add-row-on-tab="true"
      :actions="actions"
      :get-row-class="getRowClass"
      domLayout="autoHeight"
      ref="gridTable"
      @grid-ready="grid = $event"
  />
</template>
<script lang="ts" setup>
  import axios from 'axios'
  import i18n from '@/i18n'
  import {
    employeeRatesTypes,
    rateTypeFrequencies,
    rateTypeFrequencyOptions,
    accumulationIntervalOptions,
  } from '@/modules/payroll/components/rates/util'
  import { computed, ref } from 'vue'
  import { jobTableOverrides } from '@/enum/enums'
  import { Column } from '@/components/ag-grid/tableTypes'
  import {cellClasses, stopEditingOnTab} from '@/components/ag-grid/columnUtils'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { EmployeePayCode } from '@/modules/payroll/types/codes'
  import {
    CellClassParams,
    ICellEditorParams,
    ICellRendererParams,
    ValueFormatterParams,
    ValueGetterParams,
    ValueSetterParams,
  } from '@ag-grid-community/core'
  import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'
  import { formatPrice } from '@/plugins/formatPrice'
  import { formatPercent } from '@/plugins/formatPercent'
  import { getTableData } from '@/components/ag-grid/tableUtils'
  import { saveRates } from '@/modules/payroll/components/employee-rates/storeEmployeeRates'
  import { $deleteConfirm } from '@/components/common/modal/modalPlugin'
  import {
    deductionOverrideTypes,
    deductionOverrideTypeOptions,
    sourceTypeConfigs
  } from '@/modules/payroll/components/employee-rates/util.js'
  import { findGlobalResource } from '@/components/ag-grid/cellEditors/cellEditorUtils'
  import { calculateAmountAs } from '@/enum/payroll'
  import {isAuthorized} from "@/plugins/actionAuthorization";

  const props = defineProps({
    rateType: String,
    hasUnionRates: Boolean,
    employeeId: {
      type: [Number, String],
      default: null,
    },
    mapRates: Function,
    title: String,
    readOnly: Boolean,
  })

  const entries = ref([])
  const grid = ref()
  const gridTable = ref()

  const sourceTypeConfig = sourceTypeConfigs[props.rateType as string]

  const actions = computed(() => {
    if (props.readOnly) {
      return
    }
    return 'add,refresh,search'
  })

  const isDeductionRate = computed(() => {
    return props.rateType === employeeRatesTypes.DeductionRates
  })

  function getRowClass(params: any) {
    if (!params?.data?.active) {
      return '!opacity-70 !line-through !bg-gray-100'
    }
  }

  const columns = computed<Column<EmployeePayCode>[]>(() => {
    return [
      {
        headerName: i18n.t(sourceTypeConfig.cellTitle),
        field: 'source_id',
        minWidth: 250,
        editable: true,
        cellEditor: cellEditors.GlobalResourceSelect,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            resourceName: sourceTypeConfig.resourceName,
          }
        },
        valueFormatter: params => {
          if (!params.data) {
            return
          }
          const resourceName = sourceTypeConfig.resourceName
          const source = findGlobalResource(resourceName, params.value, 'id')
          return source?.code ? `${ source.code } (${ source.description })` : ''
        },
        valueSetter: params => {
          params.data.source_id = params.newValue
          const resourceName = sourceTypeConfig.resourceName
          const source = findGlobalResource(resourceName, params.newValue, 'id')
          params.data['calculate_by'] = source.calculate_by
          params.data['multiply_by'] = source.multiply_by
          return true
        },
      },
      {
        headerName: i18n.t('Calculate By'),
        field: 'calculate_by',
        component: 'FormattedText',
        minWidth: 100,
        maxWidth: 150,
        suppressNavigable: true,
        cellClass: cellClasses.ReadOnly,
      },
      {
        headerName: i18n.t('Multiply By'),
        field: 'multiply_by',
        component: 'FormattedText',
        minWidth: 100,
        maxWidth: 150,
        suppressNavigable: true,
        cellClass: cellClasses.ReadOnly,
      },
      {
        headerName: i18n.t('Standard Union'),
        field: 'union_rate',
        minWidth: 100,
        cellClass: cellClasses.ReadOnly,
        hide: !props.hasUnionRates,
        valueFormatter: (params: ValueFormatterParams) => {
          // @ts-ignore
          const {calculate_by} = params.data
          if (calculate_by === calculateAmountAs.Percentage) {
            return formatPercent(params.value, {
              maximumFractionDigits: 3,
            })
          }
          return formatPrice(params.value)
        },
      },
      {
        headerName: i18n.t('Active'),
        field: 'active',
        minWidth: 100,
        component: 'Status',
        editable: true,
        cellEditor: cellEditors.Boolean,
      },
      {
        headerName: i18n.t('Amount'),
        field: 'rate',
        minWidth: 100,
        editable: true,
        cellEditor: cellEditors.Numeric,
        cellEditorParams: {
          step: '0.001'
        },
        valueSetter: (params: ValueSetterParams) => {
          const value = + params.newValue
          params.data.rate = value
          if (!value && params.data.id && params.data.union_rate) {
            resetRate(params)
          }
          return true
        },
        valueFormatter: (params: ValueFormatterParams) => {
          // @ts-ignore
          const {calculate_by} = params.data
          if (calculate_by === calculateAmountAs.Percentage) {
            return formatPercent(params.value, {
              maximumFractionDigits: 3,
            })
          }
          return formatPrice(params.value)
        },
      },
      {
        headerName: i18n.t('Effective Rate'),
        field: 'effective_rate',
        minWidth: 100,
        valueGetter: (params: ValueGetterParams<EmployeePayCode>) => {
          if (params.data?.rate) {
            return params.data.rate
          }
          return params.data?.effective_rate
        },
        valueFormatter: (params: ValueFormatterParams) => {
          // @ts-ignore
          const {calculate_by} = params.data
          if (calculate_by === calculateAmountAs.Percentage) {
            return formatPercent(params.value, {
              maximumFractionDigits: 3,
            })
          }
          return formatPrice(params.value)
        },
        cellClass: (params: CellClassParams) => {
          if (params.data?.rate) {
            return 'bg-primary-100'
          }
          return cellClasses.ReadOnly
        },
        hide: !props.hasUnionRates,
      },
      {
        headerName: i18n.t('Frequency'),
        field: 'frequency',
        minWidth: 100,
        editable: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return payFrequencyOptions.value.find((option) => option.value === params.value)?.label
        },
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: payFrequencyOptions.value,
        },
      },
      {
        headerName: i18n.t('Override'),
        field: 'override',
        minWidth: 80,
        editable: true,
        hide: !isDeductionRate.value,
        valueFormatter: (params: ValueFormatterParams) => {
          return deductionOverrideTypeOptions.find((option) => option.value === params.value)?.label
        },
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: deductionOverrideTypeOptions,
        },
      },
      {
        headerName: i18n.t('Limit on Accumulated Amount'),
        field: 'limit_on_accumulated_amount',
        component: 'FormattedPrice',
        minWidth: 100,
        editable: true,
        cellEditor: cellEditors.Numeric,
      },
      {
        headerName: i18n.t('Limit Type'),
        field: 'accumulation_interval',
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => {
          return accumulationIntervalOptions.find((option) => option.value === params.value)?.label
        },
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: accumulationIntervalOptions,
        },
      },
      {
        headerName: i18n.t('Limit on Each Pay Period'),
        field: 'period_limit_amount',
        minWidth: 100,
        editable: true,
        component: 'FormattedPrice',
        cellEditor: cellEditors.Numeric,
        suppressKeyboardEvent: stopEditingOnTab,
      },
      {
        headerName: i18n.t('Pay Amount To-Date'),
        field: 'accumulated_amount_to_date',
        minWidth: 100,
        component: 'FormattedPrice',
        cellEditor: cellEditors.Numeric,
        cellClass: cellClasses.ReadOnly,
      },
      {
        ...getDeleteColumn({
          title: i18n.t('Delete Rate'),
          description: i18n.t('Are you sure you want to delete this rate?'),
          skipRowDelete: true,
          cellRendererParams: (params: ICellRendererParams) => {
            return {
              hide: params.data.is_union_rate,
            }
          },
          url: url.value,
          cellClass: (params: CellClassParams) => {
            if (params.data.is_used) {
              return cellClasses.ReadOnlyLight
            }
          },
          editable: (params: ICellEditorParams) => !params.data.is_union_rate && !params.data.is_used,
          onConfirm: () => {
            gridTable.value?.refresh()
          },
          hide: (params: any) => {
            return props.readOnly || params.data?.is_used
          },
        }),
      },
    ]
  })

  const url = computed<string | null>(() => {
    return props.employeeId ? `/restify/employee-${ props.rateType }` : null
  })

  const urlParams = computed(() => {
    let params: Record<string, any> = {}
    if (props.employeeId) {
      params.employee_id = props.employeeId
    }

    return params
  })

  const payFrequencyOptions = computed(() => {
    return rateTypeFrequencyOptions[props.rateType as string]
  })

  async function resetRate(params: ValueSetterParams) {
    const confirmed = await $deleteConfirm({
      title: i18n.t('Reset employee rate'),
      description: i18n.t('By setting rate to $0, the employee rate will be reset to the union rate. Do you want to continue?'),
      buttonText: i18n.t('Reset Rate'),
    })
    if (!confirmed) {
      return
    }
    await axios.delete(`/restify/employee-${ props.rateType }/${ params.data.id }`)
    await gridTable.value.refresh()
  }

  async function mapData(data: any) {
    if (props.readOnly) {
      return data.map((entry: Record<string, any>) => entry.attributes as EmployeePayCode)
    }

    if (!props.mapRates) {
      return []
    }
    entries.value = await props.mapRates(data)
    return entries.value
  }

  function getEmptyEntry(): EmployeePayCode {
    // @ts-ignore
    const frequency = rateTypeFrequencies.EveryPayPeriod

    const override = isDeductionRate.value ? deductionOverrideTypes.Both : ''

    return {
      id: null,
      employee_id: props.employeeId,
      is_union_rate: false,
      active: true,
      source_id: '',
      source_type: sourceTypeConfig.sourceType,
      calculate_by: '',
      multiply_by: '',
      rate: 0,
      union_rate: 0,
      effective_rate: 0,
      job_can_override_rate: jobTableOverrides.OverrideAlways,
      accumulation_interval: 'annual',
      limit_on_accumulated_amount: 0,
      period_limit_amount: 0,
      accumulated_amount_to_date: 0,
      frequency,
      override,
    }
  }

  async function storeProgress() {
    const tableData = getTableData(grid.value.api)
    await saveRates(url.value, tableData)
    await gridTable.value?.refresh()
  }

  defineExpose({
    storeProgress,
  })
</script>
