import axios from 'axios'
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";
import { resourceStatuses } from "@/enum/enums";

const types = {
  SET_CURRENT_INVOICE: 'SET_CURRENT_INVOICE',
  RESET_CURRENT_INVOICE: 'RESET_CURRENT_INVOICE',
  SET_CURRENT_VENDOR: 'SET_CURRENT_VENDOR',
  SET_CURRENT_VENDOR_LOADING: 'SET_CURRENT_VENDOR_LOADING',
  SET_CURRENT_INVOICE_LOADING: 'SET_CURRENT_INVOICE_LOADING',
  SET_CURRENT_RECURRING_INVOICE: 'SET_CURRENT_RECURRING_INVOICE',
  SET_CURRENT_RECURRING_INVOICE_LOADING: 'SET_CURRENT_RECURRING_INVOICE_LOADING',
  SET_CURRENT_VENDOR_1099_BATCH: 'SET_CURRENT_VENDOR_1099_BATCH',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
};
const state = {
  currentInvoice: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentRecurringInvoice: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentVendor: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentVendor1099Batch: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  vendorLoading: false,
  invoiceLoading: false,
  recurringInvoiceLoading: false,
};

const mutations = {
  [types.SET_CURRENT_INVOICE]: (state, invoice) => {
    state.currentInvoice = invoice
  },
  [types.RESET_CURRENT_INVOICE]: (state, invoice) => {
    state.currentInvoice = invoice
  },
  [types.SET_CURRENT_RECURRING_INVOICE]: (state, invoice) => {
    state.currentRecurringInvoice = invoice
  },
  [types.SET_CURRENT_VENDOR]: (state, vendor) => {
    state.currentVendor = vendor
  },
  [types.SET_CURRENT_VENDOR_LOADING]: (state, value) => {
    state.vendorLoading = value
  },
  [types.SET_CURRENT_INVOICE_LOADING]: (state, value) => {
    state.invoiceLoading = value
  },
  [types.SET_CURRENT_RECURRING_INVOICE_LOADING]: (state, value) => {
    state.recurringInvoiceLoading = value
  },
  [types.UPDATE_VENDOR]: (state, vendor) => {
    state.currentVendor.attributes = vendor.attributes
  },
  [types.SET_CURRENT_VENDOR_1099_BATCH]: (state, vendor1099Batch) => {
    state.currentVendor1099Batch = vendor1099Batch
  }
};

const actions = {
  async getInvoice({ commit }, invoiceId) {
    try {
      commit(types.SET_CURRENT_INVOICE_LOADING, true)
      const params = {
        related: `payments,${entityPreviewFields.Vendor},vendor.address,${entityPreviewFields.PurchaseOrder}`
      }
      const { data } = await axios.get(`/restify/invoices/${invoiceId}`, {
        params,
      })
      commit(types.SET_CURRENT_INVOICE, data)
    } finally {
      commit(types.SET_CURRENT_INVOICE_LOADING, false)
    }
  },
  resetCurrentInvoice({ commit }) {
    commit(types.RESET_CURRENT_INVOICE, {
      attributes: {},
      relationships: {},
      meta: {}
    })
  },
  async holdInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/invoices/${invoiceId}/actions?action=hold-invoice`)
    dispatch('getInvoice', invoiceId)
  },
  async unHoldInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/invoices/${invoiceId}/actions?action=unhold-invoice`)
    dispatch('getInvoice', invoiceId)
  },
  async patchInvoice({ state, dispatch }, data = {}) {
    if (data?.status === resourceStatuses.Posted && data?.old_status === resourceStatuses.Hold) {
      await dispatch('unHoldInvoice', data.id)
      return
    } else if (data?.status === resourceStatuses.Hold && data?.old_status === resourceStatuses.Posted) {
      await dispatch('holdInvoice', data.id)
      return
    }
    await axios.patch(`/restify/invoices/${data.id}`, data)
  },
  async getRecurringInvoice({ commit }, invoiceId) {
    try {
      commit(types.SET_CURRENT_RECURRING_INVOICE_LOADING, true)
      const params = {
        related: `${entityPreviewFields.Vendor}`
      }
      const { data } = await axios.get(`/restify/recurring-invoices/${invoiceId}`, {
        params,
      })
      if (!data?.attributes?.vendor_id) {
        data.attributes.vendor_id = data?.relationships?.vendor?.id
      }
      commit(types.SET_CURRENT_RECURRING_INVOICE, data)
    } finally {
      commit(types.SET_CURRENT_RECURRING_INVOICE_LOADING, false)
    }
  },
  async deactivateRecurringInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/recurring-invoices/${invoiceId}/actions?action=deactivate-recurring-invoice`)
    dispatch('getRecurringInvoice', invoiceId)
  },
  async activateRecurringInvoice({ commit, dispatch }, invoiceId) {
    await axios.post(`/restify/recurring-invoices/${invoiceId}/actions?action=activate-recurring-invoice`)
    dispatch('getRecurringInvoice', invoiceId)
  },
  async getVendor({ commit }, invoiceId) {
    try {
      commit(types.SET_CURRENT_VENDOR_LOADING, true)
      const params = {
        related: 'addresses,contacts,activities'
      }
      const { data } = await axios.get(`/restify/vendors/${invoiceId}`, {
        params,
      })
      commit(types.SET_CURRENT_VENDOR, data)
    } finally {
      commit(types.SET_CURRENT_VENDOR_LOADING, false)
    }
  },
  async patchVendor({ state }, data = {}) {
    await axios.patch(`/restify/vendors/${data.id}`, data)
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
