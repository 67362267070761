<template>
  <div>
    <base-form :layout="layout"
               :loading="loading"
               :save-text="saveText"
               :show-cancel="showCancel"
               @cancel="$emit('cancel')"
               @submit="onSubmit"
    >
      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.name"
                    :name="$t('Name')"
                    :placeholder="$t('Name')"
                    :label="$t('Name')"
                    rules="required"
                    id="name"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <BaseInput
          v-model="model.email"
          :name="$t('Email')"
          :placeholder="$t('Email')"
          :label="$t('Email')"
          rules="email"
          id="email"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-select v-model="model.type"
                     :name="$t('Type')"
                     :label="$t('Type')"
                     :placeholder="$t('Type')"
                     :options="contactTypeOptions"
                     id="type"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-input v-model="model.title"
                    :name="$t('Title')"
                    :placeholder="$t('Title')"
                    :label="$t('Title')"
                    id="title"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-phone-input v-model="model.phone"
                          :name="$t('Phone')"
                          :placeholder="$t('Phone')"
                          :label="$t('Phone')"
                          id="phone"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <base-date-picker v-model="model.dob"
                          :label="$t('Birth Date')"
                          :name="$t('Birth Date')"
                          :placeholder="$t('Birth Date')"
                          id="dob"
        />
      </div>
      <div class="col-span-6">
        <base-input v-model="model.address"
                    :label="$t('Address')"
                    :name="$t('Address')"
                    :placeholder="$t('Address')"
                    id="address"
        />
      </div>
      <div class="col-span-6">
        <base-textarea v-model="model.notes"
                       :label="$t('Notes')"
                       :placeholder="$t('Notes')"
                       id="notes"
        />
      </div>
    </base-form>
  </div>
</template>
<script lang="ts">
import axios from 'axios'
import { defineComponent } from 'vue'
import {ContactType, ContactTypes} from "@/enum/enumTypes";

interface ContactModel {
  name: string,
  type: ContactType,
  title: string,
  phone: string,
  address: string,
  email: string,
  dob: string,
  notes: string,
}

export default defineComponent({
  props: {
    showCancel: {
      type: Boolean,
      default: true,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    saveText: String,
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
    parentEntityId: String,
    parentEntityName: String,
  },
  data() {
    return {
      loading: false as boolean,
      contactTypeOptions: [
        {
          label: this.$t('Normal'),
          value: ContactTypes.Normal
        },
        {
          label: this.$t('Billing'),
          value: ContactTypes.Billing
        },
        {
          label: this.$t('Payment'),
          value: ContactTypes.Payment
        },
        {
          label: this.$t('Emergency'),
          value: ContactTypes.Emergency
        },
      ],
      model: {
        name: '',
        type: ContactTypes.Normal,
        title: '',
        phone: '',
        address: '',
        email: '',
        dob: '',
        notes: '',
      } as ContactModel,
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical' : 'modal'
    },
  },
  methods: {
    async onSubmit() {
      if (this.redirectToListAfterStore) {
        this.$emit('onSubmit', this.model)
        return
      }
      try {
        this.loading = true
        const {data} = await axios.post(`/restify/${ this.parentEntityName }/${ this.parentEntityId }/contacts`, this.model)
        this.$success('Contact added successfully.')
        this.$emit('save', data, true)
      } catch (err: any) {
        if (err.handled) {
          return
        }
        console.warn(err)

      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    entity: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
      }
    }
  }
})
</script>
