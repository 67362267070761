<template>
  <div>
    <a v-if="formattedAddress && !editableCell"
       :href="locationLink"
       target="_blank"
       class="hover:text-primary-900 hover:underline cursor-pointer"
    >
      <address class="address-field truncate">
        {{ formattedAddress }}
      </address>
    </a>
    <span v-else>{{ formattedAddress }}</span>
  </div>
</template>
<script>
  import { formattedAddress } from "@/utils/utils";

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({})
      },
      row: {
        type: Object,
        default: () => ({})
      },
      editableCell: Boolean,
    },
    computed: {
      formattedAddress() {
        const address = this.row?.attributes || this.row
        if (!address) {
          return ''
        }
        return formattedAddress(address)
      },
      locationLink() {
        return `https://www.google.com/maps/search/?api=1&query=${this.formattedAddress}`
      },
    }
  }
</script>
