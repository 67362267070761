import { delay } from "@/utils/utils";

export const selectEditorMixin = {
  provide() {
    return {
      initialSearchQuery: this.params?.charPress,
    }
  },
  data() {
    return {
      selectedValue: null,
      selectedFullValue: null,
    }
  },
  methods: {
    getValue() {
      return this.selectedValue
    },
    isPopup() {
      return this.params?.colDef?.cellEditorPopup
    },
    onEntityCreated(fullValue) {
      if (this.params.onEntityCreated) {
        this.params.onEntityCreated(fullValue)
      }
    },
    async onChange(newValue, fullValue) {
      this.selectedFullValue = fullValue
      if (this.selectedValue === newValue) {
        return
      }
      this.selectedValue = newValue
      // When the user clicks on an option and navigates to next cell, the next dropdown won't focus, therefore we do a timeout
      // This happens only on click and not on keyboard navigation.
      if (!this.params.stopNavigationOnChange) {
        await delay(80)
        await this.goToNextCell()
      }
      if (this.params.onValueChanged) {
        this.params.onValueChanged(this.params, newValue, fullValue)
      }
      if (this.params.stopNavigationOnChange && !this.params.multiple) {
        await this.$nextTick()
        this.params.api.stopEditing()
      }
    },
    async goToNextCell() {
      if (this.params.delayNextCellNavigation) {
        await delay(80)
      }

      if (this.params.onValueChanged) {
        this.params.onValueChanged(this.params, this.selectedValue, this.selectedFullValue)
      }

      if (this.params.stopNavigationOnChange) {
        return
      }
      this.params.api.tabToNextCell()
    },
    initCellValueChangedListener() {
      const colDef = this.params?.colDef || {}
      colDef.onCellValueChanged = params => {
        const currentIndex = this.params?.node?.rowIndex
        const paramsIndex = params?.node?.rowIndex
        if (params.newValue !== this.selectedValue && currentIndex === paramsIndex) {
          this.selectedValue = params.newValue
        }
      }
    }
  },
  created() {
    this.initCellValueChangedListener()
  },
  watch: {
    'params.value': {
      immediate: true,
      handler(value) {
        this.selectedValue = value
      }
    },
  },
}
