<template>
  <div class="flex items-center">
    <span
      v-if="showLabel"
      class="mx-2 text-sm font-medium leading-5 text-gray-700">
      {{ $t('Calendar Year') }}
    </span>
    <base-select
        v-model="calendar_year"
        :options="getOptions"
        inline-errors
        @change="onChangeCalendarYear"
    />
  </div>
</template>
<script>
  export default {
    props: {
      showLabel: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        calendar_year: this.$settings(this.$modules.PR, 'calendar_year'),
      }
    },
    computed: {
      getOptions() {
        const settingsCalendarYear = this.$settings(this.$modules.PR, 'calendar_year')
        const previewYear = settingsCalendarYear - 1
        return [
          {
            label: this.$t('This Year - ') + settingsCalendarYear,
            value: settingsCalendarYear,
          },
          {
            label: this.$t('Last Year - ') + previewYear,
            value: previewYear,
          },
        ]
      },
      getCalendarYear() {
        return this.$store.getters['payroll/getCalendarYear']
      },
    },
    methods: {
      onChangeCalendarYear(year) {
        this.$store.dispatch('payroll/updateCalendarYear', year)
      },
    },
    mounted() {
      this.calendar_year = this.getCalendarYear || this.$currentYear
    }
  }
</script>
