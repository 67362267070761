<template>
  <div>
    <BaseForm
      :loading="loading"
      :show-back="showBack"
      :show-cancel="showCancel"
      :submit-disabled="gridContext.loading"
      :can-create-another-entity="!model.id"
      :save-text="saveText"
      :update-text="$t('Update purchase order')"
      layout="vertical"
      grid-classes="grid grid-cols-1"
      ref="baseForm"
      @cancel="$router.push('/purchasing-inventory/purchase-orders')"
      @submit="onSubmit"
    >
      <div class="col-span-1">
        <ElCollapse v-model="expandedSections">
          <BaseCollapseSection
            key="form-header"
            class="-m-6 mb-6"
            name="form-header"
            has-background
          >
            <template slot="title">
              <PurchaseOrderFormHeader
                :order="model"
              />
            </template>
            <div class="grid grid-cols-8 gap-4 mx-2">
              <div class="col-span-8 md:col-span-2">
                <VendorSelect
                  v-model="model.vendor_id"
                  :disabled="!!model.id"
                  :initial-value="get(data, 'vendor')"
                  :on-map-entry="onVendorChange"
                  class="col-span-8 md:col-span-2"
                  rules="required"
                />
                <template v-if="selectedVendorCode">
                  <div class="text-sm text-gray-700 font-medium -mt-4">
                    {{ $t(`Last PO for Vendor ${ selectedVendorCode }: #`) }}
                    {{ lastVendorPurchaseOrder }}
                  </div>
                </template>
              </div>
              <TaggableInput
                v-model="model.number"
                :tip="!model.id ? $t('Required for Manual purchase orders. Optional for Normal purchase orders. The system will automatically assign the next sequential P.O. #.') : ''"
                :label="$t('P.O. Number')"
                :name="$t('P.O. Number')"
                :placeholder="numberPlaceholder"
                :disabled="!!model.id"
                :default-state="authorizedToEditNumber"
                :rules="numberValidator"
                :is-boolean-source-type="true"
                class="col-span-8 md:col-span-1"
                id="number"
                @on-update-state="onToggleNumberInputState"
              />
              <BaseDatePicker
                v-model="model.date"
                :label="$t('Order Date')"
                :name="$t('Order Date')"
                :placeholder="$t('Order Date')"
                class="col-span-8 md:col-span-2"
                id="date"
                rules="required"
              />
              <div class="col-span-8 md:col-span-1">
                <BaseSwitch :value="model.status === resourceStatuses.NoPost"
                             :label-info="$t('No Post')"
                             :disable="noPostToggleDisabled"
                             @input="onNoPostChange"
                />
              </div>
              <div class="col-span-8 md:col-span-2"/>
              <BaseDatePicker
                v-model="model.delivery_date"
                :label="$t('Delivery Date')"
                :name="$t('Delivery Date')"
                :placeholder="$t('Delivery Date')"
                class="col-span-8 md:col-span-2"
                rules="required"
              />
              <BaseTextarea
                v-model="model.description"
                :label="$t('Description')"
                :name="$t('Description')"
                :placeholder="$t('Description')"
                :rows="1"
                class="col-span-8 md:col-span-4"
                id="description"
                rules="max:150"
              />
              <BaseSwitch
                :label-info="$t('Extended Print Description')"
                v-model="useExtendedDescription"
                class="col-span-8 md:col-span-2"
              />
              <div
                v-if="useExtendedDescription"
                class="col-span-8">
                <BaseInput :label="$t('Print Description')" :tip="$t('Used in certain PO print templates when defined')">
                  <template #container>
                    <HtmlEditor
                      v-model="model.print_description"
                      :config="{
                    plugins: ['alignment', 'fontcolor', 'fontsize']
                  }"
                    />
                  </template>
                </BaseInput>
              </div>

              <ContactSelect
                v-model="model.vendor_contact_id"
                :key="model.vendor_id"
                :disabled="!model.vendor_id"
                :contactable-id="model.vendor_id"
                :label="$t('Vendor Contact')"
                :name="$t('Vendor Contact')"
                :placeholder="$t('Vendor Contact')"
                class="col-span-8 md:col-span-2"
                clearable
                @entity-change="onChangeVendorContact"
                @keydown.tab.exact.native="focusOnEmployeeSelect"

              />
              <BaseInput
                v-model="model.vendor_contact_name"
                :label="$t('Contact Name')"
                :name="$t('Contact Name')"
                :placeholder="$t('Contact Name')"
                class="col-span-8 md:col-span-2"
                rules="max:150"
              />
              <EmployeeSelect
                v-model="model.employee_id"
                :label="$t('Order Placed By')"
                :name="$t('Order Placed By')"
                id="employeeSelect"
                class="col-span-8 md:col-span-2"
                clearable
                @entity-change="onChangeEmployee"
                @keydown.tab.exact.native="focusOnShipToSelect"
              />
              <BaseInput
                v-model="model.placed_by_name"
                :label="$t('Placed By Name')"
                :name="$t('Placed By Name')"
                :placeholder="$t('Placed By Name')"
                class="col-span-8 md:col-span-2"
                rules="max:150"
              />
              <BaseSelect
                v-model="model.ship_to"
                :options="shippingLocationOptions"
                :tip="$t('If you select a cost center, shipping will be referred to the selected cost center.')"
                :label="$t('Ship To')"
                :name="$t('Ship To')"
                class="col-span-8 md:col-span-1"
                rules="required"
                id="shipToSelect"
              />
              <BaseInput
                v-model="model.fob_point"
                :label="$t('F.O.B')"
                :name="$t('F.O.B point')"
                :placeholder="$t('F.O.B point')"
                class="col-span-8 md:col-span-1"
                rules="max:20"
              />
              <BaseInput
                v-model="model.discount_percent"
                :label="$t('Discount')"
                :name="$t('Discount')"
                :min="0"
                :max="99.99"
                :step="1"
                class="col-span-8 md:col-span-1"
                rules="min_value:0|max_value:99.99"
                format="percent"
                type="number"
              />
              <SalesTaxDistrictSelect
                v-model="model.meta.sales_tax_district_id"
                :on-map-entry="onSelectDistrict"
                :add-entity="true"
                class="col-span-3 md:col-span-1"
                clearable
              />
              <BaseInput
                v-model="model.sales_tax_percent"
                v-bind="$validator.salesTaxPercent"
                :label="$t('Sales Tax')"
                :name="$t('Sales Tax')"
                class="col-span-8 md:col-span-1"
                format="percent"
                type="number"
                @change="shouldEntriesChange"
              />
              <BaseCheckbox
                v-model="model.are_shop_drawing_submitted"
                :label="$t('Submittals Required')"
                class="col-span-8 md:col-span-2"
                id="are_shop_drawing_submitted"
              />
              <BaseTextarea
                v-model="model.terms"
                :label="$t('Terms')"
                :name="$t('Terms')"
                :placeholder="$t('Terms')"
                :rows="2"
                class="col-span-8 md:col-span-3"
                rules="max:50"
              />
              <BaseTextarea
                v-model="model.instructions"
                :label="$t('Special Instructions')"
                :name="$t('Special Instructions')"
                :placeholder="$t('Special Instructions')"
                :rows="2"
                class="col-span-8 md:col-span-3"
                rules="max:150"
              />
            </div>
          </BaseCollapseSection>
        </ElCollapse>
        <PurchaseOrderItems
          :data="model"
          :vendor_id="model.vendor_id"
          ref="gridTable"
          @on-collapse-form-header="collapseFormHeader"
        />
      </div>
      <FileAttachments
          ref="attachments"
          class="col-span-8 mt-4 -m-4"
          entity="purchase-orders"
          :entity-id="entityId"
      />
    </BaseForm>
  </div>
</template>
<script>
import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import FileAttachments from '@/modules/accounts-payable/components/attachements/FileAttachments'
import { shippingLocations } from '@/modules/purchasing-inventory/components/purchase-orders/util'
import PurchaseOrderItems from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems'
import PurchaseOrderFormHeader
  from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderFormHeader'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { gridContext } from "@/components/ag-grid/gridContext";
import { shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";

const metaFields = {
  sales_tax_district_id: null,
}

export default {
  components: {
    FileAttachments,
    PurchaseOrderItems,
    PurchaseOrderFormHeader,
  },
  props: {
    data: {
      typ: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lastVendorPurchaseOrder: '',
      selectedVendorCode: '',
      resourceStatuses,
      expandedSections: [],
      loading: false,
      showCancel: true,
      showBack: true,
      templateOptions: [],
      shippingLocationOptions: [
        {
          label: this.$t('Warehouse'),
          value: shippingLocations.Warehouse,
        },
        {
          label: this.$t('Cost Center'),
          value: shippingLocations.CostCenter,
        },
      ],
      testing: '',
      entityId: null,
      model: {
        vendor_id: null,
        status: null,
        number: null,
        is_manual: false,
        date: this.$now,
        delivery_date: this.$now,
        description: null,
        print_description: null,
        vendor_contact_id: null,
        vendor_contact_name: null,
        employee_id: null,
        placed_by_name: null,
        ship_to: shippingLocations.Warehouse,
        fob_point: null,
        are_shop_drawing_submitted: false,
        discount_percent: 0,
        sales_tax_percent: 0,
        terms: null,
        instructions: null,
        meta: metaFields,
      },
      useExtendedDescription: false,
      gridContext,
    }
  },
  computed: {
    noPostToggleDisabled() {
      return ![resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
    },
    numberValidator() {
      return (this.model.is_manual && !this.model.id) ? 'required' : ''
    },
    authorizedToEditNumber() {
      return !this.model.is_manual || !this.model.id
    },
    numberPlaceholder() {
      if (this.model.is_manual) {
        return this.$t('Enter P.O. Number')
      }

      return this.$t('Auto assign')
    },
    saveText() {
      if (this.model.is_manual) {
        return this.$t('Create P.O.')
      }

      return this.$t('Create P.O. and assign number')
    }
  },
  methods: {
    async shouldEntriesChange() {
      await shouldEntriesChange(this.$refs.gridTable)
    },
    focusOnEmployeeSelect(event) {
      if (!this.model.vendor_contact_id) {
        return
      }

      const employeeSelect = document.querySelector('#employeeSelect input')

      if (!employeeSelect) {
        return
      }

      event.preventDefault()
      employeeSelect.focus()
    },
    focusOnShipToSelect(event) {
      if (!this.model.employee_id) {
        return
      }

      const shipToSelect = document.querySelector('#shipToSelect input')

      if (!shipToSelect) {
        return
      }

      event.preventDefault()
      shipToSelect.focus()
    },
    onSelectDistrict(district) {
      this.model.sales_tax_percent = this.get(district, 'attributes.sales_tax_percent', 0)
    },
    onChangeVendorContact(contact) {
      if (!contact) {
        return
      }
      this.model.vendor_contact_name = this.get(contact, 'attributes.name')
    },
    onChangeEmployee(employee) {
      if (!employee) {
        return
      }
      this.model.placed_by_name = this.get(employee, 'attributes.name')
    },
    onToggleNumberInputState(state) {
      this.model.number = null
      this.model.is_manual = state
    },
    onNoPostChange(value) {
      value ? this.model.status = resourceStatuses.NoPost : this.model.status = resourceStatuses.Pending
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/purchase-orders/${ this.model.id }`, this.model)
          await this.$refs.gridTable.storeProgress(this.model.id)
          this.$success(this.$t(`PO #${ this.model.number } was updated successfully`))
          this.$emit('refresh')
        } else {
          const { data } = await axios.post('/restify/purchase-orders', this.model)
          await this.$refs.gridTable.storeProgress(data.id)

          const { number } = data.attributes
          this.$success(this.$t(`PO #${ number } was created successfully`))
          this.entityId = data.id
          await this.$refs.attachments.triggerUpload()
          this.$createAnotherEntity ? this.$emit('create-another') : await this.$router.push('/purchasing-inventory/purchase-orders/pending')
        }
        await this.$store.dispatch('globalLists/getIVConfiguration', true)
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong please try again.'))
      } finally {
        this.loading = false
      }
    },
    setDefaultProps() {
      this.model.status = this.$settings(this.$modules.IV, 'purchase_order_status') || resourceStatuses.Pending
      this.model.sales_tax_percent = this.$settings(this.$modules.IV, 'sales_tax_percent') || 0
      this.expandedSections = ['form-header']
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
    async onVendorChange(vendor) {
      try {
        this.model.vendor_contact_id = null
        this.selectedVendorCode = this.get(vendor, 'attributes.code', false)
        const { number } = await axios.get('/restify/purchase-orders/last', {
          params: {
            vendor_id: vendor.id,
          },
        })
        this.lastVendorPurchaseOrder = number
      } catch (err) {
        console.warn(err)
      }
    },
    tryPrefillVendor() {
      const { vendor_id } = this.$route.query
      if (!vendor_id || this.data?.id) {
        return
      }
      this.model.vendor_id = vendor_id
      this.onVendorChange()
    }
  },
  mounted() {
    this.tryPrefillVendor()
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value.id) {
          return this.setDefaultProps()
        }

        this.model = {
          ...this.model,
          meta: { ...metaFields },
          ...value,
        }

        this.collapseFormHeader()
        this.useExtendedDescription = !!value.print_description
      },
    },
  },
}
</script>
