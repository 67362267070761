<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :hide-presets="true"
    :consistentRowColor="true"
    :no-cache="true"
    :focusOnFirstInput="false"
    :isPrint="isPrint"
    :autoRun="format === formats.Vendor1099s_ProofListing"
    :postUrl="postUrl"
    class="vendor-1099s-report"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-4"
          @change="onFormatChanged"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Tax Year')"
      >
        <BaseInput
          v-model="model.tax_year"
          :name="$t('Tax Year')"
          :placeholder="$t('Tax Year (YYYY)')"
          type="number"
          class="col-span-4"
          rules="required|min_value:2024"
        />
      </BaseFilterRow>
      <Vendor1099PrintPaperFormsOptions
        v-if="isPrint"
        v-model="model"
        :tax_year="model.tax_year"
      />
    </template>

    <template #table="{ data, loading }">
      <Vendor1099sProofListingReportTable
        v-if="format === formats.Vendor1099s_ProofListing"
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
      <PdfPreview
        v-else-if="isPrint"
        :file="data"
        :loading="loading"
      />
      <div v-else>
        Not implemented
      </div>
    </template>
  </BaseReportForm>
</template>
<script>
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import Vendor1099sProofListingReportTable from "@/modules/accounts-payable/components/reports/vendor-1099s/Vendor1099sProofListingReportTable.vue";
import {
  Vendor1099sReportOptions
} from '@/modules/accounts-payable/components/reports/util'
import PdfPreview from "@/components/common/PdfPreview.vue";
import Vendor1099PrintPaperFormsOptions from '@/modules/accounts-payable/components/vendor1099/Vendor1099PrintPaperFormsOptions.vue'

export default {
  components: {
    TextFileList,
    Vendor1099sProofListingReportTable,
    Vendor1099PrintPaperFormsOptions,
    PdfPreview,
  },
  data() {
    const priorYear = new Date().getFullYear() - 1
    return {
      formats: Vendor1099sReportOptions.Formats,
      format: this.$route.query.format || Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing,
      model: {
        tax_year: priorYear,
        template_type: Vendor1099sReportOptions.TemplateTypes["1099"],
        form_types: [
          Vendor1099sReportOptions.FormTypes.MISC,
          Vendor1099sReportOptions.FormTypes.NEC,
        ],
        copies: [],
        vendor_code_from: null,
        vendor_code_to: null,
      },
    }
  },
  computed: {
    reportNumber() {
      const reportMap = {
        [Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing]: '3884a',
        default: '3884a',
      }
      return reportMap[this.format] || reportMap.default
    },
    isPrint() {
      return this.format === Vendor1099sReportOptions.Formats.Vendor1099s_PaperForms
    },
    formatOptions() {
      const formatOptions = [
        {
          label: this.$t('Vendor 1099s Proof Listing'),
          value: Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing,
        },
        {
          label: this.$t('Vendor 1099s Paper Forms'),
          value: Vendor1099sReportOptions.Formats.Vendor1099s_PaperForms,
        },
      ]

      return formatOptions
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    yearOptions() {
      const minYear = 2000
      const maxYear = new Date().getFullYear()

      return Array.from({ length: maxYear - minYear + 1 }, (_, i) => {
        const year = maxYear - i
        return {
          label: year,
          value: year,
        }
      })
    },
    postUrl() {
      if (this.model.template_type === Vendor1099sReportOptions.TemplateTypes["1096"]) {
        return `/restify/vendor1099s/actions?action=print-vendor-1096`
      }
      return `/restify/vendor1099s/actions?action=print-vendor-1099s`
    }
  },
  methods: {
    onFormatChanged() {
      this.model.vendor_code_from = null
      this.model.vendor_code_to = null
    },
  },
}
</script>
