<template>
  <div>
    <router-link v-if="data.id" :to="purchaseOrderLink">
      {{ number }}
    </router-link>
    <span v-else>
      {{ number }}
    </span>
  </div>
</template>
<script>

export default {
  name: 'PurchaseOrderLink',
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({}),
    },
    showTypePrefix: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    purchaseOrderLink() {
      const id = this.data?.id || this.id
      return `/purchasing-inventory/purchase-orders/${id}/view`
    },
    number() {
      const number = this.data?.attributes?.number || this.data?.number || ''
      return this.showTypePrefix ?
        `PO #${number}`
        : number
    },
  }
}
</script>
