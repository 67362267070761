<template>
  <AgDataTable
    :columns="columns"
    :masterDetail="true"
    :detailRowAutoHeight="true"
    :add-entity-in-new-tab="true"
    :detailCellRendererParams="detailCellRendererParams"
    :detailCellRenderer="detailCellRenderer"
    :actionsColumnWidth="showActions ? 300: 100"
    entity="accounts-receivable-inits"
    :hide-actions="showActions ? 'view' : 'filters,add,view'"
    url="/restify/accounts-receivable-inits"
    :url-params="urlParams"
    :actions="showActions ? 'add,search,refresh,print,import': ''"
    import-url="/accounts-receivable/settings/ar-initialize/import"
    permission="vendors"
    ref="table"
    :perPage="9999"
    :showPagination="false"
    @grid-ready="grid = $event"
    @data-fetch="$emit('data-fetch', $event)"
  >

    <template
      v-if="showActions"
      #extra-actions="{row}">
      <ProofListingButton
        v-if="row.meta?.authorizedToPost"
        :path="`/accounts-receivable/settings/ar-initialize/${row.id}/proof-listing`"
      />
      <AccountsReceivableInitExport
        :id="row.id"
        size="xs"
        class="!mr-0"
      />

    </template>

  </AgDataTable>
</template>
<script>
import AccountsReceivableInitEntries
  from '@/modules/accounts-receivable/components/settings/AccountsReceivableInitEntries'
import AccountsReceivableInitExport
  from "@/modules/accounts-receivable/components/settings/AccountsReceivableInitExport.vue";

export default {
  components: {
    AccountsReceivableInitExport,
    AccountsReceivableInitEntries,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({})
    },
    showActions: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      grid: null,
      detailCellRenderer: null,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Period'),
          prop: 'attributes.period',
          cellRenderer: 'agGroupCellRenderer',
        },
        {
          label: this.$t('Fiscal Year'),
          prop: 'attributes.fiscal_year',
        },
        {
          label: this.$t('Entries'),
          prop: 'attributes.entries_count',
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          component: 'StatusLink',
          align: 'center',
        },
      ]
    },
    detailCellRendererParams() {
      return {
        parentGrid: this.grid,
        readOnly: true,
      }
    }
  },
  created() {
    this.detailCellRenderer = 'AccountsReceivableInitEntries'
  },
}
</script>
