<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders"
    :customizeLastSubtotalRow="false"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header" />
    </template>

    
    <template #header="{ row }">
      <div
        v-if="row.header.bank"
        class="flex space-x-2 items-inline-base"
      >
        <div>
          {{ $t('Bank') }}:
        </div>
        <BankLink
          :id="row.header?.bank.id"
          :show-preview="false"
          includeInitials
          class="min-w-[400px]"
        />
      </div>
      <div
        v-else-if="row.header.fiscalPeriod"
        class="flex space-x-2 items-inline-base"
      >
        <div>
          {{ $t('Fiscal Period') }}:
        </div>
        <div>
          {{ row.header.fiscalPeriod.period }} {{ row.header.fiscalPeriod.year }}
        </div>
      </div>
      <div
        v-else-if="row.header.vendor"
        class="flex space-x-2 items-inline-base"
      >
        <div>
          {{ $t('Vendor') }}:
        </div>
        <VendorLink
          :data="row.header.vendor"
          class="min-w-[400px]"
        />
      </div>

      <div
        v-else-if="row.header.journal"
        class="flex space-x-2 items-inline-base"
      >
        <div>
          {{ $t('Journal') }}:
        </div>
        <JournalLink
          :data="row.header.journal"
          class="min-w-[400px]"
        />
      </div>
    </template>

    <template #journal="{ row }">
      <JournalLink :data="row.journal" />
    </template>

    <template #vendor="{ row }">
      <VendorLink
        :data="row.vendor"
        :show-preview="false"
      />
    </template>
    <template #joint_vendor="{ row }">
      <VendorLink
        :data="row.joint_vendor"
        :show-preview="false"
      />
    </template>
    <template #html-row="{ htmlData }">
      <tr
        v-if="htmlData.outOfSequence"
        class="content-row"
      >
        <td
          :colspan="columns.length"
          style="background: white"
        >
          **
          {{ $t('Out of sequence') }}
          **
        </td>
      </tr>

      <tr
        v-else-if="htmlData.directDepositLegend"
        class="content-row"
      >
        <td colspan="2" style="vertical-align: top;">
          <div class="mt-5">
            {{ $t('Direct Deposit Legend') }}
          </div>
        </td>
        <td
          :colspan="columns.length - 2"
          style="background: white"
        >
          <div class="mt-5 whitespace-normal">
            <div class="flex space-x-2">
              <div class="font-bold">{{ $t('Pending') }}:</div>
              <div>{{ $t('Payment was assigned and posted to Open Payables file but not yet added to the ACH Batch file.') }}</div>
            </div>
            <div class="flex space-x-2">
              <div class="font-bold">{{ $t('Batch') }}:</div>
              <div>{{ $t('Payment has been added to ACH Batch file but has not yet been posted to the History file.') }}</div>
            </div>
            <div class="flex space-x-2">
              <div class="font-bold">{{ $t('History') }}:</div>
              <div>{{ $t('Payment has been added to ACH Batch file and posted to History file (generally, this is done after the payment Batch file has been uploaded to the bank).') }}</div>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-else-if="htmlData.invoiceDetails"
        class="content-row"
      >
        <td />
        <td />
        <td :colspan="columns.length - 2">
          <div
            class="grid grid-cols-7 gap-4 w-full "
            :class="{
              'border-t border-gray-400 pt-2': htmlData.invoiceDetails.index === 0,
            }"
          >
            <div class="col-span-1 flex flex-wrap justify-between">
              <div>{{ $t('Invoice') }}:</div>
              <InvoiceLink
                :id="htmlData.invoiceDetails.id"
                :data="htmlData.invoiceDetails"
              />
            </div>
            <div class="col-span-1 flex flex-wrap justify-between">
              <div>{{ $t('Date') }}:</div>
              <div>{{ $formatDate(htmlData.invoiceDetails.date) }}</div>
            </div>
            <div class="col-span-1 flex flex-wrap justify-between">
              <div>{{ $t('Paid') }}:</div>
              <div>{{ $formatPrice(htmlData.invoiceDetails.net_amount) }}</div>
            </div>
            <div class="col-span-1 flex flex-wrap justify-between">
              <div>{{ $t('Discount') }}:</div>
              <div>{{ $formatPrice(htmlData.invoiceDetails.discount_amount) }}</div>
            </div>
            <div class="col-span-3 flex flex-wrap space-x-2">
              <div>{{ $t('Description') }}:</div>
              <div>{{ htmlData.invoiceDetails.description }}</div>
            </div>
          </div>
        </td>
      </tr>
      <tr v-else-if="htmlData.unusedBlankPayments?.length">
        <td :colspan="columns.length / 2">
          <div class="font-bold">
            {{ $t('Unused Blank Checks') }}
          </div>

          <BaseDataTable
            :columns="unusedBlankPaymentsColumns"
            :data="htmlData.unusedBlankPayments"
            :show-pagination="false"
            :has-summary="false"
            class="no-borders"
          />
        </td>

        <td :colspan="columns.length / 2 + 1" />
      </tr>
    </template>

    <template #subtotal="{ subtotal }">
      <td />
      <td align="right">
        {{ subtotal.label }}
      </td>
      <td align="right"> {{ $formatPrice(subtotal.value) }} </td>
      <td :colspan="columns.length - 3" />
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import InvoiceLink from "@/components/links/InvoiceLink.vue";
import { VendorPaymentRegisterOptions } from '@/modules/accounts-payable/components/reports/util'

export default {
  extends: ReportTableWrapper,
  components: {
    InvoiceLink,
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('Bank'),
          prop: 'bank_no',
          align: 'left',
          minWidth: 40,
          maxWidth: 80,
        },
        {
          label: this.$t('Payment No.'),
          prop: 'payment_no',
          align: 'left',
          minWidth: 40,
          maxWidth: 120,
        },
        {
          label: this.$t('Payment Amount'),
          prop: 'payment_amount',
          align: 'right',
          minWidth: 60,
          maxWidth: 80,
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Payment <br> Date'),
          prop: 'payment_date',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('Journal'),
          prop: 'journal',
          align: 'left',
          minWidth: 80,
          maxWidth: 80,
        },
        {
          label: this.$t('Fiscal <br> Period'),
          prop: 'fiscal_period',
          align: 'left',
          minWidth: 80,
          maxWidth: 80,
        },
        {
          label: this.$t('Joint Payee'),
          prop: 'joint_vendor',
          align: 'left',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          label: this.$t('Vendor'),
          prop: 'vendor',
          align: 'left',
          minWidth: 100,
          maxWidth: 200,
        },
        
        {
          label: this.$t('Direct <br> Deposit'),
          prop: 'direct_deposit',
          align: 'left',
          minWidth: 40,
          maxWidth: 80,
        },
      ]
    },
    unusedBlankPaymentsColumns() {
      return [
        {
          label: this.$t('Bank'),
          prop: 'bank.initials',
          align: 'left',
          minWidth: 40,
          maxWidth: 80,
        },
        {
          label: this.$t('Check No.'),
          prop: 'check.number',
          align: 'left',
          minWidth: 40,
          maxWidth: 120,
        },
        {
          label: this.$t('Check Date'),
          prop: 'check.created_at',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
          component: 'FormattedDate',
        },
        {
          label: this.$t('User'),
          prop: 'user.code',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Account'),
          prop: 'bank.account',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
        },
      ]
    },
    sortBy() {
      return this.options.sort_by
    }
  },
  methods: {
    // Sort by Payment
    addBankGroups(data) {
      data.forEach(this.addBankRows)
    },
    addBankRows(bankGroup) {
      const {
        bank,
        total,
        payments
      } = bankGroup

      this.rows.push({
        header: {
          bank,
        }
      })

      payments.forEach(paymentGroups => {
        if (!Array.isArray(paymentGroups)) {
          paymentGroups.bank = bank
          this.addPaymentRows(paymentGroups)
          return
        }

        paymentGroups.forEach(paymentGroup => {
          paymentGroup.bank = bank
          this.addPaymentRows(paymentGroup)
        })
      })

      this.rows.push({
        subtotal: {
          label: this.$t('Bank Total'),
          value: total,
        }
      })
    },
    // Sort by Fiscal Periods
    addFiscalPeriods(data) {
      data.forEach(this.addFiscalPeriodRows)
    },
    addFiscalPeriodRows(fiscalPeriodGroup) {
      const {
        fiscal_year_period,
        total,
        items
      } = fiscalPeriodGroup

      const fiscalPeriod = {
        year: fiscal_year_period.toString().substring(0, 4),
        period: fiscal_year_period.toString().substring(4),
      }

      this.rows.push({
        header: {
          fiscalPeriod,
        }
      })

      for (const bankGroup of items) {
        this.addBankRows(bankGroup)
      }

      this.rows.push({
        subtotal: {
          label: this.$t('Fiscal Period Total'),
          value: total,
        }
      })
    },
    // Sort by Vendor Payment
    addVendorPaymentGroups(data) {
      data.forEach(this.addVendorPaymentRows)
    },
    addVendorPaymentRows(vendorPaymentGroup) {
      const {
        vendor,
        total,
        payments
      } = vendorPaymentGroup

      this.rows.push({
        header: {
          vendor,
        }
      })

      payments.forEach(paymentGroups => {
        if (!Array.isArray(paymentGroups)) {
          paymentGroups.vendor = vendor
          this.addPaymentRows(paymentGroups)
          return
        }

        paymentGroups.forEach(paymentGroup => {
          paymentGroup.vendor = vendor
          this.addPaymentRows(paymentGroup)
        })
      })
      
      this.rows.push({
        subtotal: {
          label: this.$t('Vendor Total'),
          value: total,
        }
      })
    },
    // Sort by Journal
    addJournalGroups(data) {
      data.forEach(this.addJournalRows)
    },
    addJournalRows(journalGroup) {
      const {
        journal,
        total,
        payments
      } = journalGroup

      this.rows.push({
        header: {
          journal,
        }
      })

      for (const paymentGroup of payments) {
        paymentGroup.journal = journal
        this.addPaymentRows(paymentGroup)
      }

      this.rows.push({
        subtotal: {
          label: this.$t('Journal Total'),
          value: total,
        }
      })
    },
    // Common
    addPaymentRows(paymentGroup) {
      const {
        vendor,
        joint_vendor,
        journal,
        payment,
        invoices,
        bank,
      } = paymentGroup

      const bank_no = payment.is_duplicate
        ? `${bank.initials}*`
        : bank.initials

      const row = {
        bank_no,
        payment_no: payment.number,
        payment_amount: payment.net_amount,
        payment_date: payment.date,
        journal,
        fiscal_period: `${journal?.period} ${journal?.fiscal_year}`,
        joint_vendor,
        vendor,
        direct_deposit: payment.direct_deposit, // TODO: Add direct deposit: pending | batch | history
      }

      if (payment.has_sequence_gap) {
        this.rows.push({
          htmlData: {
            outOfSequence: true,
          },
        })
      }

      this.rows.push(row)

      this.addPaymentInvoiceRows(invoices)
    },
    addPaymentInvoiceRows(invoices) {
      invoices.forEach((invoice, index) => {
        const invoiceDetails = {
          index,
          id: invoice.id,
          number: invoice.number,
          date: invoice.pivot?.created_at,
          description: invoice?.description,
          net_amount: invoice.pivot?.net_amount,
          discount_amount: invoice.pivot?.discount_amount,
        }

        this.rows.push({
          htmlData: {
            invoiceDetails,
          },
        })
      })
    },
    addGrandTotals() {
      const total = this.data.grand_totals

      this.rows.push({
        subtotal: {
          label: this.$t('Grand Total'),
          value: total,
        }
      })
    },
    addDirectDepositLegend() {
      this.rows.push({
        htmlData: {
          directDepositLegend: true,
        },
      })
    },
    addunusedBlankPayments() {
      const unusedBlankPayments = this.data.unused_blank_payments || []

      this.rows.push({
        htmlData: {
          unusedBlankPayments,
        },
      })
    },
    composeRows(data) {
      const dataHandlers = {
        [VendorPaymentRegisterOptions.SortBy.Payment]: this.addBankGroups,
        [VendorPaymentRegisterOptions.SortBy.FiscalPeriods]: this.addFiscalPeriods,
        [VendorPaymentRegisterOptions.SortBy.VendorPayment]: this.addVendorPaymentGroups,
        [VendorPaymentRegisterOptions.SortBy.Journal]: this.addJournalGroups,
        [VendorPaymentRegisterOptions.SortBy.VendorInvoice]: this.addVendorPaymentGroups,
      }

      dataHandlers[this.sortBy]?.(data)

      this.addGrandTotals()

      this.addDirectDepositLegend()

      this.addunusedBlankPayments()

      return this.rows
    },
  }
}
</script>
