<template>
  <div>
    <base-go-to-edit-alert
        v-if="!!existingUnionRateId"
        :path="`/payroll/rates/unions/${existingUnionRateId}/edit`"
        message="Rates for selected union are defined. Please add another code, or edit."
    />
    <base-form :loading="loading"
               :show-back="true"
               :show-cancel="true"
               :focus-on-first-input="!$route.params.id"
               :save-text="$t('Add union rates')"
               :update-text="$t('Update union rates')"
               :submit-disabled="!!existingUnionRateId"
               ref="baseForm"
               layout="vertical"
               submit-button-type="button"
               grid-classes="grid grid-cols-4 gap-x-3"
               @submit="onSubmit"
               @cancel="$router.push('/payroll/rates/unions')">
      <div class="col-span-4 md:col-span-1">
        <code-input
          v-model="model.code"
          :model="model"
          :resource-name="globalResources.UnionCodes"
        />
      </div>
      <div class="col-span-4 md:col-span-3 flex items-center">
        <base-input v-model="model.description"
                    :label="$t('Description')"
                    :name="$t('Description')"
                    id="description"
                    rules="max:150"
                    class="flex-1 mr-3"
        />
        <copy-rates-action v-if="model.id"
                           :loading="copyProgress"
                           @submit="onCopyRates"
                           @close="copyFromUnion = null"
                           @cancel="copyFromUnion = null">
          <union-select
              v-model="copyFromUnion"
              :label="$t('Union Code to Copy From')"
              :add-entity="false"
              :exclude-ids="[model.id]"
              rules="required"

          />
          <base-date-picker
              v-model="model.start_date"
              :label="$t('Start Date')"
              :name="$t('Start Date')"
              id="date"
              rules="required"
              class="col-span-6 md:col-span-3"
          />
        </copy-rates-action>
      </div>
      <div v-if="!onEditMode"
           class="col-span-4 md:col-span-1">
        <base-date-picker v-model="model.start_date"
                          :label="$t('Start Date')"
                          :name="$t('Start Date')"
                          :placeholder="$t('Start Date')"
                          :disabled="!authorizedToUpdateStartDate"
                          :picker-options="getSuggestionsForStartDate"
                          rules="required"
                          id="start_date"
        />
      </div>
      <div class="col-span-4">
        <RatesStartDates
          :key="refreshRatesKey"
          :url-params.sync="startDateParams"
          :id="model.id"
          :show-date-selections="onEditMode"
          :entity-repo="ratesWithStartDate.Unions"
        >
          <UnionAndJobRateEntries
            :data="model"
            :selected-date="startDateParams.start_date"
            :is-union-rate="true"
            api-url="/restify/union-rates"
            ref="entriesTable"
          />
        </RatesStartDates>
      </div>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import CopyRatesAction from '@/modules/payroll/components/rates/CopyRatesAction'
  import RatesStartDates from '@/modules/payroll/components/rates/RatesStartDates'
  import UnionAndJobRateEntries from '@/modules/payroll/components/rates/UnionAndJobRateEntries.vue'
  import { composeSuggestionsForStartDate, ratesWithStartDate } from '@/modules/payroll/components/rates/util'
  import { globalResources } from "@/components/form/util";

  export default {
    inheritAttrs: false,
    components: {
      CopyRatesAction,
      RatesStartDates,
      UnionAndJobRateEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        existingUnionRateId: false,
        ratesWithStartDate,
        loading: false,
        copyProgress: false,
        copyFromUnion: null,
        startDateParams: {},
        refreshRatesKey: 1,
        model: {
          code: '',
          description: '',
          start_date: null,
        },
      }
    },
    computed: {
      getSuggestionsForStartDate() {
        if (!this.data?.attributes?.start_date) {
          const now = new Date()
          return composeSuggestionsForStartDate(now)
        }
        return composeSuggestionsForStartDate(this.data?.attributes?.start_date)
      },
      authorizedToUpdateStartDate() {
        if (!this.model.id) {
          return true
        }
        return this.$isAuthorized('authorizedToUpdateStartDate', this.data)
      },
      onEditMode() {
        return !!this.$route.params.id
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/unions/${this.model.id}`, this.model)
            await this.$refs.entriesTable.storeProgress(this.model.id, this.startDateParams.start_date)

            this.$success(this.$t('Union updated successfully.'))
          } else {
            const { data } = await axios.post('/restify/unions', this.model)
            await this.$refs.entriesTable.storeProgress(data.id, this.model.start_date)

            this.$success(this.$t('Union added successfully.'))
            await this.$router.push(`/payroll/rates/unions/${data.id}/edit`)
          }
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async onCopyRates() {
        try {
          this.copyProgress = true
          await axios.post('/restify/union-rates/actions?action=copy-union-rates-to-union', {
            from: this.copyFromUnion,
            to: this.model.id,
            start_date: this.model.start_date,
          })
          this.refreshRatesTable()
        } catch (err) {
          console.warn(err)
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.copyProgress = false
        }
      },
      refreshRatesTable() {
        this.refreshRatesKey++;

        const { entriesTable } = this.$refs
        if (!entriesTable) {
          return
        }
        entriesTable.refreshEntriesTable(this.model.id)
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
