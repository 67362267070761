<template>
  <div class="flex items-center">
    <slot name="activator" :open="onSendClick">
      <BaseButton
        variant="primary-link"
        class="print:hidden send-email-button"
        v-bind="$attrs"
        @click="onSendClick"
      >
      <span class="flex items-center">
        <MailIcon class="w-4 h-4 mr-2"/>
        <span>
          {{ $t('Send Emails') }}
        </span>
      </span>
      </BaseButton>
    </slot>

    <BaseFormDialog
      v-if="showSendEmailDialog"
      :title="$t('Send Vendor 1099s via Email')"
      :open.sync="showSendEmailDialog"
      size="2xl"
      :appendToBody="true"
      @close="showSendEmailDialog = false"
    >
      <BaseForm
        v-bind="$attrs"
        :save-text="$t('Send')"
        :loading="loading"
        layout="modal"
        :focusOnFirstInput="false"
        :showCancel="true"
        :submitDisabled="submitDisabled"
        @cancel="showSendEmailDialog = false"
        @submit="sendEmail"
      >
        <Vendor1099PrintPaperFormsOptions
          v-model="model"
          :tax_year="tax_year"
          :showTemplateSelect="false"
          :showVendorRangeFilter="false"
          :showFormTypesSelect="false"
          :show-recipient-only-options="true"
          :disabled="true"
          class="col-span-full"
          inputColSpanClass="col-span-2 md:col-span-6 xl:col-span-10"
        />
        <BaseFilterRow
          :title="$t('Tax Year')"
          class="col-span-full"
        >
          <div class="text-gray-600 text-sm">{{ tax_year }}</div>
        </BaseFilterRow>
        <div class="col-span-6">
          <h4>
            {{ $t('Select Vendors') }}
          </h4>
          <div class="text-gray-600 text-sm mb-4">
            ({{ $t('When all selected, only vendor 1099s with valid recipient email addresses will be sent') }})
          </div>
          <AgDataTable
            :pagination="false"
            :tooltipShowDelay="0"
            :data="tableData"
            :columns="columns"
            :selected-rows.sync="selectedRows"
            domLayout="autoHeight"
          />
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import axios from 'axios'
import i18n from "@/i18n";
import { get, set } from "lodash";
import { error } from '@/components/common/NotificationPlugin'
import { ContactModel } from "@/modules/common/types/common";
import { ContactTypes } from "@/enum/enumTypes";
import { Contact } from "@/modules/common/types/models";
import {
  ICellEditorParams,
  ValueFormatterParams,
} from "@ag-grid-community/core";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import {
  Vendor1099sReportOptions,
} from '@/modules/accounts-payable/components/reports/util'
import { MailIcon } from 'vue-feather-icons'
import Vendor1099PrintPaperFormsOptions from '@/modules/accounts-payable/components/vendor1099/Vendor1099PrintPaperFormsOptions.vue'
import Data = API.Data;
import pluralize from 'pluralize';

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  entity: {
    type: String,
  },
  contactEntity: {
    type: String,
  },
  tax_year: {
    type: [Number, String],
    required: true,
  },
  actionUrl: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
})

const showSendEmailDialog = ref(false)
const loading = ref(false)

interface Recipient {
  id: string,
  contacts: ContactModel[],
}

const model = ref({
  tax_year: props.tax_year,
  form_types: [
    Vendor1099sReportOptions.FormTypes.NEC,
    Vendor1099sReportOptions.FormTypes.MISC,
  ],
  copies: [],
  recipients: [] as Recipient[],
})

const tableData = ref<any[]>([])
const selectedRows = ref([])

function initTableData() {
  tableData.value = props.data.map((entry: any) => {
    return {
      ...entry,
      selected_email: getDefaultContact(entry)?.email,
    }
  })
}

watch(() => [props.data, showSendEmailDialog.value], () => {
  initTableData()
}, { immediate : true })

async function onSendClick() {
  showSendEmailDialog.value = true
}

const submitDisabled = computed(() => {
  return !selectedRows.value.length
})

const columns = computed(() => {
  return [
    {
      headerName: i18n.t('Vendor'),
      field: 'vendor.id',
      minWidth: 350,
      maxWidth: 350,
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        return params.data.selected_email && params.data.types?.length
      },
      component: 'VendorLink',
    },
    {
      headerName: i18n.t('Payment Amount'),
      field: 'vendor_payment_amount',
      component: 'FormattedPrice',
      minWidth: 140,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('Form Types'),
      field: 'types',
      minWidth: 140,
      maxWidth: 160,
      valueFormatter: (params: any) => {
        if (!params.value?.length) {
          return 'None'
        }

        const formTypeLabels = {
          [Vendor1099sReportOptions.FormTypes.MISC]: 'MISC',
          [Vendor1099sReportOptions.FormTypes.NEC]: 'NEC',
        }
        return (params.value || []).map((x: string) => formTypeLabels[x]).join(', ')
      },
      cellClass: (params: any) => {
        if (!params.value?.length) {
          return 'text-red-500'
        }

        return ''
      },
      tooltipValueGetter: (params: any) => {
        if (!params.value?.length) {
          return i18n.t('No payments reported on 1099-MISC or 1099-NEC')
        }

        return null
      },
    },
    {
      headerName: i18n.t('NEC Amount'),
      field: 'non_compensation_amount',
      component: 'FormattedPrice',
      minWidth: 140,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('MISC Amount'),
      field: 'misc_amount',
      component: 'FormattedPrice',
      minWidth: 140,
      maxWidth: 160,
    },
    {
      headerName: i18n.t('1099s Total Amount'),
      field: 'total_amount',
      component: 'FormattedPrice',
      minWidth: 100,
      maxWidth: 150,
      cellClass: (params: any) => {

        if (Number(params.data.total_amount) !== Number(params.data.vendor_payment_amount)) {
          return 'text-red-500'
        }

        return ''
      },
      tooltipValueGetter: (params: any) => {
        if (Number(params.value) !== Number(params.data.vendor_payment_amount)) {
          return i18n.t('Total Amount does not match Vendor Payment Amount')
        }

        return null
      },
    },
    {
      headerName: i18n.t('Recipient Email'),
      field: 'selected_email',
      minWidth: 300,
      headerClass: cellClasses.HeaderEditable,
      cellEditor: cellEditors.ContactSelect,
      editable: true,
      cellEditorParams: (params: ICellEditorParams) => {
        return {
          contactableId: get(params.data, `vendor.id`),
          parentEntityName: pluralize(props.contactEntity as string, 2),
          valueKey: 'email',
          labelKey: 'email',
          onEntityCreated(entity: any) {
            const contacts = get(params.data, `vendor.relationships.contacts`, [])
            contacts.push(entity)
            set(params.data, `vendor.relationships.contacts`, contacts)
            params.node?.setData(params.data)
          }
        }
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return i18n.t('Double click to select or add a contact')
        }

        return params.value
      }
    }
  ]
})

function getDefaultContact(row: any) {
  const contacts = get(row, `vendor.relationships.contacts`, [])
  return contacts
    .find((c: Data<Contact>) => c.attributes.type === ContactTypes.Normal && c.attributes.email)
    ?.attributes || {}
}

function getContacts(row: any) {
  const contacts = get(row, `vendor.relationships.contacts`, [])
  return contacts
    .filter((c: Data<Contact>) => c.attributes.email)
    .map((c: Data<Contact>) => c.attributes)
}

function getRowContacts(row: any) {
  const selectedEmail = get(row, 'selected_email')
  return getContacts(row).filter((c: Contact) => c.email === selectedEmail)
}

const emit = defineEmits(['save', 'cancel'])

function getRecipients() {
  return selectedRows.value.map((row: any) => {
    const contacts = getRowContacts(row).map((contact: Contact) => {
      return {
        id: contact.id,
        email: contact.email,
        name: contact.name,
      }
    })

    return {
      id: get(row, `vendor.id`),
      contacts,
    }
  })
}

async function sendEmail() {
  try {
    loading.value = true
    const data = {
      tax_year: model.value.tax_year,
      form_types: model.value.form_types,
      copies: model.value.copies,
      recipients: getRecipients(),
    }

    await axios.post(props.actionUrl, data, {
      params: {
        action: props.actionName,
      }
    })
    // success(i18n.t('Emails are queued to be sent. The selected recipients will receive their email in a couple of minutes.'))
    showSendEmailDialog.value = false
    emit('save')
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(i18n.t('Could not send emails'))
  } finally {
    loading.value = false
  }
}
</script>
