<template>
  <div class="h-full">
    <AgDataTable
      :url="url"
      :columns="columns"
      :url-params="urlParams"
      :add-text="$t('New equipment')"
      :add-entity-in-new-tab="true"
      :per-page="$pagination.list"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :default-match-filters="defaultMatchFilters"
      import-url="/equipment/import"
      ref="table"
      default-sort="code"
      permission="equipment"
      entity="equipment"
      actions="search,refresh,import,export,print,bulk-delete"
      deleteCustom
      @delete="itemToDelete = $event; showDeleteDialog = true"
    >
      <template #address="{row}">
        <AddressLink
          v-if="row?.relationships?.address"
          :row="row?.relationships?.address"
        />
      </template>
      <template #attributes.description="{row}">
        <span class="truncate" :title="row.attributes.description">{{ row.attributes.description }}</span>
      </template>
    </AgDataTable>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.Equipments"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import AddressLink from "@/components/table/cells/AddressLink.vue";
import { computed, ref } from "vue";
import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { ValueSetterParams } from "@ag-grid-community/core";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { equipmentStatusOptions } from "@/modules/equipment/util/equipmentUtils";

const url = '/restify/equipment'

const urlParams = {
  related: 'class[id|code],address',
}

const columns = [
  {
    label: i18n.t('Equipment'),
    prop: 'attributes.code',
    component: 'EntityLink',
    redirectTo: '/equipment/list/{ID}/view',
    minWidth: 110,
    maxWidth: 120,
  },
  {
    label: i18n.t('Class'),
    prop: 'relationships.class.attributes.code',
    component: 'EntityLink',
    redirectTo: '/equipment/settings/classes/{ID}/view',
    entityKey: 'attributes.class_id',
    minWidth: 100,
    maxWidth: 160,
  },
  {
    label: i18n.t('Description'),
    prop: 'attributes.description',
    minWidth: 250,
    flex: 1,
  },
  {
    label: i18n.t('Location'),
    prop: 'address',
    maxWidth: 250,
    minWidth: 160,
  },
  {
    label: i18n.t('Standard Job Cost Hourly Rate'),
    prop: 'attributes.standard_job_cost_hourly_rate',
    component: 'FormattedPrice',
    align: 'right',
    maxWidth: 180,
  },
  {
    label: i18n.t('Acquisition Cost'),
    prop: 'attributes.acquisition_cost',
    component: 'FormattedPrice',
    align: 'right',
    hide: true,
    minWidth: 100,
    maxWidth: 150,
  },
  {
    label: i18n.t('Depreciation Amount'),
    prop: 'attributes.depreciation_amount',
    component: 'FormattedPrice',
    align: 'right',
    hide: true,
    minWidth: 100,
    maxWidth: 150,
  },
  {
    label: i18n.t('Year & Model'),
    prop: 'attributes.year_and_model',
    minWidth: 100,
    maxWidth: 150,
  },
  {
    label: i18n.t('Status'),
    prop: 'attributes.status',
    component: 'Status',
    align: 'center',
    minWidth: 90,
    editable: true,
    cellEditor: cellEditors.Status,
    cellEditorParams: {
      options: equipmentStatusOptions,
    },
    headerClass: cellClasses.HeaderEditable,
    valueSetter: (params: ValueSetterParams) => {
      return valueSetterWithUpdate({
        storeAction: 'equipmentManagement/patchEquipment',
      })(params)
    },
  },
  {
    label: i18n.t('Serial Number'),
    prop: 'attributes.serial_no',
    hide: true,
  },
  {
    label: i18n.t('License Number'),
    prop: 'attributes.license_no',
    hide: true,
  },
]

const defaultMatchFilters = computed(() => {
  return getDefaultMatchFilter('show_inactive_equipment')
})

const itemToDelete = ref(null)
const showDeleteDialog = ref(false)
const table = ref(null)

function onItemDeleted() {
  showDeleteDialog.value = false
  // @ts-ignore
  table.value?.refresh()
}
</script>
