<template>
  <div>
    <employee-form-minimal
        v-if="isProduction"
        :data="employee"
        layout="vertical"
        show-back
    />
    <employee-form-full
        v-else
        v-on="$listeners"
        :data="employee"
        layout="vertical"
        show-back
        @attach-user="showAttachUserDialog = true"
        @re-fetch-employee="reFetchEmployee"
    />
    <InviteEmployeeUser
      v-if="showAttachUserDialog"
      :employee="employee"
      :open.sync="showAttachUserDialog"
      @close="showAttachUserDialog = false"
      @save="onAttachUser"
    />
  </div>
</template>
<script>
  import EmployeeFormFull from "@/modules/payroll/components/employee/EmployeeFormFull";
  import EmployeeFormMinimal from "@/modules/payroll/components/employee/EmployeeFormMinimal";
  import InviteEmployeeUser from "@/modules/payroll/components/employee/InviteEmployeeUser.vue";

  export default {
    components: {
      InviteEmployeeUser,
      EmployeeFormMinimal,
      EmployeeFormFull,
    },
    data() {
      return {
        showAttachUserDialog: false,
      }
    },
    computed: {
      employee() {
        return this.$store.state.payroll.currentEmployee
      },
    },
    methods: {
      async reFetchEmployee() {
        await this.$store.dispatch('payroll/getEmployee', this.$route.params.id)
      },
      async onAttachUser() {
        this.showAttachUserDialog = false
        await this.reFetchEmployee()
      }
    }
  }
</script>
