<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Payment differences')"
  >
    <BaseForm
      layout="vertical"
      :save-text="$t('Transfer to cash on deposit')"
      :show-cancel="true"
      @cancel="$emit('close')"
      @submit="$emit('transfer-to-cash-on-deposit')"
    >
      <div class="col-span-6">
        <p class="prose prose-gray prose-lg max-w-full mb-6">
          {{
            $t('There are payments with differences greater than $0. Check each payment below and choose whether you want to apply a discount or transfer the difference to cash on deposit.')
          }}
        </p>
        <div class="flex justify-between mb-2 font-medium text-sm">
          <span>{{$t('Customer')}}</span>
          <span>{{$t('Difference')}}</span>
        </div>
        <div
          v-for="(payment, index) in data"
          :key="index"
          class="flex justify-between"
        >
        <span>
          <CustomerLink :id="payment.payment?.customer_id"/>
        </span>
          <span>{{ $formatPrice(payment.difference) }}</span>
        </div>
      </div>
      <template #extra-buttons>
        <BaseButton class="ml-2" @click="$emit('apply-discounts')">
          {{ $t('Apply Discounts') }}
        </BaseButton>
      </template>
    </BaseForm>

  </BaseFormDialog>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  }
}
</script>
