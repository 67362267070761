<template>
  <div v-loading="billingLoading">
    <template v-if="currentResource.id">
      <portal to="page-title">
        {{ $t('Billing #') }}{{ currentResource.attributes.number }},&nbsp;@
        <router-link :to="`/accounts-receivable/customers/${customer.id}/view`">
          {{ customer.code }}
          <span v-if="customer.name">({{ customer.name }})</span>
        </router-link>
      </portal>
      <div class="flex justify-between items-center">
        <layout-tabs :items="filteredItems"/>
        <div class="flex items-center">
          <ProofListingButton
              v-if="$isAuthorized('authorizedToPost', currentResource) && $can('billings_update')"
              :selected-rows="[currentResource]"
              :path="getProofListingPath"
          />
          <VoidAction
              v-if="$isAuthorized($actionPolicies.Void, currentResource) && $can('billings_update')"
              :id="currentResource.id"
              entity-name="Billing"
              resource-name="billings"
              @on-action-callback="id => getCurrentResource(id, true)"
          />
          <TableShareButton
            v-if="authorizedToShare"
            :link="getSharableLink"
          />

          <StimulsoftPrintButton
            v-if="stimulsoftEntity"
            :url="`/restify/billings/${currentResource.id}/stimulsoft`"
            :entity="stimulsoftEntity"
            :extraData="printOptions"
            :name="pageTitle"
            :alwaysOpenDialog="true"
            @template-obj-changed="selectedTemplateObj = $event"
          >
            <template
              #extra-data
            >
              <BillingPrintOptions
                :billing="currentResource"
                :billingType="billingType"
                :data="printOptions"
                :selectedTemplateObj="selectedTemplateObj"
              />
            </template>
          </StimulsoftPrintButton>

          <BillingPrintAction
            v-if="isDetailsPage && !stimulsoftEntity"
            :billing-type="billingType"
            :billings="[currentResource.id]"
          />

          <SendEmailBillingAction
            v-if="sendEmailSupportedTypes.includes(billingType) && $can('billings_update')"
            :billing="currentResource"
            :billing-type="billingType"
            :entity="stimulsoftEntity"
            @save="getCurrentResource(currentResource.id, true)"
          />

        </div>
      </div>
      <portal to="entity-name">
        {{ currentResource.attributes.number }}
      </portal>
    </template>
    <router-view :key="renderKey"/>
  </div>
</template>
<script lang="ts">
import layoutPermissions from '@/mixins/layoutPermissions'
import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
import BillingPrintAction from '@/modules/accounts-receivable/layout/billings/BillingPrintAction.vue'
import ProofListingButton from "@/components/common/ProofListingButton.vue";
import { defineComponent } from 'vue';
import SendEmailBillingAction from "@/modules/accounts-receivable/components/billings/SendEmailBillingAction.vue";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import { isBetaTestingOrganization } from "@/modules/common/config";
import BillingPrintOptions from '@/modules/accounts-receivable/components/billings/BillingPrintOptions.vue';

  export default defineComponent({
    mixins: [layoutPermissions],
    components: {
      SendEmailBillingAction,
      BillingPrintAction,
      ProofListingButton,
      BillingPrintOptions,
    },
    props: {
      billingType: {
        type: String,
        default: billingTypes.CostPlus,
      },
    },
    data() {
      return {
        StimulsoftPrintEntities,
        sendEmailSupportedTypes: [
          billingTypes.Progress,
          billingTypes.Service,
          billingTypes.UnitPrice,
          billingTypes.LumpSum,
          billingTypes.CostPlus,
        ],
        renderKey: crypto.randomUUID(),
        printOptions: {
          flags: {
            print_retention: true,
            print_sales_tax: true,
            print_line_items: true,
            print_additional_info: false,
            print_full_line_item: false,
          }
        },
        selectedTemplateObj: null as any,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/accounts-receivable/billings/${this.billingType}/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/accounts-receivable/billings/${this.billingType}/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
            enabled: () => this.$can('billings_update')
          },
        ]
      },
      stimulsoftEntity() {
        if (this.billingType === billingTypes.LumpSum) {
          return this.StimulsoftPrintEntities.LumpSumBilling
        } else if (this.billingType === billingTypes.UnitPrice) {
          return this.StimulsoftPrintEntities.UnitPrice
        } else if (this.billingType === billingTypes.Service) {
          return this.StimulsoftPrintEntities.ServiceInvoice
        } else if (this.billingType === billingTypes.Progress) {
          return this.StimulsoftPrintEntities.ProgressBilling
        } else if (this.billingType === billingTypes.CostPlus) {
          return this.StimulsoftPrintEntities.CostPlus
        }
      },
      getProofListingPath() {
        const pathMap = {
          [billingTypes.Progress]: '/accounts-receivable/billings/progress/proof-listing',
          [billingTypes.UnitPrice]: '/accounts-receivable/billings/unit-price/proof-listing',
          [billingTypes.LumpSum]: '/accounts-receivable/billings/lump-sum/proof-listing',
          [billingTypes.CostPlus]: '/accounts-receivable/billings/cost-plus/proof-listing',
        }
        return pathMap[this.billingType]
      },
      customer() {
        const { customer: customer = {} } = this.currentResource
        return {
          ...customer.attributes,
        }
      },
      currentResource() {
        return this.$store.state.accountsReceivable.currentBilling
      },
      billingLoading() {
        return this.$store.state.accountsReceivable.billingLoading
      },
      pageTitle() {
        const number = this.currentResource?.attributes?.number || ''
        const customerCode = this.customer?.code || ''
        return `${this.$t('Billing')} ${number} @ ${customerCode}`
      },
      authorizedToShare() {
        return this.isDetailsPage && this.billingType === billingTypes.Progress
      },
      getSharableLink() {
        return `${window.origin}/billings/progress/${this.currentResource.id}`
      },
    },
    methods: {
      isBetaTestingOrganization,
      async getCurrentResource(id: string, redirectToViewPage = false) {
        if (!id) {
          id = this.$route.params.id
        }

        await this.$store.dispatch('accountsReceivable/getBilling', id)

        this.renderKey = crypto.randomUUID()

        if (!redirectToViewPage) {
          return
        }
        this.$router.push(`/accounts-receivable/billings/${this.billingType}/${this.$route.params.id}/view`)
      },
    },
    beforeDestroy() {
      this.$store.commit('accountsReceivable/SET_CURRENT_BILLING', {
        attributes: {},
        relationships: {},
        meta: {}
      })
    }
  })
</script>
