<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :append-to-body="true"
  >
    <BaseForm
      :save-text="saveText"
      :loading="loading"
      class="col-span-6"
      layout="modal"
      @submit="onSubmit"
      @cancel="$emit('close')">
      <BaseDatePicker
        v-model="model.older_than_date"
        :label="$t('Older than date')"
        rules="required"
        class="col-span-6"
      />
      <BaseAlert
        v-if="model.older_than_date"
        type="warning"
        class="col-span-6"
      >
        {{ $t('The following action will hide all payroll batches from older than ') }}
        <b>{{ $formatDate(model.older_than_date, LongDateFormat) }}</b>.
        <br>
        {{ $t('Employees that have access to My Checks, will not see the checks older than ') }} <b>{{ $formatDate(model.older_than_date, LongDateFormat) }}</b>
      </BaseAlert>
    </BaseForm>

  </BaseFormDialog>
</template>
<script>
import axios from "axios";
import WarningTip from "@/modules/payroll/components/WarningTip.vue";
import { LongDateFormat } from "@/plugins/dateFormatPlugin";

const Actions = {
  Hide: 'hide',
  Show: 'show'
}
export default {
  components: { WarningTip },
  props: {
    action: {
      type: String,
      default: Actions.Hide
    }
  },
  data() {
    return {
      LongDateFormat,
      loading: false,
      model: {
        older_than_date: null,
      }
    }
  },
  computed: {
    saveText() {
      if (this.action === Actions.Hide) {
        return this.$t('Hide from My Checks')
      }
      return this.$t('Show in My Checks')
    },
    title() {
      if (this.action === Actions.Hide) {
        return this.$t('Hide Payroll Batches from My Checks')
      }
      return this.$t('Show Payroll Batches in My Checks')
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        await axios.post('/restify/payroll-batches/actions?action=hide-older-than-from-my-checks', {
          older_than_date: this.model.older_than_date,
        })
        this.$emit('save')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style>
</style>
