<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Quarter')"
      >
        <BaseSelect
          v-model="model.quarter"
          :add-entity="false"
          :options="quarterOptions"
          class="col-span-3"
        />

      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('State')"
      >
        <StateSelect
          v-model="model.state"
          :add-entity="false"
          :name="$t('State')"
          rules="required"
          class="col-span-3"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-3"
        />
      </BaseFilterRow>
      <template v-if="format === formats.StockPaper">
        <BaseFilterRow
          :title="$t('Name Format')"
        >
          <BaseSelect
            v-model="model.nameFormat"
            :add-entity="false"
            :options="nameFormatOptions"
            class="col-span-3"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Include')"
        >
          <BaseSelect
            v-model="model.include"
            :add-entity="false"
            :options="includeOptions"
            class="col-span-3"
          />
        </BaseFilterRow>
      </template>
      <template v-if="format === formats.MagMediaEFW2_MMREF">
        <BaseFilterRow
          :title="$t('Contact Person')"
        >
          <BaseInput
            v-model="model.contact_person"
            :placeholder="$t('Contact Person')"
            :name="$t('Contact Person')"
            rules="required"
            class="col-span-3"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Contact Email')"
        >
          <BaseInput
            v-model="model.contact_email"
            :placeholder="$t('Contact Email')"
            :name="$t('Contact Email')"
            class="col-span-3"
            rules="required|email"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('File Name')"
        >
          <BaseInput
            v-model="model.file_name"
            :placeholder="$t('File Name (Optional)')"
            :name="$t('File Name')"
            class="col-span-3"
          />
        </BaseFilterRow>
      </template>
    </template>

    <template #table="{ data, loading }">
      <div v-if="isTextFileList">
        <TextFileList
          :data="data?.fileData ? [data.fileData] : []"
          :loading="loading"
          :file-name="data.fileName"
        />
      </div>
      <QuarterlyStateUnemploymentStockPaperReportTable
        v-else-if="format === formats.StockPaper"
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
      <div v-else>
        Not implemented
      </div>
    </template>

  </BaseReportForm>
</template>
<script>
import { quarterOptions } from '@/enum/enums'

import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import QuarterlyStateUnemploymentStockPaperReportTable from "@/modules/payroll/components/reports/quarterly-state-unemployment/QuarterlyStateUnemploymentStockPaperReportTable.vue";
import {
  QuarterlyStateUnemploymentOptions
} from '@/modules/payroll/components/reports/util'

export default {
  components: {
    TextFileList,
    QuarterlyStateUnemploymentStockPaperReportTable,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    preNoticeFormat: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      quarterOptions,
      formats: QuarterlyStateUnemploymentOptions.Formats,
      format: QuarterlyStateUnemploymentOptions.Formats.StockPaper,
      model: {
       quarter: 1,
       state: null,
       include: QuarterlyStateUnemploymentOptions.Include.None,
       nameFormat: QuarterlyStateUnemploymentOptions.NameFormats.FirstLast,
        contact_person: '',
        contact_email: '',
        file_name: '',
      },
    }
  },
  computed: {
    reportNumber() {
      const reportMap = {
        [QuarterlyStateUnemploymentOptions.Formats.CaliforniaDe9C]: '4878he',
        [QuarterlyStateUnemploymentOptions.Formats.MagMediaEFW2_MMREF]: '4878fe',
        [QuarterlyStateUnemploymentOptions.Formats.StockPaper]: '4878',
        default: '4878'
      }
      return reportMap[this.format] || reportMap.default
    },
    formatOptions() {
      const formatOptions = [
        {
          label: this.$t('Stock Paper'),
          value: QuarterlyStateUnemploymentOptions.Formats.StockPaper,
        },
        {
          label: this.$t('Mag Media-EFW2/MMREF'),
          value: QuarterlyStateUnemploymentOptions.Formats.MagMediaEFW2_MMREF,
          statesUnavailable: ['CA'],
        },
        {
          label: this.$t('California DE9/C'),
          value: QuarterlyStateUnemploymentOptions.Formats.CaliforniaDe9C,
          statesAvailable: ['CA'],
        }
      ]

      return formatOptions.filter(option => {
        if (option.statesAvailable) {
          return option.statesAvailable.includes(this.model.state)
        }

        if (option.statesUnavailable) {
          return !option.statesUnavailable.includes(this.model.state)
        }
        return true
      })
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    nameFormatOptions() {
      return [
        {
          label: this.$t('First Name, Last Name'),
          value: QuarterlyStateUnemploymentOptions.NameFormats.FirstLast,
        },
        {
          label: this.$t('Last Name, First Name'),
          value: QuarterlyStateUnemploymentOptions.NameFormats.LastFirst,
        }
      ]
    },
    includeOptions() {
      return [
        {
          label: this.$t('None'),
          value: QuarterlyStateUnemploymentOptions.Include.None,
        },
        {
          label: this.$t('Hours Worked'),
          value: QuarterlyStateUnemploymentOptions.Include.HoursWorked,
        },
        {
          label: this.$t('Weeks Worked'),
          value: QuarterlyStateUnemploymentOptions.Include.WeeksWorked,
        },
      ]
    },
    isTextFileList() {
      return [
        this.formats.CaliforniaDe9C,
        this.formats.MagMediaEFW2_MMREF,
      ].includes(this.format)
    },
  },
  watch: {
    ['model.state'](state) {
      this.format = this.formatOptions[0]?.value
    },
    currentUser: {
      handler() {
        this.model.contact_person = this.currentUser?.name
        this.model.contact_email = this.currentUser?.email
      },
      immediate: true,
    },
  },
}
</script>
