import i18n from "@/i18n";
import { laborHourTypes } from "@/enum/enums";

export const sourceTypes = {
  Subcontract: 'job',
  Equipment: 'equipment',
  Labor: 'craft-code',
  Material: 'material',
  WorkOrder: 'work-order',
  PurchaseOrder: 'purchase-order',
  Optional: null,
}

export const sourceTypeAbbrMap = {
  SUB: sourceTypes.Optional,
  EQP: sourceTypes.Equipment,
  LAB: sourceTypes.Labor,
  MTL: sourceTypes.Material,
}
// * Mapped by sb type line type
export const additionalSourceTypeMap = {
  equipment: 'equipment',
  material: 'material',
  labor: 'craft-code',
  default: '',
}

export const lineTypeOptions = [
  {
    label: 'Equipment',
    value: 'equipment',
  },
  {
    label: 'Material',
    value: 'material',
  },
  {
    label: 'Labor',
    value: 'labor',
  },
]

export const workSiteTypes = {
  Home: 'home',
  Apartment: 'apartment',
  Business: 'business',
}

export const workSiteTypeLabels = {
  [workSiteTypes.Home]: i18n.t('Home'),
  [workSiteTypes.Apartment]: i18n.t('Apartment'),
  [workSiteTypes.Business]: i18n.t('Business'),
}

export const sbServiceCodeMorphKey = 'service-billing-code'

export const TransactionTypes = {
  Burden: 'burden',
  OtherPay: 'other-pay',
  RegularHours: 'regular-hours',
  OvertimeHours: 'overtime-hours',
  PremiumHours: 'premium-hours',
  NA: 'n/a',
}

export const TransactionTypeLabels = {
  [TransactionTypes.Burden]: 'Burden',
  [TransactionTypes.RegularHours]: 'Hour R',
  [TransactionTypes.PremiumHours]: 'Hour P',
  [TransactionTypes.OvertimeHours]: 'Hour O',
  [TransactionTypes.OtherPay]: 'Other Pay',
  [TransactionTypes.OtherPay]: 'Other Pay',
  [TransactionTypes.NA]: '',
}

export const laborHourTypeOptions = [
  {
    label: i18n.t('?'),
    value: null,
  },
  {
    label: i18n.t('Reg Hours'),
    value: laborHourTypes.Regular,
  },
  {
    label: i18n.t('Ovt Hours'),
    value: laborHourTypes.Overtime,
  },
  {
    label: i18n.t('Prm Hours'),
    value: laborHourTypes.Premium,
  },
]

export function getLaborHourAbbr(value) {
  const map = {
    default: '?',
    [laborHourTypes.Regular]: 'REG',
    [laborHourTypes.Overtime]: 'OVT',
    [laborHourTypes.Premium]: 'PRM',
  }
  return map[value] || map.default
}
