export const agedByViewFormats = {
  EachInvoice: 'each-invoice',
  VendorTotal: 'vendor-total',
  JobTotal: 'job-total',
}

export const reportNumbers = {
  AgedByVendor: 3882,
  AgedByJob: 3883,
}

export const vendorHistoryFormats = {
  ByVendor: 'by-vendor',
  ByJob: 'by-job',
  Payments: 'payments',
  CostType: 'cost-type',
}

export const VendorPaymentRegisterOptions = {
  SortBy: {
    Payment: 'payment',
    FiscalPeriods: 'fiscal_periods',
    VendorPayment: 'vendor_payment',
    Journal: 'journal',
    VendorInvoice: 'vendor_invoice',
  },
  SelectBy: {
    PaymentDates: 'payment_dates',
    FiscalPeriods: 'fiscal_periods',
    AllPayments: 'all_payments',
    DirectDeposit: 'direct_deposit',
  }
}

export const Vendor1099sReportOptions = {
  Formats: {
    Vendor1099s_ProofListing: 'vendor_1099s_proof_listing',
    Vendor1099s_PaperForms: 'vendor_1099s_paper_forms',
  },
  FormTypes: {
    NEC: 'nec',
    MISC: 'misc',
  },
  TemplateTypes: {
    1099: '1099',
    1096: '1096',
  },
  Copies: {
    A: 'A',
    One: '1',
    B: 'B',
    Two: '2',
    C: 'C',
  }
}

export function get1099EntryTotalAmount(entry) {
  return [
    'non_compensation_amount',
    'rent_amount',
    'royalties_amount',
    'other_income_amount',
    'fit_amount',
    'health_care_amount',
    'attorney_fees_amount',
    'sit_amount',
  ].reduce((acc, key) => acc + (Number(entry[key] || 0)), 0)
}
