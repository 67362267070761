<template>
  <base-report-form
      ref="reportForm"
      :filters="model"
      :report-number="reportNumber"
      @data-fetch="onDataFetched"
  >

    <template #filters>

      <base-filter-row :title="$t('View')">
        <base-select
            v-model="model.sort_by"
            :add-entity="false"
            :options="sortByOptions"
            class="col-span-2"
            @change="onChangeSortType"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Format')">

        <base-select
          v-model="model.format"
          :options="formatOptions"
          @change="onFormatChange"
          class="col-span-2"
        />

        <div class="col-span-3 flex items-baseline">
          <span class="text-label">{{ $t('Report Date') }}</span>
          <base-date-picker
            v-model="model.report_date"
            class="w-[300px]"
          />
        </div>

      </base-filter-row>

      <base-filter-row
          :title="$t('Print')"
      >
        <template v-if="model.format !== formats.WH347">
          <div class="col-span-10 flex">
            <base-checkbox
              v-model="model.print_name"
              :label="$t('Print Name')"
              id="print_name"
            />
            <base-checkbox
              v-model="model.print_address"
              :label="$t('Print Address')"
              id="print_address"
            />
            <base-checkbox
              v-model="model.print_filing_status"
              :label="$t('Filing Status')"
              id="print_address"
            />
          </div>
          <div class="col-span-2"></div>
          <div class="col-span-10 flex mb-2">
            <base-checkbox
              v-model="model.include_certified_payroll"
              :label="$t('Print Certified Payroll')"
              id="include_certified_payroll"
            />
            <base-checkbox
              v-model="model.include_compliance_statement"
              :label="$t('Compliance Statement')"
              id="include_compliance_statement"
            />
            <base-checkbox
              v-model="model.include_non_performance"
              :label="$t('Non-Performance')"
              id="include_non_performance"
            />
            <BaseCheckbox
              v-model="model.expand_sub_trades"
              :label="$t('Expand Sub Trades')"
              id="expand_sub_trades"
            />
          </div>
          <div class="col-span-2"></div>
        </template>
        <div class="col-span-10 flex mb-4">
          <base-checkbox
            v-model="model.print_total_hours_worked"
            :label="$t('Total Weekly Hours Worked')"
            id="print_total_hours_worked"
          />

          <base-checkbox
            v-model="model.print_customer_info"
            :label="$t('Customer Info')"
            id="print_customer_info"
          />
          <base-checkbox
            v-if="sortByJob"
            v-model="model.print_payroll_number"
            :label="$t('Payroll Number')"
            id="print_payroll_number"
          />
        </div>

      </base-filter-row>

      <base-filter-row :title="$t('Contract Number')">
        <base-select
          v-model="model.contract_number"
          :options="contractNumberOptions"
          class="col-span-2"
          :placeholder="$t('Contract Number')"
        />
      </base-filter-row>
      <base-filter-row :title="$t('Display')">
        <div class="col-span-4 flex items-baseline">
          <base-input
              v-model="model.name"
              :rules="nameAndTitleRequired ? 'required': undefined"
              :name="$t('Name')"
              :placeholder="$t('Name')"
              class="flex-1"
          />
        </div>
        <div class="col-span-4 flex items-baseline">
          <base-input
              v-model="model.title"
              :rules="nameAndTitleRequired ? 'required': undefined"
              :name="$t('Title')"
              :placeholder="$t('Title')"
              class="flex-1"
          />
        </div>
        <template v-if="model.format === formats.WH347">
          <div class="md:col-span-8 xl:col-span-12"/>
          <div class="col-span-2"/>
          <div class="col-span-4 flex items-baseline">
            <base-textarea
              v-model="model.remarks"
              :name="$t('Remarks')"
              :placeholder="$t('Remarks')"
              class="flex-1"
            />
          </div>
        </template>
      </base-filter-row>

      <job-range-filter
          v-if="sortByJob"
          v-model="model"
          :url-params="certifiedPayrollFilters"
      >
        <div class="col-span-2 flex items-baseline">
          <span class="text-label">{{ $t('Status') }}</span>
          <base-select
              v-model="model.job_statuses"
              :add-entity="false"
              :options="jobStatusOptions"
              class="flex-1"
              rules="required"
              collapse-tags
              multiple
          />

        </div>
      </job-range-filter>

      <work-order-range-filter
          v-else
          v-model="model"
          :url-params="certifiedPayrollFilters"
      >
        <div class="col-span-2 flex items-baseline">
          <span class="text-label">{{ $t('Status') }}</span>
          <base-select
              v-model="model.work_order_statuses"
              :add-entity="false"
              :options="workOrderStatusOptions"
              class="flex-1"
              rules="required"
              collapse-tags
              multiple
          />
        </div>

      </work-order-range-filter>

      <payroll-dates-filter v-model="model"/>

      <base-filter-row>
        <base-checkbox
            v-model="model.itemize_other_direct_pay"
            :label="$t('Itemize `Other Direct Pay` in Job Summary')"
            class="col-span-4 mb-4"
            id="itemize_other_direct_pay"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <certified-payroll-report-table
          v-if="model.format === formats.Detail"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :sort-by="model.sort_by"
          ref="reportTable"
      />
      <div v-else-if="[formats.CA_CPR_XML, formats.WA_PWC_PR].includes(model.format)">
        <XmlFileList :data="data" :loading="loading"/>
      </div>
      <div v-else-if="[formats.LCP_TRACKER].includes(model.format)">
        <ExcelFileList :data="data" :loading="loading"/>
      </div>
      <div v-else-if="[formats.WH347].includes(model.format)">
        <StimulsoftPrintButton
          ref="printButton"
          :data="data"
          :hide-button="true"
          :entity="StimulsoftPrintEntities.CertifiedPayroll"
          @close="onPrintPreviewClose"
        />
      </div>

    </template>

  </base-report-form>
</template>
<script>
  import { jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import { JobRangeFilter, WorkOrderRangeFilter } from '@/components/range-filters'
  import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
  import {
    selectionDateFields,
    certifiedPayloadFormats,
    certifiedPayrollFilters, certifiedContractTypes,
  } from '@/modules/payroll/components/reports/util'
  import CertifiedPayrollReportTable from '@/modules/payroll/components/reports/CertifiedPayrollReportTable'
  import XmlFileList from "@/modules/payroll/components/reports/XmlFileList.vue";
  import FilePreviewDetails from "@/modules/common/components/documents/FilePreviewDetails.vue";
  import ExcelFileList from "@/modules/payroll/components/reports/ExcelFileList.vue";
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
  import { $modules } from "@/enum/enums";
  import { setSettingValue } from "@/plugins/settingsAndEnumsPlugin";

  const formats = {
    Detail: 'detail',
    WA_PWC_PR: 'wa_pwc_pr',
    CA_CPR_XML: 'ca_cpr_xml',
    LCP_TRACKER: 'lcp_tracker',
    WH347: 'wh_347',
  }

  export default {
    components: {
      ExcelFileList,
      FilePreviewDetails,
      XmlFileList,
      JobRangeFilter,
      PayrollDatesFilter,
      WorkOrderRangeFilter,
      CertifiedPayrollReportTable,
    },
    data() {
      return {
        certifiedPayrollFilters,
        jobStatusOptions,
        model: {
          sort_by: certifiedPayloadFormats.Job,
          print_name: true,
          print_address: false,
          print_filing_status: true,
          contract_number: 'none',
          include_certified_payroll: true,
          include_compliance_statement: true,
          include_non_performance: true,
          expand_sub_trades: true,
          print_total_hours_worked: false,
          name: this.$settings($modules.PR, 'certified_payroll_name') || '',
          title: this.$settings($modules.PR, 'certified_payroll_title') || '',
          remarks: '',
          print_customer_info: false,
          print_payroll_number: true,
          expand_sub_trades: true,

          job_number_from: null,
          job_number_to: null,
          job_statuses: [this.$resourceStatuses.Open],

          work_order_number_from: null,
          work_order_number_to: null,
          work_order_statuses: [this.$resourceStatuses.Open],

          format: formats.Detail,
          report_date: this.$now,
          itemize_other_direct_pay: false,

          ...selectionDateFields,
        },
        filePreviewData: null,
        formats,
        contractNumberOptions: certifiedContractTypes,
        sortByOptions: [
          {
            label: this.$t('Jobs'),
            value: certifiedPayloadFormats.Job,
          },
          {
            label: this.$t('Work Orders'),
            value: certifiedPayloadFormats.WorkOrder,
          },
        ],
        workOrderStatusOptions: [
          {
            label: this.$t('Open'),
            value: this.$resourceStatuses.Open,
          },
          {
            label: this.$t('Closed'),
            value: this.$resourceStatuses.Closed,
          },
        ],
        formatOptions: [
          {
            label: this.$t('Details'),
            value: formats.Detail,
          },
          {
            label: this.$t('CA CPR XML'),
            value: formats.CA_CPR_XML,
          },
          {
            label: this.$t('WA PWCPR'),
            value: formats.WA_PWC_PR,
          },
          {
            label: this.$t('LCPtracker'),
            value: formats.LCP_TRACKER,
          },
          {
            label: this.$t('WH-347'),
            value: formats.WH347,
          },
        ],
      }
    },
    computed: {
      StimulsoftPrintEntities() {
        return StimulsoftPrintEntities
      },
      sortByJob() {
        return this.model.sort_by === certifiedPayloadFormats.Job
      },
      reportNumber() {
        const reportMap = {
          [formats.WA_PWC_PR]: '4886i',
          [formats.CA_CPR_XML]: '4886f',
          [formats.LCP_TRACKER]: '4886a',
          [formats.WH347]: '4886w',
          [formats.Detail]: '4886',
          default: '4886'
        }
        return reportMap[this.model.format] || reportMap.default
      },
      nameAndTitleRequired() {
        return this.model.format === formats.WH347 || this.model.include_compliance_statement || this.model.include_non_performance
      },
    },
    methods: {
      onChangeSortType() {
        this.model.job_statuses = this.model.work_order_statuses = [this.$resourceStatuses.Open]
        this.model.job_number_from = this.model.job_number_to = null
        this.model.work_order_number_from = this.model.work_order_number_to = null
      },
      onFormatChange(format) {
        if (format === formats.WH347) {
          this.model.print_address = true
          this.model.include_compliance_statement = false
          this.model.include_non_performance = false
          this.model.print_payroll_number = true
          this.model.expand_sub_trades = false
          this.model.contract_number = 'project_number'
        } else {
          this.model.print_address = false
          this.model.include_compliance_statement = true
          this.model.include_non_performance = true
          this.model.print_payroll_number = false
          this.model.expand_sub_trades = true
          this.model.contract_number = 'none'
        }
      },
      async onDataFetched() {
        if (this.model.name) {
          setSettingValue($modules.PR, 'certified_payroll_name', this.model.name)
        }
        if (this.model.title) {
          setSettingValue($modules.PR, 'certified_payroll_title', this.model.title)
        }
        if (this.model.format !== formats.WH347) {
          return
        }
        await this.$nextTick()
        this.$refs.printButton?.triggerPreview()
      },
      onPrintPreviewClose() {
        if (!this.$refs.reportForm) {
          return
        }
        this.$refs.reportForm.showReportTable = false
      }
    },
  }
</script>
