import i18n from "@/i18n";
import { workOrderStatuses } from "@/enum/enums";

export const SBHistorySortBy = {
  Account: 'account',
  Journal: 'journal',
  WorkOrder: 'work_order',
  Worksite: 'worksite',
  Customer: 'customer',
}

export const WOSortBy = {
  WorkOrder: 'work_order',
  Customer: 'customer',
  Worksite: 'worksite',
  Assigned: 'assigned',
  SalesPerson: 'salesperson',
  WorkOrderDate: 'work_order_date',
  Category: 'category',
}

export const WODetailSortBy = {
  WorkOrder: 'work_order',
  Worksite: 'worksite',
  Journal: 'journal',
  Customer: 'customer',
}

export const WOBilledStatus = {
  All: 'all',
  Billed: 'billed',
  Unbilled: 'unbilled',
}

export function getWOBilledStatusOptions() {
  return [
    {
      label: i18n.t('All'),
      value: WOBilledStatus.All
    },
    {
      label: i18n.t('Billed Only'),
      value: WOBilledStatus.Billed
    },
    {
      label: i18n.t('Unbilled Only'),
      value: WOBilledStatus.Unbilled
    }
  ]
}

export function getWorkOrderDetailSortOptions() {
  return [
    { label: i18n.t('Work Order'), value: WODetailSortBy.WorkOrder },
    { label: i18n.t('Customer'), value: WODetailSortBy.Customer },
    { label: i18n.t('Worksite'), value: WODetailSortBy.Worksite },
    { label: i18n.t('Journal'), value: WODetailSortBy.Journal },
  ]
}

export function getWorkOrderSortOptions() {
  return [
    { label: i18n.t('Work Order'), value: WOSortBy.WorkOrder },
    { label: i18n.t('Customer'), value: WOSortBy.Customer },
    { label: i18n.t('Worksite'), value: WOSortBy.Worksite },
    { label: i18n.t('Assigned Employee'), value: WOSortBy.Assigned },
    { label: i18n.t('Sales Person'), value: WOSortBy.SalesPerson },
    { label: i18n.t('Work Order Date'), value: WOSortBy.WorkOrderDate },
    { label: i18n.t('Category'), value: WOSortBy.Category },
  ]
}

export function getSBHistorySortOptions() {
  return [
    { label: i18n.t('Account'), value: SBHistorySortBy.Account },
    { label: i18n.t('Journal'), value: SBHistorySortBy.Journal },
    { label: i18n.t('Work Order'), value: SBHistorySortBy.WorkOrder },
    { label: i18n.t('Worksite'), value: SBHistorySortBy.Worksite },
    { label: i18n.t('Customer'), value: SBHistorySortBy.Customer },
  ]
}

export function getSBHistorySortOption(value) {
  return getSBHistorySortOptions().find(option => option.value === value)?.label || value
}

export function getWorkOrderStatusOptions() {
  return [
    { label: i18n.t('Open'), value: workOrderStatuses.Open },
    { label: i18n.t('Closed'), value: workOrderStatuses.Closed },
    { label: i18n.t('Inactive'), value: workOrderStatuses.Inactive },
    { label: i18n.t('Completed'), value: workOrderStatuses.Completed },
    { label: i18n.t('Hold Parts'), value: workOrderStatuses.HoldParts },
    { label: i18n.t('Hold Other'), value: workOrderStatuses.HoldOther },
  ]
}

export const IncludeOptions = {
  All: 'all',
  Cost: 'cost',
  Billed: 'billed',
}

export function getIncludeOptions() {
  return [
    { label: i18n.t('Cost & Billing'), value: IncludeOptions.All },
    { label: i18n.t('Cost Only'), value: IncludeOptions.Cost },
    { label: i18n.t('Billing Only'), value: IncludeOptions.Billed },
  ]

}

export const WorkOrderDetailFormats = {
  Detail: 'detail',
  WorkOrderTotals: 'work_order_totals',
  SummaryTotals: 'summary_totals',
}
