<template>
  <div>
    <AgDataTable
        url="/restify/financial-reports"
        actions="search,refresh,add,edit,delete"
        entity="financial-reports"
        :columns="columns"
        :add-text="$t('New Financial Report')"
        :add-entity-in-new-tab="true"
        :map-duplicate-row="mapDuplicateRow"
        @grid-ready="grid = $event"
    />
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import i18n from "@/i18n";
  import { FinancialReportType, FinancialReportTypeLabels } from "@/modules/ledger/enum/financialReports";
  import { ValueFormatterParams } from '@ag-grid-community/core'
  import { FinancialReport } from "@/modules/common/types/models";
  import { dateTypes } from "@/plugins/dateFormatPlugin";
  import Data = API.Data;
  import axios from "axios";
  import {error} from "@/components/common/NotificationPlugin";

  const columns = computed<Column<Data<FinancialReport>>[]>(() => {
    return [
      {
        headerName: i18n.t('Code'),
        field: 'attributes.code',
        minWidth: 80,
      },
      {
        headerName: i18n.t('Description'),
        field: 'attributes.description',
        minWidth: 180,
      },
      {
        headerName: i18n.t('Type'),
        field: 'attributes.type',
        minWidth: 180,
        valueFormatter: (params: ValueFormatterParams) => {
          const value = params.value as FinancialReportType
          return FinancialReportTypeLabels[value] || ''
        }
      },
      {
        headerName: i18n.t('Last Updated'),
        field: 'attributes.updated_at',
        minWidth: 160,
        component: 'FormattedDate',
        cellRendererParams: {
          format: dateTypes.DateTime,
        }
      }
    ]
  })

  const grid = ref()

  function mapDuplicateRow(row: Data<any>) {
    row.attributes.description = `${row.attributes.description} (Copy)`
    row.attributes.code = `${row.attributes.code}C`
    copyReport(row)
    return row
  }

  async function copyReport(row: any) {
    try {
      const res = await axios.post(`/restify/financial-reports`, {
        ...row.attributes,
        id: undefined,
      })
      grid.value?.api?.applyTransaction({
        remove: [row]
      })
      grid.value?.api?.applyTransaction({
        add: [res.data],
        addIndex: 0,
      })
    } catch (err: any) {
      if (err.handled) {
        return
      }
      error(i18n.t('Failed to copy financial report'))
    }
  }
</script>
