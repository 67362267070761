<template>
  <div>
    <Portal
      v-if="year"
      to="page-title"
    >
      {{ $t(`Vendor 1099s - Calendar Year`) }} {{ year }}
      -
      {{ $t('Payments to Designated 1099 Vendors') }}
    </Portal>
    <div class="sm:rounded-md overflow-hidden">
      <SelectVendorsByFilter
        :availableVendorIds="selectByAvailableVendorIds"
        @selection:add="updateSelection($event, true)"
        @selection:remove="updateSelection($event, false)"
      />
      <AgDataTable
        v-bind="editableTableProps"
        :key="`${gridKey}_${showVendors}`"
        :authorizeToCopyLastRow="false"
        :tooltipShowDelay="0"
        ref="table"
        :data="filteredTableData"
        :columns="columns"
        :dataLoading="loading"
        actions="search,refresh"
        @refresh-click="loadData"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="grid = $event"
      >
        <template #extra-actions="{ row }">
          <TablePrintButton
            v-if="row.is_selected && row.types?.length"
            hasCallback
            :showLabel="false"
            @on-action-callback="onPrintPaperForms(row)"
          />

          <Vendor1099SendBulkEmailForm
            v-if="row.is_selected && row.types?.length"
            :data="[row]"
            :tax_year="year"
            entity="vendor1099-batch"
            contact-entity="vendor"
            action-url="/restify/vendor1099s/actions"
            action-name="send-vendor-1099s-mail"
          >
            <template #activator="{ open }">
              <BaseButton
                variant="primary-link"
                size="xs"
                class="-ml-4"
                @click="open"
              >
                <span class="flex items-center">
                  <MailIcon class="w-4 h-4" />
                </span>
              </BaseButton>
            </template>
          </Vendor1099SendBulkEmailForm>
        </template>
        <template #additional-actions-before>
          <ProofListingButton
            :path="`/accounts-payable/reports/vendor-1099s?tax_year=${year}`"
          >
            <span class="text-sm">
              {{ $t('Proof Listing') }}
            </span>
          </ProofListingButton>

          <TablePrintButton
            hasCallback
            @on-action-callback="showPrintDialog = true"
          >
            <div class="ml-2 text-sm -mr-4">{{ $t('Print Paper Forms') }}</div>
          </TablePrintButton>

          <Vendor1099SendBulkEmailForm
            v-if="year"
            :data="selectedVendors"
            :tax_year="year"
            entity="vendor1099-batch"
            contact-entity="vendor"
            action-url="/restify/vendor1099s/actions"
            action-name="send-vendor-1099s-mail"
          />

          <Vendor1099ExportDialog
            v-if="year"
            :batch_id="batchId"
            :data="selectedVendors"
            :tax_year="year"
          />

          <BaseSelect
            v-model="showVendors"
            :label="$t('Show')"
            :placeholder="$t('Show')"
            :options="showVendorsOptions"
          />
        </template>
      </AgDataTable>
      <Vendor1099PrintPaperFormsDialog
        v-if="showPrintDialog"
        :open.sync="showPrintDialog"
        :tax_year="year"
        :batch_id="batchId"
        :filterableVendorIds="selectByAvailableVendorIds"
        :data="selectedVendors"
        @close="showPrintDialog = false"
      />

      <Vendor1099PrintPaperFormsDialog
        v-if="showEntryPrintDialog"
        :open.sync="showEntryPrintDialog"
        :tax_year="year"
        :vendor="entryToPrint?.vendor"
        :_1099_entry_ids="[entryToPrint?.id]"
        :showVendorRangeFilter="false"
        @close="showEntryPrintDialog = false"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { editableTableProps } from "@/components/ag-grid/tableUtils";
import { cellClasses } from '@/components/ag-grid/columnUtils';
import { vendor1099FormTypes } from "@/enum/enums"
import SelectVendorsByFilter from '@/modules/accounts-payable/components/vendor1099/SelectVendorsByFilter.vue'
import Vendor1099SendBulkEmailForm from '@/modules/accounts-payable/components/vendor1099/Vendor1099SendBulkEmailForm.vue'
import Vendor1099ExportDialog from '@/modules/accounts-payable/components/vendor1099/Vendor1099ExportDialog.vue'
import {
  Vendor1099sReportOptions,
  get1099EntryTotalAmount,
} from '@/modules/accounts-payable/components/reports/util'
import Vendor1099PrintPaperFormsDialog from '@/modules/accounts-payable/components/vendor1099/Vendor1099PrintPaperFormsDialog.vue'
import { MailIcon } from 'vue-feather-icons'
function isAmountEditable(vendor1099Entry) {
  return vendor1099Entry.is_selected
}

const showVendorsTypes = {
  All: 'all',
  MinPayment600: 'min-payment-600'
}

export default {
  components: {
    SelectVendorsByFilter,
    Vendor1099PrintPaperFormsDialog,
    MailIcon,
    Vendor1099SendBulkEmailForm,
    Vendor1099ExportDialog,
  },
  data() {
    return {
      Vendor1099sReportOptions,
      showVendors: showVendorsTypes.MinPayment600,
      editableTableProps,
      loading: true,
      vendors: [],
      vendor1099sEntries: [],
      tableData: [],
      defaultVendor1099Values: {
        is_selected: false,
        is_box_2_resale: false,
        is_box_7_resale: false,
        non_compensation_amount: 0,
        rent_amount: 0,
        royalties_amount: 0,
        other_income_amount: 0,
        fit_amount: 0,
        health_care_amount: 0,
        attorney_fees_amount: 0,
        sit_amount: 0,
        total_amount: 0,
      },
      gridKey: 1,
      grid: null,
      showVendorsOptions: [
        {
          label: this.$t('All Vendors'),
          value: showVendorsTypes.All,
        },
        {
          label: this.$t('Min Payment $600'),
          value: showVendorsTypes.MinPayment600,
        }
      ],
      showPrintDialog: false,
      showEntryPrintDialog: false,
      entryToPrint: null,
    }
  },
  computed: {
    batchId() {
      return this.$route.params.id
    },
    batch() {
      return this.$store.state.accountsPayable.currentVendor1099Batch
    },
    year() {
      return this.batch.attributes.year
    },
    columns() {
      return [
        {
          headerName: this.$t('Vendor'),
          field: 'vendor_id',
          pinned: 'left',
          minWidth: 140,
          maxWidth: 180,
          component: 'VendorLink',
        },
        {
          headerName: this.$t('Selected'),
          field: 'is_selected',
          pinned: 'left',
          minWidth: 80,
          maxWidth: 80,
          component: 'Status',
          editable: true,
          cellEditor: cellEditors.Boolean,
        },
        {
          headerName: this.$t('Payment Amount'),
          field: 'vendor_payment_amount',
          minWidth: 140,
          maxWidth: 180,
          cellClass: cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('1. Non-Empl Compensation'),
          field: 'non_compensation_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('2. Resale Checkbox 2'),
          field: 'is_box_2_resale',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          cellEditor: cellEditors.Boolean,
          component: 'Status',
        },
        {
          headerName: this.$t('1. Rents'),
          field: 'rent_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('2. Royalties'),
          field: 'royalties_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('3. Other Income'),
          field: 'other_income_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('4. FIT Withheld'),
          field: 'fit_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('6. Medical & Health Pay'),
          field: 'health_care_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('7. Resale Checkbox 7'),
          field: 'is_box_7_resale',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          cellEditor: cellEditors.Boolean,
          component: 'Status',
        },
        {
          headerName: this.$t('10. Attorney Proceeds'),
          field: 'attorney_fees_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('5/16. State Tax Withheld'),
          field: 'sit_amount',
          minWidth: 130,
          maxWidth: 140,
          editable: (params) => isAmountEditable(params.data),
          cellClass: (params) => isAmountEditable(params.data) ? '' : cellClasses.ReadOnly,
          component: 'FormattedPrice',
        },
        {
          headerName: this.$t('Total'),
          field: 'total_amount',
          pinned: 'right',
          cellClass: (params) => {
            const classes = [
              cellClasses.ReadOnly,
            ]
            
            const totalAmount = get1099EntryTotalAmount(params.data)

            if (Number(totalAmount) !== Number(params.data.vendor_payment_amount)) {
              classes.push('text-red-500')
            }

            return classes.join(' ')
          },
          tooltipValueGetter: (params) => {
            if (Number(params.value) !== Number(params.data.vendor_payment_amount)) {
              return this.$t('Total Amount does not match Vendor Payment Amount')
            }

            return null
          },
          minWidth: 130,
          valueGetter: (params) => {
            return get1099EntryTotalAmount(params.data)
          },
          valueFormatter: (params) => {
            return this.$formatPrice(params.value)
          }
        },
        {
          label: this.$t('Sent Count'),
          prop: 'telemetry.send',
          pinned: 'right',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
          hide: true,
        },
        {
          label: this.$t('Download Count'),
          prop: 'telemetry.download',
          pinned: 'right',
          maxWidth: 100,
          align: 'center',
          component: 'Count',
          hide: true,
        },
      ]
    },
    filteredTableData() {
      if (this.showVendors === showVendorsTypes.All) {
        return this.tableData
      }

      return this.tableData.filter(vendorEntry => {
        return vendorEntry.is_selected || vendorEntry.vendor_payment_amount >= 600
      })
    },
    selectedVendors() {
      return this.filteredTableData.filter(vendorEntry => vendorEntry.is_selected)
    },
    selectByAvailableVendorIds() {
      return this.filteredTableData.map(vendorEntry => vendorEntry.vendor_id)
    }
  },
  methods: {
    async loadData() {
      try {
        this.loading = true
        if (this.tableData.length) {
          this.tableData = []
          this.gridKey++
        }
        await this.loadBatch()
        await this.loadVendors()
        await this.loadBatchEntries()
        this.mapTableData()
      }
      finally {
        this.loading = false
      }
    },
    mapTableData() {
      const tableData = []

      this.vendors.forEach(vendor => {
        const vendorActivity = vendor.relationships?.activities?.find(a => a.attributes.year === this.year)
        const vendor1099Entry = this.vendor1099sEntries.find(v => v.attributes.vendor_id === vendor.id)
        const vendorAmount = Number(vendorActivity?.attributes?.amount || 0)

        const defaultAmounts = this.getDefaultAmounts(vendor, vendorAmount, vendor1099Entry)

        tableData.push({
          _localId: vendor1099Entry?.id ? null : crypto.randomUUID(),
          id: vendor1099Entry?.id,
          vendor,
          vendor_id: vendor.id,
          batch_id: this.batchId,
          vendor_payment_amount: vendorAmount,
          year: this.year,
          ...(vendor1099Entry?.attributes || this.defaultVendor1099Values),
          ...defaultAmounts
        })
      })

      this.tableData = tableData
    },
    async loadVendors() {
      try {
        const { data } = await axios.get('/restify/vendors', {
          params: {
            related: 'activities,contacts',
            sort: 'code',
            print_1099: true,
            perPage: 9999
          }
        })
 
        this.vendors = data

      } catch (error) {
        console.error(error)
      }
    },
    async loadBatch() {
      try {
        if (this.batchId === this.batch?.id) {
          return
        }

        const { data } = await axios.get(`/restify/vendor1099-batches/${this.batchId}`)
        this.$store.commit('accountsPayable/SET_CURRENT_VENDOR_1099_BATCH', data)
      } catch (error) {
        console.error(error)
      }
    },
    async loadBatchEntries() {
      try {
        const { data } = await axios.get(`/restify/vendor1099s`, {
          params: {
            batch_id: this.batchId,
            perPage: 9999
          }
        })
        this.vendor1099sEntries = data
      } catch (error) {
        console.error(error)
      }
    },
    getDefaultAmounts(vendor, vendorAmount, vendor1099Entry) {
      if (!vendorAmount || vendor1099Entry?.id) {
        return {}
      }
      const vendor1099FormType = vendor?.attributes?.form_1099_type
      const formTypeAmountMapping = {
        [vendor1099FormTypes.Attourney]: {
          key: 'attorney_fees_amount',
        },
        [vendor1099FormTypes.FIT]: {
          key: 'fit_amount',
        },
        [vendor1099FormTypes.HealthCare]: {
          key: 'health_care_amount',
        },
        [vendor1099FormTypes.NonEmployeeCompensation]: {
          key: 'non_compensation_amount',
        },
        [vendor1099FormTypes.OtherIncome]: {
          key: 'other_income_amount',
        },
        [vendor1099FormTypes.Rent]: {
          key: 'rent_amount',
        },
        [vendor1099FormTypes.Royalties]: {
          key: 'royalties_amount',
        },
        [vendor1099FormTypes.SIT]: {
          key: 'sit_amount',
        },
        [vendor1099FormTypes.Dividends]: {
          key: 'dividends_amount',
        },
        [vendor1099FormTypes.Interest]: {
          key: 'interest_amount',
        },
        [vendor1099FormTypes.DirectSales]: {
          key: 'direct_sales_amount',
        },
        [vendor1099FormTypes.CropInsurance]: {
          key: 'crop_insurance_amount',
        },
      }

      const key = formTypeAmountMapping[vendor1099FormType]?.key
      if (!key) {
        return {}
      }

      if (vendor1099Entry?.attributes?.[key]) {
        return {
          [key]: vendor1099Entry.attributes[key]
        }
      }

      return {
        [key]: vendorAmount
      }
    },
    async onCellValueChanged(params) {
      const entry = params.data
      entry.total_amount = get1099EntryTotalAmount(entry)

      if (!entry?.id) {
        const vendor1099Entry = await this.createVendor1099Entry(entry)
        entry.id = vendor1099Entry.id
        entry.types = vendor1099Entry?.attributes?.types || []
        entry.misc_amount = vendor1099Entry.attributes?.misc_amount || 0
      }
      else {
        const updatedEntry = await this.updateVendor1099Entry(entry)
        entry.types = updatedEntry?.attributes?.types || []
        entry.misc_amount = updatedEntry?.attributes?.misc_amount || 0
      }

      this.grid?.api.redrawRows({
        rowNodes: [params.node],
      })
    },
    async createVendor1099Entry(entry) {
      try {
        const { data } = await axios.post('/restify/vendor1099s', entry)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async updateVendor1099Entry(entry) {
      try {
        const { data } = await axios.put(`/restify/vendor1099s/${entry.id}`, entry)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async updateSelection(model, addToSelection) {
      const promises = []

      this.grid.api.forEachNode((node) => {
        const params = {
          data: node.data,
          node
        }

        if ((addToSelection && node.data.is_selected) || (!addToSelection && !node.data.is_selected)) {
          return
        }

        if (model.min_payment_amount && node.data.vendor_payment_amount < model.min_payment_amount) {
          return
        }

        if (model.vendor_ids?.length && !model.vendor_ids.includes(node.data.vendor_id)) {
          return
        }

        node.data.is_selected = addToSelection

        promises.push(this.onCellValueChanged(params))
      })

      try {
        this.loading = true

        await Promise.all(promises)

        this.$success(addToSelection
          ? this.$t('Selection added')
          : this.$t('Selection removed')
        )
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Could not change selection'))
      }
      finally {
        this.loading = false
      }
    },
    onPrintPaperForms(row) {
      this.entryToPrint = row
      this.showEntryPrintDialog = true
    },
  },
  beforeMount() {
    this.loadData()
  }
}
</script>
