<template>
  <BaseDataTable
    :key="payrollCalendarYear"
    :columns="columns"
    :is-expandable="true"
    :default-match-filters="defaultMatchFilters"
    :add-entity-in-new-tab="true"
    :open-entity-in-new-tab="false"
    :url-params="urlParams"
    :data-map-function="mapAdjustments"
    :add-text="addText"
    :delete-action="onDelete"
    :delete-description="getDeleteDescription"
    ref="table"
    url="/restify/ytds"
    permission="employees"
    actions="search,refresh,print"
    default-sort="code"
    hide-actions="view,delete"
    base-entity-path="/payroll/adjustments"
    add-entity-url-query="/payroll/adjustments/add"
    @data-fetch="adjustments = $event"
    @edit="onRowEdit"
  >
    <template #additional-actions-before>
      <CalendarYearSelect :show-label="false"/>
    </template>
    <template #employee="{row}">
      <employee-link :data="row"/>
    </template>
    <template #expand-content="{row}">
      <div class="p-4">
        <AdjustmentEntries
          :data="getExpandedRowData(row)"
          :read-only="true"
        />
      </div>
    </template>
  </BaseDataTable>
</template>
<script lang="ts">
  import axios from 'axios'
  import groupBy from 'lodash/groupBy'
  import { defineComponent } from 'vue'
  import { adjustmentTypes } from '@/modules/payroll/pages/adjustment/util'
  import AdjustmentEntries from "@/modules/payroll/components/adjustment/AdjustmentEntries.vue";
  import { TranslateResult } from 'vue-i18n';
  import { Employee } from "@/modules/common/types/models";
  import Data = API.Data;
  import CalendarYearSelect from "@/components/select/entities/CalendarYearSelect.vue";

  export default defineComponent({
    inheritAttrs: false,
    components: {
      CalendarYearSelect,
      AdjustmentEntries,
    },
    props: {
      type: {
        type: String,
        default: adjustmentTypes.EARNINGS,
        required: true,
      },
    },
    data() {
      return {
        adjustments: [],
      }
    },
    computed: {
      addText(): string {
        const labelMapping: Record<string, TranslateResult> = {
          [adjustmentTypes.EARNINGS]: this.$t('New Earning'),
          [adjustmentTypes.DEDUCTIONS]: this.$t('New Deduction'),
          [adjustmentTypes.BENEFITS]: this.$t('New Benefit'),
          [adjustmentTypes.TAXES]: this.$t('New Tax'),
          [adjustmentTypes.W2MEMOS]: this.$t('New W2 Memo'),
          [adjustmentTypes.ALL]: this.$t('New Adjustment'),
        }
        return labelMapping[this.type] as string
      },
      urlParams() {
        const type = this.type === adjustmentTypes.ALL ? undefined : this.type
        return {
          sort: 'employee.code',
          related: 'employee,source,state,local',
          type,
        }
      },
      columns() {
        return [
          {
            label: this.$t('Employee'),
            prop: 'employee',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'Status',
            minWidth: 80,
            maxWidth: 80,
          },
          {
            label: this.$t('Adjustments'),
            prop: 'adjustments_count',
            component: 'Count',
          },
        ]
      },
      payrollCalendarYear() {
        return this.$store.getters['payroll/getAdjustmentsCalendarYear']
      },
      defaultMatchFilters() {
        return {
          having_ytds: true,
          year: this.payrollCalendarYear,
        }
      },
      getDeleteDescription() {
        return this.$tc(`The following action will delete not used ${this.type}-adjustments setup for this employee. This action is irreversible.`)
      },
    },
    methods: {
      getExpandedRowData(row: any) {
        return {
          type: this.type,
          employee_id: row.originalRow?.id,
          year: this.payrollCalendarYear,
        }
      },
      mapAdjustments(employees: Data<Employee>[]) {
        let groups = groupBy(employees, 'attributes.employee_id')
        const result = []

        for (let id in groups) {
          const adjustments = groups[id] || []
          result.push({
            ...adjustments[0].relationships?.employee,
            adjustments_count: adjustments.length,
            data: adjustments,
          })
        }
        return result
      },
      onRowEdit(row: Data<Employee>) {
        const { employee_id } = this.get(row, 'data.[0].attributes', {})

        this.$router.push({
          path: `/payroll/adjustments/${this.type}/${employee_id}/edit`,
          query: {
            employee_id,
          }
        })
      },
      async onDelete(employee: Data<Employee>) {
        try {
          await axios.post(`/restify/employees/${employee.id}/actions?action=delete-adjustments-from-employee`, {
            type: this.type,
          })
        } catch (err) {
          console.warn(err)
        } finally {
          const table = this.$refs.table as any
          table?.refresh({}, true)
        }
      },
    },
  })
</script>
