import axios from "axios";
import orderBy from "lodash/orderBy";
import capitalize from "lodash/capitalize";
import { jobLineItemTypes, masterJobKey, resourceStatuses, uniqueJobTypeAbbreviations, } from "@/enum/enums";
import { globalResources, moduleAccountTypes, storeMutations } from "@/components/form/util";
import sortBy from "lodash/sortBy";
import Cache from '@/utils/Cache';
import { cacheTTL } from "./cacheConfig";
import { JobTypeFor } from "@/modules/job-costing/enum/jobs";

export const jobCostingState = {
  [globalResources.Jobs]: [],
  [globalResources.ClosedOrInactiveJobs]: [],
  [globalResources.JobTypes]: [],
  [globalResources.LineItems]: [],
  [globalResources.IncomeLineItems]: [],
  [globalResources.CostLineItems]: [],
  [globalResources.MasterLineItems]: [],
  [globalResources.PhaseTitles]: [],
  [globalResources.JobCostTypes]: [],
  [globalResources.JobIncomeTypes]: [],
  masterJobId: null,
};

export const jobCostingActions = {
  async getJobs({ commit }, { invalidateCache = false } = {}) {
    const data = await Cache.getRequest('/restify/jobs/list', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        status: resourceStatuses.Open,
      }
    }) || []

    commit(storeMutations.SetResource, { data, resource: globalResources.Jobs })
  },
  async getClosedOrInactiveJobs({ commit }) {
    const data = await Cache.getRequest('/restify/jobs/list', {
      ttl: cacheTTL,
      params: {
        status: `${resourceStatuses.Closed},${resourceStatuses.Inactive}`,
      }
    }) || []

    commit(storeMutations.SetResource, { data, resource: globalResources.ClosedOrInactiveJobs })
  },
  setLineItems({ commit }, data) {
    let costLineItems = []
    let incomeLineItems = []

    data.forEach(lineItem => {
      if (lineItem.type === jobLineItemTypes.Cost) {
        costLineItems.push(lineItem)
      } else {
        incomeLineItems.push(lineItem)
      }
    })

    commit(storeMutations.SetResource, { data, resource: globalResources.LineItems })
    commit(storeMutations.SetResource, { data: costLineItems, resource: globalResources.CostLineItems })
    commit(storeMutations.SetResource, { data: incomeLineItems, resource: globalResources.IncomeLineItems })
  },
  setJobTypes( { commit }, options) {
    let allTypes = []
    let jobCostTypes = []
    let jobIncomeTypes = []

    options.forEach(op => {
      if (op.type === moduleAccountTypes.Cost) {
        jobCostTypes.push(op)
      } else {
        jobIncomeTypes.push(op)
      }

      allTypes.push({
        ...op,
        id: op.id,
        abbr: op.abbr,
        name: op.name,
        index: op.index,
        type_abbr: capitalize(op.type.at(0)),
        type: op.type,
      })
    })

    allTypes = orderBy(allTypes, 'type_abbr', 'desc')

    commit(storeMutations.SetResource, { data: allTypes, resource: globalResources.JobTypes })
    commit(storeMutations.SetResource, { data: jobCostTypes, resource: globalResources.JobCostTypes })
    commit(storeMutations.SetResource, { data: jobIncomeTypes, resource: globalResources.JobIncomeTypes })
    commit('jobCosting/SET_JOB_TYPES', allTypes, { root: true })
  },
};

export const jobCostingGetters = {
  getJobLumpSumBillingTypeId: state => {
    const type = state[globalResources.JobIncomeTypes].find(type => type.for === JobTypeFor.Income.LumpSum) || {}
    return type.id
  },
  getJobUnitPriceBillingTypeId: state => {
    const type = state[globalResources.JobIncomeTypes].find(type => type.for === JobTypeFor.Income.UnitPrice) || {}
    return type.id
  },
  getJobProgressBillingTypeId: state => {
    const type = state[globalResources.JobIncomeTypes].find(type => type.for === JobTypeFor.Income.Progress) || {}
    return type.id
  },
  getJobSubcontractTypeId: state => {
    const type = state[globalResources.JobCostTypes].find(type => type.for === JobTypeFor.Cost.Subcontract) || {}
    return type.id
  },
  getJobCostTypeById: state=> {
    return id => {
      return state[globalResources.JobCostTypes].find(type => type.id === id) || {}
    }
  },
  getJobTypeById: state=> {
    return id => {
      return state[globalResources.JobTypes].find(type => type.id === id) || {}
    }
  },
  getLaborTypeIdByModule: state => (resourceName) => {
    let checkForField = false
    if ([globalResources.JobTypes, globalResources.JobIncomeTypes, globalResources.JobCostTypes].includes(resourceName)) {
      checkForField = true
    }
    const options = state[resourceName] || []
    const type = options.find(op => {
      if (checkForField) {
        return op.for === JobTypeFor.Cost.Labor
      }
      return op.abbr === uniqueJobTypeAbbreviations.Labor
    }) || {}
    return type.id
  },
  getLastJobNumber: state => {
    let jobs = state[globalResources.Jobs] || []
    jobs = sortBy(jobs, 'created_at')
    return jobs.length ? jobs[jobs.length - 1].number : 0
  }
};
