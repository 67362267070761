<template>
  <base-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="availableYears"
    :label="$t('Calendar Year')"
    :name="$t('Calendar Year')"
    :tip="$t('The calendar year based on which the payroll taxes are calculated')"
    id="year"
  />
</template>
<script>
export default {
  computed: {
    payrollCalendarYear() {
      const currentYear = new Date().getFullYear()
      return this.$settings(this.$modules.PR, 'calendar_year') || currentYear
    },
    availableYears() {
      return [
        {
          label: this.payrollCalendarYear,
          value: this.payrollCalendarYear,
        },
        {
          label: this.payrollCalendarYear - 1,
          value: this.payrollCalendarYear - 1,
        },
      ]
    }
  }
}
</script>
