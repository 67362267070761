<template>
  <base-button
      variant="primary-light"
      size="icon"
      class="fixed right-5 bottom-5 z-999999"
      id="to-top-button"
      @click="toTop"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="1.5em"
         height="1.5em">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"/>
    </svg>
  </base-button>
</template>
<script setup>
  function toTop() {
    // * we can't use window scroll because of h-screen style
    document.getElementById('page-header')?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
    const printViewer = document.querySelector('.html-viewer #viewer')
    if (printViewer) {
      printViewer.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
</script>
