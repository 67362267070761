<template>
  <ReleaseRetentionProofListing
      :entity="entity"
      :url="proofListingUrl"
      :postUrl="proofListingPostUrl"
      :urlParams="urlParams"
      :mapPostPayload="mapPostPayload"
      :mapData="mapData"
      :module="modules.AccountsReceivable"
      @on-posted="onPosted"
  />
</template>
<script>
  import groupBy from 'lodash/groupBy'
  import { useStorage } from '@vueuse/core'
  import ReleaseRetentionProofListing
    from '@/modules/common/components/release-retention/ReleaseRetentionProofListing.vue'
  import { costCenterTypes } from '@/components/grid-table/utils/cost-center'
  import { modules } from "@/modules/common/components/settings/util";

  export default {
    components: {
      ReleaseRetentionProofListing,
    },
    setup() {
      const selectedRetentions = useStorage('selected-ar-retentions', {})
      return {
        selectedRetentions,
      }
    },
    data() {
      return {
        modules,
        proofListingUrl: '/restify/customer-retentions/actions?action=release-retention-proof-listing',
        proofListingPostUrl: '/restify/customer-retentions/actions?action=release-retention',
        entity: 'customer-retentions',
      }
    },
    computed: {
      urlLineItems() {
        return Object.values(this.selectedRetentions)
          .map(item => {
            return {
              ...item,
              id: item.line_item_id,
              retention_amount: item.release_retention,
            }
          })
          .filter(item => item.retention_amount > 0)
      },
      urlParams() {
        const groups = groupBy(this.urlLineItems, 'source_id')
        const customers = []
        for (const customerId in groups) {
          const customerLineItems = groups[customerId]
          const firstLineItem = customerLineItems[0]
          const overrides = firstLineItem?.overrides
          customers.push({
            id: customerId,
            line_items: customerLineItems,
            overrides,
          })
        }
        return {
          customers
        }
      },
    },
    methods: {
      resetSelections() {
        this.selectedRetentions = {}
      },
      async onPosted() {
        this.resetSelections()
      },
      getOriginalItem(id) {
        return this.urlLineItems?.find(item => item.id === id) || {}
      },
      getPostPayload(data) {
        const customers = []
        data.jobs.forEach(jobObject => {
          jobObject.customers.forEach((customer) => {
            const row = {
              id: customer.id,
              items: [],
            }

            customer.line_items.forEach(lineItem => {
              const originalItem = this.getOriginalItem(lineItem.id)
              if (originalItem.overrides) {
                row.overrides = originalItem.overrides
              }
              const jobType = this.$store.getters['globalLists/getJobTypeById'](originalItem?.job_type_id)
              row.items.push({
                source_id: originalItem?.job_id,
                type_id: originalItem?.job_type_id,
                type: jobType?.abbr,
                addl_source_id: lineItem.id,
                retention_amount: lineItem.release_retention_amount,
              })
            })

            customers.push(row)
          })
        })
        return {
          customers
        }
      },
      mapPostPayload(payload, data) {
        const {customers} = this.getPostPayload(data)
        payload.customers = customers
        delete payload.repositories
        return payload
      },
      mapData(data) {
        if (!data?.jobs) {
          return []
        }
        return data.jobs.flatMap(jobObject => {
          const job = {
            id: jobObject.id,
            number: jobObject.number,
            description: jobObject.description,
          };

          return jobObject.customers.flatMap(customer => {
            const lineItems = Array.isArray(customer.line_items) ? customer.line_items : Object.values(customer.line_items)

            return lineItems.map(lineItem => ({
              ...lineItem,
              id: undefined,
              _localId: crypto.randomUUID(),
              customer,
              job,
              addl_source_id: lineItem.id,
              invoice_date: this.$now,
              addlSource: lineItem,
              cost_center: costCenterTypes.Job,
            }))
          })
        })
      }
    },
  }
</script>
