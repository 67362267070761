<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Computed'),
            tooltip: this.$t('Payroll registers that were computed but not posted'),
            path: `/payroll/batches/computed`,
            permission: 'payroll_show',
          },
          {
            name: this.$t('Posted'),
            tooltip: this.$t('Posted payroll registers'),
            path: `/payroll/batches/posted`,
            permission: 'payroll_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided payroll registers'),
            path: `/payroll/batches/voided`,
            permission: 'payroll_show',
          },
          {
            name: this.$t('All'),
            tooltip: this.$t('All payroll registers'),
            path: `/payroll/batches/all`,
            permission: 'payroll_show',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
