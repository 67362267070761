import axios from 'axios'
import store from '@/store'
import get from 'lodash/get'

export async function getAccountByNumber(accountNumber = '', fiscalYear) {
  if (!accountNumber) {
    return {}
  }

  const account = store.state.company.accounts.find(a => {
    if (fiscalYear) {
      return a.number === accountNumber && a.fiscal_year === fiscalYear
    }
    return a.number === accountNumber
  })
  if (!account?.id) {
    const { data } = await axios.get('/restify/accounts', {
      params: {
        number: accountNumber,
        fiscal_year: fiscalYear
      }
    })
    if (data.length === 1) {
      return data[0]?.attributes
    }
    return {}
  }

  const { data } = await axios.get(`/restify/accounts/${account.id}`)

  return data.attributes
}

export function getAccountByNumberSync(accountNumber = '') {
  if (!accountNumber) {
    return {}
  }
  return store.state.company.accounts.find(a => a.number === accountNumber)
}

// * Compute account balance by account number
export async function getAccountBalance(accountNumber, period) {
  const account = await getAccountByNumber(accountNumber)

  return computedAccountBalance(account, period)
}

// * Compute account balance by account data
export function computedAccountBalance(account, period) {
  let sum = 0

  for (let i = 0; i <= period; i++) {
    sum += get(account, `p${i}_amount`, 0)
  }
  return sum
}
