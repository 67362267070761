<template>
  <div v-if="showAlert"
       class="border-l-4 p-4 rounded"
       :class="{
            'bg-yellow-50 border-yellow-400': type === $promptType.Warning,
            'bg-green-50 border-green-400': type === $promptType.Success,
            'bg-primary-50 border-primary-400': type === $promptType.Primary,
            'bg-red-50 border-red-400': type === $promptType.Danger,
            'bg-gray-50 border-gray-400': type === $promptType.Light,
         }"
  >
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <svg v-if="type === $promptType.Warning" class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"/>
        </svg>
        <svg v-else-if="type === $promptType.Success" class="h-5 w-5 text-green-400" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"/>
        </svg>
        <svg v-else-if="type === $promptType.Danger" class="h-5 w-5 text-red-400" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"/>
        </svg>
        <svg v-else-if="type === $promptType.Primary" class="h-5 w-5 text-primary-400" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"/>
        </svg>
        <svg v-else-if="type === $promptType.Light" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
             fill="currentColor">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"/>
        </svg>
      </div>
      <div class="ml-3 flex-1" :class="contentWrapperClass">
        <p class="text-sm whitespace-normal leading-5"
           :class="{
                    'text-yellow-700': type === $promptType.Warning,
                    'text-green-700': type === $promptType.Success,
                    'text-primary-700': type === $promptType.Primary,
                    'text-red-700': type === $promptType.Danger,
                    'text-gray-700': type === $promptType.Light,
                 }"
        >
          <slot/>
        </p>
      </div>
      <div v-if="closable"
           class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <div
              role="button"
              class="inline-flex p-1 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600 rounded-md"
              @click="onClose">
            <span class="sr-only">Dismiss</span>
            <IconClose/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'primary',
      },
      contentWrapperClass: String,
      closable: Boolean,
    },
    data() {
      return {
        showAlert: true,
      }
    },
    methods: {
      onClose() {
        this.showAlert = false
      },
    },
  }
</script>
