<template>
  <BaseReportForm
    :report-number="reportNumber"
    :filters="model"
    :can-export-to-csv="true"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Sort & Format')">
        <baseSelect
          v-model="model.sort_by"
          :options="sortByOptions"
          class="col-span-2"
        />

        <baseSelect
          v-model="model.print_by"
          :options="printOptions"
          class="col-span-2"
        />
      </BaseFilterRow>

      <EmployeeRangeFilter
        v-model="model"
        :title="$t('Employees')"
      />

      <BaseFilterRow :title="$t('Accounts')">
        <AccountRangeFilter
          v-model="model"
          class="col-span-4"
        />
      </BaseFilterRow>
      <BaseFilterRow :title="$t('Jobs & Work Orders')">
        <RangeFilter
          :config="RangeFilterConfig.Job"
          :model="model"
          :from.sync="model.job_number_from"
          :to.sync="model.job_number_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Work Orders') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.WorkOrder"
          :model="model"
          :from.sync="model.work_order_number_from"
          :to.sync="model.work_order_number_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Material & Equipment')">
        <RangeFilter
          :config="RangeFilterConfig.MaterialCode"
          :model="model"
          :from.sync="model.material_code_from"
          :to.sync="model.material_code_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Equipment') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.Equipment"
          :model="model"
          :from.sync="model.equipment_code_from"
          :to.sync="model.equipment_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t('Pay & Deduction')">
        <RangeFilter
          :config="RangeFilterConfig.PayCode"
          :codeTypes="[
            $globalResources.PayCodes,
            $globalResources.BenefitCodes,
            $globalResources.TimeOffPolicies
          ]"
          :model="model"
          :from.sync="model.pay_code_from"
          :to.sync="model.pay_code_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Deductions') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.DeductionCode"
          :codeTypes="[
            $globalResources.DeductionCodes,
            $globalResources.TaxCodes,
            $globalResources.FlexibleBenefitPlans,
            $globalResources.RetirementPlans,
          ]"
          :model="model"
          :from.sync="model.deduction_code_from"
          :to.sync="model.deduction_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t(`Union`)">
        <RangeFilter
          :config="RangeFilterConfig.Union"
          :model="model"
          :from.sync="model.union_code_from"
          :to.sync="model.union_code_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Department') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.Department"
          :model="model"
          :from.sync="model.department_code_from"
          :to.sync="model.department_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t(`Worker's Comp & Gen Liab`)">
        <RangeFilter
          :config="RangeFilterConfig.WorkerComp"
          :model="model"
          :from.sync="model.workers_comp_code_from"
          :to.sync="model.workers_comp_code_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Gen Liability') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.GenLiability"
          :model="model"
          :from.sync="model.deduction_code_from"
          :to.sync="model.deduction_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>


      <BaseFilterRow :title="$t(`W/H & Unemployment States`)">
        <RangeFilter
          :config="RangeFilterConfig.WithholdingState"
          :model="model"
          :from.sync="model.withholding_state_code_from"
          :to.sync="model.withholding_state_code_to"
          class="col-span-4"
        />
        <span class="whitespace-nowrap text-sm text-gray-600 font-medium">
          {{ $t('Unemployment') }}
        </span>
        <RangeFilter
          :config="RangeFilterConfig.UIState"
          :model="model"
          :from.sync="model.ui_state_code_from"
          :to.sync="model.ui_state_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <payroll-dates-filter v-model="model"/>
    </template>

    <template #table="{loading, data}">
      <SelectedPayrollHistoryReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import {
  getSelectedHistoryOptions,
  SelectedHistoryPrintBy,
  SelectedHistorySortBy,
  selectionDateFields
} from "@/modules/payroll/components/reports/util";
import PayrollDatesFilter from "@/modules/payroll/components/reports/PayrollDatesFilter.vue";
import { AccountRangeFilter, EmployeeRangeFilter, RangeFilterConfig } from "@/components/range-filters";
import SelectedPayrollHistoryReportTable
  from "@/modules/payroll/components/reports/selected-payroll-history/SelectedPayrollHistoryReportTable.vue";
import RangeFilter from "@/components/range-filters/RangeFilter.vue";

export default {
  components: {
    RangeFilter,
    SelectedPayrollHistoryReportTable,
    AccountRangeFilter,
    EmployeeRangeFilter,
    PayrollDatesFilter
  },
  data() {
    return {
      RangeFilterConfig,
      model: {
        sort_by: SelectedHistorySortBy.Code,
        print_by: SelectedHistoryPrintBy.Detail,
        employee_code_from: null,
        employee_code_to: null,
        account_number_from: null,
        account_number_to: null,
        job_number_from: null,
        job_number_to: null,
        union_code_from: null,
        union_code_to: null,
        department_code_from: null,
        department_code_to: null,
        material_code_from: null,
        material_code_to: null,
        equipment_code_from: null,
        equipment_code_to: null,
        work_order_number_from: null,
        work_order_number_to: null,
        pay_code_from: null,
        pay_code_to: null,
        deduction_code_from: null,
        deduction_code_to: null,
        workers_comp_code_from: null,
        workers_comp_code_to: null,
        general_liability_from: null,
        general_liability_to: null,
        ui_state_code_from: null,
        ui_state_code_to: null,
        withholding_state_code_from: null,
        withholding_state_code_to: null,
        ...selectionDateFields,
      },
      sortByOptions: getSelectedHistoryOptions(),
      printOptions: [
        { label: this.$t('Detail'), value: SelectedHistoryPrintBy.Detail },
        { label: this.$t('Sub Totals Only'), value: SelectedHistoryPrintBy.SubTotalsOnly },
      ],
    }
  },
  computed: {
    reportNumber() {
      return '4875'
    }
  }
}
</script>
