<template>
  <settings-form
    :fields="fields"
    :module-name="moduleName"
    :authorized-to-collapse="true"
    :before-save="mapTableDataToModel"
    grid-classes="grid grid-cols-1"
    @cancel="$router.push('/payroll/employees')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-6 gap-x-3 px-4">
        <h4 class="section-title col-span-6 mb-4">
          {{ $t('Pay Period & Archival Settings') }}
        </h4>
        <div class="col-span-3 lg:col-span-1">
          <BaseSelect
            :value="+model.payroll_period_start_day"
            :label="$t('Payroll Period Start Day')"
            :options="dayOptions"
            rules="required"
            :tip="$t('Day of the week that payroll period starts')"
            @change="model.payroll_period_start_day = $event"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSelect
            :value="model.payroll_period_payment_day"
            :label="$t('Payroll Period Payment Day')"
            :options="dayOptions"
            :tip="$t('Day of the week that payroll is paid')"
            @change="model.payroll_period_payment_day = $event"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseInput
            v-model="model.prune_archived_batches_after_days"
            rules="required|min_value:1"
            :label="$t(`Delete archived batches after`)"
            :placeholder="$t(`Delete archived batches after`)"
            type="number"
          >
            <template #suffix>
              {{ $t('days') }}
            </template>
          </BaseInput>
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch v-model="model.is_biweekly_payroll"
                      :label-info="$t('Bi-Weekly')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.use_same_series_for_dd"
            :label-info="$t('Same payment series for DD')"
          />
        </div>
        <h4 class="section-title col-span-6 mb-4">
          {{ $t('Identification & Banking') }}
        </h4>
        <div class="col-span-3 lg:col-span-1">
          <BaseInput
            v-model="model.federal_employer_id"
            :label="$t('Federal I.D No.')"
            :placeholder="$t('Federal I.D No.')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BankSelect
            v-model="model.default_bank_id"
            :used-for="BankUsedInTypes.Payroll"
            :label="$t('Default Bank')"
            rules="required"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.is_ssn_masked"
            :label-info="$t('Mask SSN')"
          />
        </div>
        <h4 class="section-title col-span-6 mb-4">
          {{ $t('Calculation Methods') }}
        </h4>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.calculate_hours_using_salary"
            :label-info="$t('Calculate hours using Salary')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.calculate_rate_using_salary"
            :label-info="$t('Calculate rate using Salary')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.distribute_benefit_hours"
            :label-info="$t('Distribute Benefits On Hours')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.weighted_average_overtime"
            :label-info="$t('Weighted Average Overtime')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.round_fit_to_the_nearest_dollar"
            :label-info="$t('Round FIT to the nearest Dollar')"
          />
        </div>
        <div class="col-span-6 my-4"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseSwitch
            v-model="model.round_hourly_rates_to_two_decimal_places"
            :label-info="$t('Round hourly rates to two decimal places')"
          />
        </div>
        <h4 class="section-title col-span-6 mb-4 mt-4">
          {{ $t('Pay Code Settings') }}
        </h4>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.is_fringe_build"
            :label-info="$t('Limit Pay Code')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <pay-code-select v-model="model.paycode_for_fringe_build"
                           :disabled="!model.is_fringe_build"
                           :label="$t('Pay Code')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <BaseInput v-model="model.workers_comp_mod_percent"
                      :label="$t(`Worker's Comp Modification %`)"
                      :placeholder="$t(`Worker's Comp Modification %`)"
                      type="number"
                      format="percent"
          />
        </div>
      </div>
    </template>
    <template #overrides="{model}">
      <AgDataTable
        v-bind="tableProps(model)"
        :data="entries"
        class="mt-6 col-span-6"
        ref="gridTable"
      >
      </AgDataTable>
    </template>
  </settings-form>
</template>
<script>
import { BankUsedInTypes, weekDays } from '@/enum/enums';
import SettingsForm from '@/modules/common/components/SettingsForm';
import PayCodeSelect from '@/components/select/entities/PayCodeSelect';
import { cellClasses } from '@/components/ag-grid/columnUtils'
import { editableTableProps } from '@/components/ag-grid/tableUtils'
import { accountCellProps, subAccountCellProps } from '@/components/ag-grid/columns/editableColumns'

export default {
  components: {
    SettingsForm,
    PayCodeSelect,
  },
  data() {
    return {
      entries: [],
      moduleName: 'payroll',
      fields: [
        'LCPtracker',
        'calendar_year',
        'is_ssn_masked',
        'default_bank_id',
        'is_fringe_build',
        'last_calendar_year',
        'federal_employer_id',
        'is_biweekly_payroll',
        'fit_liability_account',
        'fui_liability_account',
        'gen_liability_account',
        'lit_liability_account',
        'sdi_liability_account',
        'sit_liability_account',
        'sui_liability_account',
        'fica_liability_account',
        'fui_ga_expense_account',
        'fui_sb_expense_account',
        'lit_ga_expense_account',
        'lit_sb_expense_account',
        'sui_ga_expense_account',
        'sui_sb_expense_account',
        'fica_ga_expense_account',
        'fica_sb_expense_account',
        'fui_eqp_expense_account',
        'fui_job_expense_account',
        'last_fiscal_year_posted',
        'lit_eqp_expense_account',
        'lit_job_expense_account',
        'sui_eqp_expense_account',
        'sui_job_expense_account',
        'distribute_benefit_hours',
        'fica_eqp_expense_account',
        'fica_job_expense_account',
        'fit_liability_subaccount',
        'round_fit_to_the_nearest_dollar',
        'round_hourly_rates_to_two_decimal_places',
        'fui_liability_subaccount',
        'gen_liability_subaccount',
        'lit_liability_subaccount',
        'paycode_for_fringe_build',
        'payroll_period_start_day',
        'payroll_period_payment_day',
        'sdi_liability_subaccount',
        'sit_liability_subaccount',
        'sui_liability_subaccount',
        'workers_comp_mod_percent',
        'fica_liability_subaccount',
        'fui_ga_expense_subaccount',
        'fui_sb_expense_subaccount',
        'last_fiscal_period_posted',
        'lit_ga_expense_subaccount',
        'lit_sb_expense_subaccount',
        'sui_ga_expense_subaccount',
        'sui_sb_expense_subaccount',
        'weighted_average_overtime',
        'estimated_burden_job_phase',
        'fica_ga_expense_subaccount',
        'fica_sb_expense_subaccount',
        'fui_eqp_expense_subaccount',
        'fui_job_expense_subaccount',
        'is_8_hour_max_certified_job',
        'lit_eqp_expense_subaccount',
        'lit_job_expense_subaccount',
        'medicare_liability_account',
        'sui_eqp_expense_subaccount',
        'sui_job_expense_subaccount',
        'calculate_rate_using_salary',
        'fica_eqp_expense_subaccount',
        'fica_job_expense_subaccount',
        'last_payroll_number_journal_number',
        'medicare_ga_expense_account',
        'medicare_sb_expense_account',
        'calculate_hours_using_salary',
        'employer_benefits_ga_expense_account',
        'medicare_eqp_expense_account',
        'medicare_job_expense_account',
        'estimated_burden_sb_cost_type',
        'medicare_liability_subaccount',
        'only_post_estimated_burden_sb',
        'estimated_burden_eqp_cost_type',
        'estimated_burden_job_cost_code',
        'estimated_burden_job_cost_type',
        'medicare_ga_expense_subaccount',
        'medicare_sb_expense_subaccount',
        'only_post_estimated_burden_eqp',
        'only_post_estimated_burden_job',
        'workers_comp_liability_account',
        'medicare_eqp_expense_subaccount',
        'medicare_job_expense_subaccount',
        'workers_comp_ga_expense_account',
        'workers_comp_sb_expense_account',
        'employer_benefits_ga_expense_subaccount',
        'gen_liability_ga_expense_account',
        'gen_liability_sb_expense_account',
        'workers_comp_eqp_expense_account',
        'workers_comp_job_expense_account',
        'gen_liability_eqp_expense_account',
        'gen_liability_job_expense_account',
        'workers_comp_liability_subaccount',
        'workers_comp_ga_expense_subaccount',
        'workers_comp_sb_expense_subaccount',
        'estimated_burden_sb_expense_account',
        'gen_liability_ga_expense_subaccount',
        'gen_liability_sb_expense_subaccount',
        'workers_comp_eqp_expense_subaccount',
        'workers_comp_job_expense_subaccount',
        'employer_benefits_sb_expense_account',
        'estimated_burden_eqp_expense_account',
        'estimated_burden_job_expense_account',
        'gen_liability_eqp_expense_subaccount',
        'gen_liability_job_expense_subaccount',
        'employer_benefits_eqp_expense_account',
        'employer_benefits_job_expense_account',
        'estimated_burden_sb_liability_account',
        'estimated_burden_eqp_liability_account',
        'estimated_burden_job_liability_account',
        'estimated_burden_sb_expense_subaccount',
        'percent_of_estimated_burden_sb_to_post',
        'employer_benefits_sb_expense_subaccount',
        'estimated_burden_eqp_expense_subaccount',
        'estimated_burden_job_expense_subaccount',
        'percent_of_estimated_burden_eqp_to_post',
        'percent_of_estimated_burden_job_to_post',
        'employer_benefits_eqp_expense_subaccount',
        'employer_benefits_job_expense_subaccount',
        'estimated_burden_sb_liability_subaccount',
        'estimated_burden_eqp_liability_subaccount',
        'estimated_burden_job_liability_subaccount',
        'prune_archived_batches_after_days',
        'dollars_per_hour_of_estimated_burden_sb_to_post',
        'dollars_per_hour_of_estimated_burden_eqp_to_post',
        'dollars_per_hour_of_estimated_burden_job_to_post',
        'is_burden_charged_to_specified_phase_and_cost_code',
        'use_same_series_for_dd',
      ],
    }
  },
  computed: {
    BankUsedInTypes() {
      return BankUsedInTypes
    },
    dayOptions() {
      return weekDays
    },
    accountsData() {
      return [
        {
          id: 1,
          account: this.$t('Employer Benefits'),
          job_account: 'employer_benefits_job_expense_account',
          job_subaccount: 'employer_benefits_job_expense_subaccount',
          service_billing_account: 'employer_benefits_sb_expense_account',
          service_billing_subaccount: 'employer_benefits_sb_expense_subaccount',
          equipment_account: 'employer_benefits_eqp_expense_account',
          equipment_subaccount: 'employer_benefits_eqp_expense_subaccount',
          ga_account: 'employer_benefits_ga_expense_account',
          ga_subaccount: 'employer_benefits_ga_expense_subaccount',
        },
        {
          id: 2,
          account: this.$t('Fed W/Hold'),
          liability_account: 'fit_liability_account',
          liability_subaccount: 'fit_liability_subaccount',
        },
        {
          id: 3,
          account: this.$t('Social Security'),
          liability_account: 'fica_liability_account',
          liability_subaccount: 'fica_liability_subaccount',
          job_account: 'fica_job_expense_account',
          job_subaccount: 'fica_job_expense_subaccount',
          service_billing_account: 'fica_sb_expense_account',
          service_billing_subaccount: 'fica_sb_expense_subaccount',
          equipment_account: 'fica_eqp_expense_account',
          equipment_subaccount: 'fica_eqp_expense_subaccount',
          ga_account: 'fica_ga_expense_account',
          ga_subaccount: 'fica_ga_expense_subaccount',
        },
        {
          id: 4,
          account: this.$t('Medicare'),
          liability_account: 'medicare_liability_account',
          liability_subaccount: 'medicare_liability_subaccount',
          job_account: 'medicare_job_expense_account',
          job_subaccount: 'medicare_job_expense_subaccount',
          service_billing_account: 'medicare_sb_expense_account',
          service_billing_subaccount: 'medicare_sb_expense_subaccount',
          equipment_account: 'medicare_eqp_expense_account',
          equipment_subaccount: 'medicare_eqp_expense_subaccount',
          ga_account: 'medicare_ga_expense_account',
          ga_subaccount: 'medicare_ga_expense_subaccount',
        },
        {
          id: 5,
          account: this.$t('Federal Unemployment'),
          liability_account: 'fui_liability_account',
          liability_subaccount: 'fui_liability_subaccount',
          job_account: 'fui_job_expense_account',
          job_subaccount: 'fui_job_expense_subaccount',
          service_billing_account: 'fui_sb_expense_account',
          service_billing_subaccount: 'fui_sb_expense_subaccount',
          equipment_account: 'fui_eqp_expense_account',
          equipment_subaccount: 'fui_eqp_expense_subaccount',
          ga_account: 'fui_ga_expense_account',
          ga_subaccount: 'fui_ga_expense_subaccount',
        },
        {
          id: 6,
          account: this.$t('State W/Hold'),
          liability_account: 'sit_liability_account',
          liability_subaccount: 'sit_liability_subaccount',
        },
        {
          id: 7,
          account: this.$t('State Disability'),
          liability_account: 'sdi_liability_account',
          liability_subaccount: 'sdi_liability_subaccount',
        },
        {
          id: 8,
          account: this.$t('State Unemployment'),
          liability_account: 'sui_liability_account',
          liability_subaccount: 'sui_liability_subaccount',
          job_account: 'sui_job_expense_account',
          job_subaccount: 'sui_job_expense_subaccount',
          service_billing_account: 'sui_sb_expense_account',
          service_billing_subaccount: 'sui_sb_expense_subaccount',
          equipment_account: 'sui_eqp_expense_account',
          equipment_subaccount: 'sui_eqp_expense_subaccount',
          ga_account: 'sui_ga_expense_account',
          ga_subaccount: 'sui_ga_expense_subaccount',
        },
        {
          id: 9,
          account: this.$t('Local W/Hold'),
          liability_account: 'lit_liability_account',
          liability_subaccount: 'lit_liability_subaccount',
          job_account: 'lit_job_expense_account',
          job_subaccount: 'lit_job_expense_subaccount',
          service_billing_account: 'lit_sb_expense_account',
          service_billing_subaccount: 'lit_sb_expense_subaccount',
          equipment_account: 'lit_eqp_expense_account',
          equipment_subaccount: 'lit_eqp_expense_subaccount',
          ga_account: 'lit_ga_expense_account',
          ga_subaccount: 'lit_ga_expense_subaccount',
        },
        {
          id: 10,
          account: this.$t(`Worker's Comp`),
          liability_account: 'workers_comp_liability_account',
          liability_subaccount: 'workers_comp_liability_subaccount',
          job_account: 'workers_comp_job_expense_account',
          job_subaccount: 'workers_comp_job_expense_subaccount',
          service_billing_account: 'workers_comp_sb_expense_account',
          service_billing_subaccount: 'workers_comp_sb_expense_subaccount',
          equipment_account: 'workers_comp_eqp_expense_account',
          equipment_subaccount: 'workers_comp_eqp_expense_subaccount',
          ga_account: 'workers_comp_ga_expense_account',
          ga_subaccount: 'workers_comp_ga_expense_subaccount',
        },
        {
          id: 11,
          account: this.$t(`General Liability`),
          liability_account: 'gen_liability_account',
          liability_subaccount: 'gen_liability_subaccount',
          job_account: 'gen_liability_job_expense_account',
          job_subaccount: 'gen_liability_job_expense_subaccount',
          service_billing_account: 'gen_liability_sb_expense_account',
          service_billing_subaccount: 'gen_liability_sb_expense_subaccount',
          equipment_account: 'gen_liability_eqp_expense_account',
          equipment_subaccount: 'gen_liability_eqp_expense_subaccount',
          ga_account: 'gen_liability_ga_expense_account',
          ga_subaccount: 'gen_liability_ga_expense_subaccount',
        },
      ]
    },
    additionalAccountCellProps() {
      return {
        ...accountCellProps,
        cellClass: this.getCellClass,
        editable: this.isCellEditable,
      }
    },
    additionalSubAccountCellProps() {
      return {
        ...subAccountCellProps,
        cellClass: this.getCellClass,
        editable: this.isCellEditable,
      }
    },
    getColumns() {
      let columns = [
        {
          headerName: this.$t('Liability Account'),
          field: 'liability_account',
        },
        {
          headerName: this.$t('Sub'),
          field: 'liability_subaccount',
        },
        {
          headerName: this.$t('Job Expense Account'),
          field: 'job_account',
        },
        {
          headerName: this.$t('Sub'),
          field: 'job_subaccount',
        },
        {
          headerName: this.$t('S/B Expense Account'),
          field: 'service_billing_account',
        },
        {
          headerName: this.$t('Sub'),
          field: 'service_billing_subaccount',
        },
        {
          headerName: this.$t('Equipment Expense Account'),
          field: 'equipment_account',
        },
        {
          headerName: this.$t('Sub'),
          field: 'equipment_subaccount',
        },
        {
          headerName: this.$t('G&A Expense Account'),
          field: 'ga_account',
        },
        {
          headerName: this.$t('Sub'),
          field: 'ga_subaccount',
        },
      ]

      columns = columns.map(column => {

        if (column.field.includes('subaccount')) {
          column = {
            ...column,
            ...this.additionalSubAccountCellProps,
          }
        } else {
          column = {
            ...column,
            ...this.additionalAccountCellProps,
          }
        }

        return column
      })

      columns.splice(0, 0, {
        headerName: this.$t('Defaults'),
        field: 'account',
        minWidth: 160,
        maxWidth: 220,
        cellClass: cellClasses.ReadOnly,
      })

      return columns
    },
  },
  methods: {
    getCellClass(params) {
      const field = params.colDef.field
      const rowIndex = params.rowIndex

      return (this.get(this.entries, `[${rowIndex}][${field}]`, undefined) === undefined) ? cellClasses.ReadOnly : false
    },
    isCellEditable(params) {
      const field = params.colDef.field
      return params.data[field] !== undefined
    },
    tableProps(model) {
      let entries = this.accountsData.map(row => {
        let newRow = {}
        for (let key in row) {
          const value = row[key]
          if (key === 'id' || key === 'account') {
            newRow[key] = value
          } else {
            newRow[key] = model[value]
          }
        }
        return newRow
      })

      if (this.entries.length === 0) {
        this.entries = entries
      }

      return {
        ...editableTableProps,
        columns: this.getColumns,
      }
    },
    mapTableDataToModel(model) {

      this.entries.forEach((row, index) => {
        for (let rowKey in row) {
          if (rowKey === 'id' || rowKey === 'account') {
            continue
          }
          let value = row[rowKey]
          let modelKey = this.accountsData[index][rowKey]

          if (model[modelKey] !== undefined) {
            this.$set(model, modelKey, value)
          }
        }
      })
      return true
    },
  },
}
</script>
