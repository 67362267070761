<template>
  <div
    class="h-[80vh] max-h-full flex justify-center items-center mt-4"
  >
    <img
      v-if="isImage"
      :src="data?.attributes?.url"
      :alt="data?.attributes?.name"
      class="max-h-full"
    >
    <div v-else-if="isIframePreview" class="h-full w-full">
      <iframe
        v-if="fileUrl || localUrl"
        :src="fileUrl || localUrl"
        :title="data?.attributes?.name"
        class="h-full w-full"
      />
      <div
        v-else
        v-loading="true"
        class="h-full w-full items-center justify-center"
      />
    </div>
    <iframe
      v-else-if="isDocPreview"
      :src="officePreviewUrl"
      class="w-full h-full"
      allowfullscreen
    />
    <div v-else-if="isInvalidType" class="mt-2">
      <span>{{ $t('Unfortunately this file type is not supported and cannot be downloaded') }}</span>
    </div>
    <div v-else class="w-full flex flex-col items-center justify-center text-gray-700 font-medium mb-20">
      <BaseButton variant="gray-light" class="mb-1" @click="downloadFile">
        <DownloadIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Download') }}</span>
      </BaseButton>
      <span class="mt-2">{{ $t('Unfortunately this file type is not supported for preview.') }}</span>
      <span>{{ $t('Click ') }} <button
        class="text-primary hover:underline"
        @click="downloadFile"
      >{{ $t('here') }}</button> {{ $t('to download the file') }}</span>
    </div>

  </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, watch} from "vue"
import {DownloadIcon} from 'vue-feather-icons'
import {docTypes, Document, fileTypes, getFileType, isAcceptedFile, previewTypes} from "./documentUtils"
import {useRoute, useRouter} from "vue2-helpers/vue-router";
import axios from "axios";
import Data = API.Data;

const props = defineProps({
  data: {
    type: Object as PropType<Data<Document>>,
    default: () => ({})
  }
})

const localUrl = ref('')
const router = useRouter()
const route = useRoute()

const fileType = computed(() => {
  return getFileType(props.data?.attributes?.file_name)
})
const isImage = computed(() => {
  return fileType.value === fileTypes.Image
})

const isIframePreview = computed(() => {
  return previewTypes.includes(fileType.value as string)
})

const fileUrl = computed(() => {
  if (fileType.value === fileTypes.Csv) {
    return null
  }
  return props.data?.attributes?.url
})

const isDocPreview = computed(() => {
  return docTypes.includes(fileType.value as string)
})

const officePreviewUrl = computed(() => {
  let url = props.data?.attributes?.url
  url = encodeURIComponent(url)
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
})

const isInvalidType = computed(() => {
  return !isAcceptedFile({name: props.data?.attributes?.file_name} as File)
})

watch(() => fileType.value, (newFileType) => {
  if (newFileType === fileTypes.Csv) {
    downloadFileLocally()
  }
}, {immediate: true})

async function downloadFileLocally() {
  const url = props.data?.attributes?.url
  if (url?.startsWith('blob')) {
    localUrl.value = url
    return
  }
  const instance = axios.create()
  const res = await instance.get(url, {
    responseType: 'blob',
  })
  localUrl.value = window.URL.createObjectURL(new Blob([res.data]))
}

function downloadFile() {
  const url = props.data?.attributes?.url
  window.open(url, '_blank')
}
</script>
