<template>
  <div>
    <TableAddButton
        :text="$t('Add new Interval')"
        variant="secondary"
        size="xs"
        class="pr-2"
        @click="showAddDialog = true"
    />
    <BaseFormDialog
        v-if="showAddDialog"
        :title="$t('Add new Interval')"
        :open.sync="showAddDialog"
        :append-to-body="true"
        size="xl"
    >
      <base-form
          layout="modal"
          :loading="loading"
          @submit="submitForm"
      >
        <base-date-picker
            v-model="model.start_date"
            :label="$t('Start Date')"
            :picker-options="datePickerOptions"
            id="date"
            class="col-span-6 md:col-span-2"
            rules="required"
        />
        <AgDataTable
            :url="getUrl"
            :entries="rates"
            :columns="getColumns"
            :url-params="getUrlParams"
            :transform-data="mapData"
            class="col-span-6"
            domLayout="autoHeight"
            ref="ratesTable"
        />
      </base-form>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import addDays from 'date-fns/addDays'
import { ratesWithStartDate } from '@/modules/payroll/components/rates/util'
import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'
import { ratesColumns } from '@/modules/payroll/components/rates/add-start-date-util'
import { dateTypes } from '@/plugins/dateFormatPlugin'

export default {
  props: {
    entityRepo: {
      type: String,
      default: 'union-rates',
    },
    lastStartDate: {
      type: String,
      default: new Date(),
    },
  },
  data() {
    return {
      datePickerOptions: {
        disabledDate: (date) => {
          return date < new Date(this.lastStartDate)
        },
      },
      rates: [],
      loading: false,
      showAddDialog: false,
      model: {
        start_date: addDays(new Date(this.lastStartDate), 1),
      },
    }
  },
  computed: {
    getColumns() {
      const filteredColumns = this.entityRepo ? ratesColumns[this.entityRepo] : []
      return [
        ...filteredColumns,
        {
          ...getDeleteColumn({
            url: '',
            onConfirm: this.onDelete,
            minWidth: 65,
            maxWidth: 65,
          })
        }
      ]
    },
    getUrl() {
      return `/restify/${this.entityRepo}`
    },
    getUrlParams() {
      let params = {
        end_date: null,
        perPage: 999,
      }
      if (this.entityRepo === ratesWithStartDate.Unions) {
        params = {
          ...params,
          union_id: this.$route.params.id,
        }
      }
      if (this.entityRepo === ratesWithStartDate.Jobs) {
        params = {
          ...params,
          job_id: this.$route.params.id,
        }
      }

      return params
    },
    getEntriesIds() {
      return this.rates.map(rate => rate.id)
    },
  },
  methods: {
    onDelete({rowIndex}) {
      this.rates.splice(rowIndex, 1)
    },
    mapData(data) {
      this.rates = data.map(rate => rate.attributes)
      return this.rates
    },
    async submitForm() {
      try {
        this.loading = true
        await this.addNewRates()
        this.$emit('refresh', this.model.start_date)
        this.showAddDialog = false
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
        this.$refs.ratesTable?.refresh()
      }
    },
    async addNewRates() {
      const newRates = this.rates.map(rate => {
        delete rate.created_at
        delete rate.updated_at
        delete rate.id
        return {
          ...rate,
          start_date: this.model.start_date
        }
      })

      await axios.post(`/restify/${this.entityRepo}/bulk`, newRates)
    },
  },
}
</script>
