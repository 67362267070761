<template>
  <div class="mb-4">
    <AgDataTable
        dom-layout="autoHeight"
        url="/restify/timecards"
        ref="table"
        v-bind="$attrs"
        :key="renderKey"
        :actions="actions"
        :add-text="$t('New employee timecard')"
        :url-params="urlParams"
        :columns="columns"
        :masterDetail="true"
        :detailRowAutoHeight="true"
        :detailCellRendererParams="detailCellRendererParams"
        :detailCellRenderer="detailCellRenderer"
        :transformData="transformData"
        :actions-column-width="actionColumnWidth"
        :get-row-class="getRowClass"
        suppressColumnReordering
        @data-fetch="onDataFetch"
        @grid-ready="grid = $event"
    >
      <template #additional-actions>
        <BulkAddTimecardEntry
          v-if="!readOnly"
          class="mr-2"
          :days="days"
          @save="onRefresh"
        />
        <TableSyncButton
          class="mr-2"
          @click="syncTimeCardBatch"
        />
      </template>
      <template #extra-actions="{row, index}">
        <template v-if="canUpdateBatch">
          <table-edit-button
              v-if="canEditTimeCard(row) && readOnly"
              :openEntityInNewTab="true"
              :row="row"
              :link="getEditUrl(row)"
              class="flex items-center mt-1"
          />
          <base-button
              v-if="$isAuthorized('authorizedToUpdate', row)"
              variant="gray-light"
              :title="$t('Open new timecard')"
              :tabindex="0"
              size="icon"
              @click="openNewTimeCard(row)"
          >
            <PlusIcon class="w-4 h-4"/>
          </base-button>
          <table-delete-button
              v-if="$isAuthorized('authorizedToDelete', row) && row"
              @click="onDeleteTimeCard(row, index)"
          />
        </template>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
  import { delay } from '@/utils/utils'
  import TimeCardEntries from "@/modules/payroll/components/timecard/TimeCardEntries";
  import { getTotals } from "@/modules/payroll/utils/timeCardUtils";
  import axios from "axios";
  import { PlusIcon } from 'vue-feather-icons'
  import { isAuthorized } from "@/plugins/actionAuthorization";
  import TableSyncButton from "@/components/table/actions/TableSyncButton.vue";
  import BulkAddTimecardEntry from "@/modules/payroll/components/timecard/BulkAddTimecardEntry.vue";

  export default {
    components: {
      BulkAddTimecardEntry,
      TableSyncButton,
      PlusIcon,
      TimeCardEntries,
    },
    props: {
      days: {
        type: Array,
        default: () => [],
      },
      readOnly: {
        type: Boolean,
      }
    },
    data() {
      return {
        detailCellRenderer: null,
        grid: null,
        timeCardExpanded: false,
        renderKey: 1,
      }
    },
    computed: {
      actionColumnWidth() {
        if (this.readOnly) {
          return 140
        }
        return 100
      },
      actions() {
        const actions = ['search', 'refresh']
        if (this.readOnly && this.canUpdateBatch) {
          actions.push('add')
        }
        return actions.join(',')
      },
      urlParams() {
        return {
          related: 'employee,entries',
          sort: 'employee.code',
          timecard_batch_id: this.$route.params.id,
        }
      },
      columns() {
        return [
          {
            label: 'Employee',
            prop: 'employee.code',
            cellRenderer: 'agGroupCellRenderer',
            valueFormatter: params => {
              const { code, name } = params.data.employee
              if (!code && !name) {
                return `${this.$t('Employee not found')} #${params.data.number}`
              }
              return `${code} (${name}) #${params.data.number}`
            }
          },
          {
            label: 'Status',
            prop: 'employee.status',
            component: 'Status',
            minWidth: 90,
            maxWidth: 100,
          },
          {
            label: 'Pay Frequency',
            prop: 'employee.pay_frequency',
            component: 'Status',
            minWidth: 90,
            maxWidth: 100,
          },
          {
            label: 'Regular Hours',
            prop: 'totals.regular_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Overtime Hours',
            prop: 'totals.overtime_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Premium Hours',
            prop: 'totals.premium_hours',
            minWidth: 50,
            maxWidth: 90,
          },
          {
            label: 'Total Hours',
            prop: 'totals.total_hours',
            minWidth: 70,
            maxWidth: 210,
          },
          {
            label: 'Entries',
            prop: 'entries_count',
            minWidth: 70,
            maxWidth: 120,
          },
        ]
      },
      timeCardBatch() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      canUpdateBatch() {
        return isAuthorized('authorizedToUpdate', this.timeCardBatch)
      },
      detailCellRendererParams() {
        return {
          days: this.days,
          parentGrid: this.grid,
          onUpdateTimeCard: this.onUpdateTimeCard,
          readOnly: this.readOnly,
          onRefresh: this.onRefresh,
        }
      }
    },
    methods: {
      getRowClass(params) {
        const row = params.data
        if (!row) {
          return
        }
        if (row?.entries_count === 0) {
          return '!bg-orange-100'
        }
      },
      onRefresh() {
        this.$refs.table.refresh()
      },
      async syncTimeCardBatch() {
        const timeCards = this.$store.state.payroll.currentTimecardBatchTimecards || []
        const hasAdjustments = timeCards.some(t => t.payroll_has_adjustments)
        if (hasAdjustments) {
          const confirmed = await this.$deleteConfirm({
            title: this.$t('Payroll adjustments will be lost.'),
            description: this.$t('This action will sync the timecard batch with the latest timecard entries. Note that this action will overwrite any manual adjustments made to the timecards.'),
            buttonText: this.$t('Sync Timecards'),
          })
          if (!confirmed) {
            return
          }
        }
        const batchId = this.$route.params.id
        await axios.post(`/restify/timecard-batches/${batchId}/actions?action=sync-timecard-batch`)
        this.onRefresh()
      },
      transformData(data) {
        const mappedData = data.map(timeCard => {
          let entries = timeCard?.relationships?.entries || []
          let employee = timeCard?.relationships?.employee?.attributes || {}

          let totals = getTotals(entries)
          entries = entries.map(entry => {
            return {
              id: entry.id,
              ...entry.attributes,
            }
          })
          return {
            id: timeCard.id,
            ...timeCard.attributes,
            totals,
            entries,
            employee,
            entries_count: entries.length,
          }
        })
        this.$store.commit('payroll/SET_CURRENT_TIMECARD_BATCH_TIMECARDS', mappedData)
        return mappedData
      },
      async onDataFetch(data) {
        if (this.timeCardExpanded) {
          return
        }
        const timecardId = this.$route.params?.timecardId
        const index = data.findIndex(data => data.id === timecardId)
        if (index === -1) {
          return
        }
        await this.$nextTick()
        if (!this.grid) {
          // The grid is not ready yet. Happens when data is fetched from cache
          await delay(50)
        }
        this.grid.api?.getDisplayedRowAtIndex(index)?.setExpanded(true);
        this.timeCardExpanded = true
      },
      onUpdateTimeCard(timeCard, params) {
        for (let key in timeCard) {
          const value = timeCard[key]
          if (params.data[key] !== value) {
            params.data[key] = value
          }
        }
        params.node.setData(params.data)
      },
      async onDeleteTimeCard(row) {
        const confirmed = await this.$deleteConfirm({
          title: this.$t(`Delete Timecard`),
          description: this.$t(`The following action will remove the timecard along with it's entries from the system. Note that this action is irreversible.`),
          buttonText: this.$t(`Delete`),
        })
        if (!confirmed) {
          return
        }
        await axios.delete(`/restify/timecards/${row.id}`)
        await this.$store.dispatch('payroll/resetTimecardBatchToPending')
        this.grid.api.applyTransaction({ remove: [row] })

        this.$emit('refresh-employees')
      },
      getEditUrl(timeCard) {
        return `/payroll/timecard-batches/${this.$route.params.id}/timecards/${timeCard.id}/edit`
      },
      canEditTimeCard(timeCard) {
        return isAuthorized('authorizedToUpdate', timeCard) && timeCard
      },
      async openNewTimeCard(timeCard) {
        try {
          const confirmed = await this.$confirm({
            title: this.$t('Open New Time Card'),
            description: this.$t('This action will open a new time card for the selected employee with a different pay number.'),
          })
          if (!confirmed) {
            return
          }
          const { timecard_batch_id, employee_id } = timeCard || {}
          await axios.post(`/restify/timecard-batches/${timecard_batch_id}/actions?action=open-new-timecard`, {
            employee_id,
          })
          await this.$store.dispatch('payroll/resetTimecardBatchToPending')
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not open a new timecard'))
        }
      },
    },
    created() {
      this.detailCellRenderer = 'TimeCardEntries';
    },
  }
</script>
