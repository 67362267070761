<template>
  <ContactSelect
    ref="select"
    v-focus
    v-bind="params"
    v-model="selectedValue"
    :value-key="params.valueKey"
    :contactable-id="params.contactableId"
    :parent-entity-name="params.parentEntityName"
    :fetch-on-mount="false"
    :ignore-remote-search="true"
    inline-errors
    class="w-full"
    @entity-created="onEntityCreated"
    @change="onChange"
    @keydown.enter.native="goToNextCell"
  />
</template>
<script>
import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";

export default {
  name: 'ContactSelectCellEditor',
  mixins: [selectEditorMixin],
}
</script>
