<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Format')">

        <BaseSelect
          v-model="model.format"
          :options="formatOptions"
          class="col-span-3"
          @change="onFormatChanged"
        />

        <div
          v-if="employeeSummary"
          class="col-span-2 flex items-baseline"
        >
          <span class="text-label">{{ $t('State') }}</span>
          <StateSelect
            v-model="model.entry_state"
            :placeholder="$t('Select State')"
            class="flex-1"
            clearable
          />
        </div>
      </BaseFilterRow>
      <BaseFilterRow
        v-if="csv_export"
        :title="$t('Separate ROTH')"
      >
        <BaseSwitch
          v-model="model.separate_ROTH"
          class="col-span-3"
        />

      </BaseFilterRow>

      <BaseFilterRow
        v-if="is125s401kFormat"
        :title="$t('Include')"
      >
        <BaseSelect
          v-model="model.include"
          :options="includeOptions"
          :name="$t('Include')"
          :placeholder="$t('Include')"
          class="col-span-3"
          rules="required"
        />
      </BaseFilterRow>

      <EmployeeRangeFilter
        v-model="model"
        title="Employees"
      />

      <PayrollDatesFilter
        v-model="model"
        :report-format="model.format"
      />

      <BaseFilterRow
        v-if="employeeSummary"
        :title="$t('Include Pending Payrolls')">

        <BaseSwitch
          v-model="model.include_pending"
          class="col-span-3"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="is125s401kFormat"
        :title="$t('Print Detail')"
      >
        <BaseSwitch
          v-model="model.print_detail"
          class="col-span-3"
        />
      </BaseFilterRow>


      <BaseFilterRow
        v-if="is125s401kFormat"
        :title="$t('Export')"
      >
        <BaseSwitch
          v-model="csv_export"
          :labelInfo="$t('Export to CSV')"
          inline
          class="col-span-3"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ data, loading }">
      <div v-if="csv_export">
        <TextFileList
          :data="data?.fileData ? [data.fileData] : []"
          :loading="loading"
          :file-name="data.fileName"
        />
      </div>
      <component
        v-else
        :is="getReportTable"
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import { EmployeeRangeFilter } from '@/components/range-filters'
import PayrollDatesFilter from '@/modules/payroll/components/reports/PayrollDatesFilter'
import {
  historyByEmployeeFormats,
  selectionDateFields,
  historyByEmployee125S401KIncludeOptions,
} from '@/modules/payroll/components/reports/util'
import PayrollHistoryByEmployeeReportTable from '@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistoryByEmployeeReportTable'
import PayrollHistoryRegisterRecapReportTable from '@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistoryRegisterRecapReportTable'
import PayrollHistory125S401KReportTable from '@/modules/payroll/components/reports/payroll-history-by-employee/PayrollHistory125S401KReportTable'
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";

export default {
  components: {
    PayrollDatesFilter,
    EmployeeRangeFilter,
    PayrollHistoryByEmployeeReportTable,
    PayrollHistoryRegisterRecapReportTable,
    PayrollHistory125S401KReportTable,
    TextFileList,
  },
  data() {
    return {
      csv_export: false,
      model: {
        format: historyByEmployeeFormats.PeriodSummary,
        entry_state: null,
        employee_code_from: null,
        employee_code_to: null,
        include_pending: false,
        print_detail: true,
        include: historyByEmployee125S401KIncludeOptions.All,
        separate_ROTH: false,
        ...selectionDateFields,
      },
      formatOptions: [
        {
          label: this.$t('Employee Period Summary'),
          value: historyByEmployeeFormats.PeriodSummary,
        },
        {
          label: this.$t('Payroll Register Recap'),
          value: historyByEmployeeFormats.RegisterRecap,
        },
        {
          label: this.$t('125S & 401K Plan Summary'),
          value: historyByEmployeeFormats.Both401KAnd125S,
        },
        {
          label: this.$t('125S Plan Summary Only'),
          value: historyByEmployeeFormats.Only125S,
        },
        {
          label: this.$t('401K Plan Summary Only'),
          value: historyByEmployeeFormats.Only401K,
        },
      ],
    }
  },
  computed: {
    is125s401kFormat() {
      return [
        historyByEmployeeFormats.Both401KAnd125S,
        historyByEmployeeFormats.Only125S,
        historyByEmployeeFormats.Only401K,
      ].includes(this.model.format)
    },
    includeOptions() {
      const withLabels = {
        [historyByEmployeeFormats.Both401KAnd125S]: this.$t('125S & 401K Only'),
        [historyByEmployeeFormats.Only125S]: this.$t('125S Only'),
        [historyByEmployeeFormats.Only401K]: this.$t('401K Only'),
      }

      return [
        {
          label: withLabels[this.model.format] || this.$t('With Only'),
          value: historyByEmployee125S401KIncludeOptions.With,
        },
        {
          label: this.$t('All Employees'),
          value: historyByEmployee125S401KIncludeOptions.All,
        },
      ]
    },
    employeeSummary() {
      return this.model.format === historyByEmployeeFormats.PeriodSummary
    },
    reportNumber() {
      if (this.csv_export) {
        return '4872be'
      }

      const reportNumbers = {
        [historyByEmployeeFormats.PeriodSummary]: '4872c',
        [historyByEmployeeFormats.RegisterRecap]: '4872f',
        default: '4872b',
      }
      return reportNumbers[this.model.format] || reportNumbers.default
    },
    getReportTable() {
      const reportTables = {
        [historyByEmployeeFormats.PeriodSummary]: PayrollHistoryByEmployeeReportTable,
        [historyByEmployeeFormats.RegisterRecap]: PayrollHistoryRegisterRecapReportTable,
        default: PayrollHistory125S401KReportTable,
      }

      return reportTables[this.model.format] || reportTables.default
    },
  },
  methods: {
    onFormatChanged() {
      this.csv_export = false
      this.model.separate_ROTH = false
    },
  },
}
</script>
