<template>
  <div>
    <base-alert v-if="!employeeId"
                :type="$promptType.Danger"
                class="col-span-6 mb-3">
      {{
        $t(`We couldn't find employee information associated to your account. Please contact your company admin for more information.`)
      }}
    </base-alert>
    <PayrollBatchEmployeeSummary
      :my-checks="true"
      :expand="false"
    />
    <BaseDataTable
      v-if="false"
      :columns="columns"
      :url-params="urlParams"
      :row-select-disabled="rowSelectDisabled"
      url="/restify/payrolls"
      actions="search,refresh"
      ref="table"
    >
      <template #extra-actions="{row}">
        <div>
          <DownloadChecksDialog
            :payroll="getDownloadPayroll(row)"
            :batch="{
              id: row?.attributes?.payroll_batch_id
            }"
          />
        </div>
      </template>

      <template #employee="{row}">
        <EmployeeLink :data="get(row, 'relationships.employee')"/>
      </template>

      <template #email="{row}">
        <div class="flex items-center h-8">
          <template v-if="getEmail(row) && !row.editing">
            <WarningTip v-if="isEmployeeConsentRequired && !getEmployee(row).payroll_consent_sent_at"
                        :message="$t('There was no consent email sent for this employee yet')"
            />
            <BaseEmailLink :value="getEmail(row)"/>
          </template>
          <span v-else>

          </span>
        </div>
      </template>

      <template #attributes.payment_number="{row}">
        <div class="flex justify-between">
          <PaymentTypeIcon :type="row.attributes.type" class="w-4 h-4"/>
          <span>{{ row.attributes.payment_number }}</span>
        </div>
      </template>

    </BaseDataTable>

  </div>
</template>
<script>
import CheckPreviewDialog from "@/modules/payroll/components/CheckPreviewDialog";
import EmployeeChecksTable from "@/modules/payroll/components/EmployeeChecksTable.vue";
import DownloadChecksDialog from "@/modules/payroll/components/payroll/DownloadChecksDialog.vue";
import PaymentTypeIcon from "@/modules/payroll/components/payroll/PaymentTypeIcon.vue";
import ConfirmEmailSendDialog from "@/components/common/modal/ConfirmEmailSendDialog.vue";
import CheckTemplateSelect from "@/modules/payroll/components/CheckTemplateSelect.vue";
import WarningTip from "@/modules/payroll/components/WarningTip.vue";
import SendPayrollChecksForm from "@/modules/payroll/components/checks/SendPayrollChecksForm.vue";
import { resourceStatuses } from "@/enum/enums";
import PayrollBatchEmployeeSummary from "@/modules/payroll/components/payroll/PayrollBatchEmployeeSummary.vue";

export default {
  components: {
    PayrollBatchEmployeeSummary,
    SendPayrollChecksForm,
    WarningTip,
    CheckTemplateSelect,
    ConfirmEmailSendDialog,
    PaymentTypeIcon,
    DownloadChecksDialog,
    CheckPreviewDialog,
    EmployeeChecksTable,
  },
  data() {
    return {
      showCheckPreviewDialog: false,
      selectedIndex: -1,
      rowToEdit: null,
      downloading: false,
    }
  },
  computed: {
    employeeId() {
      return this.$store.state.auth.user?.employee?.id
    },
    url() {
      return `/restify/payrolls`
    },
    columns() {
      return [
        {
          label: this.$t('Employee'),
          prop: 'employee',
          maxWidth: 200,
        },
        {
          label: this.$t('Email'),
          prop: 'email',
          minWidth: 200,
          maxWidth: 270,
        },
        {
          label: this.$t('Check Pay'),
          prop: 'attributes.totals.check_pay',
          maxWidth: 130,
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('DD Pay'),
          prop: 'attributes.totals.direct_deposit_pay',
          maxWidth: 130,
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Net Pay'),
          prop: 'attributes.totals.net_pay',
          maxWidth: 130,
          component: 'FormattedPrice',
          align: 'right',
        },
        {
          label: this.$t('Payment Date'),
          prop: 'attributes.payment_date',
          maxWidth: 150,
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Payment Number'),
          prop: 'attributes.payment_number',
          maxWidth: 200,
        },
        {
          label: this.$t('Period Ending'),
          prop: 'attributes.period_end_date',
          maxWidth: 150,
          align: 'center',
          component: 'FormattedDate',
        },
      ]
    },
    urlParams() {
      return {
        only_my_checks: true,
        related: 'employee',
      }
    },
    isPosted() {
      return this.payrollBatch?.attributes?.status === resourceStatuses.Posted
    },
    isEmployeeConsentRequired() {
      return !!this.$store.getters['company/isEmployeeConsentRequired']
    },
  },
  methods: {
    getEmployee(row) {
      return this.get(row, 'relationships.employee.attributes', {})
    },
    getEmail(row) {
      return this.get(row, 'relationships.employee.attributes.email')
    },
    rowSelectDisabled(row) {
      return this.getEmail(row) === null
    },
    getDownloadPayroll(row) {
      return {
        payroll: row?.attributes,
        employee: row?.relationships?.employee?.attributes || {}
      }
    },
  },
}
</script>
