import { codeLabels, codeTypes } from "@/modules/payroll/components/rates/util";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import store from "@/store";
import {ValueSetterParams} from "@ag-grid-community/core";

export function getSpecialCodeTypeOptions(costCenter = costCenterTypes.GeneralAndAdministrative) {
  const hasMaterialOrEquipment =  [costCenterTypes.Job, costCenterTypes.WorkOrder].includes(costCenter)
  let options = [
    {
      label: codeLabels[codeTypes.PAY],
      value: codeTypes.PAY,
    },
    {
      label: codeLabels[codeTypes.BEN],
      value: codeTypes.BEN,
    },
    {
      label: codeLabels[codeTypes.DEDUCTION],
      value: codeTypes.DEDUCTION,
    },
    {
      label: codeLabels[codeTypes.TIME_OFF],
      value: codeTypes.TIME_OFF,
    },
    {
      label: codeLabels[codeTypes.RETIREMENT_PLAN],
      value: codeTypes.RETIREMENT_PLAN,
    },
    {
      label: codeLabels[codeTypes.FLEX_BEN],
      value: codeTypes.FLEX_BEN,
    },
    {
      label: codeLabels[codeTypes.TAX],
      value: codeTypes.TAX,
    },
  ]
  if (!hasMaterialOrEquipment) {
    return options
  }
  return [
    {
      label: codeLabels[codeTypes.EQUIPMENT],
      value: codeTypes.EQUIPMENT,
    },
    {
      label: codeLabels[codeTypes.MATERIAL],
      value: codeTypes.MATERIAL,
    },
    ...options,
  ]
}

export function getSpecialCodeTypeLabel(type: string) {
  return codeLabels[type]
}

export function computeSpecialPayUnits(params: ValueSetterParams) {
  if (params?.data?.special_source_type === codeTypes.EQUIPMENT) {
    const { regular_hours, overtime_hours, premium_hours } = params.data
    params.data.units = +regular_hours + +overtime_hours + +premium_hours
  }
}

export function findOptionLabel(options: any[], value: string) {
  const option = options.find(option => option.value === value)
  return option ? option.label : value
}

export function findGlobalResource(resourceName: string, value: any, valueKey: string) {
  const resourceOptions = store.getters['globalLists/getResourceList'](resourceName) || []
  return resourceOptions.find((option: any) => option[valueKey] === value) || {}
}

export function valueSetterWithUpdate({field, storeAction, getEntity, path = 'attributes'}: { field?: string, storeAction: string, path: string, getEntity?: (id: string) => any }) {
  return (params: ValueSetterParams) => {
    let colDefField = params.colDef.field || ''
    path = path || 'attributes'
    colDefField = colDefField.replace(`${path}.`, '')
    field = field || colDefField
    if (params.newValue === params.oldValue) {
      return true
    }
    params.data[path][field] = params.newValue
    if (storeAction) {
      store.dispatch(storeAction, {
        id: params.data.id,
        [field]: params.newValue,
        [`old_${field}`]: params.oldValue
      }).then(async () => {
        if (getEntity) {
          const entity = await getEntity(params.data.id)
          params.node?.setData({
            ...params.data,
            ...(entity.data || {})
          })
        }
        params.api?.redrawRows({ rowNodes: [params.node] })
      })
    }
    return true
  }
}
