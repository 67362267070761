<template>
  <div>
    <div :title="$t('Address')"
         class="detail-row mb-2">
      <div class="flex items-center">
        <MapPinIcon class="detail-icon"/>
        <span class="mx-1">{{$t('Address: ')}}</span>
      </div>
      <BaseAddressLink :value="address"/>
    </div>
    <iframe
      v-if="!sourceLoading && address"
      v-loading="sourceLoading"
      class="overflow-hidden" width="100%" height="300" frameborder="0" scrolling="no" marginheight="0"
      marginwidth="0"
      :src="addressSrc"
    />
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { formattedAddress } from "@/utils/utils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import axios from "axios";
import { MapPinIcon } from "vue-feather-icons";
import BaseAddressLink from "@/components/common/BaseAddressLink.vue";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  costCenter: {
    type: String,
  }
})

const sourceWithAddress = ref()
const sourceLoading = ref(false)

async function getSourceWithAddress() {
  const urlMap = {
    [costCenterTypes.WorkOrder]: '/restify/work-sites',
    [costCenterTypes.Job]: '/restify/jobs',
    [costCenterTypes.Equipment]: '/restify/equipment',
    [costCenterTypes.Material]: '/restify/materials',
  }
  const partialUrl = urlMap[props.costCenter]
  if (!partialUrl) {
    return
  }
  let itemId = props.item.id
  if (isWorkOrder.value) {
    itemId = props.item?.work_site_id
  }

  const url = `${partialUrl}/${itemId}?related=address`
  try {
    sourceLoading.value = true
    const { data } = await axios.get(url, {
      ignore404: true,
    })
    sourceWithAddress.value = data
  } catch (err) {
    console.warn(err)
  } finally {
    sourceLoading.value = false
  }
}

const isWorkOrder = computed(() => {
  return props.costCenter === costCenterTypes.WorkOrder
})

const address = computed(() => {
  if (isWorkOrder.value) {
    return sourceWithAddress.value
  }
  return sourceWithAddress.value?.relationships?.address
})

const addressText = computed(() => {
  return formattedAddress(address.value, isWorkOrder.value)
})


const addressSrc = computed(() => {
  if (!address.value) {
    return
  }
  return `https://maps.google.com/maps?q=${addressText.value}&z=16&output=embed`
})

onMounted(() => {
  getSourceWithAddress()
})

</script>
