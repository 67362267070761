<template>
  <div class="h-full">
    <JournalList
      v-if="module"
      :module="module"
      :title="getModuleTitle"
      :module-code="getModuleCode"
    />
    <div v-else
         class="w-full py-16 shadow-lg bg-white rounded font-medium leading-5 flex justify-center">
      {{ $t('No Journal Entries for this module') }}
    </div>
  </div>
</template>
<script>
  import { journalTypes } from '@/enum/journal'
  import { moduleBasePatches } from '@/enum/enums'
  import JournalList from '@/modules/common/components/journal/JournalList'

  const mapResources = {
    [moduleBasePatches.GeneralLedger]: {
      code: journalTypes.GL,
    },
    [moduleBasePatches.Equipment]: {
      code: journalTypes.Equipment,
    },
    [moduleBasePatches.JobCosting]: {
      code: journalTypes.JobCosting,
    },
    [moduleBasePatches.Payroll]: {
      code: journalTypes.Payroll,
    },
    [moduleBasePatches.AccountsPayable]: {
      code: `${journalTypes.AccountsPayable},${journalTypes.CashDisbursement}`,
    },
    [moduleBasePatches.AccountsReceivable]: {
      code: `${journalTypes.AccountsReceivable},${journalTypes.CashReceipts}`,
    },
    [moduleBasePatches.PurchasingInventory]: {
      code: `${journalTypes.Inventory},${journalTypes.PurchaseOrder}`,
    },
    [moduleBasePatches.ServiceBilling]: {
      code: journalTypes.ServiceBilling,
    },
    default: '',
  }

  export default {
    components: {
      JournalList,
    },
    data() {
      return {
        module: journalTypes.JobCosting,
      }
    },
    computed: {
      getModuleTitle() {
        const { title } = mapResources[this.module]
        return this.$t(title)
      },
      getModuleCode() {
        const { code } = mapResources[this.module]
        return code
      },
    },
    watch: {
      '$route.meta': {
        immediate: true,
        handler(meta) {
          this.module = meta.module
        },
      },
    },
  }
</script>
