<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="dialogTitle"
    :append-to-body="true"
    :size="dialogSize"
  >
    <BaseForm
      layout="modal"
      :saveText="$t('Print')"
      :cancelText="printResult ? $t('Back') : $t('Cancel')"
      :showCancel="true"
      :loading="loading"
      :focusOnFirstInput="false"
      :canSubmitForm="!printResult"
      :submitDisabled="!canSubmit"
      :submitTooltip="submitTooltip"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <PdfPreview
        v-show="printResult"
        :file="printResult"
        :loading="loading"
        class="col-span-full"
      />
      <div
        v-show="!printResult"
        class="col-span-full"
      >
        <div class="flex space-x-2 items-center justify-between col-span-full mb-4 text-gray-700 text-sm">
          <div>
            <span class="font-semibold">{{ $t('Tax Year') }}</span>
            <span class="ml-2">{{ tax_year }}</span>
          </div>
          <div v-if="vendor">
            <span class="font-semibold">{{ $t('Vendor') }}</span>
            <span class="ml-2">{{ vendorDescription }}</span>
          </div>
        </div>
        <Vendor1099PrintPaperFormsOptions
          v-model="model"
          :tax_year="tax_year"
          :showVendorRangeFilter="false"
          :filterableVendorIds="filterableVendorIds"
          :defaultCopies="[
            Vendor1099sReportOptions.Copies.B,
            Vendor1099sReportOptions.Copies.Two,
          ]"
          class="col-span-full"
          inputColSpanClass="col-span-2 md:col-span-6 xl:col-span-10"
        />
        <div
          v-if="!_1099_entry_ids.length"
          class="col-span-6"
        >
          <h4 class="mb-4">
            {{ $t(`Select Vendors (Leave selection blank to print all ${data?.length || 0} Vendor 1099s below)`) }}
          </h4>
          <AgDataTable
            :pagination="false"
            :tooltipShowDelay="0"
            :data="data"
            :columns="columns"
            :selected-rows.sync="selectedRows"
            domLayout="autoHeight"
          />
        </div>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import axios from 'axios'
import {
  Vendor1099sReportOptions
} from '@/modules/accounts-payable/components/reports/util'
import Vendor1099PrintPaperFormsOptions from '@/modules/accounts-payable/components/vendor1099/Vendor1099PrintPaperFormsOptions.vue'
import PdfPreview from "@/components/common/PdfPreview.vue";
import i18n from "@/i18n";

export default {
  components: {
    Vendor1099PrintPaperFormsOptions,
    PdfPreview,
  },
  props: {
    tax_year: {
      type: [Number, String],
      required: true
    },
    batch_id: {
      type: String,
      default: null,
    },
    _1099_entry_ids: {
      type: Array,
      default: () => [],
    },
    vendor: {
      type: Object,
      default: null,
    },
    showVendorRangeFilter: {
      type: Boolean,
      default: true,
    },
    filterableVendorIds: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      printResult: null,
      Vendor1099sReportOptions,
      model: {
        tax_year: this.tax_year,
        template_type: Vendor1099sReportOptions.TemplateTypes["1099"],
        form_types: [
          Vendor1099sReportOptions.FormTypes.NEC,
          Vendor1099sReportOptions.FormTypes.MISC,
        ],
        copies: [],
        vendor_code_from: null,
        vendor_code_to: null,
      },
      selectedRows: [],
    }
  },
  computed: {
    dialogTitle() {
      if (this.model.template_type === Vendor1099sReportOptions.TemplateTypes["1096"]) {
        return this.$t('Print 1096 Paper Forms')
      }
      return this.$t('Print 1099 Paper Forms')
    },
    dialogSize() {
      if (this.printResult) {
        return 'xl'
      }

      if (this._1099_entry_ids?.length) {
        return 'md'
      }

      return 'lg'
    },
    vendorDescription() {
      if (!this.vendor) {
        return ''
      }

      return `${this.vendor.attributes.name} (${this.vendor.attributes.code})`
    },
    columns() {
      return [
        {
          headerName: i18n.t('Vendor'),
          field: 'vendor.id',
          minWidth: 400,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => {
            return params.data.types?.length
          },
          component: 'VendorLink',
        },
        {
          headerName: i18n.t('Payment Amount'),
          field: 'vendor_payment_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('Form Types'),
          field: 'types',
          minWidth: 140,
          maxWidth: 160,
          valueFormatter: (params) => {
            if (!params.value?.length) {
              return 'None'
            }

            const formTypeLabels = {
              [Vendor1099sReportOptions.FormTypes.MISC]: 'MISC',
              [Vendor1099sReportOptions.FormTypes.NEC]: 'NEC',
            }
            return (params.value || []).map((x) => formTypeLabels[x]).join(', ')
          },
          cellClass: (params) => {
            if (!params.value?.length) {
              return 'text-red-500'
            }

            return ''
          },
          tooltipValueGetter: (params) => {
            if (!params.value?.length) {
              return i18n.t('No payments reported on 1099-MISC or 1099-NEC')
            }

            return null
          },
        },
        {
          headerName: i18n.t('NEC Amount'),
          field: 'non_compensation_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('MISC Amount'),
          field: 'misc_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 160,
        },
        {
          headerName: i18n.t('1099s Total Amount'),
          field: 'total_amount',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
          cellClass: (params) => {

            if (Number(params.data.total_amount) !== Number(params.data.vendor_payment_amount)) {
              return 'text-red-500'
            }

            return ''
          },
          tooltipValueGetter: (params) => {
            if (Number(params.value) !== Number(params.data.vendor_payment_amount)) {
              return i18n.t('Total Amount does not match Vendor Payment Amount')
            }

            return null
          },
        },
      ]
    },
    entriesToPrint() {
      if (this.selectedRows.length) {
        return this.selectedRows
      }

      return this.data
    },
    hasMISCVendors() {
      return this.entriesToPrint.some((x) => x.types.includes(Vendor1099sReportOptions.FormTypes.MISC))
    },
    hasNECVendors() {
      return this.entriesToPrint.some((x) => x.types.includes(Vendor1099sReportOptions.FormTypes.NEC))
    },
    canSubmit() {
      if (!this.model.form_types.length || !this.entriesToPrint.length) {
        return false
      }

      if (this.model.form_types.length === 2) {
        return this.hasMISCVendors || this.hasNECVendors
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.MISC) {
        return this.hasMISCVendors
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.NEC) {
        return this.hasNECVendors
      }

      return true
    },
    submitTooltip() {
      if (this.canSubmit) {
        return ''
      }

      if (!this.model.form_types.length) {
        return this.$t('Please select at least one Form Type.')
      }

      if (!this.entriesToPrint.length) {
        return this.$t('No Vendor 1099s to print.')
      }

      if (this.model.form_types.length === 2 && !this.hasMISCVendors && !this.hasNECVendors) {
        return this.$t('No Vendors with payments reported on 1099-MISC or 1099-NEC forms in selection.')
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.MISC && !this.hasMISCVendors) {
        return this.$t('No Vendors with payments reported on the 1099-MISC form in selection.')
      }

      if (this.model.form_types[0] === Vendor1099sReportOptions.FormTypes.NEC && !this.hasNECVendors) {
        return this.$t('No Vendors with payments reported on the 1099-NEC form in selection.')
      }
    }
  },
  methods: {
    async onSubmit() {
      if (this.printResult) {
        this.printResult = null
        return
      }

      try {

        this.loading = true
        const data = {
          ...this.model
        }

        if (this.batch_id) {
          data.batch_id = this.batch_id
        }

        if (this._1099_entry_ids?.length) {
          data.repositories = this._1099_entry_ids
        }

        if (this.selectedRows?.length) {
          data.repositories = this.selectedRows.map((x) => x.id)
        }

        if (data.repositories?.length) {
          delete data.batch_id
        }

        let postUrl = '/restify/vendor1099s/actions?action=print-vendor-1099s'
        if (this.model.template_type === Vendor1099sReportOptions.TemplateTypes["1096"]) {
          postUrl = '/restify/vendor1099s/actions?action=print-vendor-1096'
        }
        const result = await axios.post(postUrl, data, {
          responseType: 'blob'
        })

        this.printResult = result
      } finally {
        this.loading = false
      }
    },
    onCancel() {
      if (this.printResult) {
        this.printResult = null
        return
      }

      this.$emit('close')
    }
  }
}
</script>
