<template>
  <div>
    <BaseTooltip :content="$t('Bulk add timecard entry')" :tabindex="-1">
      <BaseButton
        :loading="loading"
        variant="gray-icon"
        size="xs"
        @click="showDialog = true">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="w-5 h-5 text-gray-500">
          <line x1="15" x2="15" y1="12" y2="18"/>
          <line x1="12" x2="18" y1="15" y2="15"/>
          <rect width="14" height="14" x="8" y="8" rx="2" ry="2"/>
          <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"/>
        </svg>
      </BaseButton>
    </BaseTooltip>
    <BaseFormDialog
      v-if="showDialog"
      :title="$t('Bulk add timecard entry')"
      :open.sync="showDialog"
      :loading="loading"
      :append-to-body="true"
      size="xl"
    >
      <BaseForm
        layout="modal"
        :save-text="$t('Add timecard entry')"
        :submit-disabled="selectedEmployees.length === 0"
        @submit="onSubmit"
      >
        <h5 class="form-section-title col-span-6 my-2">
          {{ $t('Timecard Entry') }}
        </h5>
        <AgDataTable
          :data="data"
          :compact="true"
          :columns="columns"
          :show-pagination="false"
          :pagination="false"
          domLayout="autoHeight"
          class="col-span-6"
          @cell-value-changed="onCellValueChanged"
          @cell-focused="onCellFocused"
          @grid-ready="grid = $event"
        >

          <template #date="{row}">
            <TimecardEntryDate :row="row" :days="days"/>
          </template>
        </AgDataTable>
        <h5 class="form-section-title col-span-6 mt-6 mb-2">
          {{ $t('Select Employees') }}
          <template v-if="selectedEmployees.length">
            <span class="text-sm text-gray-600 ml-1">
              ({{ selectedEmployees.length }} {{ $t('selected') }})
            </span>
          </template>
        </h5>
        <AgDataTable
          url="/restify/timecards"
          actions="refresh,search"
          :selected-rows.sync="selectedEmployees"
          :url-params="urlParams"
          :columns="employeeColumns"
          class="col-span-6"
        >

        </AgDataTable>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>

import { GridIcon } from "vue-feather-icons"
import { costCenterTypes, setTypeSources } from "@/components/grid-table/utils/cost-center";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
import {
  additionalSourceCol,
  costCenterCol,
  hoursCol,
  sourceCol,
  specialUnitsCol,
  typeCol,
  updateCostCenterHeaderNames
} from "@/components/ag-grid/columns/costCenterColumns";
import { computeSpecialPayUnits, getSpecialCodeTypeLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import { hourFields } from "@/modules/common/util/costCenterUtils";
import { getSpecialSourceOption } from "@/components/grid-table/utils/cost-center-cell";
import { getCostCenterRowDefaults, getTypeByCostCenter } from "@/modules/payroll/utils/timeCardUtils";
import TimecardEntryDate from "@/modules/payroll/components/timecard/TimecardEntryDate.vue";
import axios from "axios";
import { getTableData } from "@/components/ag-grid/tableUtils";

export default {
  components: {
    TimecardEntryDate,
    GridIcon
  },
  props: {
    days: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      grid: null,
      selectedEmployees: [],
      data: [this.getEmptyEntry()]
    }
  },
  computed: {
    urlParams() {
      return {
        sort: 'employee.code',
        timecard_batch_id: this.$route.params.id,
      }
    },
    employeeColumns() {
      return [
        {
          label: 'Employee',
          prop: 'attributes.employee_id',
          component: 'EmployeeLink',
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
      ]
    },
    columns() {
      return [
        {
          headerName: this.$t('Day'),
          field: 'date',
          minWidth: this.readOnly ? 150 : 120,
          maxWidth: this.readOnly ? 120 : 180,
          cellEditor: cellEditors.BaseSelect,
          cellEditorParams: {
            options: this.days,
          },
          required: true,
          editable: true,
          valueSetter: requiredValueSetter,
        },
        {
          ...costCenterCol,
          valueSetter: params => {
            params.data.cost_center = params.newValue
            params.data = getCostCenterRowDefaults(params.data)
            params.node.setData(params.data)
            return true
          }
        },
        {
          ...sourceCol,
        },
        {
          ...typeCol,
        },
        {
          ...additionalSourceCol(),
        },
        {
          ...hoursCol,
          field: 'regular_hours',
          headerName: 'Regular Hours',
        },
        {
          ...hoursCol,
          field: 'premium_hours',
          headerName: 'Premium Hours',
        },
        {
          ...hoursCol,
          field: 'overtime_hours',
          headerName: 'Overtime Hours',
        },
        {
          field: 'special_source_type',
          headerName: 'Special Type',
          minWidth: 60,
          maxWidth: 150,
          editable: true,
          cellEditor: cellEditors.SpecialCodeType,
          valueGetter: params => {
            return getSpecialCodeTypeLabel(params.data?.special_source_type)
          },
          valueSetter: params => {
            params.data.special_source_id = ''
            params.data.units = 0
            params.data.special_source_type = params.newValue
            if (!params.newValue) {
              params.data.special_rate = 0
              params.data.special_account = null
            }
            computeSpecialPayUnits(params)
            params.node.setData(params.data)
            return true
          },
          suppressKeyboardEvent: params => {
            let isTabKey = params.event.key === 'Tab';
            if (isTabKey && !params.data.special_source_type) {
              params.api.stopEditing();
            }
          }
        },
        {
          field: 'special_source_id',
          headerName: 'Special Code',
          minWidth: 120,
          maxWidth: 180,
          editable: params => {
            return !!params.data.special_source_type
          },
          suppressNavigable: params => {
            return !params.data.special_source_type
          },
          component: 'SpecialSourceLink',
          cellRendererParams: {
            target: '_blank',
          },
          cellEditor: cellEditors.SpecialCode,
          cellClass: params => {
            const hasValue = params.data?.special_source_id
            if (!hasValue && params.data?.special_source_type) {
              return cellClasses.Invalid
            }
            if (!params.data?.special_source_type) {
              return cellClasses.ReadOnly
            }
            return ''
          },
          valueSetter: params => {
            params.data.special_source_id = params.newValue
            const option = getSpecialSourceOption(params.data)

            if (params.data.special_source_type === codeTypes.EQUIPMENT) {
              params.data.special_rate = option?.standard_job_cost_hourly_rate || 0
            }
            if (params.data.special_source_type === codeTypes.MATERIAL) {
              params.data.special_rate = option?.standard_unit_rate || 0
            }
            if (!params.newValue) {
              params.data.special_account = null
            }
            return true
          },
        },
        {
          ...specialUnitsCol,
        },
      ]
    }
  },
  methods: {
    getEmptyEntry() {
      return {
        source_id: null,
        source_type: null,
        addl_source_id: null,
        addl_source_type: null,
        special_source_id: null,
        special_source_type: null,
        type_id: null,
        type_type: null,

        cost_center: costCenterTypes.GeneralAndAdministrative,
        date: this.days[0]?.value,
        regular_hours: 0,
        overtime_hours: 0,
        premium_hours: 0,
      }
    },
    async onCellValueChanged(params) {
      const field = params?.colDef?.field

      if (hourFields.includes(field)) {
        computeSpecialPayUnits(params)
      }
    },
    onCellFocused(params) {
      updateCostCenterHeaderNames(params)
    },
    getTimeCardRow() {
      const data = getTableData(this.grid.api)
      const row = data[0] || {}
      return setTypeSources(row)
    },
    async onSubmit() {
      try {
        this.loading = true
        const batchId = this.$route.params.id
        const employees = this.selectedEmployees.map(e => e.attributes.employee_id)
        const timeCardEntry = this.getTimeCardRow()
        await axios.post(`/restify/timecard-batches/${batchId}/actions?action=add-timecard-entry`, {
          ...timeCardEntry,
          employees,
        })
        this.$success(this.$t(`Timecard entry added to ${employees.length} employees`))
        this.resetData()
        this.$emit('save')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Failed to add the timecard entry'))
      } finally {
        this.loading = false
      }
    },
    resetData() {
      this.data = [this.getEmptyEntry()]
      this.selectedEmployees = []
      this.showDialog = false
    }
  }
}
</script>
