<template>
  <div class="h-full">
    <AgDataTable
      :url="url"
      :columns="columns"
      :url-params="urlParams"
      :default-match-filters="defaultMatchFilters"
      :add-entity-in-new-tab="addEntityInNewTab"
      :add-text="$t(`New customer`)"
      :per-page="$pagination.list"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :disable-col-flex="true"
      import-url="/accounts-receivable/customers/import"
      actions="search,refresh,view,import,export"
      permission="customers"
      entity="customers"
      ref="table"
      :deleteCustom="true"
      @delete="itemToDelete = $event; showDeleteDialog = true"
      @add="$router.push('/accounts-receivable/customers/add')"
    >
      <template #attributes.code="{row}">
        <CustomerLink :data="getCustomer(row)" :show-name="false"/>
      </template>
    </AgDataTable>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.Customers"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import {ValueSetterParams} from "@ag-grid-community/core";
  import {valueSetterWithUpdate} from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import {cellEditors} from "@/components/ag-grid/cellEditors/cellEditors";
  import {cellClasses} from "@/components/ag-grid/columnUtils";
  import {CustomerStatusOptions} from "@/modules/accounts-receivable/models/customer";
  import Data = API.Data;
  import {Customer} from "@/modules/common/types/models";
  import {getDefaultMatchFilter} from "@/components/ag-grid/filterUtils";

  export default defineComponent({
    data() {
      return {
        url: '/restify/customers',
        urlParams: {
          related: 'addresses,activities',
        },
        addEntityInNewTab: true,
        showDeleteDialog: false,
        itemToDelete: null,
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Name'),
            field: 'attributes.name',
            minWidth: 200,
            maxWidth: 350,
            editable: true,
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params: ValueSetterParams) => {
              return valueSetterWithUpdate({
                storeAction: 'accountsReceivable/patchCustomer',
              })(params)
            }
          },
          {
            headerName: this.$t('Phone'),
            minWidth: 150,
            maxWidth: 200,
            field: 'attributes.phone',
            component: 'PhoneLink',
          },
          {
            headerName: this.$t('Email'),
            field: 'attributes.email',
            component: 'EmailLink',
            minWidth: 160,
            maxWidth: 320,
          },
          {
            headerName: this.$t('Address'),
            field: 'addresses',
            minWidth: 120,
            maxWidth: 200,
            component: 'PrimaryAddressLink',
            hide: true,
          },
          {
            headerName: this.$t('Status'),
            field: 'attributes.status',
            minWidth: 90,
            maxWidth: 120,
            component: 'Status',
            editable: true,
            cellEditor: cellEditors.Status,
            cellEditorParams: {
              options: CustomerStatusOptions,
            },
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params: ValueSetterParams) => {
              return valueSetterWithUpdate({
                storeAction: 'accountsReceivable/patchCustomer',
              })(params)
            },
          },
          {
            headerName: this.$t('Last Payment Date'),
            field: 'attributes.last_payment_date',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            headerName: this.$t('Open Amount'),
            field: 'attributes.current_ar_amount',
            minWidth: 100,
            maxWidth: 180,
            component: 'FormattedPrice',
          },
          {
            headerName: this.$t('Retention'),
            field: 'attributes.retention_amount',
            minWidth: 100,
            maxWidth: 180,
            component: 'FormattedPrice',
            hide: true,
          },
        ]
      },
      defaultMatchFilters() {
        return getDefaultMatchFilter('show_inactive_customers')
      },
    },
    methods: {
      getCustomer(row: Data<Customer>) {
        return {
          ...(row.attributes || {}),
          ...(row.relationships || {}),
        }
      },
      onItemDeleted() {
        this.showDeleteDialog = false
        this.$refs.table?.refresh()
      },
    }
  })
</script>
